import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { riskModuleTypes } from "@app2/risk/constants";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { RiskModuleType } from "@app2/type-defs/risk/risk-types";
import { SKU_RISK_THIRD_PARTY_RISK, SKU_RISK_INFORMATION_SECURITY, SKU_RISK_SYSTEMS_AND_APPLICATIONS } from "@app2/type-defs/user/user-types";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RiskService {
    private readonly skuToModule: Record<string, RiskModuleType> = {
        [SKU_RISK_INFORMATION_SECURITY]: RiskModuleType.INFORMATION_SECURITY,
        [SKU_RISK_SYSTEMS_AND_APPLICATIONS]: RiskModuleType.SYSTEMS_AND_APPLICATIONS,
        [SKU_RISK_THIRD_PARTY_RISK]: RiskModuleType.THIRD_PARTY_RISK,
    };
    private allModules: RiskModuleType[] = riskModuleTypes;
    private purchasedModules: RiskModuleType[] = [];

    private selectedModules$ = new ReplaySubject<RiskModuleType[]>(1);
    private selectedModules: RiskModuleType[] = [];

    constructor(private orgsService: OrgsService,
                private storageService: StorageService) {
        const purchasedModules = [];
        this.orgsService.getCurrentOrg().productSkus
            .forEach(sku => {
                if (this.skuToModule[sku]) {
                    purchasedModules.push(this.skuToModule[sku]);
                }
            });
        this.purchasedModules = purchasedModules.sort();

        if (this.purchasedModules.length > 0) {
            const selectedModules = this.storageService.get(Keys.selectedRiskModules);
            if (selectedModules) {
                this.selectedModules = JSON.parse(selectedModules);
                this.selectedModules$.next(this.selectedModules);
            } else {
                this.selectModule(this.purchasedModules[0], true);
            }
        } else {
            this.selectModule(RiskModuleType.INFORMATION_SECURITY, true);
        }
    }

    public getPurchasedModules(): RiskModuleType[] {
        return [...this.purchasedModules];
    }

    public getNonPurchasedModules(): RiskModuleType[] {
        return this.allModules.filter((module) => !this.purchasedModules.includes(module));
    }

    public getSelectedModules$(): Observable<RiskModuleType[]> {
        return this.selectedModules$.asObservable();
    }

    public isModuleSelected$(module: RiskModuleType): Observable<boolean> {
        return this.getSelectedModules$()
            .pipe(map(modules => modules.includes(module)));
    }

    /**
     * Parameter `select` can be used to select or deselect the module.
     */
    public selectModule(module: RiskModuleType, select: boolean): void {
        // If the module we want to select is not purchased, or we were previewing a non-purchased module, then replace
        // the current module selection by the new one
        const replaceSelection = !this.purchasedModules.includes(module) ||
            this.selectedModules.some(module => !this.purchasedModules.includes(module));

        if (replaceSelection) {
            this.selectedModules = [module];
        } else {
            // Can't deselect a module if it's the only one selected
            if (!select && this.selectedModules.length === 1) {
                return;
            }

            this.selectedModules = select
                ? [...this.selectedModules, module]
                : this.selectedModules.filter(m => m !== module);
        }
        this.selectedModules$.next(this.selectedModules);
        this.storageService.set(Keys.selectedRiskModules, JSON.stringify(this.selectedModules));
    }

    public selectModules(modules: RiskModuleType[]): void {
        if (modules.length < 1 || modules.some(module => !this.purchasedModules.includes(module))) return;

        this.selectedModules = modules;
        this.selectedModules$.next(this.selectedModules);
        this.storageService.set(Keys.selectedRiskModules, JSON.stringify(this.selectedModules));
    }

    public selectAllModules(): void {
        this.selectedModules = this.purchasedModules.every((module) => this.selectedModules.includes(module))
            ? [this.purchasedModules[0]] : this.purchasedModules;
        this.selectedModules$.next(this.selectedModules);
        this.storageService.set(Keys.selectedRiskModules, JSON.stringify(this.selectedModules));
    }
}
