import { AggregationCardinalityException, TriggerType } from "@app2/clients/types/alert-typedefs";
import { SortType } from "@app2/fraud/type-defs";
import { localization } from "@app2/shared/localization/localization";
import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";
import { localizeScheduleUnit } from "@app2/util/util";

export type AlertUserFriendlyError =
    AggregationCardinalityError
    | AlertAlreadyInStateError
    | AlertCannotUpdateEscalatedStateError
    | AlertChangeToSameStateError
    | AlertFiltersInvalidFormatError
    | AlertFiltersInvalidKeyCountError
    | AlertInboxInvalidSortFilterError
    | AttachingTicketWhenFlaggingAlertAsFraudError
    | ConfirmingFraudAsUnknownError
    | TracManagedTriggerEditPermissionError
    | EmptyAlertStateChangeRequestError
    | EscalateTicketWithoutTicketSlugError
    | FetchUbaAlertPageForNonUbaTriggerError
    | FlaggingSecurityEventAsFraudError
    | InvalidBooleanAlertFilterError
    | InvalidTaskScheduleDueDateError
    | InvalidTicketOwnerError
    | InvalidTriggerError
    | InvalidUuidAlertFilterError
    | MissingAdditionalDetailsForDismissingAlertError
    | MissingAlertDismissReasonError
    | MissingFraudTypeToFlagAlertAsFraudError
    | SuspectingFraudAsOtherTypeError
    | TaskScheduleAncestorCycleError
    | TaskScheduleHierarchyLevelError
    | TaskScheduleInvalidChildrenIntervalError
    | TaskScheduleInvalidParentError
    | TaskScheduleInvalidParentIntervalError
    | TaskScheduleInvalidQueryError
    | TaskScheduleMissingQueriesError
    | TaskScheduleNextRunMonth15Error
    | TaskScheduleNextRunMonthBeginError
    | TaskScheduleParentHasQueriesError
    | TaskSchedulePastNextRunError
    | TriggerInvalidEmailOptionsError
    | TriggerMissingAggregationError
    | TriggerMissingArchiveConfirmationError
    | TriggerMissingSavedQueryError
    | TriggerUbaTypeUsageError
    | TriggerUebaTypeUsageError
    | TriggerValidationError
    | WatchlistUploadTooLargeError
    | TriggerIdsNotFoundDuringResyncCalculationError
    | TriggersMustBeActiveForResyncCalculaionError
    | TriggersMustBeCopiedFromLibraryForResyncCalculationError
    | NoFieldsToUpdateDuringResyncError
    | CannotResyncIntervalSecondsError
    | CannotResyncInactiveTriggerError
    | CannotResyncTriggerNotCopiedFromLibraryError
    | CannotResyncTriggerBeforeResyncInfoCalculationsError
    | CannotResyncTriggerWithDifferingTriggerConfigTypeError
    | DefaultTriggerGroupIdsNotFoundError
    | TriggerNotCopiedAndActiveInSomeOrgsError
    | TriggerActiveInSomeOrgsError
    | TracEngineerNotAuthorizedError;

interface InvalidTicketOwnerError {
    key: "INVALID_TICKET_OWNER";
    ticketOwner: uuid;
}

interface TriggerValidationError {
    key: "TRIGGER_VALIDATION_ERROR";
    error: string; // non-localized message directly from backend
}

interface TriggerInvalidEmailOptionsError {
    key: "INVALID_TRIGGER_UPDATE_EMAIL_OPTIONS";
}

interface WatchlistUploadTooLargeError {
    key: "WATCHLIST_UPLOAD_TOO_LARGE";
}

interface TaskSchedulePastNextRunError {
    key: "TASK_SCHEDULE_NEXT_RUN_IN_PAST";
}

interface TaskScheduleNextRunMonthBeginError {
    key: "TASK_SCHEDULE_NEXT_RUN_DOESNT_MATCH_MONTH_BEGIN";
}

interface TaskScheduleNextRunMonth15Error {
    key: "TASK_SCHEDULE_NEXT_RUN_DOESNT_MATCH_MONTH_15";
}

interface AlertAlreadyInStateError {
    key: "ALERT_ALREADY_IN_STATE";
    state: string;
}

interface AlertCannotUpdateEscalatedStateError {
    key: "ESCALATED_ALERT_CANNOT_CHANGE_STATE";
}

interface TaskScheduleMissingQueriesError {
    key: "TASK_SCHEDULE_INVALID_HAS_QUERIES";
}

interface TaskScheduleInvalidChildrenIntervalError {
    key: "TASK_SCHEDULE_INVALID_CHILDREN_INTERVAL";
    scheduleName: string;
    scheduleUnit: string;
    scheduleInterval: string;
}

interface TaskScheduleInvalidParentIntervalError {
    key: "TASK_SCHEDULE_INVALID_PARENT_INTERVAL";
    scheduleUnit: string;
    scheduleInterval: string;
}

interface TaskScheduleInvalidQueryError {
    key: "INVALID_TASK_SCHEDULE_QUERY";
    query: string;
}

interface TaskScheduleInvalidParentError {
    key: "LIBRARY_TASK_SCHEDULE_INVALID_PARENT" | "TASK_SCHEDULE_INVALID_PARENT";
}

interface TaskScheduleAncestorCycleError {
    key: "LIBRARY_TASK_SCHEDULE_CYCLE_DETECTED" | "TASK_SCHEDULE_CYCLE_DETECTED";
}

interface TaskScheduleHierarchyLevelError {
    key: "LIBRARY_TASK_SCHEDULE_TOO_MANY_LEVELS" | "TASK_SCHEDULE_TOO_MANY_LEVELS";
    maxLevels: number;
}

interface TaskScheduleParentHasQueriesError {
    key: "TASK_SCHEDULE_INVALID_PARENT_HAS_QUERIES";
    numQueries: number;
}

interface TriggerMissingArchiveConfirmationError {
    key: "MISSING_TRIGGER_ARCHIVE_CONFIRMATION";
}

interface TriggerMissingSavedQueryError {
    key: "TRIGGER_MISSING_SAVED_QUERY";
}

interface TriggerMissingAggregationError {
    key: "TRIGGER_MISSING_AGGREGATION";
}

interface TriggerUbaTypeUsageError {
    key: "CANNOT_USE_UBA_TRIGGER_TYPE";
}

interface TriggerUebaTypeUsageError {
    key: "CANNOT_USE_UEBA_TRIGGER_TYPE";
}

interface AlertChangeToSameStateError {
    key: "CANNOT_CHANGE_ALERT_STATE_TO_SAME_STATE";
}

interface AlertInboxInvalidSortFilterError {
    key: "INVALID_ALERT_INBOX_SORT_FILTER_COMBINATION";
    sortType: SortType;
    filters: string; // refer to filters.state because that's what's important
}

interface AlertFiltersInvalidKeyCountError {
    key: "ONLY_ONE_KEY_PER_ALERT_FILTER_ALLOWED";
    filterName: string;
}

interface AlertFiltersInvalidFormatError {
    key: "INVALID_ALERT_FILTERS_FORMAT";
    filter: string;
}

interface FetchUbaAlertPageForNonUbaTriggerError {
    key: "CANNOT_GET_UBA_ALERTS_FOR_NON_UBA_TRIGGER";
    triggerType: TriggerType;
}

interface AggregationCardinalityError extends AggregationCardinalityException {
    key: "AGGREGATION_CARDINALITY";
}

interface InvalidUuidAlertFilterError {
    key: "INVALID_UUID_ALERT_FILTER";
    filterName: string;
    filterValue: string;
}

interface InvalidBooleanAlertFilterError {
    key: "INVALID_BOOLEAN_ALERT_FILTER";
    filterName: string;
    filterValue: string;
}

interface InvalidTriggerError {
    key: "INVALID_TRIGGER";
    errorMsg: string; // non-localized error from e.getMessage()
}

interface EmptyAlertStateChangeRequestError {
    key: "EMPTY_ALERT_STATE_CHANGE_REQUEST";
}

interface InvalidTaskScheduleDueDateError {
    key: "INVALID_TASK_SCHEDULE_DUE_DATE";
}

interface EscalateTicketWithoutTicketSlugError {
    key: "CANNOT_ESCALATE_ALERT_WITHOUT_TICKET_SLUG";
}

interface MissingAdditionalDetailsForDismissingAlertError {
    key: "DISMISSING_ALERT_AS_OTHER_REQUIRES_ADDITIONAL_DETAILS"
}

interface MissingFraudTypeToFlagAlertAsFraudError {
    key: "CANNOT_FLAG_AS_FRAUD_WITHOUT_FRAUD_TYPE";
}

interface AttachingTicketWhenFlaggingAlertAsFraudError {
    key: "CANNOT_ATTACH_TICKET_WHEN_FRAUD_FLAGGING_ALERT"
}

interface SuspectingFraudAsOtherTypeError {
    key: "SUSPECTED_FRAUD_TYPE_CANNOT_BE_OTHER";
}

interface ConfirmingFraudAsUnknownError {
    key: "CONFIRMED_FRAUD_TYPE_CANNOT_BE_UNKNOWN";
}

interface TracManagedTriggerEditPermissionError {
    key: "CANNOT_EDIT_TRAC_MANAGED_TRIGGER";
}

interface FlaggingSecurityEventAsFraudError {
    key: "CANNOT_FLAG_SECURITY_EVENT_AS_FRAUD";
}

interface MissingAlertDismissReasonError {
    key: "NULL_REASON_KEY";
}

interface TriggerIdsNotFoundDuringResyncCalculationError {
    key: "TRIGGER_IDS_NOT_FOUND_DURING_RESYNC_CALCULATION";
    nonexistentTriggerIds: uuid[];
}

interface TriggersMustBeActiveForResyncCalculaionError {
    key: "TRIGGERS_MUST_BE_ACTIVE_FOR_RESYNC_CALCULATION";
    inactiveTriggerIds: uuid[];
}

interface TriggersMustBeCopiedFromLibraryForResyncCalculationError {
    key: "TRIGGERS_MUST_BE_COPIED_FROM_LIBRARY_FOR_RESYNC_CALCULATION";
    triggersNotCopiedFromLibrary: uuid[];
}

interface NoFieldsToUpdateDuringResyncError {
    key: "NO_FIELDS_TO_UPDATE_DURING_TRIGGER_RESYNC";
}

interface CannotResyncIntervalSecondsError {
    key: "CANNOT_RESYNC_INTERVAL_SECONDS";
}

interface CannotResyncInactiveTriggerError {
    key: "CANNOT_RESYNC_INACTIVE_TRIGGER";
}

interface CannotResyncTriggerNotCopiedFromLibraryError {
    key: "CANNOT_RESYNC_TRIGGER_NOT_COPIED_FROM_LIBRARY";
}

interface CannotResyncTriggerBeforeResyncInfoCalculationsError {
    key: "CANNOT_RESYNC_TRIGGER_BEFORE_RESYNC_INFO_CALCULATIONS";
}

interface CannotResyncTriggerWithDifferingTriggerConfigTypeError {
    key: "CANNOT_RESYNC_TRIGGER_WITH_DIFFERING_TRIGGER_CONFIG_TYPE";
}

interface DefaultTriggerGroupIdsNotFoundError {
    key: "DEFAULT_TRIGGER_GROUP_IDS_NOT_FOUND";
    nonexistentDefaultTriggerGroupIds: uuid[];
}

interface TriggerNotCopiedAndActiveInSomeOrgsError {
    key: "TRIGGER_NOT_COPIED_AND_ACTIVE_IN_SOME_ORGS";
    orgsWithoutTriggerCopiedAndActive: uuid[];
}

interface TriggerActiveInSomeOrgsError {
    key: "TRIGGER_ACTIVE_IN_SOME_ORGS";
    orgsWithActiveTrigger: uuid[];
}

interface TracEngineerNotAuthorizedError {
    key: "TRAC_ENGINEER_NOT_AUTHORIZED_FOR_SOME_ORGS_DURING_BULK_EDIT",
    orgIds: uuid[];
}

export function getMessageForAlertUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "CANNOT_ATTACH_TICKET_WHEN_FRAUD_FLAGGING_ALERT":
            return localization.getString("CANNOT_ATTACH_TICKET_WHEN_FRAUD_FLAGGING_ALERT");
        case "CONFIRMED_FRAUD_TYPE_CANNOT_BE_UNKNOWN":
            return localization.getString("CONFIRMED_FRAUD_TYPE_CANNOT_BE_UNKNOWN");
        case "CANNOT_EDIT_TRAC_MANAGED_TRIGGER":
            return localization.getString("CANNOT_EDIT_TRAC_MANAGED_TRIGGER");
        case "EMPTY_ALERT_STATE_CHANGE_REQUEST":
            return localization.getString("EMPTY_ALERT_STATE_CHANGE");
        case "CANNOT_ESCALATE_ALERT_WITHOUT_TICKET_SLUG":
            return localization.getString("MISSING_TICKET_SLUG_WHEN_ESCALATING_ALERT");
        case "CANNOT_FLAG_SECURITY_EVENT_AS_FRAUD":
            return localization.getString("CANNOT_FLAG_SECURITY_ALERT_AS_FRAUD");
        case "INVALID_BOOLEAN_ALERT_FILTER":
        case "INVALID_UUID_ALERT_FILTER":
            return localization.getString("INVALID_ALERT_FILTER", error.filterValue, error.filterName);
        case "INVALID_TASK_SCHEDULE_DUE_DATE":
            return localization.getString("INVALID_TASK_SCHEDULE_DUE_DATE");
        case "INVALID_TRIGGER":
            return localization.getString("TRIGGER_DOESNT_EXIST");
        case "DISMISSING_ALERT_AS_OTHER_REQUIRES_ADDITIONAL_DETAILS":
            return localization.getString("CANNOT_DISMISS_ALERT_AS_OTHER_WITHOUT_ADDITIONAL_DETAILS");
        case "NULL_REASON_KEY":
            return localization.getString("CANNOT_DISMISS_ALERT_WITHOUT_REASON");
        case "CANNOT_FLAG_AS_FRAUD_WITHOUT_FRAUD_TYPE":
            return localization.getString("CANNOT_FLAG_AS_FRAUD_WITHOUT_FRAUD_TYPE");
        case "SUSPECTED_FRAUD_TYPE_CANNOT_BE_OTHER":
            return localization.getString("CANNOT_SUSPECT_FRAUD_AS_OTHER");
        case "TRIGGER_VALIDATION_ERROR":
            return error.error;
        case "AGGREGATION_CARDINALITY":
            return localization.getString("TRIGGER_CARDINALITY_ERROR_DETAIL",
                error.primaryField,
                error.cardinality,
                error.maxCardinality);
        case "INVALID_TICKET_OWNER":
            return localization.getString("INVALID_TICKET_OWNER");
        case "ONLY_ONE_KEY_PER_ALERT_FILTER_ALLOWED":
            return localization.getString("ONLY_ONE_KEY_PER_ALERT_FILTER_ALLOWED", error.filterName);
        case "INVALID_ALERT_FILTERS_FORMAT":
            return localization.getString("INVALID_ALERT_FILTERS_FORMAT", error.filter);
        case "CANNOT_GET_UBA_ALERTS_FOR_NON_UBA_TRIGGER":
            return localization.getString("CANNOT_GET_UBA_ALERTS_FOR_NON_UBA_TRIGGER");
        case "INVALID_ALERT_INBOX_SORT_FILTER_COMBINATION":
            return localization.getString("INVALID_ALERT_INBOX_SORT_FILTER_COMBINATION",
                error.sortType,
                error.filters);
        case "CANNOT_CHANGE_ALERT_STATE_TO_SAME_STATE":
            return localization.getString("CANNOT_CHANGE_ALERT_STATE_TO_SAME_STATE");
        case "TRIGGER_MISSING_SAVED_QUERY":
            return localization.getString("TRIGGER_MISSING_SAVED_QUERY");
        case "CANNOT_USE_UBA_TRIGGER_TYPE":
            return localization.getString("CANNOT_USE_UBA_TRIGGER_TYPE");
        case "CANNOT_USE_UEBA_TRIGGER_TYPE":
            return localization.getString("CANNOT_USE_UEBA_TRIGGER_TYPE");
        case "MISSING_TRIGGER_ARCHIVE_CONFIRMATION":
            return localization.getString("MISSING_TRIGGER_ARCHIVE_CONFIRMATION");
        case "TRIGGER_MISSING_AGGREGATION":
            return localization.getString("TRIGGER_AGG_FIELD_MISSING");
        case "INVALID_TRIGGER_UPDATE_EMAIL_OPTIONS":
            return localization.getString("INVALID_TRIGGER_UPDATE_EMAIL_OPTIONS");
        case "WATCHLIST_UPLOAD_TOO_LARGE":
            return localization.getString("WATCHLIST_UPLOAD_TOO_LARGE");
        case "TASK_SCHEDULE_NEXT_RUN_IN_PAST":
            return localization.getString("TASK_SCHEDULE_NEXT_RUN_IN_PAST");
        case "TASK_SCHEDULE_NEXT_RUN_DOESNT_MATCH_MONTH_BEGIN":
            return localization.getString("TASK_SCHEDULE_NEXT_RUN_SHOULD_MATCH_MONTH_BEGIN");
        case "TASK_SCHEDULE_NEXT_RUN_DOESNT_MATCH_MONTH_15":
            return localization.getString("TASK_SCHEDULE_NEXT_RUN_SHOULD_MATCH_MONTH_15");
        case "ALERT_ALREADY_IN_STATE":
            return localization.getString("ALERT_ALREADY_IN_STATE", error.state);
        case "ESCALATED_ALERT_CANNOT_CHANGE_STATE":
            return localization.getString("CANNOT_CHANGE_STATE_FOR_ESCALATED_ALERT");
        case "TASK_SCHEDULE_INVALID_HAS_QUERIES":
            return localization.getString("TASK_SCHEDULE_INVALID_HAS_QUERIES");
        case "TASK_SCHEDULE_INVALID_CHILDREN_INTERVAL":
            return localization.getString("TASK_SCHEDULE_INVALID_CHILDREN_INTERVAL",
                error.scheduleName,
                error.scheduleInterval,
                localizeScheduleUnit(error.scheduleUnit));
        case "TASK_SCHEDULE_INVALID_PARENT_INTERVAL":
            return localization.getString("TASK_SCHEDULE_INVALID_PARENT_INTERVAL",
                error.scheduleInterval,
                localizeScheduleUnit(error.scheduleUnit));
        case "INVALID_TASK_SCHEDULE_QUERY":
            return localization.getString("ERROR_INVALID_QUERY_STRING");
        case "LIBRARY_TASK_SCHEDULE_INVALID_PARENT":
        case "TASK_SCHEDULE_INVALID_PARENT":
            return localization.getString("TASK_SCHEDULE_INVALID_PARENT");
        case "LIBRARY_TASK_SCHEDULE_CYCLE_DETECTED":
        case "TASK_SCHEDULE_CYCLE_DETECTED":
            return localization.getString("TASK_SCHEDULE_CYCLE_DETECTED");
        case "LIBRARY_TASK_SCHEDULE_TOO_MANY_LEVELS":
        case "TASK_SCHEDULE_TOO_MANY_LEVELS":
            return localization.getString("TASK_SCHEDULE_TOO_MANY_LEVELS", error.maxLevels);
        case "TASK_SCHEDULE_INVALID_PARENT_HAS_QUERIES":
            return localization.getString("TASK_SCHEDULE_INVALID_PARENT_HAS_QUERIES", error.numQueries);
        case "TRIGGER_IDS_NOT_FOUND_DURING_RESYNC_CALCULATION":
            return localization.getString("TRIGGER_IDS_NOT_FOUND_DURING_RESYNC_CALCULATION", error.nonexistentTriggerIds.join(", "));
        case "TRIGGERS_MUST_BE_ACTIVE_FOR_RESYNC_CALCULATION":
            return localization.getString("TRIGGERS_MUST_BE_ACTIVE_FOR_RESYNC_CALCULATION", error.inactiveTriggerIds.join(", "));
        case "TRIGGERS_MUST_BE_COPIED_FROM_LIBRARY_FOR_RESYNC_CALCULATION":
            return localization.getString("TRIGGERS_MUST_BE_COPIED_FROM_LIBRARY_FOR_RESYNC_CALCULATION", error.triggersNotCopiedFromLibrary.join(", "));
        case "NO_FIELDS_TO_UPDATE_DURING_TRIGGER_RESYNC":
            return localization.getString("NO_FIELDS_TO_UPDATE_DURING_TRIGGER_RESYNC");
        case "CANNOT_RESYNC_INTERVAL_SECONDS":
            return localization.getString("CANNOT_RESYNC_INTERVAL_SECONDS");
        case "CANNOT_RESYNC_INACTIVE_TRIGGER":
            return localization.getString("CANNOT_RESYNC_INACTIVE_TRIGGER");
        case "CANNOT_RESYNC_TRIGGER_NOT_COPIED_FROM_LIBRARY":
            return localization.getString("CANNOT_RESYNC_TRIGGER_NOT_COPIED_FROM_LIBRARY");
        case "CANNOT_RESYNC_TRIGGER_BEFORE_RESYNC_INFO_CALCULATIONS":
            return localization.getString("CANNOT_RESYNC_TRIGGER_BEFORE_RESYNC_INFO_CALCULATIONS");
        case "CANNOT_RESYNC_TRIGGER_WITH_DIFFERING_TRIGGER_CONFIG_TYPE":
            return localization.getString("CANNOT_RESYNC_TRIGGER_WITH_DIFFERING_TRIGGER_CONFIG_TYPE");
        case "DEFAULT_TRIGGER_GROUP_IDS_NOT_FOUND":
            return localization.getString("DEFAULT_TRIGGER_GROUP_IDS_NOT_FOUND",
                error.nonexistentDefaultTriggerGroupIds.join(", "));
        case "TRIGGER_NOT_COPIED_AND_ACTIVE_IN_SOME_ORGS":
            return localization.getString("TRIGGER_NOT_COPIED_AND_ACTIVE_IN_SOME_ORGS", error.orgsWithoutTriggerCopiedAndActive.join(", "));
        case "TRIGGER_ACTIVE_IN_SOME_ORGS":
            return localization.getString("TRIGGER_ACTIVE_IN_SOME_ORGS", error.orgsWithActiveTrigger.join(", "));
        case "TRAC_ENGINEER_NOT_AUTHORIZED_FOR_SOME_ORGS_DURING_BULK_EDIT":
            return localization.getString("TRAC_ENGINEER_NOT_AUTHORIZED_FOR_SOME_ORGS_DURING_BULK_EDIT", error.orgIds.join(", "));
    }
}
