import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { SidebarListItem } from "@app2/shared/components/sidebar-list-section.component";

@Component({
    selector: "ds-item-list",
    template: `
        <div *ngFor="let item of items"
             class="declutter item-container"
             [attr.test-id]="'list-item-' + item.id">
            <ng-container *ngIf="!customItem">
                <a *ngIf="item.linkPath"
                   class="ds-item-list-item"
                   [target]="newTab ? '_blank' : '_self'"
                   [dsRouterLink]="item.linkPath"
                   [matTooltip]="item.tooltip"
                   [matTooltipDisabled]="!item.tooltip">{{ item.text }}</a>
                <span *ngIf="!item.linkPath"
                      [class.disabled-text]="item.disabled"
                      [matTooltip]="item.tooltip"
                      [matTooltipDisabled]="!item.tooltip">{{ item.text }}</span>
            </ng-container>
            <ng-container *ngIf="customItem">
                <ng-container *ngTemplateOutlet="customItem; context: { item: item }"></ng-container>
            </ng-container>

            <ng-container *dsPermissionsRequired="editPermission">
                <button *ngIf="deleteItem.observers.length > 0 && !forceHideDeleteItem && !item.disabled"
                        type="button"
                        class="link inline-delete show-on-hover item-delete-button"
                        (click)="deleteItem.emit(item)"
                        test-id="delete-button">
                    <i class="far fa-fw fa-times alert"></i>
                </button>
            </ng-container>

            <ng-container *ngIf="customAction">
                <ng-container *ngTemplateOutlet="customAction; context: { item: item }"></ng-container>
            </ng-container>
        </div>
    `,
    styles: [`
      .item-container {
        display: flex;
        align-content: center;
        align-items: center;
        margin-bottom: 4px;
      }

      button.link.item-delete-button {
        display: flex;
        text-decoration: none;
        margin-left: 8px;
        padding: 0 4px;
      }
    `],
})
export class DsItemListComponent {
    @Input() items: SidebarListItem[];
    @Input() editPermission: string;
    @Input() forceHideDeleteItem: boolean;
    @Input() customItem: TemplateRef<any>;
    @Input() customAction: TemplateRef<any>;
    @Input() newTab: boolean;
    @Output() deleteItem = new EventEmitter<SidebarListItem>();
}
