export const ffiecCatOverviewTemplate = `
<h3 class="sub-header">What is Maturity Evaluation?</h3>
<p class="text-justify">
    The Assessment’s second part is Cybersecurity Maturity, designed to help management measure the institution’s
    level of risk and corresponding controls. The levels range from baseline to innovative. Cybersecurity Maturity
    includes statements to determine whether an institution’s behaviours, practices, and processes can support
    cybersecurity preparedness within the following five domains:
</p>
<ul>
    <li>Cyber Risk Management and Oversight</li>
    <li>Threat Intelligence and Collaboration</li>
    <li>Cybersecurity Controls</li>
    <li>External Dependency Management</li>
    <li>Cyber Incident Management and Resilience</li>
</ul>
<p class="text-justify">
    The domains include assessment factors and contributing components. Within each component, declarative
    statements describe activities supporting the assessment factor at each maturity level. Management determines
    which declarative statements best fit the current practices of the institution. All declarative statements in
    each maturity level, and previous levels, must be attained and sustained to achieve that domain’s maturity
    level. While management can determine the institution’s maturity level in each domain, the Assessment is not
    designed to identify an overall cybersecurity maturity level.
</p>
<figure>
    <figcaption class="cat-policy-img-caption">
        Domains and Assessment Factors
    </figcaption>
    <img class="cat-policy-img"
         src="assets/images/FFIEC-CAT-Overview-1.png"
         alt="Domains and Assessment Factors">
</figure>
<p class="text-justify">
    To begin scoring start with the "Domain 1" tab and evaluate each statement for an evaluation factor. For each
    statement that your programs and processes meet set the answer to Yes. Remember that you cannot select a higher
    maturity level unless you have first obtained the previous levels. For example to set Yes on Intermediate you
    must also comply with the statements in Baseline, and Evolving. Proceed through the five Domain tabs. As you
    enter your answers the Results Tab will be updated and provide a visual queue indicating your score not only
    for each factor but also each domain.
</p>
<figure>
    <img class="cat-policy-img"
         src="assets/images/FFIEC-CAT-Overview-2.png"
         alt="Statement Classification">
</figure>
<p class="text-justify">
Once completed results will be able to be reviewed on the Results Tab. The Visual Summary tab contains a number
of visualizations to help you understand your data and present it to others quickly and efficiently.
</p>
`;
