import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "lost-2fa-device-modal",
    template: `
        <span mat-dialog-title>
            {{ 'LOST_TWO_FACTOR_DEVICE_HEADER' | localize }}
        </span>
        <mat-dialog-content>
            <article>
                <div [innerHtml]="'LOST_TWO_FACTOR_DEVICE_DESCRIPTION' | localize"></div>
            </article>
        </mat-dialog-content>
        <mat-dialog-actions>
            <modal-buttons closeKey="OK"
                           (onClose)="onClose()">
            </modal-buttons>
        </mat-dialog-actions>
    `,
})
export class Lost2faDeviceModalComponent {

    constructor(private dialogRef: MatDialogRef<Lost2faDeviceModalComponent>) {
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
