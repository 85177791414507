import { Component, OnInit } from "@angular/core";
import { AuthStateService } from "@app2/account/auth-state.service";
import { EncodedCredentialRequestOptions, WebAuthnService } from "@app2/account/webauthn.service";
import { Lost2faDeviceModalComponent } from "@app2/login/lost-2fa-device-modal.component";
import { TwoFactorLoginService } from "@app2/login/two-factor-login.service";
import { ModalService } from "@app2/shared/modal/modal.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Component({
    selector: "u2f-authentication-page",
    template: `
        <div class="row">
            <div class="padded sheet small-16 medium-5 medium-centered columns">
                <div class="info-box">
                    <i class="fab fa-usb fa-5x secondary"></i>
                    <div class="info-box-text">
                        <strong>{{ "U2F_PROMPT" | localize }}</strong> <br/>
                        <a class="de-emphasize" *ngIf="google2faInitialized"
                           test-id="totp-button"
                           dsRouterLink="/totp-authentication">{{ "USE_TOTP_INSTEAD" | localize }}</a>
                        <br *ngIf="google2faInitialized"/>
                        <a class="de-emphasize"
                           test-id="u2f-retry-button"
                           (click)="promptWebAuthn()">{{ "U2F_RETRY" | localize }}</a>
                        <br/>
                        <a class="de-emphasize"
                           (click)="showLost2faDeviceModal()">{{'LOST_DEVICE' | localize}}</a>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class U2fAuthenticationPageComponent implements OnInit {
    google2faInitialized = false;

    // The user ignored the browser's prompt to use WebAuthn, don't show a login failure as they may have used TOTP.
    private userControlledDomExceptionNames = [
        "AbortError",
        "NotAllowedError",
    ];

    constructor(private authStateService: AuthStateService,
                private tfaLoginService: TwoFactorLoginService,
                private webAuthnService: WebAuthnService,
                private modalService: ModalService,
                private notificationsService: NotificationsService) {
    }

    ngOnInit() {
        this.promptWebAuthn();
    }

    promptWebAuthn() {
        const tfaDetails = this.tfaLoginService.getTwoFactorAuthDetails();
        this.google2faInitialized = tfaDetails.google2faInitialized;
        const credentialRequest: EncodedCredentialRequestOptions = JSON.parse(tfaDetails.webAuthnAuthenticationRequest);

        this.webAuthnService.credentialGet(credentialRequest)
            .then(encodedAssertionResponse => this.authStateService.attemptWebAuthnAuth(encodedAssertionResponse))
            .catch(error => {
                if (!this.userControlledDomExceptionNames.includes(error.name)) {
                    const message = getMessageForError(error.error, "LOGIN_FAILED");
                    this.notificationsService.showError(message, error);
                }
            });
    }

    showLost2faDeviceModal() {
        this.modalService.open(Lost2faDeviceModalComponent);
    }
}
