import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { EncodedCredentialCreationOptions } from "@app2/account/webauthn.service";
import { RestClient } from "@app2/clients/rest-client";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import {
    AwsIntegration,
    AzureAuthorization,
    AzureOrg,
    S3LogBucket,
    SalesForceAuthorization,
    SalesForceOrg,
} from "@app2/type-defs/user/integrations-types";
import {
    DataRestriction,
    ExternalToken,
    InfoBanner,
    JobDescriptor,
    KnowledgeBaseLogin,
    NewOrgUser,
    OrgCredentials,
    OrgSettings,
    OrgSummary,
    Person,
    PersonSummary,
    Role,
    SsoEnabledUrl,
    TwoFactorDetails,
} from "@app2/type-defs/user/user-types";

@Injectable({
    providedIn: "root",
})
export class UserClientService {
    private userClient: RestClient;
    private userClientNoOrg: RestClient;

    constructor(httpClient: HttpClient,
                private envService: EnvironmentService,
                orgsService: OrgsService) {
        const baseUrl = envService.getConfig().userService;

        this.userClient = new RestClient(baseUrl, httpClient, orgsService);
        this.userClientNoOrg = new RestClient(baseUrl, httpClient);
    }

    /**
     * Authentication methods
     */
    /**
     * Checks if the current cookie is good for authentication.
     */
    public authenticate(): Promise<Person> {
        return this.userClientNoOrg.doGet("/user");
    }

    /**
     * Attempts to authenticate with email and password. If the user has 2FA enabled, it returns a TwoFactorDetails,
     * otherwise, returns the Person that successfully logged in.
     */
    public logIn(email: string, password: string): Promise<Person | TwoFactorDetails> {
        const headers = { "Authorization": "Basic " + btoa(`${ email }:${ password }`) };
        return this.userClientNoOrg.doPost("/user/login", undefined, undefined, undefined, headers);
    }

    public validateTotpCode(totpCode: string): Promise<Person> {
        return this.userClientNoOrg.doPost("/user/login/google2fa",
            totpCode, undefined, undefined,
            { "Content-Type": "text/plain" });
    }

    public validateWebAuthnToken(encodedAssertionResponse: object): Promise<Person> {
        return this.userClientNoOrg.doPost("/user/login/webauthn/finish",
            JSON.stringify(encodedAssertionResponse), undefined, undefined,
            { "Content-Type": "text/plain;charset=UTF-8" });
    }

    public logOut(): Promise<void> {
        return this.userClientNoOrg.doPost("/user/logout");
    }

    public getHomeOrg(): Promise<OrgSummary> {
        return this.userClientNoOrg.doGet("/org");
    }

    public getAllOrgs(): Promise<OrgSummary[]> {
        return this.userClientNoOrg.doGet("/orgs");
    }

    public getAuthorizedOrgs(userId: uuid): Promise<OrgSummary[]> {
        return this.userClientNoOrg.doGet(`/user/${ userId }/orgs`);
    }

    public getCurrentPermissions(): Promise<string[]> {
        return this.userClientNoOrg.doGet("/user/permissions");
    }

    /**
     * Attempts to refresh the logged-in user's token.
     */
    public refreshToken() {
        return this.userClientNoOrg.doPost("/user/update-token");
    }

    /**
     * Business methods
     */
    public registerPerson(person: NewOrgUser): Promise<Person> {
        return this.userClient.doPost<Person>("/user", person);
    }

    public getPersons(): Promise<Person[]> {
        return this.userClient.doGet<Person[]>("/users");
    }

    public getPerson(userId: uuid): Promise<Person> {
        return this.userClient.doGet<Person>(`/user/${ userId }`);
    }

    public getPersonsCsv(): Promise<Blob> {
        return this.userClient.downloadCsvGet("/users-csv");
    }

    public getPersonSummaries(): Promise<PersonSummary[]> {
        return this.userClient.doGet<PersonSummary[]>("/user/summary", { includeInactive: true });
    }

    public getPersonSummariesForOrg(orgId: uuid): Promise<PersonSummary[]> {
        return this.userClientNoOrg.doGet(`/org/${ orgId }/user/summary`, { includeInactive: true });
    }

    public getDataRestrictions(includeDeleted: boolean = false): Promise<DataRestriction[]> {
        return this.userClient.doGet<DataRestriction[]>("/data-restrictions", { includeDeleted });
    }

    public getDataRestriction(dataRestrictionId: uuid): Promise<DataRestriction> {
        return this.userClient.doGet<DataRestriction>(`/data-restriction/${ dataRestrictionId }`);
    }

    public createDataRestriction(dataRestriction: Partial<DataRestriction>): Promise<DataRestriction> {
        return this.userClient.doPost<DataRestriction>("/data-restriction", dataRestriction);
    }

    public editDataRestriction(dataRestrictionId: uuid, dataRestriction: Partial<DataRestriction>) {
        return this.userClient.doPut<DataRestriction>(`/data-restriction/${ dataRestrictionId }`, dataRestriction);
    }

    public deleteDataRestriction(dataRestrictionId: uuid) {
        return this.userClient.doDelete<void>(`/data-restriction/${ dataRestrictionId }`);
    }

    public restoreDataRestriction(dataRestrictionId: uuid) {
        return this.userClient.doPost<DataRestriction>(`/data-restriction/${ dataRestrictionId }/restore`);
    }

    public getFreshdeskRedirectUrl() {
        return this.envService.getConfig().userService + "/redirect/freshdesk";
    }

    public getPeopleForDataRestriction(dataRestrictionId: uuid) {
        return this.userClient.doGet<PersonSummary[]>(`/data-restriction/${ dataRestrictionId }/users`);
    }

    public getOrgSettings(): Promise<OrgSettings> {
        return this.userClient.doGet<OrgSettings>(`/global-settings`);
    }

    public setOrgSettings(newSettings: OrgSettings): Promise<void> {
        return this.userClient.doPut<void>(`/global-settings`, newSettings);
    }

    public getRoles(includeDeleted: boolean = false, includeTracRoles: boolean = false): Promise<Role[]> {
        return this.userClient.doGet("/roles", { includeDeleted, includeTracRoles });
    }

    public createCustomRole(role: Partial<Role>): Promise<Role> {
        return this.userClient.doPost<Role>("/role/custom", role);
    }

    public editCustomRole(roleId: uuid, role: Partial<Role>) {
        return this.userClient.doPut<Role>(`/role/custom/${ roleId }`, role);
    }

    public deleteCustomRole(roleId: uuid): Promise<Role> {
        return this.userClient.doDelete<Role>(`/role/custom/${ roleId }`);
    }

    public restoreCustomRole(roleId: uuid) {
        return this.userClient.doPost<Role>(`/role/custom/${ roleId }/restore`);
    }

    public updatePersonRole(userId: uuid, roleId: uuid) {
        const resource = "/user/" + userId + "/role/" + roleId;
        return this.userClient.doPut<Person>(resource);
    }

    public getJobDescriptors(includeDeleted: boolean): Promise<JobDescriptor[]> {
        return this.userClientNoOrg.doGet("/org/all/job-descriptors", { includeDeleted });
    }

    public updateJobDescriptor(descriptorId: uuid, descriptor: JobDescriptor): Promise<JobDescriptor> {
        return this.userClientNoOrg.doPut(`/org/all/job-descriptor/${ descriptorId }`, descriptor);
    }

    public createJobDescriptor(descriptor: JobDescriptor): Promise<JobDescriptor> {
        return this.userClientNoOrg.doPost(`/org/all/job-descriptor`, descriptor);
    }

    public deleteJobDescriptor(descriptorId: uuid) {
        return this.userClientNoOrg.doDelete(`/org/all/job-descriptor/${ descriptorId }`);
    }

    public updatePersonDescriptors(personId: uuid, descriptorIds: uuid[]) {
        return this.userClient.doPut(`/user/${ personId }/job-descriptors`, null, { descriptorIds });
    }

    public getGlobalPeopleWithDescriptor(descriptorId: uuid): Promise<Person[]> {
        return this.userClientNoOrg.doGet(`/org/all/job-descriptor/${ descriptorId }/users`);
    }

    public updatePersonAuthorizedOrgs(userId: uuid, authorizedOrgIds: uuid[]) {
        return this.userClient.doPut(`/user/${ userId }/authorized-orgs`, authorizedOrgIds);
    }

    public registerSalesForceOrg(authorization: SalesForceAuthorization) {
        return this.userClient.doPost<SalesForceOrg>(`/salesForce/register`, authorization);
    }

    public registerSalesForceSandboxOrg(authorization: SalesForceAuthorization): Promise<SalesForceOrg> {
        return this.userClient.doPost<SalesForceOrg>(`/salesForceSandbox/register`, authorization);
    }

    public getAllSalesForceOrgs(): Promise<SalesForceOrg[]> {
        const resource = "/salesForce/all";
        return this.userClient.doGet(resource);
    }

    public deleteSalesForceOrg(salesForceOrgId: uuid): Promise<void> {
        const resource = "/salesForce/" + salesForceOrgId;
        return this.userClient.doDelete(resource);
    }

    public getKnowledgeBaseLogin(redirectUrl: string): Promise<KnowledgeBaseLogin> {
        return this.userClient.doGet("/knowledge-base-login", { redirectUrl });
    }

    public deactivatePerson(userId: uuid): Promise<void> {
        const resource = "/user/" + userId;
        return this.userClient.doDelete(resource);
    }

    public activatePerson(userId: uuid): Promise<void> {
        const resource = "/user/" + userId + "/activate";
        return this.userClient.doPost(resource);
    }

    public setupGoogle2fa(userId: uuid): Promise<TwoFactorDetails> {
        return this.userClient.doPost(`/user/${ userId }/google2fa/setup`);
    }

    public resetGoogle2fa(userId: uuid): Promise<void> {
        const resource = `/user/${ userId }/google2fa/reset`;
        return this.userClient.doPost(resource);
    }

    public resetU2f(userId: uuid): Promise<void> {
        return this.userClient.doPost(`/user/${ userId }/u2f/reset`);
    }

    public beginU2fRegistration(): Promise<EncodedCredentialCreationOptions> {
        return this.userClientNoOrg.doGet(`/user/webauthn/register/begin`, undefined, ["*"]);
    }

    public finishU2fRegistration(deviceResponse: object): Promise<void> {
        return this.userClientNoOrg.doPost(`/user/webauthn/register/finish`, deviceResponse, undefined, ["*"]);
    }

    public changePersonEmail(userId: uuid, emailId: uuid, email: string): Promise<void> {
        return this.userClient.doPut(`/user/${ userId }/email/${ emailId }`, null, { e: email });
    }

    public updatePerson(user: Person): Promise<Person> {
        return this.userClient.doPut(`/user/${ user.id }`, user);
    }

    public updateSelf(user: Person): Promise<Person> {
        return this.userClient.doPut("/user", user);
    }

    public changePassword(oldPassword: string, newPassword: string, userId: string) {
        return this.userClientNoOrg.doPost(`/user/${ userId }/password`, {
            requiredPassword: oldPassword,
            newPassword: newPassword,
        });
    }

    public registerAzureOrg(integrationType: string, azureOrgName: string,
                            azureAuthCode: string, azureSubscriptionId: string,
                            azureResourceGroup: string, azureStorageAccount: string,
                            noDataThresholdSeconds: number | null): Promise<AzureOrg> {
        const resource = "/azure/register";
        const azureAuthorization: AzureAuthorization = {
            integrationType: integrationType,
            name: azureOrgName,
            code: azureAuthCode,
            redirectUri: this.envService.getConfig().azureRedirectUri,
            sessionState: undefined,
            subscriptionId: azureSubscriptionId,
            resourceGroup: azureResourceGroup,
            storageAccount: azureStorageAccount,
            noDataThresholdSeconds: noDataThresholdSeconds,
        };
        return this.userClient.doPost(resource, azureAuthorization);
    }

    public getAllAzureOrgs(): Promise<AzureOrg[]> {
        return this.userClient.doGet("/azure/all");
    }

    public resetAzureOrg(azureOrg: AzureOrg, azureAuthCode: string): Promise<AzureOrg> {
        const resource = `/azure/${ azureOrg.id }/refresh`;
        const azureAuthorization: AzureAuthorization = {
            integrationType: azureOrg.type,
            name: azureOrg.name,
            code: azureAuthCode,
            redirectUri: this.envService.getConfig().azureRedirectUri,
            sessionState: azureOrg.state,
            noDataThresholdSeconds: null,

        };
        return this.userClient.doPost(resource, azureAuthorization);
    }

    public deleteAzureOrg(azureOrgId: uuid): Promise<void> {
        return this.userClient.doDelete(`/azure/${ azureOrgId }`);
    }

    public editAzureOrg(azureOrg: AzureOrg): Promise<AzureOrg> {
        return this.userClient.doPost(`/azure/${ azureOrg.id }`, azureOrg);
    }

    public createAwsIntegration(awsIntegration: AwsIntegration): Promise<AwsIntegration> {
        return this.userClient.doPost("/awsIntegration", awsIntegration);
    }

    public deleteAwsIntegration(awsIntegrationId: string): Promise<void> {
        return this.userClient.doDelete(`/awsIntegration/${ awsIntegrationId }`);
    }

    public getAwsAccounts(): Promise<AwsIntegration[]> {
        return this.userClient.doGet(`/awsIntegrations`)
            .then((wrapper: { awsIntegrations: AwsIntegration[] }) => wrapper.awsIntegrations);
    }

    public createS3LogBucket(logBucket: S3LogBucket): Promise<S3LogBucket> {
        return this.userClient.doPost("/awsIntegration/s3-log-bucket", logBucket);
    }

    public editS3LogBucket(logBucket: S3LogBucket): Promise<S3LogBucket> {
        return this.userClient.doPut(`/awsIntegration/s3-log-bucket/${ logBucket.id }`, logBucket);
    }

    public deleteS3LogBucket(s3Bucket: S3LogBucket): Promise<void> {
        return this.userClient.doDelete(`/awsIntegration/s3-log-bucket/${ s3Bucket.id }`);
    }

    public getS3LogBuckets(): Promise<S3LogBucket[]> {
        return this.userClient.doGet(`/awsIntegration/s3-log-buckets`);
    }

    public sendHeartbeat() {
        return this.userClientNoOrg.doPost("/heartbeat");
    }

    public getInfoBanners(): Promise<InfoBanner[]> {
        return this.userClientNoOrg.doGet("/org/all/banners");
    }

    /**
     * External Tokens (API, DVM, Windows agent Installer, etc.)
     */

    public createInstallerToken(): Promise<ExternalToken> {
        return this.userClient.doPost("/tokens/installer");
    }

    public createApiToken(): Promise<ExternalToken> {
        return this.userClient.doPost("/token/api");
    }

    public revokeToken(id: uuid): Promise<void> {
        return this.userClient.doDelete(`/tokens/external/${ id }`);
    }

    public getExternalCredentialsWithAwsSecret(): Promise<OrgCredentials> {
        return this.userClient.doGet(`/getCredentials`);
    }

    public getTokens(): Promise<ExternalToken[]> {
        return this.userClient.doGet(`/tokens/external`);
    }

    public editToken(token: ExternalToken): Promise<void> {
        return this.userClient.doPut(`/tokens/external/${ token.id }`, token);
    }

    public getSamlEnabled(email: string): Promise<SsoEnabledUrl> {
        const headers = { "Authorization": "Basic " + btoa([email, ""].join(":")) };
        return this.userClientNoOrg.doGet(`/user/saml-enabled`, undefined, undefined, headers);
    }

    public sendForgotPasswordEmail(email: string) {
        return this.userClientNoOrg.doPost("/user/forgot", undefined, { e: email });
    }

    public resetPassword(emailId: uuid, newPassword: string, confirmationCode: string) {
        return this.userClientNoOrg.doPost(`/user/email/${ emailId }/forgot`, newPassword,
            { c: confirmationCode }, undefined, { "Content-Type": "text/plain;charset=UTF-8" });
    }

    public confirmGoogle2fa(userId: uuid, totpCode: string): Promise<void> {
        return this.userClient.doPost(`/user/${ userId }/google2fa/confirm`, totpCode, undefined,
            undefined, { "Content-Type": "text/plain" });
    }
}
