import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { map } from "rxjs/operators";
import { UserService } from "@app2/account/user.service";
import { Observable } from "rxjs";

export const isLoggedInGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
        const userService = inject(UserService);
        const router = inject(Router);

        return userService.getCurrentUser$()
            .pipe(map(user => {
                if (user) {
                    return true;
                }

                // This page is special because if an user refreshes the browser while on it, we shouldn't set
                // the current page as the redirectUrl but we should retain the current value of redirectUrl
                const redirectUrl = route.url[0]?.path === "select-org"
                    ? route.queryParams.redirectUrl
                    : state.url;

                const queryParams = { redirectUrl, orgId: route.queryParams.orgId };
                return router.createUrlTree(["/login"], { queryParams });
            }));
    };
