import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";
import { FeaturesService } from "@app2/shared/services/features.service";
import { Dictionary } from "@app2/util/common-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";


/**
 * !!!USAGE NOTES!!!
 * This is a structural directive. Use the `*` glyph.
 *    <div *featureGate="'myFeatureName'">... </div>
 *
 * You can only have one structural directive per element (no stacking this with *ngIf)
 * Angular wants to evaluate the expression, so you need both kinds of quotes
 *
 */
@UntilDestroy()
@Directive({
    selector: "[featureGate]",
})
export class FeatureGateDirective implements OnInit {
    @Input() set featureGate(gateName: string) {
        this.gateName = gateName;
        this.addOrRemoveElement();
    }

    private gateName: string = "";
    private featureSet: Dictionary<boolean> = {};
    private visible: boolean = false;

    constructor(private readonly templateRef: TemplateRef<any>,
                private readonly viewContainer: ViewContainerRef,
                private readonly featureService: FeaturesService) {
    }

    ngOnInit(): void {
        this.featureService.getFeatures$()
            .pipe(untilDestroyed(this))
            .subscribe(features => {
                this.featureSet = features;
                this.addOrRemoveElement();
            });
    }

    // see https://angular.io/guide/structural-directives#write-a-structural-directive
    addOrRemoveElement() {
        if (this.featureSet[this.gateName] && !this.visible) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.visible = true;
        } else if (!this.featureSet[this.gateName] && this.visible) {
            this.viewContainer.clear();
            this.visible = false;
        }
    }
}
