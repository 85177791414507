export const blackberry2faTemplate = `
<h3>Requirements</h3>
<p>
    To use Google Authenticator on your BlackBerry device, you must have OS 4.5-7.0. In addition, make
    sure your BlackBerry device is configured for US English -- you might not be able to download Google
    Authenticator if your device is operating in another language.
</p>

<h3>Downloading the app</h3>
<p>You'll need Internet access on your BlackBerry to download Google Authenticator.</p>
<ol>
    <li>Open the web browser on your BlackBerry.</li>
    <li>Visit m.google.com/authenticator.</li>
    <li>Download and install the application.</li>
</ol>

<h3>Setting up the app</h3>
<ol>
    <li>
        To link your phone to your account, open the Authenticator app menu and select <strong>Manual key entry</strong>.
    </li>
    <li>Enter the email address of your DefenseStorm account below "Enter account name".</li>
    <li>
        Enter the secret key on your computer screen next to "Enter key", select <strong>Time based</strong> from the
        drop-down menu and press <strong>Save</strong>.
    </li>
</ol>
`;
