import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "not-found-page",
    template: `
        <div class="padded sheet centered-container">
            <h2>{{ "NOT_FOUND" | localize }}</h2>
            <p>{{ "RESOURCE_NOT_FOUND_MESSAGE" | localize:path }}</p>
        </div>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
        }
        .centered-container {
            padding: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        h2 {
            padding-left: 0;
            border-bottom: none;
        }
    `],
})
export class NotFoundPageComponent {
    path: string;

    constructor(private route: ActivatedRoute) {
        this.path = this.route.snapshot.url.map(part => part.path).join("/");
    }
}
