import { Component } from "@angular/core";

// This is the component that shows the spinner when using `*dsLoading`, while our components are loading their content.
@Component({
    template: `
        <div class="loading-container">
            <i class="loading-spinner fa fa-spinner fa-spin fa-3x"></i>
        </div>
    `,
})
export class LoadingPlaceholderComponent {
}
