import { DOCUMENT } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { log } from "@app2/logger";
import * as _ from "underscore";
import $ from "jquery";

/**
 * Troubleshooting:
 *  - You may run into odd positioning bugs if the dropdown-trigger has a different offset-parent than the
 *    dropdown-body.
 *  - Margins on the dropdown-body will bump it off target
 *  - A pr-dropdown must contain exactly one trigger and one body.
 */

/**
 * @name Rectangle
 * @typedef {object}
 * @property {number} top
 * @property {number} left
 * @property {number} height
 * @property {number} width
 */
/**
 * @name align
 * @type {string}
 * @description One of "left", "right". Defaults to left. Describes which side of the dropdown trigger
 *      to align the body against.
 *      [*]____    _____[*]
 *      | left |  | right |
 *      |______|  |_______|
 * @name closeOnClick
 * @type {string}
 * @description If set to false this prevents clicks within the dropdown body from closing the dropdown.
 *      Defaults to true. If this is false, define what elements will close the dropdown by putting the attribute
 *      `pr-dropdown-close-on-click` on those elements.
 */

/* Example usage:

Standard pr-dropdown:

  * Put the element `pr-dropdown-trigger` on the element you want to be visible and use to open the dropdoown.
  * Put the element `pr-dropdown-body` on the element

    ```
    <pr-dropdown>
        <button pr-dropdown-trigger>Click here to view dropdown</button>
        <ul pr-dropdown-body>
           <li ng-click="$ctrl.doSomething()">Pick Something</li>
            <li ng-click="$ctrl.doSometingElse()">Pick something else</li>
        </ul>
    </pr-dropdown>
    ```

pr-dropdown that does not close itself on click:

    * `close-on-click` binding set to false
    * `pr-dropdown-close-on-click` attribute placed on the element that will close the dropdown.

    ```
    <pr-dropdown close-on-click="false">
        <button pr-dropdown-trigger>Click here to view dropdown</button>
        <div pr-dropdown-body>
            <div>
                Some content goes here.
                <button pr-dropdown-close-on-click>Click to close dropdown</button>
            </div>
        </div>
    </pr-dropdown>
    ```
*/
/** @deprecated */
@Component({
    selector: "pr-dropdown",
    template: "<ng-content></ng-content>",
})
export class PrDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() align: string;
    @Input() closeOnClick: boolean;
    @Input() showOnHover: boolean = false;
    @Input() createsCdkOverlay: boolean = false;

    @Output() popupShown = new EventEmitter<void>();
    @Output() popupHidden = new EventEmitter<void>();

    private shouldAlignRight: boolean = false;
    private shown: boolean = false;
    private selectedLiIndex = 0;
    private body;
    private trigger;
    // For cases in which a pr-dropdown is inside another pr-dropdown, we need to listen to clicks on the parent
    // dropdown's body so we automatically close the open child pr-dropdown
    private parentPopup: any; // JQuery selection

    constructor(@Inject(DOCUMENT) private document: Document,
                private element: ElementRef) {
        // showPopup and hidePopup are used as event handlers, so make use `bind` to make sure their `this` variable is
        // set to this instance of PrDropdown, rather than the dom element that the event was triggered on.
        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
    }

    ngOnInit() {
        this.shouldAlignRight = (this.align && this.align.search(/right/i) !== -1);
    }

    ngAfterViewInit() {
        //TODO: use ContentChild and ViewChild instead
        this.body = $(this.element.nativeElement).find("[pr-dropdown-body]");
        this.trigger = $(this.element.nativeElement).find("> [pr-dropdown-trigger]");

        if (this.body.length !== 1 && this.trigger.length !== 1) {
            log.error("pr-dropdown must have precisely one pr-dropdown-trigger and one pr-dropdown-body");
        }

        // make the trigger focusable when tabbing through the app
        this.trigger.attr("tabindex", 0);

        // Make the body right aligned if align="right"
        if (this.shouldAlignRight) {
            this.body.css("right", 0);
        }

        // remove the pr-dropdown-body from the dom until we're ready to display it
        this.body.detach();

        // if closeOnClick is false, intercept click events and prevent them from happening by returning false
        this.body.on("click", () => this.closeOnClick);

        // set up the events that will open the dropdown
        this.trigger.on("click", () => !this.showOnHover && this.handleTriggerEvents());
        this.trigger.on("keydown", this.handleTriggerKeydownEvents.bind(this));
        this.body.on("keydown", this.handleBodyKeydownEvents.bind(this));
    }

    ngOnDestroy() {
        // make sure to clean up the element if it's not in the DOM
        this.body.remove();
        $(this.document).off("click", this.hidePopup);
        this.parentPopup?.off("click", this.hidePopup);
    }

    public show() {
        if (!this.shown) {
            this.showPopup();
        }
    }

    public hide() {
        if (this.shown) {
            this.hidePopup();
        }
    }

    @HostListener("mouseenter")
    public onUserMenuMouseEnter() {
        if (this.showOnHover && !this.shown) {
            this.showPopup();
        }
    }

    @HostListener("mouseleave")
    public onUserMenuMouseLeave() {
        if (this.showOnHover && this.shown) {
            this.hidePopup();
        }
    }

    private handleTriggerEvents() {
        const disabledEditButton = this.trigger.find("button").attr("disabled") || this.trigger.find(".disabled-trigger").length;
        if (disabledEditButton) {
            return;
        }
        if (this.shown) {
            this.hidePopup();
        } else {
            this.showPopup();
        }
    }

    private handleTriggerKeydownEvents(event) {
        switch (event.key) {
            case "Enter":     // fallthrough
            case "ArrowDown": // fallthrough
                event.preventDefault();
                this.handleTriggerEvents();
                break;
        }
    }

    private handleBodyKeydownEvents(event) {
        switch (event.key) {
            case "ArrowUp":   //fallthrough
            case "ArrowDown": //fallthrough
                event.preventDefault();
                const items = this.focusableMenuItems();
                const totalItems = items.length;
                const offset = event.key === "ArrowDown" ? 1 : -1;
                this.selectedLiIndex = (this.selectedLiIndex + totalItems + offset) % totalItems;
                items.eq(this.selectedLiIndex).focus();
                break;
            case "Enter": // fallthrough
                if (this.focusableMenuItems().length === 0) {
                    break;
                }
                event.preventDefault();
                const selectedItem = this.focusableMenuItems().eq(this.selectedLiIndex);
                const linkChild = selectedItem.find("a[href]");
                if (linkChild.length === 1) {
                    linkChild.click();
                } else {
                    selectedItem.click();
                }
                break;
            case "Escape": //fallthrough
            case "Tab":
                this.trigger.focus();
                this.hidePopup();
                break;
        }
    }

    private focusableMenuItems() {
        return this.body.find("li")
            .filter(":not(.no-hover)"); // ignore items marked as no-hover
    }

    private showPopup() {
        this.trigger.addClass("active");
        $(this.element.nativeElement).append(this.body);

        // If this is the first time we have selected an item, initialize the selectedLiIndex.
        // This work is happening in showPopup instead of in $postLink because in $postLink, the body's html
        // is not complete yet. (Often the body element also has an ng-repeat on it, which changes the order
        // in which angular initializes stuff. Ordinarily the html would be complete in $postLink).
        if (!_.isNumber(this.selectedLiIndex)) {
            const candidateIndex = this.focusableMenuItems().index(".active");
            this.selectedLiIndex = _.isNumber(candidateIndex) ? candidateIndex : 0;
        }

        // Make sure that all menu items can be focused. This work is happening in showPopup for the same
        // detailed above, that we are initializing selectedLiIndex in showPopup.
        // tabindex="-1" means that the menu items are NOT focusable when you tab through the page, but they
        // can programmatically receive focus. This is to emulate how the built-in select element behaves.
        const focusableItems = this.focusableMenuItems();
        // If there is an element marked with class "active", start focus on that element. Otherwise start at 0.
        const currentActiveIndex = focusableItems.index(focusableItems.filter(".active"));
        this.selectedLiIndex = currentActiveIndex > 0 ? currentActiveIndex : 0;

        // Make dropdown body at least as wide as the trigger button
        // use outerWidth to account for border/margin on the trigger button
        const triggerWidth = this.trigger.outerWidth();
        this.body.css("min-width", triggerWidth);

        this.shown = true;

        this.body.find("[pr-dropdown-close-on-click]").on("click", this.hidePopup);
        // This is done on a setTimeout so the current click (that triggered showing the popup) doesn't automatically
        // execute these click handlers (thus closing the popup immediately).
        if (!this.createsCdkOverlay) {
            setTimeout(() => {
                $(this.document).on("click", this.hidePopup);
                this.parentPopup = this.body.parents("[pr-dropdown-body]");
                this.parentPopup?.on("click", this.hidePopup);
            });
        }

        this.popupShown.emit();
    }

    private hidePopup() {
        this.trigger.removeClass("active");
        this.body.detach();
        this.shown = false;
        $(this.document).off("click", this.hidePopup);
        this.parentPopup?.off("click", this.hidePopup);

        this.popupHidden.emit();
    }
}
