import { Component, OnInit } from "@angular/core";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import * as DarkReader from "darkreader";

@Component({
    selector: "dark-mode-toggle",
    template: `
        <a (click)='toggle()' class="toggleable-menu-item">
            {{ "DARK_MODE" | localize }}
            <span *ngIf="enabled" class="toggle-on">{{'READONLY_MODE_ON' | localize}}</span>
            <span *ngIf="!enabled" class="toggle-off">{{'READONLY_MODE_OFF' | localize}}</span>
        </a>
    `,
})
export class DarkModeToggleComponent implements OnInit {
    enabled: boolean = false;
    darkReader: any = DarkReader;
    constructor(private readonly storageService: StorageService) {}

    ngOnInit() {
        const initialValue = this.storageService.getShared(Keys.darkMode);
        this.enabled = initialValue === undefined ? false : initialValue;
        if (this.enabled) {
            this.enableDarkMode();
        }
    }

    toggle() {
        this.enabled = !this.enabled;
        this.storageService.setShared(Keys.darkMode, this.enabled);

        if (this.enabled) {
            this.enableDarkMode();
        } else {
            this.darkReader.disable();
        }
    }

    enableDarkMode() {
        this.darkReader.enable({
            brightness: 100,
            contrast: 90,
            sepia: 10
        });
    }
}

