import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { UserService } from "@app2/account/user.service";
import { ReportClientService } from "@app2/clients/report-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { DashboardTemplate } from "@app2/type-defs/report/report-types";
import { distinctUntilChanged } from "rxjs/operators";
import * as _ from "underscore";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Injectable({
    providedIn: "root",
})
export class DashboardsService {

    private allDashboards$ = new ReplaySubject<DashboardTemplate[]>();
    private visibleDashboards$ = new ReplaySubject<DashboardTemplate[]>();
    private selectedDashboard$ = new ReplaySubject<DashboardTemplate>();
    private isComplianceAdmin: boolean;

    constructor(private readonly reportClient: ReportClientService,
                userService: UserService,
                orgsService: OrgsService,
                private readonly notificationsService: NotificationsService,
                private readonly storageService: StorageService) {

        combineLatest([
            userService.getCurrentUser$(),
            orgsService.getCurrentOrg$(),
            userService.hasPermission$("COMPLIANCE_ADMIN"),
        ])
            .subscribe(([user, org, isComplianceAdmin]) => {
                if (!!user && !!org) {
                    this.isComplianceAdmin = isComplianceAdmin;
                    this.updateDashboards();
                }
            });

        this.allDashboards$
            .subscribe(allDashboards => {
                // Only ComplianceAdmins should see library dashboards.
                const filtered = allDashboards.filter(d => this.isComplianceAdmin || d.orgId !== null);
                const dashboards = _.sortBy(filtered, d => d.name.toLowerCase());
                this.visibleDashboards$.next(dashboards);

                const lastSelectedId = this.storageService.get(Keys.lastSelectedDashboard);
                const selected = _.findWhere(dashboards, { id: lastSelectedId }) || dashboards[0];
                this.selectedDashboard$.next(selected);
            });
    }

    /**
     * Can be invoked by other components to cause a refresh of the dashboards. For example, when a dashboard is created
     * or updated.
     */
    public updateDashboards(): void {
        this.reportClient.getDashboards(false)
            .then(dashboards => this.allDashboards$.next(dashboards))
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_LOADING_DASHBOARDS");
                this.notificationsService.showError(message, error);
            });
    }

    public getSelectedDashboard$(): Observable<DashboardTemplate> {
        return this.selectedDashboard$.asObservable()
            .pipe(distinctUntilChanged((lastDashboard, currDashboard) => lastDashboard.id === currDashboard.id
                && lastDashboard.lastModifiedOn === currDashboard.lastModifiedOn),
            );
    }

    public setSelectedDashboard(dashboard: DashboardTemplate): void {
        this.selectedDashboard$.next(dashboard);
        this.storageService.set(Keys.lastSelectedDashboard, dashboard.id);
    }

    public getDashboards$(): Observable<DashboardTemplate[]> {
        return this.visibleDashboards$.asObservable();
    }
}
