import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { log } from "@app2/logger";
import { AuthStateService } from "@app2/account/auth-state.service";
import { RouterService } from "@app2/shared/services/router.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "login-page",
    template: `
        <div class="row" *ngIf="showLoginForm">
            <div class="padded sheet small-16 medium-5 medium-centered columns">
                <form #loginForm="ngForm" name="login" method="post" role="form" novalidate
                      test-id="login-form">
                    <fieldset>
                        <legend *ngIf="redirectToFreshdesk"
                                class="center-legend">{{ "CONNECT_REDIRECT_LEGEND" | localize }}</legend>
                        <input required autofocus
                               type="email" id="email" name="email"
                               class="password-show-hide"
                               [placeholder]="'EMAIL' | localize"
                               [disabled]="processing"
                               (focus)="reset()"
                               (keypress)="reset()"
                               [email]="true"
                               [(ngModel)]="email"
                               [readonly]="showPassword"
                               (click)="showPassword = false"
                               (change)="showPassword = false"
                               test-id="email-input"/>
                        <button *ngIf="!showPassword"
                                type="submit" class="button secondary"
                                [disabled]="processing || loginForm.invalid"
                                (click)="validateUsername()"
                                test-id="submit-button">
                            <i *ngIf="processing" class="fa fa-spin fa-spinner"></i>
                            {{ "NEXT" | localize }}
                        </button>
                        <input [class.hide-password]="!showPassword"
                               #passwordInput
                               [required]="showPassword"
                               type="password" id="password" name="password"
                               class="password-show-hide"
                               [placeholder]="'PASSWORD' | localize"
                               (focus)="reset()"
                               (keypress)="reset()"
                               [(ngModel)]="password"
                               test-id="password-input"/>
                        <button *ngIf="showPassword"
                                type="submit" class="button secondary"
                                [disabled]="processing || loginForm.invalid"
                                (click)="doLogin()"
                                test-id="submit-button">
                            <i *ngIf="processing" class="fa fa-spin fa-spinner"></i>
                            {{ "LOG_IN" | localize }}
                        </button>
                        <a *ngIf="showPassword" dsRouterLink="/forgot-password"
                           test-id="forgot-password-link">
                            {{ 'FORGOT_PASSWORD' | localize }}
                        </a>
                    </fieldset>
                </form>
            </div>
            <div *ngIf="loginFailed" class="small-16 medium-5 medium-centered columns"
                 test-id="login-failed-message">
                <notification type="warning" [show]="true">
                    {{ "LOGIN_FAILED" | localize }}
                </notification>
            </div>
        </div>
    `,
    styles: [`
        .password-show-hide {
            margin-bottom: 8px;
        }

        .hide-password {
            display: none;
        }

        .center-legend {
            margin: 0 auto;
        }
    `],
})
export class LoginPageComponent implements OnInit {
    @ViewChild("passwordInput") passwordInput: ElementRef;

    showLoginForm: boolean;
    showPassword: boolean;
    loginFailed: boolean;
    email: string = "";
    password: string = "";
    processing: boolean;
    redirectToFreshdesk: boolean;

    constructor(private authStateService: AuthStateService,
                private notificationsService: NotificationsService,
                private route: ActivatedRoute,
                private routerService: RouterService,
                private userClient: UserClientService) {
    }

    ngOnInit(): void {
        this.redirectToFreshdesk = this.route.snapshot.queryParams.freshdesk;

        // Check to see if we already have cookies and we don't need to authenticate with email+password again.
        this.authStateService.attemptAuthenticateFromCookie()
            .catch(() => this.showLoginForm = true);
    }

    reset(): void {
        this.loginFailed = false;
    }

    validateUsername(): void {
        this.processing = true;
        this.userClient.getSamlEnabled(this.email)
            .then(ssoRecord => {
                if (ssoRecord === null || !ssoRecord.ssoEnabled) {
                    this.showPassword = true;
                    setTimeout(() => this.passwordInput.nativeElement.focus());
                } else {
                    const params = {
                        ssoName: ssoRecord.ssoName,
                        ssoUrl: ssoRecord.ssoUrl,
                        samlRequest: ssoRecord.samlRequest,
                    };
                    this.routerService.navigate(["/saml-redirect"], params);
                }
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_VALIDATING_USERNAME");
                this.notificationsService.showError(message, error);
            })
            .finally(() => this.processing = false);
    }

    doLogin(): void {
        this.processing = true;
        this.authStateService.attemptEmailPasswordLogIn(this.email, this.password)
            .catch(error => {
                log.info(error);
                this.loginFailed = true;
                this.processing = false;
            });
    }
}
