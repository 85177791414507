import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { IPV4Regex, IPV6Regex, isLocalIp } from "@app2/util/constants";
import { toStateParams } from "@app2/search/query";
import { TimeService } from "@app2/shared/services/time.service";
import { moment } from "@app2/util/legacy-moment";

@Component({
    selector: "ip-dropdown",
    template: `
        <pr-dropdown *dsPermissionsRequired="'EVENT_VIEW'" test-id="events-ip-dropdown">
            <a class="inline" pr-dropdown-trigger>{{ ip }}</a>
            <ul pr-dropdown-body test-class="ip-dropdown">
                <li class="no-hover threatmatch-li"><header>{{ "SEARCH_THIS_IP" | localize}}</header></li>
                <li class="threatmatch-li">
                    <a class = "wide" target="_blank"
                       dsRouterLink="/search"
                       [queryParams]="params1Minute">
                        <i class="fa fa-search visible-icon"></i> {{ "SEARCH_IP_1M" | localize}}
                    </a>
                </li>
                <li class="threatmatch-li">
                    <a class = "wide" target="_blank"
                       dsRouterLink="/search"
                       [queryParams]="params5Minutes">
                        <i class="fa fa-search visible-icon"></i> {{ "SEARCH_IP_5M" | localize}}
                    </a>
                </li>
                <div *ngFor="let lookupUrl of ipLookupUrls">
                    <li *ngIf="lookupUrl.url && !isLocalIp" class="threatmatch-li">
                        <a class="wide" href="{{ lookupUrl.url }}" target="_blank" rel="noopener noreferrer">
                            <i class="far fa-external-link visible-icon"></i> {{ lookupUrl.message | localize }}
                        </a>
                    </li>
                </div>
            </ul>
        </pr-dropdown>
        <span *dsPermissionsRequired="'!EVENT_VIEW'" class="inline">
            {{ ip }}
        </span>`,
})
export class IpDropdownComponent implements OnInit, OnChanges {
    @Input() eventTime: date | number;
    @Input() ip: string;
    @Input() fromThreatMatch: boolean;

    virusTotalBase =  (ip) => `https://www.virustotal.com/gui/ip-address/${ip}/detection`;
    abuseIpdbBase = "https://www.abuseipdb.com/check/";
    ibmXforceBase = "https://exchange.xforce.ibmcloud.com/ip/";
    robtexBase = "https://www.robtex.com/ip-lookup/";
    dShieldBase = "https://dshield.org/ipinfo.html?ip=";

    isLocalIp;
    params1Minute;
    params5Minutes;
    robtexUrl;
    dShieldUrl;
    virusTotalUrl;
    abuseIpdbUrl;
    ibmXforceUrl;
    ipLookupUrls;

    constructor(private readonly time: TimeService) {}

    ngOnInit() {
       this.initializeComponent();
    }

    ngOnChanges() {
        this.initializeComponent();
    }

    initializeComponent() {
        this.eventTime = this.getEventTime(this.eventTime);
        this.isLocalIp = isLocalIp(this.ip);
        this.params1Minute = this.convertToStateParams(1);
        this.params5Minutes = this.convertToStateParams(5);
        const isValidIpV4 = IPV4Regex.test(this.ip);
        const isValidIpV4OrV6 = isValidIpV4 || IPV6Regex.test(this.ip);

        // As of 9/8/2023, VirusTotal and DShield do not support IPV6
        this.ipLookupUrls = [
            { "url": isValidIpV4 ? this.virusTotalBase(this.ip) : false, "message": "SEARCH_VIRUS_TOTAL"},
            { "url": isValidIpV4OrV6 ? this.abuseIpdbBase + this.ip : false, "message": "SEARCH_ABUSE_IPDB"},
            { "url": isValidIpV4OrV6 ? this.ibmXforceBase + this.ip  : false, "message": "SEARCH_IBM_XFORCE"},
            { "url": isValidIpV4OrV6 ? this.robtexBase + this.ip : false, "message": "SEARCH_ROBTEX"},
            { "url": isValidIpV4 ? this.dShieldBase + this.ip : false, "message": "SEARCH_DSHIELD"},
        ];
    }

    getEventTime(inputTime) {
        let eventTime = inputTime;
        if (/^\d+$/.test(eventTime)) {
            eventTime = parseInt(eventTime);
        }
        eventTime = eventTime || this.time.now().valueOf();
        return this.time.timeZoneMoment(eventTime);
    }

    escape(ip) {
        return ip.replace(/:/g, "\\:");
    }

    convertToStateParams(minutes) {
        return toStateParams({
            query: this.fromThreatMatch ? this.escape(this.ip) + " -praesidio_skip_tm:true" : this.escape(this.ip),
            begin: moment(this.eventTime).subtract(minutes, "minutes"),
            end: moment(this.eventTime).add(minutes, "minutes"),
        });
    }

    openLink(url: string) {
        window.open(url, "_blank", "noreferrer");
    }
}
