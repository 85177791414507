import { Component, Input } from "@angular/core";

@Component({
    selector: "tooltip-date",
    template: `<span [matTooltip]="date | longDate" [innerHTML]="date | wrappableShortDate"></span>`,
})
export class TooltipDateComponent {
    //eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("for") date;
}
