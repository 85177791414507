import { Component, Input } from "@angular/core";
import { InfoNoteThreatLevel } from "@app2/type-defs/metadata/metadata-types";

@Component({
    selector: "threat-level-icon",
    template: `
        <img [attr.src]="getIconForThreatLevel()"/>
    `
})

export class ThreatLevelIconComponent {
    @Input() threatLevel: InfoNoteThreatLevel;

    getIconForThreatLevel(): string {
        switch(this.threatLevel) {
            case InfoNoteThreatLevel.FOUR:
                return "assets/icons/threat-level-4-icon.svg";

            case InfoNoteThreatLevel.THREE:
                return "assets/icons/threat-level-3-icon.svg";

            case InfoNoteThreatLevel.TWO:
                return "assets/icons/threat-level-2-icon.svg";

            case InfoNoteThreatLevel.ONE:
                return "assets/icons/threat-level-1-icon.svg";
        }
    }
}
