import { OverlayModule } from "@angular/cdk/overlay";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RecentItemsDropdownComponent } from "@app2/navigation/recent-items-dropdown.component";
import { HashDropdownComponent } from "@app2/search/search-shared/hash-dropdown.component";
import { TimestampDropdownComponent } from "@app2/search/search-shared/timestamp-dropdown.component";
import { UrlDropdownComponent } from "@app2/search/search-shared/url-dropdown.component";
import { DescriptorSelectionDropdownComponent } from "@app2/settings/descriptor-selection-dropdown.component";
import { ActionButtonsComponent } from "@app2/shared/components/action-buttons.component";
import { ActionByComponent } from "@app2/shared/components/action-by.component";
import { ActionOnComponent } from "@app2/shared/components/action-on.component";
import { AdDetectorComponent } from "@app2/shared/components/ad-detector.component";
import { AutocompleteInputComponent } from "@app2/shared/components/autocomplete-input.component";
import { ClipboardComponent } from "@app2/shared/components/clipboard-component";
import { CollapsibleDescriptionComponent } from "@app2/shared/components/collapsible-description.component";
import { CopyableInputComponent } from "@app2/shared/components/copyable-input.component";
import { DarkModeToggleComponent } from "@app2/shared/components/dark-mode-toggle.component";
import { DataRestrictionNameComponent } from "@app2/shared/components/data-restriction-name.component";
import { DatePickerHeaderComponent } from "@app2/shared/components/date-picker-header.component";
import { DatePickerComponent } from "@app2/shared/components/date-picker.component";
import { DateRangeCalendarsComponent } from "@app2/shared/components/date-range-calendars.component";
import { DateRangeDropdownComponent } from "@app2/shared/components/date-range-dropdown.component";
import { DateRangePickerComponent } from "@app2/shared/components/date-range-picker.component";
import { DateRangeSelectorComponent } from "@app2/shared/components/date-range-selector.component";
import { DateSelectionModalComponent } from "@app2/shared/components/date-selection-modal.component";
import { DateTimeTzPickerComponent } from "@app2/shared/components/date-time-tz-picker.component";
import { DsButtonBarComponent } from "@app2/shared/components/ds-button-bar.component";
import { DsButtonComponent } from "@app2/shared/components/ds-button.component";
import { DsDropdownComponent } from "@app2/shared/components/ds-dropdown.component";
import { DsExpanderComponent } from "@app2/shared/components/ds-expander.component";
import { DsIconComponent } from "@app2/shared/components/ds-icon.component";
import { DsItemListComponent } from "@app2/shared/components/ds-item-list.component";
import { DsLabelComponent } from "@app2/shared/components/ds-label.component";
import { DsMultiSelectComponent } from "@app2/shared/components/ds-multi-select.component";
import { DsRichTextEditorComponent } from "@app2/shared/components/ds-rich-text-editor.component";
import { DsSelectComponent } from "@app2/shared/components/ds-select.component";
import { DsSingleSelectComponent } from "@app2/shared/components/ds-single-select.component";
import { EmailNotificationListModalComponent } from "@app2/shared/components/email-notification-list-modal.component";
import { EmailNotificationListComponent } from "@app2/shared/components/email-notification-list.component";
import { FaProIconsComponent } from "@app2/shared/components/fa-pro-icons.component";
import { FileAttachmentsSectionComponent } from "@app2/shared/components/file-attachments-section.component";
import { FileUploaderComponent } from "@app2/shared/components/file-uploader.component";
import { FormattedHtmlComponent } from "@app2/shared/components/formatted-html.component";
import { InfoBannerComponent } from "@app2/shared/components/info-banner.component";
import { IntervalSelectorComponent } from "@app2/shared/components/interval-selector.component";
import { LabelEditorComponent } from "@app2/shared/components/label-editor.component";
import { LuceneTextAreaComponent } from "@app2/shared/components/lucene-text-area.component";
import { MarkdownHelpLinkTooltipComponent } from "@app2/shared/components/markdown-help-link-tooltip.component";
import { MarkdownHtmlComponent } from "@app2/shared/components/markdown-html.component";
import { Ng1ALinkComponent } from "@app2/shared/components/ng1-a-link.component";
import { NotificationIconComponent } from "@app2/shared/components/notifications/notification-icon.component";
import { NotificationMessageComponent } from "@app2/shared/components/notifications/notification-message.component";
import { NotificationComponent } from "@app2/shared/components/notifications/notification.component";
import { OrgDropdownComponent } from "@app2/shared/components/org-dropdown.component";
import { PaginationDetailComponent } from "@app2/shared/components/pagination-detail.component";
import { PaginationSummaryComponent } from "@app2/shared/components/pagination-summary.component";
import { PersonNameComponent } from "@app2/shared/components/person-name.component";
import { PersonPickerComponent } from "@app2/shared/components/person-picker.component";
import { PersonWithDateComponent } from "@app2/shared/components/person-with-date.component";
import { PrDropdownComponent } from "@app2/shared/components/pr-dropdown.component";
import { ProgressbarComponent } from "@app2/shared/components/progressbar.component";
import { ReadonlyToggleComponent } from "@app2/shared/components/readonly-toggle.component";
import { RoleNameComponent } from "@app2/shared/components/role-name.component";
import { SavableMultiselectDropdownComponent } from "@app2/shared/components/savable-multiselect-dropdown.component";
import { SearchLinkComponent } from "@app2/shared/components/search-link.component";
import { SeverityEditComponent } from "@app2/shared/components/severity-edit.component";
import { SeverityIconComponent } from "@app2/shared/components/severity-icon.component";
import { SidebarListSectionComponent } from "@app2/shared/components/sidebar-list-section.component";
import { SortOrderArrowsComponent } from "@app2/shared/components/sort-order-arrows.component";
import { SvgIconComponent } from "@app2/shared/components/svg-icon.component";
import { TicketOwnerSelectComponent } from "@app2/shared/components/ticket-owner-select.component";
import { TimePickerComponent } from "@app2/shared/components/time-picker.component";
import { TimezonePickerComponent } from "@app2/shared/components/timezone-picker.component";
import { TooltipDateComponent } from "@app2/shared/components/tooltip-date.component";
import { TypeaheadMultiSelectorComponent } from "@app2/shared/components/typeahead-multi-selector.component";
import { TypeaheadSelectorComponent } from "@app2/shared/components/typeahead-selector.component";
import { TypeaheadSingleSelectorComponent } from "@app2/shared/components/typeahead-single-selector.component";
import { UserBannerComponent } from "@app2/shared/components/user-banner.component";
import { UserDropdownComponent } from "@app2/shared/components/user-dropdown.component";
import { ConfirmationModalComponent } from "@app2/shared/confirmation-modal.component";
import { DsPermissionsRequiredDirective } from "@app2/shared/directives/ds-permissions-required.directive";
import { DsRouterLinkDirective } from "@app2/shared/directives/ds-router-link.directive";
import { DsResizableColumnDirective, DsTableDirective } from "@app2/shared/directives/ds-table.directive";
import { FeatureGateDirective } from "@app2/shared/directives/feature-gate.directive";
import { FilePickerDirective } from "@app2/shared/directives/file-picker.directive";
import { DsLoadingDirective } from "@app2/shared/directives/loading/ds-loading.directive";
import { LoadableDirective } from "@app2/shared/directives/loading/loadable.directive";
import { LoadingPlaceholderComponent } from "@app2/shared/directives/loading/loading-placeholder.component";
import { NumbersOnlyDirective } from "@app2/shared/directives/numbers-only.directive";
import { OnScrollDownDirective } from "@app2/shared/directives/on-scroll-down.directive";
import { TabSelectedDirective } from "@app2/shared/directives/tab-select.directive";
import { DsPageActionsComponent } from "@app2/shared/layout/ds-actions.component";
import { DsBannerComponent } from "@app2/shared/layout/ds-banner.component";
import { DsFullPageComponent } from "@app2/shared/layout/ds-full-page.component";
import { DsPageBannerSectionComponent } from "@app2/shared/layout/ds-page-banner-section.component";
import { DsPageContentComponent } from "@app2/shared/layout/ds-page-content.component";
import { DsPageHeaderComponent } from "@app2/shared/layout/ds-page-header.component";
import { DsPageSectionComponent } from "@app2/shared/layout/ds-page-section.component";
import { DsPageSidebarComponent } from "@app2/shared/layout/ds-page-sidebar.component";
import { DsPageTitleComponent } from "@app2/shared/layout/ds-title.component";
import { DsViewEditPageComponent } from "@app2/shared/layout/ds-view-edit-page.component";
import { LocalPredicateSearchBoxComponent } from "@app2/shared/local-predicate-search-box.component";
import { ModalButtonsComponent } from "@app2/shared/modal/modal-buttons.component";
import { AbbreviatePipe } from "@app2/shared/pipes/abbreviate.pipe";
import { AtDateAndTimePipe } from "@app2/shared/pipes/at-date-and-time.pipe";
import { DropPipe } from "@app2/shared/pipes/drop.pipe";
import { FormatDatePipe } from "@app2/shared/pipes/format-date.pipe";
import { HighlightPipe } from "@app2/shared/pipes/highlight.pipe";
import { IsEmptyPipe } from "@app2/shared/pipes/is-empty.pipe";
import { IsNumberPipe } from "@app2/shared/pipes/is-number.pipe";
import { LastMinuteInHourPipe } from "@app2/shared/pipes/last-minute-in-hour.pipe";
import { LocalizeTemplatePipe } from "@app2/shared/pipes/localization-template.pipe";
import { LogUnknownLogActionPipe } from "@app2/shared/pipes/log-unknown-log-action.pipe";
import { LongDatePipe } from "@app2/shared/pipes/long-date.pipe";
import { MapStringToPairPipe } from "@app2/shared/pipes/map-string-to-pair.pipe";
import { ParseMarkupPipe } from "@app2/shared/pipes/parse-markup.pipe";
import { RatioToPercentPipe } from "@app2/shared/pipes/ratio-to-percent.pipe";
import { SecondsToHumanRangePipe } from "@app2/shared/pipes/seconds-to-human-range.pipe";
import { ShortDateAndTimePipe } from "@app2/shared/pipes/short-date-and-time.pipe";
import { TakePipe } from "@app2/shared/pipes/take.pipe";
import { TimestampPipe } from "@app2/shared/pipes/timestamp.pipe";
import { UtcTimestampToLocalShortPipe } from "@app2/shared/pipes/utc-timestamp-to-local-short.pipe";
import { WrappableShortDatePipe } from "@app2/shared/pipes/wrappable-short-date.pipe";
import { PredicateDropdownMenuComponent } from "@app2/shared/predicate-dropdown-menu.component";
import { SearchBoxComponent } from "@app2/shared/search-box.component";
import { SingleInputModalComponent } from "@app2/shared/single-input-modal.component";
import { SingleSelectModalComponent } from "@app2/shared/single-select-modal.component";
import { LocalizationPipe } from "./pipes/localization.pipe";
import { ShortDatePipe } from "./pipes/short-date.pipe";
import { DsToggleComponent } from "@app2/shared/components/ds-toggle.component";
import { DsSkuRequiredDirective } from "@app2/shared/directives/ds-sku-required.directive";
import { PhoneNumbersComponent } from "@app2/shared/components/phone-numbers.component";
import { PhoneNumbersEditComponent } from "@app2/shared/components/phone-numbers-edit.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMomentDateModule,
        MatSnackBarModule,
        MatTooltipModule,
        OverlayModule,
        ReactiveFormsModule,
        ScrollingModule,
    ],
    declarations: [
        AbbreviatePipe,
        ActionButtonsComponent,
        ActionByComponent,
        ActionOnComponent,
        AdDetectorComponent,
        AtDateAndTimePipe,
        AutocompleteInputComponent,
        ClipboardComponent,
        ConfirmationModalComponent,
        CopyableInputComponent,
        DarkModeToggleComponent,
        DataRestrictionNameComponent,
        DatePickerComponent,
        DatePickerHeaderComponent,
        DateRangeCalendarsComponent,
        DateRangeDropdownComponent,
        DateRangePickerComponent,
        DateRangeSelectorComponent,
        DateSelectionModalComponent,
        DateTimeTzPickerComponent,
        DropPipe,
        DsButtonComponent,
        DsButtonBarComponent,
        DsExpanderComponent,
        DsIconComponent,
        DsItemListComponent,
        DsLabelComponent,
        DsLoadingDirective,
        DsMultiSelectComponent,
        DsResizableColumnDirective,
        DsTableDirective,
        DsToggleComponent,
        DsFullPageComponent,
        DsPageActionsComponent,
        DsPageHeaderComponent,
        DsPageContentComponent,
        DsPageBannerSectionComponent,
        DsBannerComponent,
        DsViewEditPageComponent,
        DsPageSectionComponent,
        DsPageSidebarComponent,
        DsPageTitleComponent,
        DsPermissionsRequiredDirective,
        DsRouterLinkDirective,
        DsSelectComponent,
        DsSingleSelectComponent,
        DsSkuRequiredDirective,
        DsDropdownComponent,
        EmailNotificationListComponent,
        EmailNotificationListModalComponent,
        FaProIconsComponent,
        FeatureGateDirective,
        FilePickerDirective,
        FileUploaderComponent,
        FileAttachmentsSectionComponent,
        FormatDatePipe,
        FormattedHtmlComponent,
        HashDropdownComponent,
        HighlightPipe,
        InfoBannerComponent,
        IntervalSelectorComponent,
        IsEmptyPipe,
        IsNumberPipe,
        LabelEditorComponent,
        LastMinuteInHourPipe,
        LoadableDirective,
        LoadingPlaceholderComponent,
        LocalPredicateSearchBoxComponent,
        LocalizationPipe,
        LocalizeTemplatePipe,
        LogUnknownLogActionPipe,
        LongDatePipe,
        LuceneTextAreaComponent,
        MarkdownHelpLinkTooltipComponent,
        MarkdownHtmlComponent,
        ModalButtonsComponent,
        Ng1ALinkComponent,
        NotificationComponent,
        NotificationIconComponent,
        NotificationMessageComponent,
        NumbersOnlyDirective,
        OnScrollDownDirective,
        OrgDropdownComponent,
        PaginationDetailComponent,
        PaginationSummaryComponent,
        ParseMarkupPipe,
        PersonNameComponent,
        PersonPickerComponent,
        PersonWithDateComponent,
        PhoneNumbersComponent,
        PhoneNumbersEditComponent,
        PrDropdownComponent,
        PredicateDropdownMenuComponent,
        ProgressbarComponent,
        RatioToPercentPipe,
        ReadonlyToggleComponent,
        RecentItemsDropdownComponent,
        RoleNameComponent,
        SearchBoxComponent,
        SearchLinkComponent,
        SecondsToHumanRangePipe,
        SeverityEditComponent,
        SeverityIconComponent,
        SingleInputModalComponent,
        SingleSelectModalComponent,
        ShortDateAndTimePipe,
        ShortDatePipe,
        SidebarListSectionComponent,
        SortOrderArrowsComponent,
        SvgIconComponent,
        TabSelectedDirective,
        TakePipe,
        TicketOwnerSelectComponent,
        TimePickerComponent,
        TimestampDropdownComponent,
        TimestampPipe,
        TimezonePickerComponent,
        TooltipDateComponent,
        TypeaheadSelectorComponent,
        TypeaheadMultiSelectorComponent,
        TypeaheadSingleSelectorComponent,
        UserDropdownComponent,
        UtcTimestampToLocalShortPipe,
        WrappableShortDatePipe,
        UrlDropdownComponent,
        MapStringToPairPipe,
        SavableMultiselectDropdownComponent,
        UserBannerComponent,
        DsRichTextEditorComponent,
        CollapsibleDescriptionComponent,
        DescriptorSelectionDropdownComponent,
    ],
    exports: [
        //Modules
        MatAutocompleteModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        MatTooltipModule,

        // Components
        AbbreviatePipe,
        ActionButtonsComponent,
        ActionByComponent,
        ActionOnComponent,
        AtDateAndTimePipe,
        AutocompleteInputComponent,
        ClipboardComponent,
        CopyableInputComponent,
        DarkModeToggleComponent,
        DataRestrictionNameComponent,
        DatePickerComponent,
        DatePickerHeaderComponent,
        DateRangeCalendarsComponent,
        DateRangeDropdownComponent,
        DateRangePickerComponent,
        DateRangeSelectorComponent,
        DateTimeTzPickerComponent,
        DropPipe,
        DsButtonComponent,
        DsButtonBarComponent,
        DsIconComponent,
        DsItemListComponent,
        DsLabelComponent,
        DsLoadingDirective,
        DsMultiSelectComponent,
        DsResizableColumnDirective,
        DsTableDirective,
        DsFullPageComponent,
        DsPageActionsComponent,
        DsPageBannerSectionComponent,
        DsBannerComponent,
        DsPageHeaderComponent,
        DsPageContentComponent,
        DsViewEditPageComponent,
        DsPageSectionComponent,
        DsPageSidebarComponent,
        DsPageTitleComponent,
        DsPermissionsRequiredDirective,
        DsRouterLinkDirective,
        DsSelectComponent,
        DsSingleSelectComponent,
        DsSkuRequiredDirective,
        DsDropdownComponent,
        EmailNotificationListComponent,
        EmailNotificationListModalComponent,
        FaProIconsComponent,
        FeatureGateDirective,
        FilePickerDirective,
        FileUploaderComponent,
        FormatDatePipe,
        FormattedHtmlComponent,
        HashDropdownComponent,
        HighlightPipe,
        InfoBannerComponent,
        IntervalSelectorComponent,
        IsEmptyPipe,
        IsNumberPipe,
        LabelEditorComponent,
        LastMinuteInHourPipe,
        LoadableDirective,
        LocalPredicateSearchBoxComponent,
        LocalizationPipe,
        LocalizeTemplatePipe,
        LogUnknownLogActionPipe,
        LongDatePipe,
        LuceneTextAreaComponent,
        MarkdownHelpLinkTooltipComponent,
        MarkdownHtmlComponent,
        ModalButtonsComponent,
        Ng1ALinkComponent,
        NotificationComponent,
        NotificationIconComponent,
        NumbersOnlyDirective,
        OnScrollDownDirective,
        OrgDropdownComponent,
        PaginationDetailComponent,
        PaginationSummaryComponent,
        ParseMarkupPipe,
        PersonNameComponent,
        PersonPickerComponent,
        PersonWithDateComponent,
        PhoneNumbersComponent,
        PhoneNumbersEditComponent,
        PrDropdownComponent,
        PredicateDropdownMenuComponent,
        ProgressbarComponent,
        RatioToPercentPipe,
        ReadonlyToggleComponent,
        RoleNameComponent,
        SearchBoxComponent,
        SearchLinkComponent,
        SecondsToHumanRangePipe,
        SeverityEditComponent,
        SeverityIconComponent,
        ShortDateAndTimePipe,
        ShortDatePipe,
        SidebarListSectionComponent,
        SortOrderArrowsComponent,
        SvgIconComponent,
        TakePipe,
        TicketOwnerSelectComponent,
        TimePickerComponent,
        TimestampDropdownComponent,
        TimestampPipe,
        TimezonePickerComponent,
        TooltipDateComponent,
        TypeaheadSelectorComponent,
        TypeaheadMultiSelectorComponent,
        TypeaheadSingleSelectorComponent,
        UserDropdownComponent,
        UtcTimestampToLocalShortPipe,
        WrappableShortDatePipe,
        UrlDropdownComponent,
        MapStringToPairPipe,
        DsExpanderComponent,
        SavableMultiselectDropdownComponent,
        UserBannerComponent,
        FileAttachmentsSectionComponent,
        DsRichTextEditorComponent,
        CollapsibleDescriptionComponent,
        DescriptorSelectionDropdownComponent,
        RecentItemsDropdownComponent,
        AdDetectorComponent,
        DsToggleComponent,
    ],
})
export class SharedModule {
    constructor() {}
}
