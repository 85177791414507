import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "label-editor",
    template: `
        <autocomplete-input *ngIf="!readOnly"
                            [placeholder]="'LABEL_PLACEHOLDER' | localize"
                            [allOptions]="suggestionSet"
                            [excludedOptions]="labels"
                            (optionSelected)="addLabel($event)"
                            (inputBlurred)="blurAddLabel($event)">
        </autocomplete-input>

        <section>
            <span *ngFor="let label of labels"
                  class="applied-label"
                  [attr.test-id]="'label-' + label">
                {{label}}
                <i *ngIf="!readOnly"
                   (click)="removeLabel(label)"
                   class="ion-trash-a alert click-target"
                   test-id="remove-label-button">
                </i>
            </span>
            <em *ngIf="readOnly && !labels.length"
                class="de-emphasize"
                test-id="no-labels">{{ 'NO_LABELS' | localize }}</em>
        </section>
    `,
})
export class LabelEditorComponent {
    @Input() suggestionSet: string[];
    @Input() labels: string[];
    @Input() readOnly: boolean;

    @Output() labelsChange = new EventEmitter<string[]>();

    addLabel(label: string) {
        const newLabel = label.trim();
        if (newLabel && !this.labels.includes(newLabel)) {
            this.labels = [...this.labels, newLabel];
            this.labelsChange.emit(this.labels);
        }
    }

    blurAddLabel(labelInput: HTMLInputElement) {
        this.addLabel(labelInput.value);
        labelInput.value = "";
    }

    removeLabel(label: string) {
        this.labels = this.labels.filter(l => l !== label);
        this.labelsChange.emit(this.labels);
    }
}
