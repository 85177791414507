import { localization } from "@app2/shared/localization/localization";
import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";

export type UserUserFriendlyError =
    AwsIntegrationAlreadyExistsError
    | CannotCreateS3LogBucketError
    | CannotReadWebAuthnRequestError
    | EmailTakenError
    | InvalidApitureApiBaseUrlError
    | InvalidJobDescriptorError
    | InvalidEmailError
    | InvalidAwsAccountIdError
    | InvalidQueryForDataRestrictionError
    | InvalidRoleError
    | InvalidUriError
    | OneTimeCodeNotNumberError
    | PublicKeyCreateRequestNotFoundError
    | RegistrationRequestExpiredError
    | ReadingU2fResponseError
    | WebAuthnRegistrationFailedError;

interface EmailTakenError {
    key: "EmailTaken" | "EMAIL_ALREADY_IN_USE";
}

interface InvalidEmailError {
    key: "InvalidEmail";
}

interface InvalidJobDescriptorError {
    key: "INVALID_JOB_DESCRIPTOR";
}

interface AwsIntegrationAlreadyExistsError {
    key: "AWS_INTEGRATION_ALREADY_EXISTS";
}

interface InvalidAwsAccountIdError {
    key: "INVALID_AWS_ACCOUNT_ID";
}

interface InvalidRoleError {
    key: "INVALID_ROLE" | "ROLE_NOT_FOUND";
}

interface InvalidUriError {
    key: "INVALID_URI";
    uri: string;
}

interface PublicKeyCreateRequestNotFoundError {
    key: "PUBLIC_KEY_CREDENTIAL_CREATE_REQUEST_NOT_FOUND";
    personId: uuid;
}

interface OneTimeCodeNotNumberError {
    key: "ONE_TIME_CODE_NOT_VALID_NUMBER";
}

interface RegistrationRequestExpiredError {
    key: "REGISTRATION_REQUEST_EXPIRED_OR_NONEXISTENT";
}

interface ReadingU2fResponseError {
    key: "CANNOT_READ_RESPONSE";
}

interface CannotReadWebAuthnRequestError {
    key: "CANNOT_READ_REQUEST";
}

interface WebAuthnRegistrationFailedError {
    key: "WEB_AUTHENTICATION_REGISTRATION_FAILED";
}

interface InvalidApitureApiBaseUrlError {
    key: "INVALID_API_BASE_URL";
}

interface CannotCreateS3LogBucketError {
    key: "CANNOT_CREATE_S3_LOG_BUCKET";
}

interface InvalidQueryForDataRestrictionError {
    key: "InvalidQuery";
    field: "blacklistQueryString" | "whitelistQueryString";
}

export function getMessageForUserUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "AWS_INTEGRATION_ALREADY_EXISTS":
            return localization.getString("AWS_INTEGRATION_ALREADY_EXISTS");
        case "CANNOT_CREATE_S3_LOG_BUCKET":
            return localization.getString("S3_BUCKET_CREATION_ERROR");
        case "CANNOT_READ_REQUEST":
            return localization.getString("CANNOT_READ_WEBAUTHN_REQUEST");
        case "INVALID_API_BASE_URL":
            return localization.getString("INVALID_APITURE_BASE_URL");
        case "INVALID_AWS_ACCOUNT_ID":
            return localization.getString("NO_SUCH_AWS_ACCOUNT_ID");
        case "INVALID_ROLE":
        case "ROLE_NOT_FOUND":
            return localization.getString("INVALID_ROLE_ERROR");
        case "INVALID_URI":
            return localization.getString("INVALID_INTEGRATIONS_URI");
        case "ONE_TIME_CODE_NOT_VALID_NUMBER":
            return localization.getString("ONE_TIME_CODE_NOT_NUMBER");
        case "PUBLIC_KEY_CREDENTIAL_CREATE_REQUEST_NOT_FOUND":
            return localization.getString("CANNOT_FIND_PUBLIC_KEY_REQUEST");
        case "REGISTRATION_REQUEST_EXPIRED_OR_NONEXISTENT":
            return localization.getString("CANNOT_FIND_REGISTRATION_REQUEST");
        case "CANNOT_READ_RESPONSE":
            return localization.getString("CANNOT_READ_WEBAUTHN_RESPONSE");
        case "WEB_AUTHENTICATION_REGISTRATION_FAILED":
            return localization.getString("WEBAUTHN_FAILURE");
        case "EmailTaken":
        case "EMAIL_ALREADY_IN_USE":
            return localization.getString("EMAIL_TAKEN");
        case "InvalidEmail":
            return localization.getString("EMAIL_INVALID");
        case "InvalidQuery":
            return localization.getString("INVALID_RESTRICTION_QUERY");
        case "INVALID_JOB_DESCRIPTOR":
            return localization.getString("INVALID_JOB_DESCRIPTOR_ERROR");
    }
}
