
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { hostIpRegex, macAddressRegex } from "@app2/util/constants";

export function ipValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // We allow empty IPs in the case the MAC is present
        if (!control.value?.trim()) {
            return null;
        }
        // Ensure the regex has a full match on the string, not just a substring matches
        const match = hostIpRegex.exec(control.value);
        if (match && match[0] === control.value) {
            return null;
        }
        return { badIp: true };
    };
}

export function macValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // We allow empty MACs in the case the IP is present
        if (!control.value?.trim()) {
            return null;
        }
        // Ensure the regex has a full match on the string, not just a substring matches
        const match = macAddressRegex.exec(control.value);
        if (match && match[0] === control.value) {
            return null;
        }
        return { badMac: true };
    };
}

export function ipOrMacRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const hasIp = !!control.value.ip?.trim();
        const hasMac = !!control.value.mac?.trim();
        return (hasIp || hasMac) ? null : { ipOrMacRequired: true };
    };
}
