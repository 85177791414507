import * as _ from "underscore";
import { UsersById } from "@app2/account/people.service";

export function hasPermissions(permissionsRequired: string, permissionsOwned: string[]): boolean {
    if (!permissionsRequired || !permissionsOwned) {
        return false;
    }

    const upperCasePermissionsRequired = permissionsRequired.toUpperCase();
    const satisfyAnyCondition = upperCasePermissionsRequired.startsWith("ANY: ");
    const trimmedConditions = (satisfyAnyCondition) ? upperCasePermissionsRequired.substring(5) : upperCasePermissionsRequired;
    const permissionsToCheck = trimmedConditions.split(/,\s*/);

    const { positiveConditionsPossiblyUndefined, negativeConditionsWithPrefix } =
        _.groupBy(permissionsToCheck, p => p[0] === "!" ? "negativeConditionsWithPrefix" : "positiveConditionsPossiblyUndefined");
    const positiveConditions = positiveConditionsPossiblyUndefined || [];
    const negativeConditions = _.map(negativeConditionsWithPrefix, p => p.substr(1));

    const satisfiedPositiveConditions = _.intersection(positiveConditions, permissionsOwned);
    const satisfiedNegativeConditions = _.difference(negativeConditions, permissionsOwned);

    const hasSatisfiedAnyConditions = satisfiedPositiveConditions.length > 0 || satisfiedNegativeConditions.length > 0;
    const hasSatisfiedAllConditions = satisfiedPositiveConditions.length === positiveConditions.length && satisfiedNegativeConditions.length === negativeConditions.length;
    return satisfyAnyCondition ? hasSatisfiedAnyConditions : hasSatisfiedAllConditions;
}

export function getName(people: UsersById, id: uuid, preferFullName: boolean = true) {
    const person = people[id];
    if (!person) {
        return "";
    }

    if (preferFullName) {
        return person.fullName;
    }

    return person.shortName || person.fullName;
}
