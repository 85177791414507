export const cloudtrailTemplate = `
<section class="integration-modal-setup">
    <p>To connect your AWS Cloudtrail to your DefenseStorm account, follow these steps:</p>
    <div class="flex-container">
        <section class="flex-child-same-width">
            <h3>Step One</h3>
            <ul>
                <li>
                    <a class="link-text" href="https://console.aws.amazon.com/cloudtrail/home#/configuration" target="_new">
                    Follow this link to the AWS CloudTrail Trails Configuration.</a>
                </li>
                <li>Click the <strong>Add New Trail</strong> button.</li>
                <li>Click <strong>Advanced &raquo;</strong> to expose more options.</li>
            </ul>
        </section>
        <section class="flex-child-same-width">
            <h3>Step Two</h3>
                <ul>
                    <li>
                        <strong>Fill in the following values: </strong>
                    </li>
                    <ul>
                        <li>Trail name: <em>Doesn't matter</em></li>
                        <li>Apply trail to all regions: <em>Yes</em></li>
                        <li>Create new S3 bucket: <em>No</em></li>
                        <li>Encrypt log files: <em>No</em>
                            <ul>
                                <li class="no-bullet">
                                    <em>(These log files are temporary, and will be deleted after they have been read.)</em>
                                </li>
                            </ul>
                        </li>
                        <li>Enable log validation: <em>Either</em>
                            <ul>
                                <li class="no-bullet"><em>Both scenarios are supported.</em></li>
                            </ul>
                        </li>
                        <li>Publish to SNS: <em>No</em></li>
                        <li>Log file prefix: <code>{0}</code></li>
                    </ul>
                </ul>
        </section>
        <section class="flex-child-same-width">
            <h3>Step Three</h3>
            <ul>
                <li>In the <strong>S3 bucket</strong> field paste <code>praesidio-customer-cloudtrails</code></li>
                <li>Hit return, rather than selecting a value from the dropdown.
                    AWS will ask you to verify that you want to send logs to a
                    bucket in another account.
                </li>
                <li>Select <strong>Confirm</strong>.</li>
                <li>Click <strong>Create</strong>.</li>
            </ul>
        </section>
    </div>
    <p>
        Watch this page to make sure that DefenseStorm is receiving CloudTrail information. It may take up to
        10 minutes for Amazon to send us the first log file.
    </p>

    <h3>Troubleshooting</h3>
    <ul class="troubleshooting">
        <li>
            If you see the error <code>Bucket already exists</code> make sure that <strong>Create new S3 bucket</strong> is set
            to <strong>No</strong>.
        </li>
        <li>
            If you see the error <code>Bucket doesn't exist, choose a different bucket</code> make sure that you've entered the
            bucket name correctly.
        </li>
        <li>
            If you see the error <code>There is a problem with the bucket policy</code> make sure that you've entered the log
            file prefix correctly.
        </li>
        <li>
            If you have any other problems, <a class="link-text" href="{1}" target="_new">contact DefenseStorm Customer Support</a>
            for additional help.
        </li>
    </ul>
</section>
`;
