import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "saml-redirect-page",
    template: `
        <div class="row">
            <div class="padded sheet small-16 medium-5 medium-centered columns">
                {{ "SAML_REDIRECT" | localize: ssoName }}
            </div>
        </div>
    `,
})
export class SamlRedirectPageComponent implements OnInit {
    ssoName: string = "";

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        this.ssoName = queryParams.ssoName;
        setTimeout(() => {
            let ssoUrl: string = queryParams.ssoUrl;
            if (queryParams.samlRequest) {
                ssoUrl += `?SAMLRequest=${ encodeURIComponent(queryParams.samlRequest) }`;
            }
            window.location.href = ssoUrl;
        }, 2000);
    }
}
