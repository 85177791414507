export const windows2faTemplate = `
<h3>Requirements</h3>
<p>To use Microsoft Authenticator on your Windows Phone, you must have Windows Phone 7 or later.</p>

<h3>Downloading the app</h3>
<ol>
    <li>Visit the Windows Phone Store</li>
    <li>
        Search for <strong>Authenticator</strong>, and make sure you select the app made by
        <strong>Microsoft Corporation</strong>.
    </li>
    <li>Download and install the application.</li>
</ol>

<h3>Setting up the app</h3>
<ol>
    <li>On your phone, open the Authenticator application.</li>
    <li>Tap the plus icon.</li>
    <li>To link your phone to your account:
        <ul>
            <li>
                <strong>Using Barcode</strong>: Tap the camera icon on the bottom of the screen, and then point your
                camera at the QR code on your computer screen.
            </li>
            <li>
                <strong>Using Manual Entry</strong>: Enter the email address of your DefenseStorm account below
                "Enter account name". Then, enter the secret code below "Secret key". Then click the save icon.
            </li>
        </ul>
    </li>
</ol>
`;
