import { Attribute, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router, RouterLink, UrlTree } from "@angular/router";
import { OrgsService } from "@app2/account/orgs.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LocationStrategy } from "@angular/common";

/**
 * Used the original routerLink as a reference and adapted/trimmed down from there:
 * https://github.com/angular/angular/blob/master/packages/router/src/directives/router_link.ts
 */
@UntilDestroy()
@Directive({ selector: "[dsRouterLink]" })
export class DsRouterLinkDirective extends RouterLink {

    constructor(router: Router,
                private activatedRoute: ActivatedRoute,
                @Attribute("tabindex") tabIndex: string,
                renderer: Renderer2,
                el: ElementRef,
                private orgsService: OrgsService,
                locationStrategy?: LocationStrategy) {
        super(router, activatedRoute, tabIndex, renderer, el, locationStrategy);

        orgsService.getCurrentOrg$()
            .pipe(untilDestroyed(this))
            .subscribe(() => super.ngOnChanges({}));
    }

    @Input()
    set dsRouterLink(commands: any[] | string | null | undefined) {
        super.routerLink = commands;
    }

    get urlTree(): UrlTree {
        const urlTree = super.urlTree;
        if (!urlTree) {
            return urlTree;
        }

        const orgId = this.orgsService.getCurrentOrg()?.id;
        if (orgId && !urlTree.queryParams.orgId) {
            urlTree.queryParams.orgId = this.orgsService.getCurrentOrg()?.id;
        }

        const { feature } = this.activatedRoute.snapshot.queryParams;
        if (feature) {
            urlTree.queryParams.feature = feature;
        }
        return urlTree;
    }
}
