import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorTrackingService } from "@app2/shared/services/error-tracking.service";

@Injectable()
export class HttpErrorTrackerInterceptor implements HttpInterceptor {

    constructor(private errorTrackingService: ErrorTrackingService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    // Report HTTP errors 4xx and 5xx except 401 as that is a normal error we use to know if the
                    // authorization cookies are still valid

                    // remove anything with numbers so rollbar can group ignoring ids - if something does make it
                    // through then we can manually group it with the generalized url through the rollbar ui
                    const parameterizedUrl = request.url.split("/")
                        .map(segment => /\d/.test(segment) ? "{param}" : segment)
                        .join("/");
                    if (error.status !== 401 && error.status >= 400 && error.status <= 599) {
                        this.errorTrackingService.sendError(`HTTP ${ error.status }: ${ error.url }`, {
                            error,
                            method: request.method,
                            parameterizedUrl,
                        }, false);
                    }
                    return throwError(error);
                })
            );
    }
}
