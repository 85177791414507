import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AuthStateService } from "@app2/account/auth-state.service";
import { catchError } from "rxjs/operators";

const noLogoutPatterns = [/login/, /logout/, /user$/];

@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {
    constructor(private readonly authStateService: AuthStateService) {
    }

    private handleAuthError(req: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 && noLogoutPatterns.every(pattern => !pattern.test(req.url))) {
            this.authStateService.logOut();
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(catchError(error => this.handleAuthError(req, error)));
    }
}
