import * as _ from "underscore";

function transformObject(obj: any, transformer: (name: string) => string, exclude?: string | string[]): any {
    if (!obj || _.isEqual(exclude, ["*"])) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(x => transformObject(x, transformer, exclude));
    }

    if (typeof obj !== "object") {
        return obj;
    }

    return _.chain(obj)
        .pairs()
        .map(function (pair) {
            const name = pair[0];
            let value = pair[1];
            if (typeof value === "object" && exclude !== name && !_.contains(exclude, name)) {
                value = transformObject(value, transformer, exclude);
            }
            return [transformer(name), value];
        })
        .object()
        .value();
}

/**
 * Transforms object property names from snake to camel case.
 *
 * Note: This can be harmful to objects with mixed casing, use exclude to protect non-snake property names.
 *
 * @param {*} snakeObj - the original object with snake-cased property names
 * @param {string|string[]=} exclude - either a string property name or an array of string property names
 *  the _children_ of which will not be modified
 * @returns {*} the input object with all property names converted from snake to camel case
 */
export function snakeToCamel(snakeObj: any, exclude?: string | string[]): any {
    return transformObject(snakeObj, fieldNameToCamel, exclude);
}

export function fieldNameToCamel(str) {
    return str.toLowerCase()
        .replace(/_(.)/g, (match, group1) => group1.toUpperCase())
        .replace(/@/, "");
}

/**
 * Transforms object property names from camel to snake case.
 *
 * Note: This can be harmful to objects with mixed casing, use exclude to protect non-camel property names.
 *
 * @param {*} camelObj the original object with camel-cased property names
 * @param {string|string[]=} exclude either a string property name or an array of string property names
 *  the _children_ of which will not be modified
 * @returns {*} the input object with all property names converted from camel to snake case
 */
export function camelToSnake(camelObj: any, exclude?: string | string[]): any {
    return transformObject(camelObj, fieldNameToSnake, exclude);
}

export function fieldNameToSnake(str) {
    return str.replace(/([A-Z])/g, (match, group1) => "_" + group1.toLowerCase());
}
