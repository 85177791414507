import { Component, OnInit } from "@angular/core";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { UserService } from "@app2/account/user.service";

@Component({
    selector: "readonly-toggle",
    template: `
        <a (click)='toggle()' class="toggleable-menu-item">
            {{ 'READONLY_MODE' | localize }}
            <span *ngIf="enabled" class="toggle-on">{{'READONLY_MODE_ON' | localize}}</span>
            <span *ngIf="!enabled" class="toggle-off">{{'READONLY_MODE_OFF' | localize}}</span>
        </a>
    `,
})
export class ReadonlyToggleComponent implements OnInit {
    enabled = false;
    userServiceJS: any;
    constructor(private readonly storageService: StorageService,
                private readonly userService: UserService) {
    }

    ngOnInit() {
        const initialValue = this.storageService.getShared(Keys.readonlyMode);
        this.enabled = initialValue === undefined ? false : initialValue;
    }

    toggle() {
        this.enabled = !this.enabled;
        this.storageService.setShared(Keys.readonlyMode, this.enabled);
        this.userService.filterPermissions();
    }
}
