// This file is used when the console is built with "ng build --prod". Otherwise, "environment.ts" is used.
export const environment = {
    production: true,
    envName: "production",
    appVersion: require("../../package.json").version,
    hideAppVersion: true,

    // Service urls
    alertService: "https://alert.prod.defensestorm.com",
    assetService: "https://asset.prod.defensestorm.com",
    integrationsMgmtService: "https://integrations-mgmt.prod.defensestorm.com",
    policyService: "https://policy.prod.defensestorm.com",
    reportService: "https://report.prod.defensestorm.com",
    revealService: "https://reveal.prod.defensestorm.com",
    restoreService: "https://restore.prod.defensestorm.com",
    searchService: "https://search.prod.defensestorm.com",
    surveyService: "https://survey.prod.defensestorm.com",
    ticketService: "https://ticket.prod.defensestorm.com",
    threatService: "https://threat.prod.defensestorm.com",
    userService: "https://user.prod.defensestorm.com",
    metadataService: "https://metadata.prod.defensestorm.com",
    insightService: "https://insight.prod.defensestorm.com",
    mlIncidentSimilarityService: "https://incident.prod.defensestorm.com",

    // Console urls
    azureRedirectUri: "https://console.defensestorm.com/settings/integrations/azure",
    salesForceRedirectUri: "https://console.defensestorm.com/settings/integrations/salesforce",
    salesForceSandboxRedirectUri: "https://console.defensestorm.com/settings/integrations/salesforce-sandbox",
    oktaRedirectUri: "https://dev-184950.okta.com/home/defensestormdev184950_defensestorm_1/0oa12w6l9erT7Krsa4x7/aln12wcp0gfbcrnNY4x7",
    consoleUrl: "https://console.defensestorm.com",

    // Public Keys and Ids
    heapAppId: "3382717587",
    mapbox: {
        mapId: "enazario/ckazy9naa08k51imfb4igkb8i",
        publicKey: "pk.eyJ1IjoiZW5hemFyaW8iLCJhIjoiSVJhR0g0WSJ9.JV9xQOAIF08TlcrzxSPZIA",
    },
    azureO365ApplicationId: "57ee65ce-4626-42c9-8df1-4e4163626fd2",
    azureStorageApplicationId: "bc251694-d0db-4306-ac0f-46b074b75d1d",
    salesForceApplicationId: "3MVG9LBJLApeX_PDPKJ0bcL0V1a115I.Tbad_MD1cyUL8aVHPLpfx.euffsnctuLpHhxsJ8pHYRoVOPjMPyRl",
    rollbarToken: "c6a1c89e9c7540cfbbd2cf152cb2efc6",

    // Other
    feedbackEmail: "feedback@defensestorm.com",

    // User Id for features only accessible from the DefenseStorm TRAC org:
    tracEngineerUserId: "977287f2d4654d10ae39179c3b28884b",

    tracOrgId: "3da3b9e330704ef7966745ef6b5fef32",

    // Watchlist related config
    maxWatchlistUploadSize: "32MiB", // watchlistUploadMaxSizeBytes
    maxWatchlistItems: 30000, // watchlistMaxItems
    watchlistItemWarningThreshold: 29000, // some number less than maxWatchlistItems

    // Dashboards related config
    defaultWorkspaceId: "ad1a1a26e2454669860e508f91f6b320", // GRID Monitoring workspace
    defaultDashboardId: "15637397423a432c93ace378d68d19b0", // GRID Monitoring dashboard

    maxTicketCsvDownloadSize: 1000, // CSV_DOWNLOAD_SIZE variable in ticket service
};
