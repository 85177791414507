import { ProductSku } from "@app2/type-defs/user/user-types";
import { TimeService } from "@app2/shared/services/time.service";

export function hasAnyFraudSku(skus: ProductSku[]) {
    return skus.includes("CYBERFRAUD_STANDARD") || skus.includes("CYBERFRAUD_PREMIUM");
}

export function getBeginDate(timestamp: date, time: TimeService): date {
    return time.utcMoment(timestamp).startOf("day").toISOString();
}

export function getEndDate(timestamp: date, time: TimeService): date {
    return time.utcMoment(timestamp).endOf("day").toISOString();
}
