import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: "hostnames-edit",
    template: `
        <form class="nice-form"
              [formGroup]="hostnamesForm">
            <table class="hover-table">
                <thead>
                <tr>
                    <th class="hover-tab"></th>
                    <th colspan>{{ 'ASSET_HOSTNAME' | localize }}</th>
                    <th class="button-col add-button click-target"
                        (click)="addHostname('')"
                        test-id="add-hostname">
                        <i class="fas fa-plus"></i>
                    </th>
                </tr>
                </thead>
                <tbody formArrayName="hostnames"
                       class="dont-add-error-border">
                <tr *ngFor="let hostname of hostnameControls.controls; let i=index"
                    class="dont-add-error-border">
                    <td class="hover-tab"></td>
                    <td>
                        <input type="text"
                               [formControlName]="i"
                               [placeholder]="'ASSET_HOSTNAME_PLACEHOLDER' | localize"
                               [attr.test-id]="'hostname-' + i"/>
                    </td>
                    <td class="button-col">
                        <div class="click-target"
                             (click)="removeHostname(i)"
                             [attr.test-id]="'delete-hostname-' + i">
                            <i class="fas fa-trash alert"></i>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="hostnameControls.length === 0">
                    <td class="no-data-text" colspan="3">{{ 'ASSET_NO_HOSTNAME' | localize }}</td>
                </tr>
                </tbody>
            </table>
        </form>
    `,
})
export class HostnamesEditComponent implements OnInit {
    @Input() hostnames: string[];

    hostnamesForm = this.fb.group({
        hostnames: this.fb.array([]),
    });

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.hostnames.forEach(hostname => this.addHostname(hostname));
    }

    public getFormGroup(): FormArray {
        return this.hostnameControls;
    }

    get hostnameControls(): FormArray {
        return this.hostnamesForm.get("hostnames") as FormArray;
    }

    addHostname(hostname: string) {
        this.hostnameControls.push(this.fb.control(hostname, Validators.required));
    }

    removeHostname(index: number) {
        this.hostnameControls.removeAt(index);
    }
}
