import { HttpStatusCode } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "@app2/account/user.service";
import { AssetClientService } from "@app2/clients/asset-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { Asset } from "@app2/type-defs/assets/assets-types";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash-es";

interface DialogData {
    modalTitleKey: string;
    asset: Asset;
    labelList: string[];
}

@UntilDestroy()
@Component({
    selector: "asset-add-edit-modal",
    template: `
        <span mat-dialog-title>
            {{ data.modalTitleKey | localize }}
        </span>
        <mat-dialog-content class="no-top-padding">
            <ng-container *dsPermissionsRequired="'ASSET_EDIT'">
                <div *ngIf="!isNew && asset.tracked" class="edit-toggle-container">
                    <button class="link" (click)="toggleEditing()"
                            test-id="toggle-editing-button">
                        <i [ngClass]="isEditing ? 'fas fa-xmark' : 'fas fa-pen'"></i>
                    </button>
                </div>
            </ng-container>
            <section>
                <mat-tab-group mat-stretch-tabs disableRipple animationDuration="0ms">
                    <mat-tab [label]="'ASSET_INFO_TAB' | localize">
                        <ng-template matTabContent>
                            <asset-details-view *ngIf="!isEditing"
                                                [asset]="asset"
                                                (toggleEditing)="toggleEditing()">
                            </asset-details-view>
                            <asset-details-edit *ngIf="isEditing"
                                                [(asset)]="asset"
                                                [labelList]="data.labelList"
                                                (formValidityChange)="changeFormValidity($event)">
                            </asset-details-edit>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [label]="'ASSET_IP_TAB' | localize">
                        <ng-template matTabContent>
                            <ip-mac-pairs-view *ngIf="!isEditing"
                                               [ipMacPairs]="asset.ipMacPairs">
                            </ip-mac-pairs-view>
                            <ip-mac-pairs-edit *ngIf="isEditing"
                                               [(ipMacPairs)]="asset.ipMacPairs"
                                               (formValidityChange)="isIpMacFormValid = $event">
                            </ip-mac-pairs-edit>
                            <div *ngIf="ipOrMacInUse" class="form-error-text text-center">
                                {{ 'ASSET_IP_OR_MAC_IN_USE' | localize}}
                            </div>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="!isNew"
                             [label]="'ASSET_SOFTWARE_TAB' | localize">
                        <ng-template matTabContent>
                            <asset-software-list [assetId]="asset.id"></asset-software-list>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </section>
        </mat-dialog-content>
        <mat-dialog-actions>
            <modal-buttons [canSave]="isDetailsFormValid && isIpMacFormValid && hasEditPerms"
                           (onCancel)="onCancel()"
                           (onSave)="onSave()"
                           [saveInProgress]="saving">
            </modal-buttons>
        </mat-dialog-actions>
    `,
    host: {
        class: "asset-details-modal",
    },
    styles: [`
      /* As there is a button at the top, it already acts as padding */
      .no-top-padding {
        padding-top: 0;
      }

      .edit-toggle-container {
        display: flex;
        justify-content: flex-end;

        button {
          padding: 5px;
        }
      }
    `],
})
export class AssetAddEditModalComponent implements OnInit {
    asset: Asset;
    isEditing: boolean;
    isDetailsFormValid: boolean = true;
    isIpMacFormValid: boolean = true;
    ipOrMacInUse: boolean;
    hasEditPerms: boolean;
    saving: boolean;

    constructor(private dialogRef: MatDialogRef<AssetAddEditModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData,
                private assetClient: AssetClientService,
                private cdr: ChangeDetectorRef,
                private notificationsService: NotificationsService,
                userService: UserService) {
        this.asset = cloneDeep(data.asset);

        userService.hasPermission$("ASSET_EDIT")
            .pipe(untilDestroyed(this))
            .subscribe(hasPermission => this.hasEditPerms = hasPermission);
    }

    ngOnInit() {
        this.isEditing = this.isNew || !this.asset.tracked;
    }

    get isNew(): boolean {
        return !this.asset.id;
    }

    toggleEditing(): void {
        this.isEditing = !this.isEditing;
    }

    onSave(): void {
        this.saving = true;
        const promise = this.isNew
            ? this.assetClient.createAsset(this.asset)
            : this.assetClient.updateAsset(this.asset);
        promise
            .then(asset => this.dialogRef.close(asset),
                response => {
                    if (response.status === HttpStatusCode.Conflict) {
                        this.ipOrMacInUse = true;
                    } else {
                        const message = getMessageForError(response.error, "ERROR_SAVING_ASSET");
                        this.notificationsService.showError(message, response);
                    }
                })
            .finally(() => this.saving = false);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    changeFormValidity(validity: boolean): void {
        this.isDetailsFormValid = validity;
        this.cdr.detectChanges();
    }
}
