import { Pipe, PipeTransform } from "@angular/core";
import { ShortDatePipe } from "@app2/shared/pipes/short-date.pipe";
import { TimeService } from "@app2/shared/services/time.service";
import * as _ from "underscore";

@Pipe({ name: "wrappableShortDate" })
export class WrappableShortDatePipe implements PipeTransform {
    constructor(private readonly timeService: TimeService) {
    }

    transform(date: string) {
        return WrappableShortDatePipe.transformImpl(date, this.timeService);
    }

    public static transformImpl(date: string , time: any) {
        // &#8203; is a zero-width space which allows wrapping at that point without without using
        // word-wrap: break-word; or adding visible spaces.
        if (_.isUndefined(date) || _.isNull(date)) { return date; }
        return ShortDatePipe.transformImpl(date, time).replace(/[:-]/g, "&#8203$&&#8203;");
    }
}
