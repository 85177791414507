import { Component } from "@angular/core";
import { InactivityTimeoutService } from "@app2/shared/services/inactivity-timeout.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "inactivity-timeout-toggle",
    template: `
        <a (click)="toggleTimeout()" class="toggleable-menu-item">
            {{ "INACTIVITY_TIMEOUT" | localize }}
            <span *ngIf="enabled" class="toggle-on">
                {{ "INACTIVITY_TIMEOUT_ON"  | localize }}
            </span>
            <span *ngIf="!enabled" class="toggle-off">
                {{ "INACTIVITY_TIMEOUT_OFF"  | localize }}
            </span>
        </a>
    `,
})
export class InactivityTimeoutToggleComponent {
    enabled: boolean;

    constructor(private readonly inactivityTimeoutService: InactivityTimeoutService) {
        this.inactivityTimeoutService.getTimeoutEnabled$()
            .pipe(untilDestroyed(this))
            .subscribe(enabled => {
                this.enabled = enabled;
            });
    }

    toggleTimeout() {
        this.enabled = !this.enabled;
        this.inactivityTimeoutService.setTimeoutEnabled(this.enabled);
    }
}
