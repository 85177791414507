import { Component } from "@angular/core";
import { UserClientService } from "@app2/clients/user-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Component({
    selector: "forgot-password-page",
    template: `
        <div class="row">
            <div class="padded sheet small-16 medium-5 medium-centered columns">
                <form #forgotPasswordForm="ngForm" name="forgotPassword" role="form">
                    <input required autofocus
                           type="email" id="email" name="email"
                           [email]="true"
                           [placeholder]="'EMAIL' | localize"
                           [(ngModel)]="email"/>
                    <button type="submit"
                            class="button"
                            [disabled]="forgotPasswordForm.invalid"
                            (click)="sendEmail()">
                        {{ "RESET_PASSWORD" | localize }}
                    </button>
                    <a dsRouterLink="/login">{{ 'LOGIN' | localize }}</a>
                </form>
            </div>
        </div>
    `,
    styles: [`
        button {
            margin-top: 8px
        }
    `],
})
export class ForgotPasswordPageComponent {
    email: string;

    constructor(public notificationsService: NotificationsService,
                public userClient: UserClientService) {
    }

    sendEmail(): void {
        this.userClient.sendForgotPasswordEmail(this.email)
            .then(() => this.notificationsService.showSuccess("FORGOT_EMAIL_SENT", this.email))
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_SENDING_FORGOT_PASSWORD_EMAIL");
                this.notificationsService.showError(message, error);
            });
    }
}
