import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { localStorageUtils } from "@app2/util/local-storage";
import { StorageServiceKeys as Keys } from "@app2/util/storage-service-keys";

export { Keys };

@Injectable({
    providedIn: "root",
})
export class StorageService {

    constructor(private userService: UserService,
                private orgsService: OrgsService) {
    }

    // get/set stores at a userId-orgId level
    set(key: string, data: any): void {
        localStorageUtils.setUserOrgLocalStorageData(key, this.userService.getCurrentUser().id, this.orgsService.getOrgsInfoSnapshot().currentOrg.id, data);
    }

    get(key: string): any {
        return localStorageUtils.getUserOrgLocalStorageData(key, this.userService.getCurrentUser().id, this.orgsService.getOrgsInfoSnapshot().currentOrg.id);
    }

    keyExists(key: string): boolean {
        return localStorageUtils.keyExistsInUserOrgLocalStorageData(key, this.userService.getCurrentUser().id, this.orgsService.getOrgsInfoSnapshot().currentOrg.id);
    }

    // getShared/setShared stores at a userId level
    setShared(key: string, data: any): void {
        localStorageUtils.setUserLocalStorageData(key, this.userService.getCurrentUser().id, data);
    }

    getShared(key: string): any {
        return localStorageUtils.getUserLocalStorageData(key, this.userService.getCurrentUser().id);
    }
}
