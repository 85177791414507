import { Component, Input, OnInit, ViewContainerRef } from "@angular/core";
import { AssetAddEditModalComponent } from "@app2/asset/asset-add-edit-modal/asset-add-edit-modal.component";
import { AssetClientService } from "@app2/clients/asset-client.service";
import { ModalService } from "@app2/shared/modal/modal.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { TimeService } from "@app2/shared/services/time.service";
import { Asset } from "@app2/type-defs/assets/assets-types";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { cloneDeep } from "lodash-es";
import { ErrorTrackingService } from "@app2/shared/services/error-tracking.service";
import { Moment } from "@app2/util/legacy-moment";

@Component({
    selector: "asset-dropdown",
    template: `
        <span *ngFor="let asset of assets; index as i; first as isFirst; last as isLast;">
            <pr-dropdown>
                <span *ngIf="isFirst">(</span>
                <a class="inline" pr-dropdown-trigger>{{ asset.name ? asset.name : ("UNTRACKED_ASSET" | localize) }}</a>
                <span *ngIf="!isLast">, </span>
                <span *ngIf="isLast">)</span>
                <ul pr-dropdown-body>
                    <li class="no-hover">{{ 'PIVOT_ON_ASSET_DESCR' | localize }}</li>
                    <li *ngIf="!(assetDropdownIps(asset) | isEmpty)"
                        class="indented">
                        <search-link [queryString]="joinAssetTerms(assetDropdownIps(asset))"
                                     [begin]="pivotBegin" [end]="pivotEnd" [linkClass]="false">
                            <i class="fa fa-search visible-icon"></i>
                            {{ 'PIVOT_ON_IPS' | localize }}
                        </search-link>
                    </li>
                    <li *ngIf="!(assetDropdownMacs(asset) | isEmpty)"
                        class="indented">
                        <search-link [queryString]="joinAssetTerms(assetDropdownMacs(asset))"
                                     [begin]="pivotBegin" [end]="pivotEnd" [linkClass]="false">
                            <i class="fa fa-search visible-icon"></i>
                            {{ 'PIVOT_ON_MACS' | localize }}
                        </search-link>
                    </li>
                    <li *ngIf="!(asset.hostnames | isEmpty)"
                        class="indented">
                        <search-link [queryString]="joinAssetTerms(asset.hostnames)"
                                     [begin]="pivotBegin" [end]="pivotEnd" [linkClass]="false">
                            <i class="fa fa-search visible-icon"></i>
                            {{ 'PIVOT_ON_HOSTNAME' | localize }}
                        </search-link>
                    </li>
                    <li *ngIf="!(asset.ipMacPairs | isEmpty) || !(asset.hostnames | isEmpty)"
                        class="indented">
                        <search-link [queryString]="joinAssetTerms(assetDropdownAllTerms(asset))"
                                     [begin]="pivotBegin" [end]="pivotEnd" [linkClass]="false">
                            <i class="fa fa-search visible-icon"></i>
                            {{ 'PIVOT_ON_ASSET' | localize }}
                        </search-link>
                    </li>
                    <li *ngIf="asset.tracked" (click)="openAssetModal(asset, i)">
                        <i class="ion-cube visible-icon"></i>
                        {{ 'ASSET_DETAILS' | localize }}
                    </li>
                </ul>
            </pr-dropdown>
        </span>
    `,
})
export class AssetDropdownComponent implements OnInit {
    @Input() eventTime: date | number;
    //should have one of:
    @Input() ip: string;
    @Input() mac: string;
    @Input() hostname: string;

    assets: Asset[] = [];
    pivotBegin: string;
    pivotEnd: string;

    constructor(private readonly time: TimeService,
                private readonly assetClient: AssetClientService,
                private readonly modalService: ModalService,
                private readonly notificationsService: NotificationsService,
                private readonly errorTrackingService: ErrorTrackingService,
                private viewContainerRef: ViewContainerRef) {
    }

    ngOnInit() {
        let eventTime = this.getEventTime(this.eventTime);
        let pivotTime = eventTime || this.time.now();
        this.pivotBegin = pivotTime.clone().subtract(5, "minutes");
        this.pivotEnd = pivotTime.clone().add(5, "minutes");

        if (this.ip) {
            this.assetClient.getAssetsForIp(this.ip, eventTime?.toISOString())
                .then(ipAssets => this.assets = this.assets.concat(ipAssets))
                .catch(error =>
                    this.errorTrackingService.sendError("Failed call to asset-service by IP", error));
        }
        if (this.mac) {
            this.assetClient.getAssetsForMac(this.mac, eventTime?.toISOString())
                .then(macAssets => this.assets = this.assets.concat(macAssets))
                .catch(error =>
                    this.errorTrackingService.sendError("Failed call to asset-service by MAC", error));
        }
        if (this.hostname) {
            this.assetClient.getAssetsForHostname(this.hostname)
                .then(hostAssets => this.assets = this.assets.concat(hostAssets))
                .catch(error =>
                    this.errorTrackingService.sendError("Failed call to asset-service by hostname", error));
        }
    }

    getEventTime(inputTime): Moment | null {
        if (!inputTime) {
            return null;
        }
        let eventTime = inputTime;
        if (/^\d+$/.test(eventTime)) {
            eventTime = parseInt(eventTime, 10);
        }
        return this.time.timeZoneMoment(eventTime);
    }

    openAssetModal(asset: Asset, assetIndex: number): void {
        this.assetClient.getLabels()
            .then(labels => {
                this.modalService
                    .open(AssetAddEditModalComponent, {
                        modalTitleKey: "ASSET_DETAILS_HEADER",
                        asset: cloneDeep(asset),
                        labelList: labels,
                    }, {
                        height: "80%",
                        viewContainerRef: this.viewContainerRef,
                    })
                    .afterClosedWithResult()
                    .subscribe(updatedAsset => this.assets[assetIndex] = updatedAsset);
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_LOADING_ASSET_LABELS");
                this.notificationsService.showError(message, error);
            });
    }

    assetDropdownIps(asset: Asset): string[] {
        return asset.ipMacPairs ? asset.ipMacPairs.filter(pair => !!pair.ip).map(pair => pair.ip) : [];
    }

    assetDropdownMacs(asset: Asset): string[] {
        return asset.ipMacPairs ? asset.ipMacPairs.filter(pair => !!pair.mac).map(pair => pair.mac) : [];
    }

    assetDropdownAllTerms(asset: Asset): string[] {
        let hostnames = asset.hostnames || [];
        return hostnames.concat(this.assetDropdownIps(asset), this.assetDropdownMacs(asset));
    }

    joinAssetTerms(terms: string[]): string {
        return terms.filter(term => term.length > 0)
            .map(term => `"${ term }"`)
            .join(" OR ");
    }
}
