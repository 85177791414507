export type LocalizationKey = keyof typeof defaultStrings;

export const defaultStrings = {
    // global error handling
    HTTP_REQUEST_ERROR: "Request failed",
    HTTP_REQUEST_ERROR_MESSAGE: "Server returned status {0}.",
    HTTP_REQUEST_ERROR_ID_MESSAGE: "Server returned status {0} with errorId: '{1}'",
    GENERIC_LOAD_ERROR: "An error occurred. Please refresh the page or contact support.",


    // shared
    ACTIONS: "Actions",
    SAVE: "Save",
    GENERATE: "Generate",
    FILTERS: "Filters",
    RESET_ALL: "Reset All",
    STATUS: "Status",
    QUERY: "Query",
    SEE_MORE: "See More",
    SEE_LESS: "See Less",
    DESCRIPTION: "Description",
    OPTIONAL_DESCRIPTION: "Description (optional)",
    ALERTS: "Alerts",
    ALERT: "Alert",
    CLASSIFIER: "Classifier",
    TICKETS: "Tickets",
    INCIDENTS: "Incidents",
    INCIDENT: "Incident",
    TASKS: "Tasks",
    TASK: "Task",
    DRAFTS: "Test Tickets",
    DRAFT: "Test Ticket",
    NAME: "Name",
    NONE: "None",
    SETTINGS: "Settings",
    QUERY_STRING_PLACEHOLDER: "Query string",
    CHART_PREVIEW_COMING_SOON: "Chart Preview Coming Soon",
    CREATED: "Created",
    COMPLETED: "Completed",
    FILTERS_CLEAR_ALL: "Clear All",
    SKIP: "Don't Show Again",
    OKAY: "Okay, Got It!",
    UNDERSTOOD: "I understand",
    SYSTEM_USER: "System",
    UPLOAD: "Upload",
    UPLOAD_FILE: "Upload File",
    CREATE: "Create",
    SELECT: "Select",
    COPIED_TO_CLIPBOARD: "Copied to clipboard!",
    PATTERN_SCOUT: "PatternScout",
    BY_LOWERCASE: "by",
    PAGINATION_DISPLAY_MESSAGE: "{0} - {1} of {2}",
    UNEXPECTED_COMMUNICATION_ERROR: "A communication problem has occurred. Please try again later.",
    PAGE_NOT_FOUND: "Page not found on this org. Redirected to list page.",
    INBOX_ALERT_NOT_FOUND: "Alert not found for this org. Redirected to inbox.",
    INBOX_TRIGGER_NOT_FOUND: "Trigger not found for this org. Redirected to inbox.",
    CSV_DOWNLOAD_ERROR: "Something went wrong that prevented the file from being downloaded. Please either try again, edit your query, or contact support if this continues.",
    PRINT_OR_EXPORT_TO_PDF: "Print or Export to PDF",
    PDF_REPORTS_ONLY: "PDF Reports only",
    AND: "AND",
    AND_NOT: "AND NOT",
    OR: "OR",
    OR_LOWER: "or",
    PRIORITY: "Priority",
    INVALID: "Invalid",
    BETA: "BETA",
    UNDO: "Undo",
    MOST_RECENT: "Most Recent",
    LEARN_MORE: "Learn More",
    GENERAL: "General",
    CONFIRM: "Confirm",
    USER: "User",
    VERSUS: "vs",
    AT: "at",
    BACK: "Back",
    EDIT_ITEM: "Edit {0}",
    ADD_ITEM: "Add {0}",
    EXPORT: "Export",
    DEFAULT_DATE_FORMAT: "yyyy-mm-dd",
    VERSION: "Version",
    REPLY: "Reply",
    NOTE: "Note",
    PREVIOUS_VERSION: "Previous Version",
    NEXT_VERSION: "Next Version",
    REQUIRED_ACTION: "Required Action",
    NOTIFICATIONS: "Notifications",
    CHANGE_FROM_TO: "From {0} To {1}",
    AFFIRM_DELETE: "Yes, Delete",
    DARK_MODE: "Dark Mode",
    REPLACE: "Replace",
    ACCESS: "Access",
    NEXT: "Next",
    PREVIOUS: "Prev",
    PERIOD_PUNCTUATION: ".",

    // primary navigation
    MODULE_REACTOR: "Reactor",
    MODULE_POLICY: "Policy",
    MODULE_CONNECT: "Connect",

    // secondary navigation
    BASE_DASHBOARD: "Dashboard",
    BASE_SEARCH: "Search",
    BASE_TICKETS: "Tickets",
    BASE_ALERTS: "Alerts",
    BASE_CLASSIFIERS: "Classifiers",
    LIBRARY_CLASSIFIERS: "Library Classifiers",
    BASE_ASSETS: "Assets",
    ORG_SETTINGS: "Org Settings",
    POLICY_DASHBOARD: "Dashboard",
    POLICY_FRAMEWORKS: "Frameworks",
    POLICY_POLICIES: "Policies",
    POLICY_SELF_ASSESSMENTS: "Self Assessments",
    SETTINGS_SOURCES: "Input Tokens",
    SETTINGS_USERS: "Users",
    SETTINGS_PROFILE: "Profile",
    SETTINGS_INTEGRATIONS: "Integrations",
    SETTINGS_TAGS: "Tagged Items",
    SETTINGS_ACTION_INTEGRATIONS: "Actions",
    JOB_DESCRIPTORS: "Job Descriptors",
    DASHBOARDS: "Dashboards",
    ORG_ID: "OrgId",
    CROSS_ORG_SEARCH: "Cross Org Search",
    CROSS_ORG_SEARCH_CREATOR: "Creator",
    HOME: "Home",
    PRODUCTS: "Products",
    ALL_REPORTS: "All Reports",
    ALL_EVENTS: "All Events",
    ALL_ASSETS: "All Assets",
    ALL_DASHBOARDS: "All Dashboards",
    THREAT_SURVEILLANCE: "Threat Surveillance",
    INBOX: "Inbox",

    // tertiary navigation
    HELP: "Help",
    RECENT_SEARCHES: "Recent Searches",
    NO_RECENT_SEARCHES: "No Recent Searches",
    CLEAR_RECENT_SEARCHES: "Clear Recent Searches",
    RECENT_TICKETS: "Recent Tickets",
    NO_RECENT_TICKETS: "No Recent Tickets",
    CLEAR_RECENT_TICKETS: "Clear Recent Tickets",
    CLEAR_RECENT_ITEMS: "Clear Recent {0}",
    RECENT_ITEMS: "Recent {0}",
    NO_RECENT_ITEMS: "No Recent {0}",

    // settings
    EMAIL_TIMEZONE: "Email timezone",
    ORG_SETTINGS_LOAD_FAILED: "Failed to load organization settings.",
    ORG_SETTINGS_SAVED: "Organization settings successfully saved.",
    ORG_SETTINGS_SAVE_FAILED: "Failed to save organization settings.",
    UPDATE_ORG_SETTINGS: "Update organization settings",

    UNEXPECTED_ROLES_REFRESH_ERROR: "Unexpected roles refresh error",

    NOT_MODIFIABLE: "Not Modifiable",
    NO_MATCHING_DESCRIPTORS: "No Matching Descriptors",
    ERROR_EDITING_DESCRIPTOR: "An error occurred while editing descriptor.",
    ERROR_CREATING_DESCRIPTOR: "An error occurred while creating descriptor.",
    ERROR_LOADING_DESCRIPTORS: "An error occurred while loading job descriptors.",
    ERROR_LOADING_DESCRIPTOR_USERS: "An error occurred while loading descriptor users.",
    NO_DESCRIPTORS_MATCH_QUERY: "No job descriptors matched the query",

    // status bar
    FEEDBACK: "Feedback",

    // login
    EMAIL: "Email",
    PASSWORD: "Password",
    LOGIN_FAILED: "Error logging in.",
    INACTIVITY_TIMEOUT_LOGOUT_MSG: "Logged out due to inactivity.",
    TWO_FACTOR_AUTH_TIMEOUT: "Timed out during two-factor authorization. Please log in again.",
    TWO_FACTOR_AUTH_SETUP_TIMEOUT: "Timed out while setting up two-factor authentication. Your authorization " +
        "code has been reset; please log in to create a new code and set up Google Authenticator.",
    CONNECT_REDIRECT_LEGEND: "Enter GRID credentials to access DefenseStorm Connect:",
    SAML_REDIRECT: "Redirecting to {0} Login",
    ERROR_VALIDATING_USERNAME: "An error occurred while validating the username",

    // 2fa login
    AUTHENTICATE: "Authenticate",
    ONE_TIME_CODE: "Verification Code",
    LOST_DEVICE: "Lost your device?",
    LOST_TWO_FACTOR_DEVICE_HEADER: "Lost Two-Factor Device",
    LOST_TWO_FACTOR_DEVICE_DESCRIPTION: "To reset your two-factor authentication, ask your administrator to do the following:" +
        "<ul><li>Go to Settings>Users</li>" +
        "<li>Click the button \"Reset 2FA\" for the user in question</li></ul>",
    OK: "OK",
    U2F_PROMPT: "Please tap your U2F device to continue.",
    U2F_RETRY: "Click here to retry your U2F device.",
    USE_TOTP_INSTEAD: "Enter a one-time password instead.",
    INVALID_TOTP: "Invalid TOTP code input. Please try again.",
    TOTP_SUCCESSFUL_SETUP: "Successfully setup TOTP Two-factor Authentication.",
    ONE_TIME_CODE_NOT_NUMBER: "The provided one time code was not a number",

    // 2fa setup
    SETUP_TWO_FACTOR_AUTHENTICATION: "Set Up Two-Factor Authentication",
    SETUP_TWO_FACTOR_AUTHENTICATION_EXPLANATION: "We recommend Google Authenticator for managing TOTP for 2FA, however you can use any application that implements the open time-based one-time password (TOTP) system as specified in the IETF's RFC 6238.  Other popular apps for generating TOTP include: Microsoft Authenticator, Authy, Duo, KeePass and Salesforce Authenticator.",
    SETUP_TWO_FACTOR_AUTHENTICATION_DESCRIPTION: "Select your device type below and follow the instructions to set up two-factor authentication",
    ANDROID: "Android",
    BLACKBERRY: "BlackBerry",
    I_OS: "iOS",
    WINDOWS_PHONE: "Windows Phone",
    QR_CODE: "QR Code",
    QR_CODE_DESCRIPTION: "Open the Google Authenticator app and scan the following barcode.",
    MANUAL_SECRET: "Manual Secret",
    MANUAL_SECRET_DESCRIPTION: "Open the Google Authenticator app and hit the \"+\" button. Add a description and " +
        "enter the following secret.",
    TWO_FACTOR_SET_UP_COMPLETE: "Enter Generated Code",

    U2F_TWO_FACTOR_ENABLED: "U2F Two-factor Authentication is",
    ENABLE_U2F_TWO_FACTOR_DESCRIPTION: "U2F two-factor authentication uses a hardware key to provide another " +
        "level of protection. On login, you will be prompted to tap the U2F device, and the device will sign a " +
        "challenge that we will verify.",
    TAP_YOUR_U2F_DEVICE_TO_CONTINUE: "Tap your U2F device to continue",
    U2F_REGISTRATION_SUCCESS: "U2F registration success!",
    U2F_REGISTRATION_FAILURE: "U2F registration error. Please contact support.",
    QR_CODE_GENERATION_ERROR: "An error occurred generating the QR Code. Please contact support.",
    WEBAUTHN_TEST: "Test U2F key in WebAuthn mode",
    WEBAUTHN_SUCCESS: "Your U2F key works with WebAuthn!",
    WEBAUTHN_FAILURE: "Your U2F key did not work with WebAuthn. Please contact support.",
    CANNOT_READ_WEBAUTHN_REQUEST: "Unable to read WebAuthn request",
    CANNOT_READ_WEBAUTHN_RESPONSE: "Unable to read WebAuthn response",
    CANNOT_FIND_PUBLIC_KEY_REQUEST: "Unable to find a matching public key credential request",
    CANNOT_FIND_REGISTRATION_REQUEST: "The registration request expired or does not exist",

    // sign up
    LOG_IN: "Sign In",
    LOG_OUT: "Sign Out",
    ERROR_PASSWORD_MATCH: "Your passwords do not match",
    ERROR_EMAIL_MALFORMED: "Please enter a valid email address",
    EMAIL_INVALID: "For this type of user you must enter an email ending with @defensestorm.com",
    EMAIL_TAKEN: "This email has already been registered in our system",
    ERROR_REQUIRED: "Required",

    // reactor dashboard
    CATEGORY: "Category",
    CREATE_CATEGORY: "Create Category",
    REAL_TIME_EVENTS: "Real-time Events",
    HISTORICAL_EVENTS: "Total Events",
    TRENDS: "Trends",
    TOP_FIVE: "Top 5",
    SEVERITY: "Severity",
    TIME: "Time",
    EVENTS: "Events",
    LAST_MINUTE: "Last minute",
    ALL_OPEN_INCIDENTS: "All Open",
    WATCHED_INCIDENTS: "Watched Incidents",
    NETWORK_CONNECTIONS: "Network Connections",
    COUNT: "Count",
    TEST: "Test",
    NO_QUERY_APPLIED: "No query applied",
    CLEAR: "Clear",
    NEW_SEARCH: "New search",
    NO_SEARCH: "No filter",

    // policy dashboard
    ALERT_COUNT: "Alerts",
    PIPELINE: "Pipeline",
    INCIDENTS_OPENED: "Incidents",
    INCIDENTS_RESOLVED: "Resolutions",
    EVENTS_PIPELINE_DESCRIPTION: "{0} events logged",
    ALERT_PIPELINE_DESCRIPTION: "{0} alerts created, based on logged events",
    INCIDENTS_OPENED_PIPELINE_DESCRIPTION: "{0} incidents created",
    INCIDENTS_RESOLVED_PIPELINE_DESCRIPTION: "{0} incidents resolved",
    NO_POLICY: "No Policy",
    DATE: "Date",
    LAST_7_DAYS: "7D",
    LAST_30_DAYS: "30D",
    LAST_90_DAYS: "90D",
    SEVEN_DAYS: "7 Days Range",
    THIRTY_DAYS: "30 Days Range",
    NINETY_DAYS: "90 Days Range",
    THIS_WEEK: "This Week",
    LAST_WEEK: "Last Week",
    THIS_MONTH: "This Month",
    LAST_MONTH: "Last Month",
    ALL_TIME: "All Time",
    PREVIOUS_PERIOD: "Previous Period",
    NEXT_PERIOD: "Next Period",

    // tokens
    ID: "ID",
    KEY: "Key",
    SECRET: "Secret",
    SHOW_SECRET: "Show Secret",
    HIDE_SECRET: "Hide Secret",
    MASKED_SECRET: "********",
    TOKENS: "Tokens",
    REVOKED: "Revoked",
    DELETE: "Delete",
    RESTORE: "Restore",
    CLONE: "Clone",
    NEW: "New",
    INPUT_TOKENS: "Input Tokens",
    CLOSE: "Close",
    SEARCH_TOKENS: "Search for Name",
    DELETE_TOKEN: "Delete Token",
    DELETE_TOKEN_ARE_YOU_SURE: "Are you sure you want to delete '{0}'?",
    DELETE_TOKEN_EXPLAIN_DANGER: "Deleting tokens that are still in use can cause data loss.",
    DELETE_TOKEN_CACHE_EXPLAIN: "This token may be cached within DefenseStorm's systems, " +
        "and this delete action may take up to 1 hour to fully take effect.",
    AGENT_INSTALLER_TOKEN: "Windows Agent Installer Token",
    TOKEN_SECRET_ONE_TIME_ONLY_WARNING: "After closing this modal, a new token must be generated to get a new secret.",
    TOKEN_SECRET_LAST_CHANCE: "I understand this secret will never be displayed again.",
    INSTALLER_TOKEN_TEXT: "This key and secret combination can be used to install the DefenseStorm Windows Agent on one or more computers.",
    TOKEN_AFTER_TEXT: "This token will be valid until revoked.",
    ORGANIZATION_ID: "Organization ID",
    GET_TOKEN: "Create New Token",
    GET_AGENT_TOKEN: "Agent",
    GET_API_TOKEN: "API",
    GET_EXTERNAL_TOKEN: "DVM",
    API_TOKEN: "API Token",
    API_TOKEN_DESCRIPTION: "Utilize this token to access the DefenseStorm API.",
    EXTERNAL_TOKEN: "DVM Token",
    EXTERNAL_TOKEN_DESCRIPTION: "Manually enter these details into the DVM.",
    TOKEN_INSTALLER: "Windows Agent Installer",
    TOKEN_EXTERNAL: "DVM",
    TOKEN_AGENT: "Windows Agent",
    TOKEN_API: "API",
    AWS_SECRET_ACCESS_KEY_ID: "AWS Secret Access Key ID",
    AWS_SECRET_ACCESS_KEY: "AWS Secret Access Key",
    AWS_SECRET_ONE_TIME_ONLY_WARNING: "AWS Secrets are only available while this modal is open. " +
        "After closing this modal, a new DVM token must be generated to get a new AWS Access Key.",
    SQS_QUEUE_NAME: "SQS Queue Name",
    NO_TOKENS_MATCHED: "No input tokens matched the search criteria",


    // aws integrations
    ADD_AWS_ACCOUNT: "Add Amazon Web Services Account",
    NO_SUCH_AWS_ACCOUNT_ID: "Could not find an Amazon Web Services Account with that ID",
    AWS_ACCOUNT_NAME: "Amazon Web Services Account Name",
    AWS_ACCOUNT_NAME_PLACEHOLDER: "Type a display name for this Amazon Web Services Account",
    AWS_ACCOUNT: "Amazon Web Services Account",
    AWS_ACCOUNT_ID: "Amazon Web Services Account ID",
    NO_AWS_ACCOUNTS_CONFIGURED: "No Amazon Web Services accounts configured; click the button above to add your account",
    AWS_INTEGRATION_ADD_MODAL_TITLE: "Add Amazon Web Services Account",
    AWS_ACCOUNT_ID_PLACEHOLDER: "Type your Amazon Web Services Account ID",
    AWS_ACCOUNT_ID_HELP_TEXT: "Your Amazon Web Services Account ID is the 12-digit number that uniquely identifies your Amazon Web Services " +
        "account. To discover it, log into the Amazon Web Services console, click your account name in the upper right, then " +
        "click on \"My Account\". Your Amazon Web Services Account ID is available under \"Account Settings\".",
    AWS_INTEGRATION_TYPE: "Integration Type",
    AWS_INTEGRATION_CLOUDTRAIL: "CloudTrail",
    AWS_INTEGRATION_CLOUDTRAIL_SETUP_HEADER: "CloudTrail Setup",
    AWS_INTEGRATION_ELB: "Elastic Load Balancer",
    AWS_INTEGRATION_ELB_SETUP_HEADER: "Elastic Load Balancer Setup",
    AWS_INTEGRATION_S3_LOG_SOURCES: "S3 Log Sources",
    AWS_INTEGRATION_ALREADY_EXISTS: "You already have an Amazon Web Services Integration with that account ID",
    AWS_INTEGRATION_ACCOUNT_REFRESH_ERROR: "An error occured while refreshing AWS accounts",
    S3_BUCKET_CREATION_ERROR: "Error creating S3 Log Source",
    S3_BUCKET_EDIT_ERROR: "Error saving S3 Log Source",
    S3_BUCKET_DELETE_ERROR: "Error deleting S3 Log Source",
    S3_BUCKET_RESTORE_ERROR: "Error restoring S3 Log Source",
    S3_BUCKET_DELETED_UNDO: "Deleted S3 Log Source for bucket '{0}'. Click here to undo.",
    S3_BUCKET_NAME: "Bucket Name",
    S3_BUCKET_ACCESS_ROLE: "Access Role",
    S3_BUCKET_LOG_TYPE: "Logs Type",
    S3_BUCKET_NO_ARN: "<none>",
    S3_HEARTBEAT_ERROR: "Error loading latest heartbeat.",
    CREATE_S3_LOG_SOURCE: "Create S3 Log Source",
    EDIT_S3_LOG_SOURCE: "Edit S3 Log Source",
    S3_CONFLICTING_BUCKET_NAME: "You already have an S3 Log Source for this bucket",
    S3_BUCKET_ACCESS_ROLE_TOOLTIP: "If you have granted DefenseStorm access to your log bucket by modifying that " +
        "bucket's Policy Document, leave this field blank. If you have created an IAM Role to allow " +
        "DefenseStorm to access your log bucket, enter that Role's ARN here.",
    S3_LOG_SOURCE_INSTRUCTIONS: "<p>For detailed instructions on how to set up an S3 Log Source in the Amazon Web " +
        "Services Console, please see our <a href='https://kb.defensestorm.com/help/integrations#s3-logs' " +
        "class='link-text' target='_blank'>Knowledgebase Article</a>.</p> " +
        "<p>Where required, your DefenseStorm Customer ID is <code>{0}</code>.</p>",
    S3_ARN_NOT_ROLE_NAME: "Please provide the full Role ARN, not just the Role name.",
    LOG_TYPE_DEFAULT: "Default",
    LOG_TYPE_APITURE_ADB: "Apiture Digital Banking",
    LOG_TYPE_CEF_JSON: "CEF-JSON",
    LOG_TYPE_CLOUDTRAIL: "CloudTrail",
    LOG_TYPE_CLOUDWATCH: "CloudWatch",
    LOG_TYPE_JSONL: "JSONL",
    LAST_MESSAGE_RECEIVED: "Last Message Received",
    LAST_RECEIVED: "Last Received",
    GT_24_HOURS: "> 24 hours",
    INTEGRATION_DATA_NEVER_SEEN: "No data",
    AWS_INTEGRATION_DELETE_MODAL_TITLE: "Delete Amazon Web Services Account: {0} ({1})",
    AWS_INTEGRATION_DELETE_CONFIRM_TEXT: "Are you sure you want to delete this integration?" +
        " If you do, DefenseStorm will no longer be able to receive any Amazon Web Services log messages on your behalf.",
    AMAZON_WEB_SERVICES: "Amazon Web Services",
    AZURE_O365: "Azure/Office365",
    CISCO: "Cisco",
    CLOUD_TRAIL: "Cloud Trail",
    ELASTIC_LOAD_BALANCING: "Elastic Load Balancing",
    OPEN_DNS: "OpenDNS",
    ADD_OPEN_DNS: "Add an OpenDNS Account",

    OFFICE_365: "Office 365",
    AZURE: "Azure",
    AZURE_INTEGRATIONS: "Azure/Office365 Accounts",
    ADD_AZURE_ORG: "Add an Azure/Office365 account",
    INTEGRATE_YOUR_O365_ORG: "Integrate your Office365 Account",
    INTEGRATE_YOUR_AZURE_ORG: "Integrate your Azure Account",
    NO_AZURE_ORGS_FOUND: "No Azure/Office365 accounts found",
    NO_AZURE_ORGS_FOUND_SUBTITLE: "Please add one by clicking the button in the top right corner",
    REQUEST_ACCOUNT_NAME_TITLE: "Name your newly integrated account",
    REQUEST_ACCOUNT_NAME_SUBTITLE: "Thanks for approving DefenseStorm! Please enter a display name for  your Azure/Office 365 account below.",
    REQUEST_ACCOUNT_NAME_INPUT_TEXT_PLACEHOLDER: "Account Display Name",
    REQUEST_ACCOUNT_NAME_VALIDATION_ERROR_NONE_GIVEN: "Account name is required",
    REQUEST_ACCOUNT_NAME_VALIDATION_ERROR_MAX_CHA: "Account name must be {0} characters or less",
    REQUEST_ACCOUNT_NAME_VALIDATION_ERROR_DUPLICATE_NAME: "Account name must be unique",
    AZURE_STORAGE_SUBSCRIPTION_ID: "Subscription ID",
    AZURE_STORAGE_RESOURCE_GROUP: "Resource Group",
    AZURE_STORAGE_STORAGE_ACCOUNT: "Storage Account",
    INTEGRATED_AZURE_ACCOUNT_SUCCESS_TITLE: "Successfully Integrated your Azure Account",
    INTEGRATED_AZURE_ACCOUNT_SUCCESS_SUBTITLE: "Please be advised: Azure can take up to 24hrs to start sending us data.",
    RESET_TOKENS_AZURE_ACCOUNT_SUCCESS: "Successfully reauthorized {0}. We have reset the tokens and should be receiving data shortly",
    INTEGRATION_ERROR: "Error occurred, Please try again or contact customer success",
    INTEGRATION_DATES_ERROR: "Error occurred, unable to retrieve a 'last received' timestamp",
    AZURE_ACCOUNT_ERROR: "Error occurred, unable to retrieve all integrated Azure accounts",
    AZURE_ACCOUNT_REAUTH_ERROR: "Error occurred, unable to re-authenticate the Azure account",
    AZURE_ACCOUNT_DELETE_ERROR: "Error occurred, unable delete Azure account. Please try again or contact customer success.",
    AZURE_CREATION_ERROR: "Error occurred, unable to add an integrated Azure account. Please try again or contact customer success.",
    AZURE_REAUTHORIZATION_ERROR_TITLE: "Error occurred trying to reset tokens for your Azure account",
    REAUTHORIZE_AZURE_ORG_REQUEST_TITLE: "Reauthorize Azure Storage Account",
    AUTHORIZATION_AZURE_EXPIRED: "Authorization for {0} has expired",
    REAUTHORIZE_AZURE_ORG_REQUEST_SUBTITLE: "In order to reauthorize your account, please click the link below to sign in and accept our application. Once complete you'll be redirected back here. <span><a class=\"link-text\" href=\"{0}\">Reauthorize your account</a><span>",
    AZURE_OAUTH_EXTERNAL_ERROR: "There was an error authenticating your Azure account with error code: {0} and subcode: {1}",
    AZURE_SETTINGS: "Azure Integration Settings",
    AZURE_UPDATE_ERROR: "Error updating Azure Integration",
    AZURE_DELETE_ERROR: "Error deleting Azure Integration",
    AZURE_ENABLE_DELETION: "Enable Deletion",
    AZURE_ORG_DELETION_WARNING: "<strong>WARNING:</strong> This operation cannot be undone. You can however reintegrate the same account again after deletion",
    AZURE_ALERT_ON_NO_DATA: "Notify On No Data",
    AZURE_NO_DATA_THRESHOLD: "Alerting Threshold",
    INVALID_INTEGRATIONS_URI: "Could not set up integration due to an invalid uri",

    SALESFORCE: "SalesForce",
    SALESFORCE_INTEGRATIONS: "SalesForce Accounts",
    SALESFORCE_GET_ERROR: "Error occurred, unable get integrated SalesForce accounts. Please try again or contact customer success.",
    ADD_SALESFORCE_ORG: "Add a SalesForce account",
    INTEGRATE_YOUR_SALESFORCE_ORG: "Integrate your SalesForce account",
    NO_SALESFORCE_ORGS_FOUND: "No SalesForce accounts found",
    NO_SALESFORCE_ORGS_FOUND_SUBTITLE: "Please add one by clicking the button in the top right corner",
    INTEGRATED_SALESFORCE_ACCOUNT_SUCCESS_TITLE: "Successfully Integrated your SalesForce Account",
    SALESFORCE_CREATION_ERROR: "Error occurred, unable to add an integrated SalesForce account. Please try again or contact customer success.",
    REQUEST_SALESFORCE_ACCOUNT_NAME_SUBTITLE: "Thanks for approving DefenseStorm! Please enter display name for SalesForce account below.",
    SALESFORCE_SETTINGS: "SalesForce Settings",
    SALESFORCE_ENABLE_DELETION: "Enable Deletion",
    SALESFORCE_DELETION_ENABLED: "Deletion Enabled",
    SALESFORCE_ORG_DELETION_TEXT: "Would you like to permanently delete <strong>{0}</strong> from your list of SalesForce Accounts?",
    SALESFORCE_ORG_DELETION_WARNING: "<strong>WARNING:</strong> This operation cannot be undone. You can however reintegrate the same account again after deletion",
    SALESFORCE_DELETION_SUCCESS: "Successfully deleted your SalesForce org",
    SALESFORCE_DELETION_ERROR: "Error occurred, unable to  delete the selected SalesForce account. Please try again or contact customer success.",

    APITURE: "Apiture",
    ADD_APITURE: "Add Apiture Integration",
    ADD_APITURE_INSTRUCTIONS: "To collect Audit Logs from your Apiture account, please contact DefenseStorm customer support.",
    BEGIN_APITURE_OAUTH: "Connect Apiture Account",
    APITURE_DELETE_INTEGRATION_SUCCESS: "Successfully removed Apiture Integration",
    APITURE_DELETE_INTEGRATION_ERROR: "Error removing Apiture Integration",
    APITURE_SETTINGS: "Apiture Integration Settings",
    APITURE_ORG_DELETION_TEXT: "Would you like to delete this Apiture Integration?",
    APITURE_ENABLE_DELETION: "Enable Deletion",
    APITURE_DELETION_ENABLED: "Deletion Enabled",
    APITURE_DELETION_WARNING: "<strong>WARNING:</strong> You can later re-add this integration, but log data will not be collected while the integration is deleted.",
    INVALID_APITURE_BASE_URL: "The Apiture url was malformed",

    ADD_FINXACT_INSTRUCTIONS: "<p>In order to collect Finxact logs, Finxact will need to allow DefenseStorm IP addresses access to your account. Please contact DefenseStorm customer success for assistance.</p>",
    ADD_FINXACT_INTEGRATION: "Add Finxact Integration",
    FINXACT: "Finxact",
    FINXACT_CREATE_INTEGRATION: "Create integration",
    FINXACT_CREATION_ERROR: "Error creating Finxact integration, please try again",
    FINXACT_CREATION_SUCCESS: "Successfully created Finxact integration",
    FINXACT_DELETION_ERROR: "Error deleting Finxact integration, please try again",
    FINXACT_DELETION_SUCCESS: "Successfully deleted Finxact integration",
    FINXACT_GET_ERROR: "Error occurred, unable to retrieve Finxact integrations. Please try again or contact customer success.",
    FINXACT_INTEGRATION_CLIENT_ID: "Finxact client ID (for authorization)",
    FINXACT_INTEGRATION_CLIENT_SECRET: "Finxact client secret (for authorization)",
    FINXACT_INTEGRATION_DELETION_ENABLED: "Deletion Enabled",
    FINXACT_INTEGRATION_DELETION_TEXT: "Would you like to delete this Finxact Integration?",
    FINXACT_INTEGRATION_DELETION_WARNING: "<strong>WARNING:</strong> You can re-add this integration, but log data will not be collected while the integration is deleted.",
    FINXACT_INTEGRATION_ENABLE_DELETION: "Enable Deletion",
    FINXACT_INTEGRATION_NAME: "Integration name",
    FINXACT_INTEGRATION_URL: "Finxact URL",
    FINXACT_INTEGRATION_USER_ROLE: "User role associated with client ID",
    FINXACT_INTEGRATIONS: "Finxact Integrations",
    FINXACT_SETTINGS: "Finxact Integration Settings",
    INVALID_FINXACT_CREDENTIALS: "Invalid credentials provided",
    INVALID_FINXACT_URL: "Invalid URL provided",
    NO_FINXACT_INTEGRATIONS_FOUND: "No Finxact integrations found",
    NO_FINXACT_INTEGRATIONS_FOUND_SUBTITLE: "Please add one by clicking the button in the top right corner",
    PLACEHOLDER_FINXACT_URL: "https://example-url.finxact.io",

    AWS_INTEGRATION: "Amazon Web Services Integration",
    AWS_INTEGRATIONS: "Amazon Web Services Integrations",
    SETUP: "Setup",
    INTEGRATIONS: "Integrations",
    LOADING: "Loading...",
    EXPIRED: "Expired",

    KNOWLEDGE_BASE_AUTH: "KnowledgeBase Authenticating",
    REDIRECT_TO_KNOWLEDGE_BASE: `You should be redirected to DefenseStorm KnowledgeBase shortly. You may also click <a class="link-text" href="{0}">here</a>`,
    KNOWLEDGE_BASE_ERROR: "There was an error while authenticating KnowledgeBase. Please click to retry.",

    //Download CSV
    GENERIC_DOWNLOAD_CSV: "Download as CSV",
    ALERTS_DOWNLOAD_CSV: "Alerts CSV",
    EVENTS_DOWNLOAD_CSV: "Events CSV",

    // search
    EMPTY_QUERY: "Empty query",
    SEARCH_PLACEHOLDER: "...",
    DURING: "During",
    TIMESTAMP: "Timestamp",
    MESSAGE: "Message",
    RAW: "Raw",
    LAST_DROPDOWN: "Last",
    LAST_24_HOURS: "24H",
    LAST_12_HOURS: "12H",
    LAST_3_HOURS: "3H",
    LAST_HOUR: "1H",
    TWENTY_FOUR_HOURS: "24 Hours Range",
    TWELVE_HOURS: "12 Hours Range",
    THREE_HOURS: "3 Hours Range",
    ONE_HOUR: "1 Hour Range",
    MORE_THAN_30_DAYS: "More than 30 days",
    CUSTOM: "Custom Range",
    CUSTOM_ROLE: "Custom",
    FILTERS_APPLIED: "Filters Applied",
    FILTERS_APPLIED_NONE: "No Filters Applied",
    AVAILABLE: "Available",
    SEARCH_EVENTS_PER_PAGE: "Events per page",
    SORT: "Sort",
    SORT_BY: "Sort by",
    SHOW_MORE: "More",
    SHOW_LESS: "Less",
    SAVE_SEARCH: "Save Search",
    CREATE_TRIGGER: "Create Trigger",
    DOWNLOAD_TICKETS: "Download {0} Ticket(s)",
    TOO_MANY_TICKETS_FOR_NORMAL_CSV_DOWNLOAD: "Your download contains a large number of tickets; the export may take some time.",
    BULK_TICKET_CSV_REPORT_INFO: "Your download will be on the Reports page",
    BULK_TICKET_CSV_REPORT_NAME: "Bulk Ticket CSV Report",
    DOWNLOAD_TICKETS_ERROR: "An error occurred during download.",
    DOWNLOAD_TRIGGERS_CSV: "Download {0} Trigger(s)",
    DOWNLOAD_CLASSIFIERS_CSV: "Download {0} Classifier(s)",
    RUN_ANALYSIS: "Run Analysis",
    SYNC_TRIGGER_COPY: "Sync Trigger Copy",
    SYNC_COPY: "Sync Copy",
    TRIGGER_FIELDS_CHANGED: "{0} Fields Changed",
    TRIGGER_FIELD_CHANGED: "1 Field Changed",
    TRIGGER_CHANGES: "Trigger Changes ({0})",
    SYNC_TRIGGER_COPY_EXPLANATION: "These are the properties of the trigger which have been altered between the local" +
        " copy and the original Library Trigger. Select the fields you want to re-sync with the original Library Trigger.",
    SYNC_TRIGGER: "Sync Trigger",
    RUN_TRIGGER_ANALYSIS_BUTTON_DISABLED_EXPLANATION: "To run a Library Triggers Sync Analysis, filter Source by \"Copied From Library\" "
        + "and the Status by \"Enabled\"",
    SYNCED: "Synced",
    CANNOT_RESYNC_FIELD_EXPLANATION: "We are unable to sync this field. You can manually change it back to the original " +
        "value on your local trigger if you wish for them to match.",
    RESYNC_DIFFERING_TRIGGER_CONFIG_TYPES: "The Trigger type has changed. Please make sure the type matches with the " +
        "Library Trigger in order to re-sync this copy.",
    RESYNC_DIFFERING_INTERVAL_SECONDS: "We are unable to sync the Interval field. You can manually change it back to the " +
        "original value on your copy if you wish for them to match.",
    TRIGGER_IDS_NOT_FOUND_DURING_RESYNC_CALCULATION: "The following trigger ids were not found during trigger analysis: {0}.",
    TRIGGERS_MUST_BE_ACTIVE_FOR_RESYNC_CALCULATION: "Trigger analysis must be run on triggers that are active. " +
        "Analysis was ran on the following inactive triggers: {0}.",
    TRIGGERS_MUST_BE_COPIED_FROM_LIBRARY_FOR_RESYNC_CALCULATION: "Trigger analysis must be run on triggers that were copied from the library triggers page. " +
        "Analysis was ran on the following triggers that were not copied from the library: {0}.",
    NO_FIELDS_TO_UPDATE_DURING_TRIGGER_RESYNC: "At least one field must be selected when syncing a trigger.",
    CANNOT_RESYNC_INTERVAL_SECONDS: "Interval seconds cannot be synced.",
    CANNOT_RESYNC_INACTIVE_TRIGGER: "This trigger cannot be synced since it is inactive.",
    CANNOT_RESYNC_TRIGGER_NOT_COPIED_FROM_LIBRARY: "This trigger cannot be synced since it was not copied from the library.",
    CANNOT_RESYNC_TRIGGER_BEFORE_RESYNC_INFO_CALCULATIONS: "This trigger cannot be synced since an analysis has not been run on this trigger.",
    CANNOT_RESYNC_TRIGGER_WITH_DIFFERING_TRIGGER_CONFIG_TYPE: "This trigger cannot be synced since it has a different type than the library trigger it was copied from. " +
        "Please make the type of this trigger match its library trigger to sync.",
    CALCULATE_TRIGGER_RESYNC_INFO_ERROR: "An error occurred while running analysis on triggers.",
    RESYNC_TRIGGER_ERROR: "An error occured while syncing a trigger.",
    SEE_LIBRARY_TRIGGER: "See Library Trigger",
    LIBRARY_TRIGGER: "Library Trigger",
    LOCAL_COPY: "Local Copy",
    CREATE_CLASSIFIER: "Create Classifier",
    CLASSIFIER_ACTIVITY_LOG_RESUMED: "Resumed Classifier",
    CLASSIFIER_ACTIVITY_LOG_PAUSED: "Paused Classifier",
    CLASSIFIER_ACTIVITY_LOG_DELETED: "Deleted Classifier",
    CLASSIFIER_ACTIVITY_LOG_UNDELETED: "Restored Classifier",
    CLASSIFIER_ACTIVITY_LOG_CREATED: "Created Classifier",
    CLASSIFIER_ACTIVITY_LOG_UPDATED: "Updated Classifier",
    CLASSIFIER_ACTIVITY_LOG_ADDED_ATTRIBUTES: "Added Attributes",
    CLASSIFIER_ACTIVITY_LOG_EDITED_ATTRIBUTES: "Edited Attributes",
    CLASSIFIER_ACTIVITY_LOG_DELETED_ATTRIBUTES: "Deleted Attributes",
    CLASSIFIER_ACTIVITY_LOG_DESCRIPTION_CHANGED: "Name changed from {0} to {1}",
    CLASSIFIER_ACTIVITY_LOG_QUERY_CHANGED: "Query changed from {0} to {1}",
    CLASSIFIER_ACTIVITY_LOG_ATTRIBUTE_CHANGED: "Attribute changed from {0} to {1}",
    CLASSIFIER_ACTIVITY_LOGS_MODAL_TITLE: "Classifier Activity Logs",
    VIEW_CLASSIFIER_ACTIVITY_LOGS: "View Classifier Activity Logs",
    UPDATE_CLASSIFIER: "Updated Classifier",
    RESUME_CLASSIFIER: "Resumed Classifier",
    PAUSE_CLASSIFIER: "Paused Classifier",
    DELETE_CLASSIFIER: "Deleted Classifier",
    UNDELETE_CLASSIFIER: "Restored Classifier",
    ATTACH_TO_TICKET: "Attach to Ticket",
    DOWNLOAD_FIRST_EVENTS_CSV: "Download first 50,000 results as CSV",
    DOWNLOAD_SELECTED_EVENTS_CSV: "Download {0} result(s) as CSV",
    DOWNLOAD_TOO_LARGE: "Download Request is too large",
    SEARCH_RESPONSE_TOO_LARGE: "The requested search returned a response that was too large to process. Try changing " +
        "the query or reducing the period.",
    DOWNLOAD_MULTIPLE_CSV_FILES: "The results of your request are too large to serve as a single download. " +
        "You will get {0} separate download files instead.",
    CREATE_CHART: "Create Chart",
    EVENT: "Event",
    SEARCH_AROUND_EVENT: "Search all Events...",
    SEARCH_AROUND_1M: "Within 1 minute",
    SEARCH_AROUND_5M: "Within 5 minutes",
    NO_DATA: "No data",
    SEARCH_HELP: "Search help",
    SEARCH_HELP_EXACT: "Exact Phrase",
    SEARCH_HELP_BOOLEAN: "Boolean",
    SEARCH_HELP_FIELDS: "Fields",
    SEARCH_HELP_WILDCARDS: "Wildcard",
    SEARCH_HELP_RANGE: "Range",
    SAVE_SEARCH_TO: "Save Search to",
    DATE_RANGE_FROM: "From",
    DATE_RANGE_TO: "To",
    DATE_RANGE_AT: "at",
    DATE_RANGE_APPLY: "Apply",
    DATE_RANGE_CLEAR: "Clear",
    DATE_RANGE_START_ERROR: "Please set a Start Date that is before the End Date.",
    DATE_RANGE_DURATION_ERROR: "The date range you have chosen is greater than the maximum allowed of {0}",
    DATE_RANGE_MAX_ERROR: "Please set an End Date that is before {0}.",
    DATE_RANGE_MIN_ERROR: "Please set a Start Date that is after {0}.",
    TIME_RANGE_START_ERROR: "Please set an Start Time that is before the End Time.",
    GROUP_BY: "Aggregate",
    AGGREGATE_BY: "Aggregate by",
    GROUP_BY_FIELD: "[<strong>{0}</strong>] aggregated on field [<strong>{1}</strong>]",
    GROUP_BY_FIELDS: "[<strong>{0}</strong>] aggregated on fields [<strong>{1}</strong>, <strong>{2}</strong>]",
    NO_RESULTS_2D: "No events found containing both <strong>{0}</strong> and <strong>{1}</strong>.",
    TYPE_A_FIELD: "Type a field name",
    SWITCH_PRIMARY_AND_SECONDARY_FIELD: "Switch primary and secondary field",
    FIELD_NAME: "Field Name",
    COUNT_DESC: "Count ↓",
    COUNT_ASC: "Count ↑",
    ALPHA_ASC: "A → Z",
    ALPHA_DESC: "Z → A",
    ALPHABETICALLY_ASC: "Alphabetically A - Z",
    ALPHABETICALLY_DESC: "Alphabetically Z - A",
    NO_RESULTS_FOUND_FOR: "No results found for <strong>{0}</strong>",
    ERROR_OCCURRED_CLICK_TO_RETRY: "The requested search resulted in an error. Please click to retry.",
    ERROR_TIMEOUT_CLICK_TO_RETRY: "The requested search had no data available. Please click to retry.",
    ERROR_INVALID_QUERY_STRING: "The query string is invalid. Please enter a valid query string.",
    ERROR_INVALID_ASSET_TAG_QUERY: "The amount of items labeled with this $asset_tag exceeds the limit allowed number for this query.",
    ERROR_AGGREGATION_STATISTICAL_TOO_MANY_RESULTS: "The number of aggregation results for this query exceeds the " +
        "maximum limit of terms. To reduce the number of aggregation results, try changing the field used " +
        "for aggregation or changing the query to reduce the return set size.",
    ERROR_AGGREGATION_TOO_MANY_RESULTS: "The number of aggregation terms (groupings) for this query exceeds the " +
        "maximum limit of 10,000 unique terms. To reduce the number of aggregation terms, try changing the field used " +
        "for aggregation or changing the query to reduce the return set size. If the field is numeric, you may want to " +
        "perform a statistical aggregation.",
    ERROR_AGGREGATION_STATISTICAL_NON_NUMERIC: "Cannot perform statistical aggregation on a non-numeric field",
    ERROR_AGGREGATION_TEXT_FIELD: "Cannot perform aggregation on text fields.",
    SEARCH_ERROR_NON_RETRYABLE: "The requested search resulted in an error.",
    HOURLY: "Hourly",
    PRIMARY: "Primary",
    SECONDARY: "Secondary",
    BY_TIME: "By Time",
    TERM: "Term",
    VIEW: "View",
    SPREADSHEET_VIEW: "Spreadsheet View",
    COMPOUND_VIEW: "Compound View",
    AGGREGATION_VIEW_NOT_IMPLEMENTED: "Aggregation view {0} is not implemented",
    SEARCH_CUSTOMIZE_COLUMNS: "Select Columns",
    SEARCH_ADD_COLUMNS_EXPLANATION: "Type a field name and then press <Enter>. Uncommon fields may not automatically complete.",
    SEARCH_CURRENTLY_SELECTED_COLUMNS: "Currently selected columns:",
    SEARCH_ADD_COLUMN_PLACEHOLDER: "Column name",
    SEARCH_COLUMN_NAME: "Column name",
    SEARCH_ERR_DUPLICATE_COLUMN: "This column has already been selected.",
    SEARCH_ERR_EMPTY_COLUMN: "Column name cannot be empty.",
    SEARCH_ERR_NON_EXISTENT_COLUMN: "Column does not exist.",
    SEARCH_DELETE_CUSTOM_COLUMN_TOOLTIP: "Remove this column from the results table",
    SEARCH_ERR_MAX_COLUMNS: "Maximum column count reached.",
    SEARCH_FOR_INSTEAD: "Did you mean to search for",
    SAVE_QUERY_TO_USE: "Save your query to use it",
    COPY_HUMAN_READABLE: "Copy Human Readable",
    COPY_UNIX: "Copy Unix",
    DEFAULT_LIVE_DATA: "Default Live data: {0}",
    LIVE_DATA_FROM_TO: "From {0} to Today, {1}",
    RESTORE_DATA: "Restore data",
    ADD_NEW_RESTORE_REQUEST: "Add New Request(s)",
    ADD_NEW_RESTORE_REQUEST_MODAL_TITLE: "Restore Data - New Request",
    SEE_ALL_RESTORE_REQUESTS: "See All Requests",
    VIEW_RESTORE_REQUESTS_MODAL_TITLE: "Restore Data - Requests",
    ORG_LIVE_DATA_EXPLANATION: "Your Organization's Live Data is from {0} to Today, {1}.",
    SELECT_RESTORE_RANGE: "Select below the range of dates for the data you wish to restore",
    INCLUDE_ACCELERATED_ARCHIVE: "Include data sent to Accelerated Archive",
    DATA_REQUESTS: "Requests",
    ADD_NEW_RANGE: "Add New Range",
    SEND_DATA_REQUESTS: "Send Request(s)",
    CREATE_RESTORES_ERROR: "Couldn't create the requested restores. Please try again later.",
    LIVE_RESTORE: "Live",
    PROCESSING_RESTORE: "Processing ({0}%)...",
    RESTORE_STARTING: "Starting...",
    PROCESSING_RESTORE_ETA: "ETA: {0}",
    RESTORE_ARCHIVES_ON: "Archives on {0}",
    RESTORE_QUEUED: "Queued",
    RESTORE_CANCELLED: "Cancelled",
    RESTORE_QUEUED_EXPLANATION: "You can archive a Live request for the next in queue to be processed",
    RESTORE_PART: "Pt",
    RESTORE_EXPIRE_ERROR: "An error occurred while expiring a restore. Please try again later.",
    CANCEL_AND_ARCHIVE_RESTORE: "Cancel and archive part",
    CANCEL_RESTORE: "Cancel part",
    CANCEL_AND_ARCHIVE_RESTORE_CONFIRMATION_TITLE: "Cancel and Archive Request",
    CANCEL_AND_ARCHIVE_RESTORE_CONFIRMATION_MESSAGE: "You are about to cancel and archive the <b>{0} - {1}</b> restore " +
        "and its parts. Any Live data restored for this request will be re-archived.",
    REACTIVATE_REQUEST: "Re-activate request",
    CANT_REACTIVATE_REQUEST: "This request is being archived and can't be re-activated yet.",
    RESTORE_REACTIVATE_ERROR: "An error occurred while re-activating a restore. Please try again later.",
    GET_RESTORE_REQUESTS_ERROR: "An error occurred while getting the list of restores. Click here to retry.",
    CROSS_ORG_SEARCH_RESULTS: "Search Results",
    SAVE_CROSS_ORG_SEARCH: "Save Cross Org Search",
    CROSS_ORG_SEARCH_RESULTS_LOADING_ERROR: "Could not load Cross Org Search Results",
    CROSS_ORG_SEARCH_RESULTS_CREATION_ERROR: "Could not create Cross Org Search",
    CROSS_ORG_SEARCH_GET_SEARCHES_ERROR: "Could not get past Cross Org Searches",
    CROSS_ORG_SEARCH_NAME_PLACEHOLDER: "Search Name",
    CROSS_ORG_SEARCH_QUERY_PLACEHOLDER: "Search Query",
    CROSS_ORG_SEARCH_PENDING_RESULTS: "Pending...",
    CROSS_ORG_SEARCH_NO_SEARCHES: "No Cross Org Searches to Display",
    CROSS_ORG_SEARCH_SEARCH_PLACEHOLDER: "Search by name",
    CROSS_ORG_SEARCH_DELETE_MESSAGE: "You are about to delete the following Cross Org Searches",
    CROSS_ORG_SEARCH_DELETE_TITLE: "Delete Cross Org Searches",
    CROSS_ORG_SEARCH_DELETE_ERROR: "Could not delete Cross Org Searches",
    CROSS_ORG_SEARCH_DELETE_TOOLTIP: "Delete {0} Cross Org Searches",
    CROSS_ORG_SEARCH_TIME_RANGE: "Time Range",
    CROSS_ORG_SEARCH_RESULTS_DOWNLOAD_ERROR: "Could not download Cross Org Search results",
    CROSS_ORG_SEARCH_EXPORT: "Download Results",
    CROSS_ORG_SEARCH_NO_RESULTS: "There are no results associated with this search",
    CROSS_ORG_SEARCH_UNKNOWN_MEDIA_TYPE: "Cannot export cross org search results to {0}",

    // alert inbox
    //TODO: remove when removing newAlertInbox feature gate
    ALERT_INBOX_BETA: "New Alert Inbox",
    ALERT_INBOX: "Alert Inbox",
    TRIGGER_VIEW: "Trigger View",
    RECENT_ALERTS: "Recent Alerts",
    ALERT_VIEW: "Alert View",
    ALERT_STORY_VIEW: "Story View",
    RECENT_EVENTS: "Recent Events",
    RECENT_AGGREGATE_VALUES: "Most recent aggregate values",
    ALERT_STORY_SEARCH: "Search by aggregation value",
    VIEWED_IN_PAST_10_SECONDS_BY: "Viewed in past 10 seconds by",
    AGGREGATE_VALUE_LABEL: "Aggregated value",
    INBOX_VIEW_STORY: "View Alert Story",
    ALERT_STORY_DETAILS_ERROR: "An error occurred when loading details.",
    LOADING_NEXT_ALERT_PAGE: "Loading...",
    TRIGGER_UPPER_THRESHOLD: "Upper Threshold",
    TRIGGER_LOWER_THRESHOLD: "Lower Threshold",
    TRENDLINE: "Trend line",
    UPPER_THRESHOLD_ALERT: "Upper Threshold Alert",
    LOWER_THRESHOLD_ALERT: "Lower Threshold Alert",
    ENABLE: "Enable",
    ENABLED: "Enabled",
    RETURN_TO_TOP: "Return to top",
    SEARCH_QUERY: "Search Query",
    SEARCH_QUERY_SEQUENCE: "Queries",
    SEARCH_QUERY_MATCH_COUNT: "Match Count",
    SEARCH_QUERY_STEP_NUMBER: "Step {0}:",
    CONDITION: "Condition",
    CAUSE: "Cause",
    UPPER_THRESHOLD_VIOLATION: "Upper Threshold Violation",
    LOWER_THRESHOLD_VIOLATION: "Lower Threshold Violation",
    MATCHING_EVENT_FOUND: "Matching Event(s) Found",
    ANOMALY_FOUND: "Anomaly Found",
    ACTUAL_VERSUS_EXPECTED: "Actual vs Expected",
    EXPECTED_COUNT: "Expected count",
    MANAGE_TRIGGER: "Manage Trigger",
    ACK: "Acknowledge",
    ESCALATE: "Escalate",
    DISMISS: "Dismiss",
    DISMISSAL_MODAL_TITLE: "Dismiss Alert",
    DISMISSAL_MODAL_HEADER: "You are about to dismiss alert(s) that were generated from <strong>{0}</strong> trigger",
    DISMISSAL_REASON: "Dismissal Reason",
    DISMISSAL_NOTES: "Dismissal Notes",
    DISMISSAL_NO_THREAT: "No threat identified",
    DISMISSAL_ADJUST_TRIGGER: "Trigger may need tuning",
    DISMISSAL_DUPLICATE: "Duplicate alert already escalated",
    DISMISSAL_OTHER: "Other",
    ADDITIONAL_DETAILS: "Additional Details",
    NEW_30D: "New (Up to 30 days)",
    ACK_30D: "Acknowledge All (Up to 30 days)",
    ESCALATE_30D: "Escalate All (Up to 30 days)",
    DISMISS_30D: "Dismiss All (Up to 30 days)",
    FALSE_POSITIVE_30D: "False Positive (Up to 30 days)",
    TRUE_POSITIVE_30D: "True Positive (Up to 30 days)",
    FLAG_FRAUD_30D: "Flag All Fraud (Up to 30 days)",
    SEQUENCE_QUERY_ALERT_RESULT_STEP_NUMBER: "Step {0} Query:",
    SEQUENCE_QUERY_ALERT_RESULT_TRIGGER_TIME: "at ",
    SEQUENCE_QUERY_ALERT_RESULT_MATCHES: "Matched Values",
    //NEW: "New", //TODO: these commented out things are elsewhere in this file, consider how best to handle
    ACKED: "Acknowledged",
    //ESCALATED: "Escalated",
    DISMISSED: "Dismissed",
    //FALSE_POSITIVE: "False Positive",
    HANDLED: "Handled",
    FLAG_FRAUD: "Flag Fraud",
    FRAUD_FLAGGED: "Fraud Flagged",
    INTERVAL_ALERT_MESSAGE: "Alert fired at {0}",
    NEW_ALERT_MESSAGE: "Created at {0}",
    ACKED_ALERT_MESSAGE: "Acknowledged by {0} at {1}",
    ESCALATED_ALERT_MESSAGE: "Escalated by {0} at {1} : ",
    ESCALATED_NO_USER_MESSAGE: "Escalated automatically at {0} : ",
    DISMISSED_ALERT_MESSAGE: "Dismissed by {0} at {1}",
    FRAUD_FLAGGED_ALERT_MESSAGE: "Fraud Flagged by {0} at {1}",
    CANNOT_ATTACH_TICKET_WHEN_FRAUD_FLAGGING_ALERT: "A ticket cannot be attached while flagging an alert as fraud",
    CANNOT_FLAG_AS_FRAUD_WITHOUT_FRAUD_TYPE: "Cannot flag as fraud without a fraud type",
    CANNOT_FLAG_SECURITY_ALERT_AS_FRAUD: "Cannot flag a security alert as fraud",
    CANNOT_SUSPECT_FRAUD_AS_OTHER: "Cannot suspect fraud with the \"Other\" type",
    CANNOT_DISMISS_ALERT_AS_OTHER_WITHOUT_ADDITIONAL_DETAILS: "Cannot dismiss an alert as \"Other\" without additional details",
    CANNOT_DISMISS_ALERT_WITHOUT_REASON: "Cannot dismiss an alert without a reason",
    INVALID_ALERT_FILTER: "{0} is not a valid value for the {1} filter",
    CONFIRMED_FRAUD_TYPE_CANNOT_BE_UNKNOWN: "Cannot use the \"Unknown\" fraud type when confirming fraud",
    CANNOT_EDIT_TRAC_MANAGED_TRIGGER: "Cannot edit trigger managed by CTS Ops",
    DISMISSED_ALERT_MESSAGE_WITH_REASON: "with reason <span><strong>{0}</strong></span>",
    DISMISSED_NO_USER_MESSAGE: "Dismissed automatically at {0} : ",
    FALSE_POSITIVE_ALERT_MESSAGE: "Marked as False Positive by {0} at {1}",
    TRUE_POSITIVE_ALERT_MESSAGE: "Marked as True Positive by {0} at {1}",
    WARNING: "Warning",
    COUNTING_RECENT_MATCHES: "Counting recent matches to this query... ",
    ESTIMATED_DAILY_MATCHES: "Est. Daily Avg Matches: {0}",
    QUERY_ERROR: "Error performing this query.",
    RECOMMENDED_TYPES: "Recommended: ",
    CONFIRM_ARCHIVE: "Confirm Trigger Archive",
    EST_MATCHES_PER_INTERVAL: "Estimated Matches per Interval: ",
    MISSING_TRIGGER_ARCHIVE_CONFIRMATION: "Trigger update attempted with missing confirmation",
    ARCHIVE_CONFIRMATION_WARNING: "<p>You have changed the alert conditions for this trigger.</p> <br/> Because this trigger will " +
        "create new alerts under different circumstances, previous alert results must be separated.  <br/><br/>" +
        "Upon saving this trigger, existing alert results will be detached from the primary copy of this trigger, " +
        "but these archived results will remain accessible " +
        "from the Alert Inbox with a read-only copy of this trigger in it's previous state. <br/><br/>" +
        "The following changes require this trigger to be archived:",
    INSUFFICIENT_DATA_WARNING: "Based on recent averages, this query matches insufficient data for this Trigger " +
        "Type, which may lead to excessive false positives. Consider using a different Trigger Type.",
    ALERT_TOO_FREQUENTLY: "Based on recent averages, this query may fire very frequently. You may wish to change " +
        "Trigger Type or adjust Threshold.",
    ESCALATED_ALERT: "Escalated Alert: {0}",
    MISSING_TICKET_SLUG_WHEN_ESCALATING_ALERT: "A ticket should be included when escalating an alert",
    ALERT_TICKET_DESCRIPTION: "Trigger Name: {0}\n\n Fired At: {1}",
    ALERT_STORY_TICKET_DESCRIPTION: "Alert Story: {0}",
    TICKET_CREATE_SUCCESS: "Successfully created ticket",
    TICKET_CREATE_ERROR: "Error creating ticket",
    ALL_TRIGGERS_DISABLED: "{0} triggers were disabled",
    ALL_TRIGGERS_RESUMED: "{0} triggers were resumed",
    DISABLE_NODATA_TRIGGERS: "Disable all no data triggers",
    RESUME_NODATA_TRIGGERS: "Resume all no data triggers",
    PAUSE_NODATA_TRIGGERS_ERROR: "Error when trying to disable all no data triggers",
    RESUME_NODATA_TRIGGERS_ERROR: "Error when trying to resume all no data triggers",
    HIDE_MOST_RECENT_EVENTS: "Hide most recent events",
    SHOW_MOST_RECENT_EVENTS: "Show most recent events",
    SHOW_RESULTS_DURING_ALERT_INTERVAL: "Show results during Alert Interval",
    HIDE_RESULTS_DURING_ALERT_INTERVAL: "Hide results during Alert Interval",
    SHOW_THREATMATCH_RESULTS: "Show threatmatch results",
    INVALID_SEVERITY: "Expected the severity to be one of {0}",
    HIDE_THREATMATCH_RESULTS: "Hide threatmatch results",
    SHOW_STEP_RESULTS: "Show step results",
    HIDE_STEP_RESULTS: "Hide step results",
    SHOW_TRIGGER_DETAILS: "Show Trigger Details",
    HIDE_TRIGGER_DETAILS: "Hide Trigger Details",
    SEE_MORE_RISK_FACTORS: "See More Risk Factors",
    SEE_LESS_RISK_FACTORS: "See Less Risk Factors",
    INFORMATIONAL: "Informational",
    UEBA: "User/Entity Behavioral Analytics - UEBA",
    UEBA_DESCRIPTION: "UEBA triggers take a user or entity and alert on deviations from baseline behavior across defined fields",
    RISK_DETAILS: "Risk Details",
    APPLICATION: "Application",
    USER_ACTIVITY: "User Activity",
    UBA_TITLE: "User Behavioral Analytics - UBA",
    UBA_DESCRIPTION: "UBA triggers take a user or entity and alert on deviations from baseline behavior across defined fields",
    UBA: "UBA",
    UBA_RISK_FACTORS: "Risk Factors",
    UBA_RISK_FACTOR_HOUR_OF_DAY: "Unusual Login Time of Day",
    UBA_RISK_FACTOR_NEW_DEVICE: "New Device",
    UBA_RISK_FACTOR_NEW_OS: "New OS",
    UBA_RISK_FACTOR_NEW_BROWSER: "New Browser",
    UBA_RISK_FACTOR_NEW_CITY: "New City",
    UBA_RISK_FACTOR_NEW_STATE: "New State",
    UBA_RISK_FACTOR_NEW_EMAIL_ADDED: "Changed Email Address",
    UBA_RISK_FACTOR_NEW_EMAIL_ATTEMPT: "Attempt to Change Email Address",
    UBA_RISK_FACTOR_NEW_PHONE_ADDED: "Changed Phone Number",
    UBA_RISK_FACTOR_PASSWORD_CHANGED: "Changed Password",
    UBA_RISK_FACTOR_IMPROBABLE_TRAVEL: "Improbable Travel",
    POSSIBLE_RISK_FACTORS: "Possible Risk Factors",
    POSSIBLE_RISK_FACTORS_TEXT: "Activity at an unusual time, Logon type 9. 10. 11.",
    ONLY_ONE_KEY_PER_ALERT_FILTER_ALLOWED: "Cannot have multiple filters for {0}",
    INVALID_ALERT_FILTERS_FORMAT: "Received invalid alert filters: {0}",
    INVALID_ALERT_INBOX_SORT_FILTER_COMBINATION: "Cannot sort by {0} for the filter {1}",
    CANNOT_CHANGE_ALERT_STATE_TO_SAME_STATE: "Cannot update alert state to the state it is already in",
    ALERT_ALREADY_IN_STATE: "The alert is already in the {0} state",
    CANNOT_GET_UBA_ALERTS_FOR_NON_UBA_TRIGGER: "Cannot load UBA alerts for a non-UBA trigger",
    CANNOT_USE_UBA_TRIGGER_TYPE: "Cannot change a UBA trigger to a different trigger type or a non-UBA trigger to a UBA trigger",
    CANNOT_USE_UEBA_TRIGGER_TYPE: "Cannot change a UEBA trigger to a different trigger type or a non-UEBA trigger to a UEBA trigger",
    CANNOT_CHANGE_STATE_FOR_ESCALATED_ALERT: "Cannot change the state of an alert that is escalated",

    TICKET_MODIFIED_SUCCESS: "Successfully added alert to ticket",
    TICKET_MODIFIED_SUCCESS_MULTIPLE: "Successfully added alert(s) to ticket",
    TICKET_MODIFIED_ERROR: "Error adding alert to ticket",
    UPLOAD_FEWER_THAN_50_FILES: "Please select fewer than 50 files",
    ALERT_STATE_CHANGE_OK: "Updated alerts successfully.",

    EMPTY_ALERT_STATE_CHANGE: "A state change request was not included",
    ALERT_STATE_CHANGE_ERROR: "Error changing state of alert",
    ALERT_STATE_CHANGE_PARTIAL: "Updated alerts within a 30-day period. {0} alerts left unchanged.",

    INBOX_ZERO_HEADER_NEW: "All alerts acknowledged or resolved!",
    INBOX_ZERO_HEADER_ACKED: "All alerts handled!",
    INBOX_ZERO_HEADER_ESCALATED: "No Escalated alerts found",
    INBOX_ZERO_HEADER_DISMISSED: "No Dismissed alerts found",
    INBOX_ZERO_HEADER_FALSE_POSITIVE: "No False Positive alerts found",
    INBOX_ZERO_HEADER_TRUE_POSITIVE: "No True Positive alerts found",

    TIMESTAMP_DESC: "Newest First",
    TIMESTAMP_ASC: "Oldest First",
    SEVERITY_DESC: "Highest Severity",
    SEVERITY_ASC: "Lowest Severity",
    LAST_ESCALATED_DESC: "Last Escalated",
    LAST_ESCALATED_ASC: "First Escalated",
    LAST_FRAUD_FLAGGED_DESC: "Last Fraud Flagged",
    LAST_FRAUD_FLAGGED_ASC: "First Fraud Flagged",
    LAST_DISMISSED_DESC: "Last Dismissed",
    LAST_DISMISSED_ASC: "First Dismissed",
    LAST_ACKED_DESC: "Last Acked",
    LAST_ACKED_ASC: "First Acked",
    LAST_FALSE_POSITIVE_DESC: "Last Marked False Positive",
    LAST_FALSE_POSITIVE_ASC: "First Marked False Positive",
    LAST_TRUE_POSITIVE_DESC: "Last Marked True Positive",
    LAST_TRUE_POSITIVE_ASC: "First Marked True Positive",

    SEVERITY_CRITICAL: "Critical",
    SEVERITY_HIGH: "High",
    SEVERITY_MEDIUM: "Medium",
    SEVERITY_LOW: "Low",
    SEVERITY_NONE: "None",
    WITH_TERMS: "{0} with term [<strong>{1}</strong>]",

    GO_TO_TRIGGER: "Go to Trigger",
    GO_TO_ALERT: "Go to Alert",
    GO_TO_STORY_VIEW: "Go to Story View",

    //fraud inbox specific
    FLAG_FRAUD_MODAL_TITLE: "Fraud-Flag Alert",
    FRAUD_MODAL_HEADER: "You are about to flag alert(s) that were generated from <strong>{0}</strong> trigger",
    THIS_ALERT_IS: "This alert is",
    TYPE_OF_FRAUD: "Type of fraud",
    ACTION_TAKEN: "Action taken",
    FRAUD_SUSPECTED: "Suspected Fraud",
    FRAUD_CONFIRMED: "Confirmed Fraud",
    SHOW_MORE_FIELDS: "Show More Fields",
    SHOW_LESS_FIELDS: "Show Less Fields",
    RECENT_ALERT_EVENTS: "Recent Alert Events",
    SHOW_STEP_QUERY: "Show Step {0} Query",
    HIDE_STEP_QUERY: "Hide Step {0} Query",
    MATCHED_VALUES_STEPS: "MATCHED VALUES STEP {0}",
    //fraud types
    NEW_ACCOUNT: "New Account / ID Fraud",
    ACCOUNT_TAKEOVER: "Account Takeover",
    SCAM: "Scam",
    BILL_PAY: "Bill Pay",
    DEBIT_CARD: "Debit Card",
    CREDIT_CARD: "Credit Card",
    CHECK_FRAUD: "Check Fraud",
    P2P_PAYMENTS: "P2P Payments",
    ACH: "ACH",
    WIRE: "Wire",
    UNKNOWN: "Unknown",
    //fraud actions
    ACCOUNT_RESTRICTED: "Account Restricted",
    CARD_REISSUED: "Card Reissued",
    CUSTOMER_REFUND: "Customer Refund",
    CUSTOMER_NOT_REFUNDED: "Customer not refunded",
    INSTITUTION_CONTACTED: "Sending or receiving institution contacted",
    SUSPECT_DETAILS_CAPTURED: "Suspect details captured",
    RESTITUTION_ACCOUNT_OPENED: "Restitution account opened",
    //uba
    RISK_FACTOR_NEW: "New",
    RISK_FACTOR_KNOWN: "Known",
    SCORE_IMPACT: "Score Impact",

    ROLES: "Roles",

    // triggers
    THREAT_UPLOAD: "Threat Upload",
    TRIGGERS: "Triggers",
    TRIGGER_NAME: "Trigger Name",
    ALERT_STATE: "Alert State",
    ALERT_TYPE: "Alert Type",
    TRIGGER_NAME_PLACEHOLDER: "Trigger Name",
    TRIGGER_DESCRIPTION_PLACEHOLDER: "Trigger Description",
    TRIGGER_DELETE_TOOLTIP: "Delete this trigger",
    TRIGGER_DELETE_MODAL_HEADER: "Delete Trigger",
    TRIGGER_DELETE_MODAL_BODY: "Are you sure you want to delete this trigger?",
    TRIGGER_CONFIRM_DELETED: "The trigger has been deleted",
    TRIGGER_RESTORE_TOOLTIP: "Restore this trigger",
    TRIGGER_CONFIRM_RESTORED: "This trigger has been restored",
    TRIGGER_VIEW_PREVIOUS_TOOLTIP: "View previous version",
    TRIGGER_VIEW_NEXT_TOOLTIP: "View next version",
    TRIGGER_CONFIGURATION: "Trigger configuration",
    TRIGGER_CONFIGURATIONS: "Trigger configurations",
    PRIMARY_COPY: "Primary Copy",
    PRIMARY_COPY_NOTE: "Note: Primary copy can only be managed by CTS Ops. Changing it to the client will instantly remove this trigger as primary copy.",
    SHOW_ALL: "Show All",
    ACTIVE: "Active",
    PAUSED: "Paused",
    ACTIVE_PAUSED: "Active/Paused",
    NEW_TRIGGER: "New Trigger",
    NEW_LIBRARY_TRIGGER: "New Library Trigger",
    SEARCH_TRIGGER: "Search Triggers",
    TRIGGER_TYPE: "Condition Type",
    UPPER_THRESHOLD: "Upper Threshold",
    UPPER_THRESHOLD_DESCRIPTION: "The query is periodically performed. " +
        "If the count of results exceeds the threshold, an alert is created.",
    DYNAMIC_THRESHOLD: "Dynamic Threshold",
    DYNAMIC_THRESHOLD_DESCRIPTION: "The query is performed every 30 minutes. " +
        "This alerts when the most recent data point is the most anomalous of the last 100 periods.",
    LOWER_THRESHOLD: "Lower Threshold",
    LOWER_THRESHOLD_DESCRIPTION: "The query is periodically performed. " +
        "If the count of results is less than the threshold, an alert is created.",
    PATTERNSCOUT: "PatternScout Smart Threshold",
    PATTERNSCOUT_DESCRIPTION: "PatternScout analyzes your recent traffic to threshold and alert on anomalous behavior.",
    TIME_BASED: "Time Based",
    TIME_BASED_DESCRIPTION: "This type of trigger identifies time-based patterns. " +
        "It divides the week into 30-minute periods and compares the average for that day/time from previous weeks.",
    EVERY_EVENT: "Every Event",
    EVERY_EVENT_DESCRIPTION: "This trigger will alert within minutes every time a match is found. " +
        "This is the best trigger to use for extremely infrequent events where every match entails a security incident.",
    SEQUENCE_TRIGGER: "Sequence Trigger",
    SEQUENCE_TRIGGER_DESCRIPTION: "This trigger allows you to specify a sequence of queries that need to " +
        "happen in sequence, before creating an alert. " +
        "Sequence Trigger Alert Results within the Alert Inbox do not yet contain information describing why the trigger fired. " +
        "A new design for these alerts is coming soon. " +
        "Please check 'Create Incident' for this trigger type to see details describing which fields caused this trigger to fire",
    SEQUENCE_TRIGGER_CARDINALITY_CRITICAL_TOOLTIP: "This Trigger has been disabled because one of the steps has matched events with too many " +
        "values for an input or output. Click the Edit button for additional details.",
    SEQUENCE_TRIGGER_CARDINALITY_CRITICAL_DESCRIPTION: "This Trigger has been disabled because one of the steps has matched events with too many " +
        "values for an input or output. Please edit it to re-enable it.",
    SEQUENCE_TRIGGER_CHECK_OUT: "Check out the new Sequence Trigger type",
    SEQ_TRIGGER_STEP: "Step {0}",
    SEQ_TRIGGER_ADD_STEP: "Add New Trigger Step",
    SEQ_TRIGGER_REMOVE_STEP: "Remove this trigger step",
    SEQ_TRIGGER_FIELD_MAPPINGS: "Field mappings",
    SEQ_TRIGGER_ADD_MAPPING: "Add Fields to Map",
    SEQ_TRIGGER_ADD_NEW_MAPPING: "Add New Mapping",
    SEQ_TRIGGER_REMOVE_MAPPING: "Remove this mapping",
    SEQ_TRIGGER_INTERVAL_NEXT_STEP_PART_1: "Sequence continues if Step {0} occurs within",
    SEQ_TRIGGER_INTERVAL_NEXT_STEP_PART_2: "of Step {0}.",
    SEQ_TRIGGER_INPUT_FIELD: "Input fields",
    SEQ_TRIGGER_OUTPUT_FIELD: "Output fields",
    SEQ_TRIGGERS_STEP_INCOMPLETE_FORM: "All queries and field mappings should be completed to save this trigger.",
    SEQ_TRIGGER_INTERVAL_INVALID_NUMBER: "The interval should be a valid number.",
    SEQ_TRIGGER_INTERVAL_TOO_SHORT: "The interval is too short. The minimum interval is {0} {1}.",
    SEQ_TRIGGER_INTERVAL_TOO_LONG: "The interval is too long. The maximum interval is {0} {1}.",
    SEQ_TRIGGER_INTERVAL_COUNT_PART_1: "Must match at least",
    SEQ_TRIGGER_INTERVAL_COUNT_PART_2_WITH_INTERVAL: "Event(s) in a period of",
    SEQ_TRIGGER_INTERVAL_COUNT_PART_2_NO_INTERVAL: "Event(s).",
    SEQ_TRIGGER_TARGET_COUNT: "Target count",
    INTERVAL_SECONDS: "Trigger Interval",
    OLD_VALUE: "Previous Value",
    NEW_VALUE: "New Value",
    AT_OR_ABOVE: "At or Above",
    AT_OR_BELOW: "At or Below",
    ALERT_CONDITIONS: "Alert Conditions",
    LOWER: "Lower",
    UPPER: "Upper",
    INTERVAL: "Interval",
    SECONDS: "seconds",
    MINUTES: "minutes",
    HOURS: "hours",
    DAYS: "days",
    EMAIL_NOTIFICATIONS: "Email Notifications",
    EXAMPLE_EMAIL: "user@example.com",
    EDIT: "Edit",
    PAUSE: "Pause",
    RESUME: "Resume",
    CANCEL: "Cancel",
    TRIGGER_ON: "Trigger On",
    SELECT_TRIGGER_TYPE: "Select a Trigger Type",
    INCIDENT_NUMBER: "Incident Number",
    TASK_NUMBER: "Task Number",
    RECENTLY_VIEWED: "Recently Viewed: ",
    NEW_INCIDENT: "New Incident",
    NEW_TASK: "New Task",
    INCIDENT_COUNTS: "Incident Counts",
    TASK_COUNTS: "Task Counts",
    CREATE_TICKET: "Create a Ticket",
    CREATE_SUBTASK: "Create a Subtask",
    TICKET_SLUG: "Select Workflow",
    TICKET_CREATE_OWNER: "Owner",
    UNDELETE: "Restore",
    CLASSIFIER_NAME_PLACEHOLDER: "Classifier Name",
    DELETED: "Deleted",
    COPY_OF: "Copy of {0}",
    APPLY_METADATA: "Attributes to Apply",
    HITS: "Hits",
    TRENDLINE_LABEL: "Trend line",
    DISABLE: "Disable",
    DISABLED: "Disabled",
    DYNAMIC_THRESHOLDS: "Dynamic Thresholds",
    TEMPORAL_ANOMALY_DETECTION: "Temporal Anomaly Detection",
    ANOMALY: "Anomaly",
    ANOMALIES_LABEL: "Anomalies",
    ALERTABLE_LABEL: "Click to disallow this Control from being monitored by DefenseStorm Alerts",
    NOT_ALERTABLE_LABEL: "Click to allow this Control to be monitored by DefenseStorm Alerts",
    CUSTOM_RESULT_FIELDS: "Result Fields to Include in Email",
    NEW_CUSTOM_FIELD: "New Result Field",
    INTERVAL_NOT_MODIFIABLE: "Interval can not be modified after the trigger is created.",
    TRIGGER_PRIMARY_FIELD_PLACEHOLDER: "Type field name to group by in search",
    VIEW_EVENTS: "View Events",
    TRIGGER_CREATE_ERROR: "Unable to Create Trigger",
    TRIGGER_CARDINALITY_ERROR_DETAIL: "Aggregate field '{0}' has approximately {1} unique values, more than the " +
        "maximum of {2}.",
    TRIGGER_GENERIC_ERROR: "An unexpected error occurred. Please try again later.",
    MAX_ONE_ANOMALY_DETECTION: "You may not specify both Dynamic Thresholds and Temporal Anomaly Detection.",
    WHEN_ALERT_FIRES: "When Alert Fires",
    NOTIFY_ONLY: "Skip Alert Inbox",
    NOTIFY_VIA: "Notify Via Email to",
    INCIDENT_DETAILS: "Incident Details",
    ADD_INCIDENT_DETAILS: "Add Incident Details",
    MANAGE_INCIDENT_DETAILS: "Manage Incident Details",
    CREATE_INCIDENT: "Create Incident",
    CREATE_TEST_TICKET: "Create in Test Inbox",
    DELETE_INCIDENT: "Delete Incident",
    DELETE_INCIDENT_DETAILS: "Delete Incident Details",
    DELETE_INCIDENT_OWNER: "Delete Incident Owner",
    CONFIRM_DELETE_INCIDENT_OWNER: "Deleting the owner will remove all incident details and will stop Incidents from " +
        "being created when an alert fires. Do you want to proceed?",
    CONFIRM_DELETE_INCIDENT_DETAILS: "This action will remove all incident details and will stop Incidents from " +
        "being created when an alert fires. Do you want to proceed?",
    AFFIRM_DELETE_INCIDENT_OWNER: "Yes, Delete Owner",
    AFFIRM_DELETE_INCIDENT_DETAILS: "Yes, Delete Details",
    WATCHER_NOTIF_ALL: "All Notif.",
    WATCHER_NOTIF_KEY: "Key Notif.",
    WATCHER_NOTIF_ALL_ACTIONS: "All Actions",
    WATCHER_NOTIF_KEY_ACTIONS: "Key Actions (State & Comments)",
    TRIGGER_CARDINALITY_CRITICAL_TOOLTIP: "This Trigger has been disabled because its aggregate field has too many " +
        "unique values. Click the Edit button for additional details.",
    TRIGGER_CARDINALITY_CRITICAL_DESCRIPTION: "This Trigger has been disabled because its aggregate field has " +
        "too many unique values.",
    RE_ENABLE_TRIGGER: "Re-Enable Trigger",
    SEARCH_TRIGGERS: "Search Triggers",
    AGGREGATION_TYPE: "Aggregation Type:",
    AGGREGATION_CARDINAL: "Cardinal",
    AGGREGATION_STATISTICAL: "Statistical",
    AGGREGATION_FUNCTION: "Function",
    AGGREGATION_TYPE_AVERAGE: "Average",
    AGGREGATION_TYPE_COUNT: "Count (default)",
    AGGREGATION_TYPE_COUNT_DISTINCT: "Count distinct",
    AGGREGATION_TYPE_MAX: "Max",
    AGGREGATION_TYPE_MIN: "Min",
    AGGREGATION_TYPE_SUM: "Sum",
    TRIGGER_AGG_FIELD_PLACEHOLDER: "Field name",
    TRIGGER_MISSING_SAVED_QUERY: "Trigger is missing a Saved Query",
    INVALID_TRIGGER_UPDATE_EMAIL_OPTIONS: "Can't remove all emails while also providing emails to add",
    TRIGGER_AGG_FIELD_MISSING: "You need to specify a field name for this type of aggregation",
    TRIGGER_AGG_COUNT_DISTINCT: "Count Distinct is strictly accurate for values below 100. Above 100, number of " +
        "results is estimated, and may vary with 1-6% error, depending on the shape of the underlying dataset, and " +
        "proportionally to the number of distinct values.",
    TRIGGER_DOESNT_EXIST: "The trigger doesn't exist",
    CONDITIONS: "Conditions",
    TRIGGER_SOURCE: "Source",
    TRIGGER_COPIED_FROM_LIBRARY: "Copied From Library",
    TRIGGER_CUSTOM: "Custom",
    TRIGGER_IN_TEST: "Trigger In Test",
    TRIGGER_NOT_TEST: "Live Triggers",
    AGGREGATION: "Aggregation",
    EVENT_GRAPH: "Event Graph",
    ALERT_CONTROLS: "Alert Controls",
    NO_POLICIES_LINKED: "No policies linked",
    EMAIL_RECIPIENT: "Email Recipient",
    INCIDENT_CREATION: "Incident Creation",
    INCIDENT_OWNER: "Incident Owner",
    CUSTOM_EMAIL_HEADERS: "Custom Email Headers",
    IMPORTANT_FIELDS: "Important Fields",
    IMPORTANT_FIELDS_EXPLANATION: "Important fields determine which fields are displayed in notification emails and " +
        "in the new Alert Inbox",
    ADD_FIELDS: "Add Fields",
    ADD_IMPORTANT_FIELDS: "Add Important Fields",
    ADD_EMAIL_NOTIFICATIONS: "Add Email Notifications",
    INITIAL_VERSION: "Initial version",
    CURRENT_VERSION: "Current version",
    LAST_FIRED: "Last Fired",
    FIRE_AT_MOST_ONCE_EVERY: "Fire at most once every",
    INFO_UNAVAILABLE: "Info unavailable",
    FUNCTION_OF_SUB_AGGREGATION: "<strong>{0}(</strong>{1}<strong>)</strong>",
    DISABLE_TEMPORARILY: "Disable Temporarily",
    DISABLED_TEMPORARILY: "Disabled Temporarily",
    CONTINUE_DISABLING_TEMPORARILY: "Continue Disabling Temporarily",
    DISABLE_INDEFINITELY: "Disable Indefinitely",
    PAUSE_INDEFINITELY: "Pause Indefinitely",
    PAUSED_INDEFINITELY: "Paused Indefinitely",
    DISABLE_TRIGGER_TEMPORARILY_TITLE: "Disable Trigger Temporarily",
    DISABLED_FOR: "Disabled for {0}.",
    STOPPED: "Stopped",
    EVERY_EVENT_TYPE_DESCRIPTION: "Best used for infrequent, important security events that require immediate " +
        "attention and constant monitoring.",
    EVERY_EVENT_TYPE_EXAMPLE: "For example: An administrator password is reset.",
    INTERVAL_TYPE_DESCRIPTION: "Best used for events that require monitoring within a specific time span " +
        "and parameters.",
    INTERVAL_TYPE_EXAMPLE: "For example: A user has typed an incorrect password more than 5 times within 10 minutes.",
    ANOMALY_TYPE_DESCRIPTION: "Best used for monitoring events that vary from a steady baseline.",
    ANOMALY_TYPE_EXAMPLE: "For example: A rapid change in the number of connections between two computers may " +
        "indicate a compromise.",
    INVALID_TICKET_OWNER: "Please specify a valid owner for the incidents created by this trigger.",
    INVALID_EMAIL_MESSAGE: "Please enter a valid email address e.g. \"name@domain.com\"",
    UNEXPECTED_TRIGGER_CREATION_ERROR: "Unexpected trigger creation error.",
    TRIGGER_CREATE_SUCCESS: "Successfully created trigger",
    ADD_NOTIFICATIONS: "Add Notifications",
    MANAGED_BY: "Managed By",
    TRAC_MANAGEMENT: "CTS Ops Management",
    TRAC: "CTS Ops",
    CLIENT: "Client",
    TRIGGER_EVERY_EVENT_QUERY_INVALID: "Every event triggers cannot be used with a query which has an aggregate field.",
    USER_ENTITY: "User / Entity",
    RISK_FACTORS: "Risk Factor(s)",
    FAILED_TO_RETRIEVE_TRIGGER: "Failed to retrieve trigger",
    TRIGGER_ACTIVITY_LOG_AUTO_DISABLED: "Trigger disabled",
    TRIGGER_ACTIVITY_LOG_AUTO_DISABLED_FALSE: "Re-enabled trigger",

    // default triggers
    DEFAULT_TRIGGER: "Default Trigger",
    DEFAULT_TRIGGERS: "Library",
    INFORMATION: "Information",
    LIBRARY_DESCRIPTION: "Library Description",
    COPY: "Copy",
    SCHEDULE: "Schedule",
    RUNS_EVERY: "Runs every",
    RUN_SEARCH_QUERY_EVERY: "Run trigger every",
    COPY_LIBRARY_TRIGGERS: "Copy Library Triggers",
    EDIT_LIBRARY_TRIGGER: "Edit Library Trigger",
    COPY_ALL: "Copy all",
    COPY_SELECTED: "Copy selected",
    DELETE_SELECTED: "Delete selected",
    EDIT_GROUP: "Edit group",
    ADD_NEW_GROUP: "Add New Group",
    DELETE_GROUP: "Delete group",
    GROUP_BASE_QUERY: "Group Base Query",
    BASE_QUERY: "Base Query",
    CANNOT_ANALYZE_TRIGGER_GROUP: "This group can’t be analyzed since it hasn’t been assigned a base query",
    RECOMMENDED_ACTIONS: "Recommended Actions",
    ANALYZING: "Analyzing",
    ACTIVITY_FOUND: "Activity Found",
    NO_ACTIVITY_FOUND: "No Activity Found",
    WITH_ACTIVITY: "With Activity",
    WITHOUT_ACTIVITY: "Without Activity",
    LAST_ANALYSIS: "Last Analysis",
    SELECT_GROUP_FOR_ANALYSIS: "Select at least one group to run analysis",
    DEFAULT_TRIGGER_GROUP_IDS_NOT_FOUND: "The following default trigger group ids were not found when running analysis: {0}.",
    GET_DEFAULT_TRIGGER_GROUP_ANALYSES_ERROR: "An error occurred while fetching analysis results.",
    REQUEST_DEFAULT_TRIGGER_GROUP_ANALYSES_ERROR: "An error occurred while running an analysis.",
    ANALYSIS_FAILED: "Analysis Failed",
    ANALYSIS_FAILED_EXPLANATION: "An error occurred while running analysis. Please try running the analysis again.",
    ADD_LIBRARY_QUERY: "Add Library Query",
    COPIED: "Copied",
    ADD_DEFAULT_TRIGGER_GROUP: "Add Trigger Library Group",
    ADD_DEFAULT_TRIGGER: "Add Trigger to Library",
    ADD_TRIGGER_TO_GROUP: "Add Trigger to Group",
    LIBRARY_STATS: "Library Stats",
    ENABLE_IN_MULTIPLE_ORGS: "Enabled Bulk",
    DISABLE_IN_MULTIPLE_ORGS: "Disable Bulk",
    SELECT_ORGS_TO_COPY_LIBRARY_TRIGGER_TO: "Select Orgs to Copy Library Trigger to",
    SELECT_ORGS_TO_REMOVE_LIBRARY_TRIGGER_FROM: "Select Orgs to Remove Library Trigger from",
    COPY_TRIGGER: "Copy Trigger",
    COPY_LIBRARY_TRIGGER: "Copy Library Trigger",
    REMOVE_LIBRARY_TRIGGER: "Remove Library Trigger",
    REMOVE_TRIGGER: "Remove Trigger",
    REMOVE_ALL_TRIGGER_LINKED_COPIED_FROM: "Remove all trigger linked copies from",
    COPY_TRIGGER_TO: "Copy trigger to",
    BULK_EDIT: "Bulk Edit",
    RETAIN: "Retain",
    TRAC_ENGINEER: "TRAC Engineer",
    SEND_TO_TEST_TICKET_INBOX: "Send to test ticket inbox",
    SELECT_ORGANIZATION: "Select Organization",
    BULK_PAUSE_TRIGGERS_ERROR: "An error occurred while removing triggers from the selected orgs",
    BULK_ACTIVATE_TRIGGERS_ERROR: "An error occurred while copying triggers from the selected orgs",
    GET_STATUSES_FOR_DEFAULT_TRIGGER_COPIES_ERROR: "An error occurred while getting statuses for library trigger copies",
    TRAC_ENGINEER_NOT_AUTHORIZED_FOR_SOME_ORGS_DURING_BULK_EDIT: "TRAC Engineer user is not authorized for the following orgs: {0}",
    INVALID_INCIDENT_DETAILS_FOR_BULK_EDIT: "To edit the ticket watchers or send to test ticket inbox, the ticket owner must be selected",
    TRIGGER_NOT_COPIED_AND_ACTIVE_IN_SOME_ORGS: "This trigger is not copied and active in the following orgs and cannot be disabled: {0}",
    TRIGGER_ACTIVE_IN_SOME_ORGS: "This trigger is already active in the following orgs and cannot be enabled: {0}",
    ORG_COUNT: "{0} Org(s)",
    MANAGE_SELECTED_ORGS: "Manage Selected Org(s)",
    NO_ORGS_APPLICABLE: "No organizations applicable",
    DOUBLE_CHECK_SELECTED_ORGS: "Double check your organization selection before {0} this trigger.",
    BULK_TRIGGER_ACTION_SUCCESS: "{0} has been successfully {1} from {2} org(s)",
    REMOVING: "Removing",
    COPYING: "Copying",
    CONTINUE: "Continue",
    GROUP_NAME: "Group Name",
    COPY_TO_LOCAL_TRIGGERS: "Copy to local triggers",
    COPY_TO_LOCAL_TRIGGERS_TITLE: "Copy to Local Triggers",
    CANNOT_SELECT_TO_COPY_LIBRARY_TRIGGER_EXPLANATION: "There is already a copy of this Library Trigger in this organization",
    DELETE_FROM_LIBRARY: "Delete from Library",
    EDIT_TRIGGER: "Edit Trigger",
    TRIGGER_CONDITIONS: "Trigger Conditions",
    ALERT_WHEN_COUNT: "Alert when count is",
    MORE_THAN_EQUAL_TO: "More than or equal to",
    LESS_THAN_EQUAL_TO: "Less than or equal to",
    DEFAULT_TRIGGER_NAME_PLACEHOLDER: "Default trigger name",
    DEFAULT_TRIGGER_DESCRIPTION_PLACEHOLDER: "A useful description for this default trigger",
    DEFAULT_TRIGGER_GROUP_NAME_PLACEHOLDER: "Default trigger group name",
    DEFAULT_TRIGGER_GROUP_DESCRIPTION_PLACEHOLDER: "A useful description for this default trigger group",
    SEARCH_QUERY_PLACEHOLDER: "Query to run",
    UPPER_THRESHOLD_INSTRUCTIONS: "Alerts when event count is <strong>ABOVE</strong> the Upper Threshold",
    LOWER_THRESHOLD_INSTRUCTIONS: "Alerts when event count is <strong>BELOW</strong> the Lower Threshold",
    DYNAMIC_THRESHOLDS_INSTRUCTIONS: "Dynamic Thresholds are best suited to detect sharp differences in queries " +
        "that typically return non-zero results. If the query is strongly associated with the day cycle, anomaly " +
        "detection may result in false positives at the start of the day.",
    TEMPORAL_ANOMALY_INSTRUCTIONS: "Temporal Anomaly Detection is best used for data that has a wall clock pattern to it, " +
        "such as employee logons, or normal network traffic. It does not work well when there are level shifts that " +
        "do not correlate with time.",
    TEMPORAL_TRIGGER_INTERVAL_DETAIL: "Temporal Anomaly Detection is only available with an interval of 30 minutes",
    TEMPORAL_TRIGGER_UNAVAILABLE: "Temporal Anomaly Detection (only available with an interval of 30 minutes)",
    NO_DEFAULT_TRIGGERS: "There are no triggers in this group",
    NO_TRIGGERS_IN_SEARCH: "No triggers matched the search criteria",
    NO_TRIGGERS: "Need some ideas for what triggers to add? Choose some pre-configured triggers from our " +
        "<a href='/triggers/library' class='link-text'>Trigger Library</a> to get started!",
    ADD_GROUP: "Add Group",
    ADD_TRIGGER: "Add Trigger",
    DELETE_TRIGGER_GROUP_DESCRIPTION: "Are you sure you want to delete this trigger group?",
    DELETE_SELECTED_TRIGGERS_DESCRIPTION: "Are you sure you want to delete these selected triggers?",
    DELETE_SELECTED_FROM_GROUP: "Delete selected from group",
    UPDATE_DEFAULT_TRIGGER: "Update Default Trigger",
    TRIGGER_LABELS: "Tags",
    TRIGGERS_ENABLED_COUNT: "{0} of {1}",
    LABEL_PLACEHOLDER: "Search for Tags",
    NO_LABELS: "No tags have been added",
    TRIGGER_STATS: "Library Trigger Stats",
    ALERTS_CREATED: "Alerts created",
    ALERTS_ESCALATED: "Alerts escalated",
    ESCALATIONS: "Escalations",
    ESCALATION_RATE: "Escalation rate",
    COPIES: "Copies",
    TOTAL_COPIES: "Total copies",
    ENABLED_COPIES: "Enabled copies",
    DISABLED_COPIES: "Disabled copies",
    VIEW_TRIGGER: "View trigger",
    ALERT_STATISTICS: "Alert Statistics",
    COPY_STATISTICS: "Copy Statistics",
    QUERY_TYPE: "Query Type(s)",
    FAILED_TO_RETRIEVE_DEFAULT_TRIGGER: "Failed to retrieve default trigger",

    //Trigger Schedules
    TRIGGER_SCHEDULES: "Schedules",
    NEW_SCHEDULE: "New Schedule",
    EDIT_SCHEDULE: "Edit Schedule",
    ADD_SCHEDULE: "Add Schedule",
    REMOVE_SCHEDULE: "Remove Schedule",
    DELETE_SCHEDULE: "Delete Schedule",
    DELETE_SCHEDULES: "Delete Schedules",
    CREATE_TRIGGER_SCHEDULE: "Create Trigger Schedule",
    EDIT_TRIGGER_SCHEDULE: "Edit Trigger Schedule",
    TRIGGER_SCHEDULE_NAME_PLACEHOLDER: "Trigger Schedule Name",
    TRIGGER_SCHEDULE_DESCRIPTION_PLACEHOLDER: "Trigger Schedule Description",
    SCHEDULE_NOT_FOUND: "Trigger Schedule Not Found",
    SCHEDULE_UPDATE_FAILED: "Failed to Update Schedule",
    SCHEDULE_CREATION_FAILED: "Failed to Create Schedule",
    SCHEDULE_DELETE_FAILED: "There was a problem deleting the schedule(s)",
    GET_TRIGGERS_FAILED: "There was a problem retrieving triggers",
    BULK_DELETE_SCHEDULES_TOOLTIP: "Delete {0} schedules...",
    SEARCH_TRIGGER_SCHEDULES: "Search Trigger Schedules",
    INTERVALS: "Intervals",
    TRIGGERS_WITH_SCHEDULE: "All triggers using schedule \"{0}\"",
    TRIGGERS_USING_SCHEDULE: "Triggers Using Schedule",
    NO_TRIGGERS_WITH_SCHEDULE: "No triggers use this schedule",
    BULK_DELETE_SCHEDULES: "You are about to delete {0} trigger schedules",
    TRIGGER_COUNT: "Trigger count",
    DAY: "Day",
    BEGIN: "Begin",
    START: "Start",
    END: "End",
    END_OF_DAY: "End of day",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
    NO_INTERVALS_ADDED: "Add at least one interval to the trigger schedule",
    NO_TRIGGER_SCHEDULES_FOUND: "No trigger schedules found",
    TRIGGER_SCHEDULES_KB: "View details about Trigger Schedules in the DefenseStorm Knowledge Center",
    TRIGGERS_FOR_SCHEDULE_ERROR: "Couldn't retrieve the list of triggers for this trigger schedule",

    // Activity Log for Trigger
    CHANGE_TRIGGER: "Change Trigger",
    PAUSE_TRIGGER: "Disable Trigger {0}",
    RESUME_TRIGGER: "Resume Trigger",
    DELETE_TRIGGER: "Delete Trigger",
    RESTORE_TRIGGER: "Restore Trigger",
    ARCHIVE_TRIGGER: "Archive Trigger",
    BATCH_TRIGGER_UPDATE: "Batch Trigger Update",
    TRAC_MANAGED: "CTS Ops Managed",
    TICKET_OWNER: "Ticket Owner",
    NEW_TICKET_OWNER: "New ticket owner:",
    PREVIOUS_TICKET_OWNER: "Removed ticket owner:",
    NEW_OWNED_BY: "New owner:",
    PREVIOUS_OWNED_BY: "Removed owner:",
    NEXT_TRIGGER_ID: "Next Trigger ID",
    PARENT_TRIGGER_ID: "Parent Trigger ID",
    MIN_MATCH: "Min Match",
    MAX_MATCH: "Max Match",
    TEMPORAL_LAST_RUN_END: "Temporal Last Run End",
    AGGREGATION_STATUS: "Aggregation Status",
    AGGREGATION_FIELD: "Aggregation Field",
    TICKET_WATCHERS: "Ticket Watchers",
    NEW_TICKET_WATCHERS: "New Ticket Watchers:",
    PREVIOUS_TICKET_WATCHERS: "Previous Ticket Watchers:",
    REMOVED_TICKET_WATCHERS: "Removed Ticket Watchers:",
    LINE_ITEM_IDS: "Line Items",
    SCHEDULE_ID: "Schedule",
    LABELS: "Labels",
    SAVED_QUERY_ID: "Saved Query ID",
    TAGS: "Tags",
    TAG_IDS: "Tag IDs",
    ARCHIVE_PREVIOUS_TRIGGER: "A new trigger version was created because of the following changes:",
    LAST_MODIFIED_ON_ONLY: "The only value that changed was 'last modified on'",
    SAVED_QUERY_UPDATE: "Saved Query Update",
    LIBRARY_SAVED_QUERY_UPDATE: "Library Saved Query Update",

    //Task Schedules
    TASK_SCHEDULES: "Task Schedules",
    NO_TASK_SCHEDULES: "No task schedules. Create one to get started!",
    NO_TASK_SCHEDULES_MATCHING_FILTERS: "No task schedules matching filters",
    TASK_SCHEDULE_NAME_PLACEHOLDER: "Task Schedule name",
    TASK_SCHEDULE_DESCRIPTION_PLACEHOLDER: "Task Schedule description",
    NO_TASKS_ON_SCHEDULE: "There are no tasks for this task schedule.",
    NEW_TASK_SCHEDULE: "New Task Schedule",
    SINGLE_SCHEDULE_INTERVAL: "Every {0}",
    MULTIPLE_SCHEDULE_INTERVAL: "Every {0} {1}",
    TICKET_DUE_INTERVAL: "{0} {1} after ticket creation",
    CREATE_TASK_EVERY: "Create Task Every",
    STARTING_ON: "Create First Task On",
    SCHEDULE_DAY: "day(s)",
    SCHEDULE_WEEK: "week(s)",
    SCHEDULE_MONTH_BEGIN: "month(s) on the 1st",
    SCHEDULE_MONTH_15: "month(s) on the 15th",
    DUE_IN_MONTH: "month(s)",
    DUE_IN_YEAR: "year(s)",
    TASK_DUE_DATE: "Task Due Date",
    NEXT_RUN: "Next Run",
    LAST_RAN: "Last Ran",
    SKIP_WEEKEND: "Skip weekends",
    TASK_SCHEDULE_NEXT_RUN_ERROR: "Next Run must be in the future",
    SEARCH_TASK_SCHEDULES: "Search Task Schedules",
    DOWNLOAD_TASK_SCHEDULES: "Download {0} Task Schedule(s)",
    DOWNLOAD_TASK_SCHEDULES_ERROR: "An error occurred during download.",
    TASK_SCHEDULE_ADD_MODAL_TITLE: "Add Task Schedule",
    TASK_SCHEDULE_ADD_SUBSCHEDULE_MODAL_TITLE: "Add Subschedule",
    TICKET_VERSION_ERROR: "This ticket was updated while you were editing it. Please reload this page with your browser.",
    CLONE_TASK: "Clone Task",
    CUSTOM_COLUMNS: "Columns",
    ADD_QUERY_MODIFIER: "Add Query Modifier",
    ADD_MODIFIER: "Add Modifier",
    ADD_SEARCH_QUERY: "Add Search Query",
    TASK_SCHEDULE_DUPLICATE_QUERIES_ERROR_DEPRECATED: "There are duplicate queries, please remove one of them.",
    TASK_SCHEDULE_DUPLICATE_QUERIES_ERROR: "The query selected is already present.",
    INVALID_TASK_SCHEDULE_QUERY: "Invalid query",
    TASK_SCHEDULE_SEARCH_NAME_PLACEHOLDER: "Query name",
    DEFAULT_TASK_SCHEDULE_SEARCH_NAME: "Untitled",
    VIEW_SCHEDULE: "View Schedule",
    RELATED_TASKS: "Related Tasks",
    PREVIOUS_TASK: "Previous",
    NEXT_TASK: "Next",
    PARENT_TASK_SCHEDULE: "Parent Task Schedule",
    PARENT_SCHEDULE: "Parent Schedule",
    ADD_PARENT_SCHEDULE: "Add Parent Schedule",
    REMOVE_PARENT_SCHEDULE: "Remove Parent Schedule",
    TASK_SCHEDULE_PARENT_ID_QUERY_ERROR: "Parent task schedules cannot have queries",
    TASK_SCHEDULE_PARENT_ID_INTERVAL_ERROR: "Parent schedules must have equal or longer intervals than their subschedules",
    SUBSCHEDULE: "Subschedule",
    RUN_TASK_SCHEDULE_NOW: "Run task schedule now",
    TASK_SCHEDULE_WILL_RUN_WITHIN_1_HOUR: "Task schedule will run within 1 hour",
    TASK_SCHEDULE_RUN_IMMEDIATELY_ERROR: "An error occurred while updating this task schedule to run immediately",
    TASK_SCHEDULE_HAS_RUN_SCHEDULED: "Task schedule is scheduled to run within 1 hour",
    UNEXPECTED_TASK_SCHEDULE_REFRESH_ERROR: "A communication problem has occurred while refreshing the task schedules. Please try again later.",
    SCHEDULE_VIEW_DEFAULT: "Last Modified",
    SCHEDULE_VIEW_GROUPED: "Task Schedule Groups",
    TASK_SCHEDULE_PARENT_TOOLTIP: "Only parents with larger or equal intervals can be selected.",
    TASK_SCHEDULE_INVALID_HAS_QUERIES: "This task schedule cannot have queries as it already has subschedules.",
    TASK_SCHEDULE_INVALID_PARENT: "Invalid parent provided",
    TASK_SCHEDULE_CYCLE_DETECTED: "Cannot change to this parent as a cycle would be generated.",
    TASK_SCHEDULE_TOO_MANY_LEVELS: "Cannot change to this parent as there would be more than {0} levels of subschedules.",
    TASK_SCHEDULE_INVALID_PARENT_HAS_QUERIES: "Cannot change to this parent as it already has {0} queries. Task schedules with queries can't have subschedules.",
    TASK_SCHEDULE_INVALID_PARENT_INTERVAL: "This task schedule's interval is incompatible with the interval of its parent: {0} {1}.",
    TASK_SCHEDULE_INVALID_CHILDREN_INTERVAL: "Cannot change this task schedule's interval as the subschedule \"{0}\" has a larger interval: {1} {2}.",
    DETACH_PARENT_TASK_SCHEDULE: "Remove parent relationship",
    ATTACH_PARENT_TASK_SCHEDULE: "Attach parent",
    ATTACH_CHILD_TASK_SCHEDULE: "Attach subschedule",
    DETACH_CHILD_TASK_SCHEDULE: "Remove subschedule relationship",
    PARENT_AND_SUBSCHEDULES: "Parent & Subschedules",
    ADD_NEW_SUBSCHEDULE: "Add New Subschedule",
    SELECT_EXISTING_PARENT_TASK_SCHEDULE_MODAL_TITLE: "Select New Parent Schedule",
    SELECT_EXISTING_CHILD_TASK_SCHEDULE_MODAL_TITLE: "Select New Subschedule",
    TASK_SCHEDULE_CANT_ADD_SUBSCHEDULE_TOO_MANY_LEVELS: "Can't add another subschedule as there are already {0} levels.",
    TASK_SCHEDULE_CREATE_SUCCESS: "Successfully created task schedule",
    DELETE_DUE_DATE: "Delete Due Date",
    TASK_SCHEDULE_CONTROL_DUPLICATE_ADD: "This task schedule is already added to this control",
    TASK_SCHEDULE_NEXT_RUN_IN_PAST: "The task schedule's next run cannot be in the past",
    TASK_SCHEDULE_NEXT_RUN_SHOULD_MATCH_MONTH_BEGIN: "The task schedule's next run should be on the 1st",
    TASK_SCHEDULE_NEXT_RUN_SHOULD_MATCH_MONTH_15: "The task schedule's next run should be on the 15th",

    // Library Task Schedules
    LIBRARY_TASK_SCHEDULES_TAB: "Library",
    CREATE_LIBRARY_TASK_SCHEDULE: "Create Library Task Schedule",
    LIBRARY_TASK_SCHEDULE_SEARCH: "Search Library Task Schedules",
    NO_LIBRARY_TASK_SCHEDULES: "No library task schedules found",
    LIBRARY_TASK_SCHEDULE_NAME_PLACEHOLDER: "Library Task Schedule Name",
    LIBRARY_TASK_SCHEDULE_DESC_PLACEHOLDER: "Library Task Schedule Description",
    LIBRARY_TASK_SCHEDULE_DELETE_SUCCEEDED: "Library task schedule deleted successfully",
    LIBRARY_TASK_SCHEDULE_RESTORE_SUCCEEDED: "Library task schedule restored successfully",
    COPY_SELECTED_LIBRARY_TASK_SCHEDULES: "Copy selected",
    COPY_SELECTED_LIBRARY_TASK_SCHEDULES_SUCCESS: "Successfully copied {0} library task schedules",
    COPY_LIBRARY_TASK_SCHEDULES_TO_ALL_ORGS: "Copy selected to all authorized orgs",
    COPY_TO_ALL_SUCCESS: "Successfully copied selected library task schedules to all authorized orgs",
    LIBRARY_TASK_SCHEDULES_TO_COPY: "The following items will be copied to your organization's account:",
    LIBRARY_TASK_SCHEDULES_TO_COPY_ALL: "The following items will be copied to all organizations in your authorized list:",
    TASK_SCHEDULES_TO_COPY: "Task schedules to copy",
    ACTIVATE_TASK_SCHEDULES: "Activate task schedules",
    COPY_TO_ALL: "Copy to All",
    COPY_TASK_SCHEDULE: "Copy Task Schedule",
    COPIES_TO_AUTHORIZED_ORGS_ONLY: "Task schedules will be copied only to organizations currently in your authorized organization list.",
    CREATE_GROUP: "Create Group",
    GROUP: "Group",
    CATEGORY_NAME_PLACEHOLDER: "Category name",
    CATEGORY_DESCRIPTION_PLACEHOLDER: "Category description",
    CREATE_GROUP_SUCCESS: "Successfully created a new group",
    CREATE_CATEGORY_SUCCESS: "Successfully created a new category",
    IS_LIBRARY_SCHEDULE_NOTIFICATION: "This schedule has been copied from Task Schedule Library.",
    LIBRARY: "Library",
    ORG_NAME: "Organization name",
    ORG_NAME_ABBREVIATED: "Org name",
    LIBRARY_TASK_SCHEDULE_INVALID_PARENT: "Invalid parent provided",
    LIBRARY_TASK_SCHEDULE_CYCLE_DETECTED: "Cannot change to this parent as a cycle would be generated.",
    LIBRARY_TASK_SCHEDULE_TOO_MANY_LEVELS: "Cannot change to this parent as there would be more than {0} levels of subschedules.",
    RESTORE_PARENT_FIRST_MSG: "If you want to restore this subschedule please restore '{0}' first.",
    COPIED_TO_TASK_SCHEDULES: "Copied to Task Schedules",

    // users/profile
    FULL_NAME: "Full Name",
    SHORT_NAME: "Short Name",
    PHONE: "Phone",
    ADD_NEW: "Add New",
    INACTIVE: "Inactive",
    OLD_PASSWORD: "Current Password",
    NEW_PASSWORD: "New Password",
    GROUP_TICKET_EMAILS: "Group Ticket Emails",
    GROUP_TICKET_EMAILS_DETAILS: "Emails about ticket modifications will be grouped to reduce the amount of emails received.",
    ROLE: "Role",
    CONFIRM_PASSWORD: "Confirm Password",
    PASSWORDS_MUST_MATCH: "Passwords must match",
    PASSWORDS_MUST_VALIDATE_LENGTH: "Passwords must contain at least 8 characters",
    SELECT_A_ROLE: "Select a role",
    DEACTIVATE: "Deactivate",
    ACTIVATE: "Activate",
    UPDATE: "Update",
    ADD_USER: "Add User",
    EDIT_USER: "Edit User",
    EDIT_PROFILE: "Edit Profile",
    USER_DEACTIVATED: "User deactivated",
    TWO_FACTOR_AUTHENTICATION: "Two-factor Authentication",
    USERS_TWO_FACTOR_DESCRIPTION: "Requiring two-factor authentication for your organization will ensure that all " +
        "users have it enabled before accessing the application.",
    TWO_FACTOR_REQUIRED: "Required",
    TWO_FACTOR_NOT_REQUIRED: "Not Required",
    ENABLE_ORG_TWO_FACTOR_DESCRIPTION: "To change the status of two-factor authentication for your organization, please contact DefenseStorm customer service.",
    TOTP_TWO_FACTOR_ENABLED: "TOTP Two-factor Authentication is",
    SET_UP_TWO_FACTOR: "Set Up",
    ENABLE_TWO_FACTOR_DESCRIPTION: "Two-factor authentication uses your smart phone to provide another level of " +
        "protection. You install an application from Google and then provide a code from that application when " +
        "you log in.",
    DISABLE_TWO_FACTOR: "Disable",
    DISABLE_TWO_FACTOR_DESCRIPTION: "Disabling two-factor authentication requires you to submit a ticket to " +
        "DefenseStorm Support. Click the link to get started.",
    TWO_FACTOR_ON: "ON",
    TWO_FACTOR_OFF: "OFF",
    CHANGE_PASSWORD: "Change Password",
    PASSWORD_CHANGE_SUCCESS: "Your password was changed successfully.",
    PASSWORD_CHANGE_FAILED: "Password change failed.  Check your password and try again.",
    INVALID_CURRENT_PASSWORD: "Invalid current password.",
    RESET_2FA: "Reset",
    SUCCESSFULLY_RESET_2FA: "Two factor authentication successfully reset for {0}",
    ERROR_RESETTING_2FA: "An error occurred while resetting two factor authentication for {0}",
    AUTHORIZED_ORGS: "Authorized Orgs",
    SEARCH_ORG: "Search Org",
    SEARCH_ORGS: "Search Orgs",
    PLEASE_SELECT_VALUE: "Please select a value",
    STATUS_2FA: "2FA Status",
    ALL_USERS: "All Users",
    INITIALIZED_2FA: "2FA Initialized",
    NO_2FA: "No 2FA Initialized",
    NOTES_HELP: "Click for markdown documentation",
    NOTES_HELP_DOCS: "https://defensestorm.knowledgeowl.com/help/defensestorm-markdown-language-quicksheet",
    ERROR_ACTIVATING: "An error occurred activating user {0}",
    ERROR_DEACTIVATING: "An error occurred deactivating user {0}",
    MARKDOWN_HELP: "Need help with markdown syntax?",
    PROFILE_EDIT_SUCCESS: "Successfully updated user profile",
    USER_ACTIVITY_LOG_CREATED: "Created user",
    USER_ACTIVITY_LOG_DELETED: "Deleted user",
    USER_ACTIVITY_LOG_ACTIVATED: "Activated user",
    USER_ACTIVITY_LOG_UPDATED: "Updated user",
    USER_ACTIVITY_LOG_PROPERTY_REMOVED: "Removed {0}",

    // tickets
    ANALYSIS: "Analysis", // Analysis is a deprecated term.  do not use.
    NOTES: "Notes",
    TITLE: "Title",
    DETAILS: "Details",
    QUERY_ADDED: "A query was added to",
    EVENT_AND_QUERY_ADDED: "An event and a query were added to",
    EVENTS_AND_QUERY_ADDED: "{0} events and a query were added to",
    TYPE: "Type",
    WORKFLOWS: "Workflows",
    SUMMARY: "Summary",
    TICKET_SEARCH: "Search title, ID, or description",
    CHANGE_STATE: "Change",
    STATE: "State",
    NOTIFIED_REGULATOR: "Notified Regulator",
    OWNED_BY: "Owner",
    CREATED_ON: "Created On",
    CREATED_BY: "Created By",
    LAST_MODIFIED_ON: "Last Modified",
    LAST_MODIFIED_BY: "Last Modified By",
    LINKS: "Links",
    RESOLUTION: "Resolution",
    WATCHERS: "Watchers",
    WATCHER: "Watcher",
    NEW_WATCHER: "New Watcher",
    INCIDENT_WATCHERS: "Incident Watchers",
    WATCHER_TYPE_STATE: "Open, close, or overdue",
    WATCHER_TYPE_ALL: "All messages",
    WATCHER_NOTIFICATIONS_STATE: "Only state notifications",
    WATCHER_NOTIFICATIONS_ALL: "All notifications",
    WATCHERS_NOTIFICATIONS_TOOLTIP: "Notifications are set to \"All messages\" by default",
    STATE_WATCHER_DESCRIPTION: "Notify only when ticket is opened or closed",
    ALL_WATCHER_DESCRIPTION: "Notify for all ticket events",
    NOTIFICATION: "Notification",
    BY: "By",
    ADD: "Add",
    WATCH: "Watch",
    DONE: "Done",
    SHOW_DELETED: "Show deleted",
    HIDE_DELETED: "Hide deleted",
    FILES: "Files",
    SEARCHES: "Searches",
    SEARCH: "Search",
    FILE: "File",
    TRIGGER: "Trigger",
    COMMENT: "Comment",
    COMMENTS: "Comments",
    SELECT_ELLIPSES: "Select...",
    NO_SEARCHES: "No associated searches",
    NO_LINKS: "No associated links",
    SET_TO: "Set to",
    PREVIOUS_STATE: "Previous state",
    PREVIOUS_OWNER: "Previous owner",
    PREVIOUS_RESOLUTION: "Previous resolution",
    PREVIOUS_SUMMARY: "Previous summary",
    SEARCH_ADDED: "Search added",
    PREVIOUS_SEARCH: "Previous search",
    PREVIOUS_SEVERITY: "Previous severity",
    SEARCH_REMOVED: "Search removed",
    LINK_ADDED: "Link added",
    PREVIOUS_LINK: "Previous link",
    LINK_REMOVED: "Link removed",
    FILE_ADDED: "File added",
    PREVIOUS_FILE: "Previous file",
    FILE_REMOVED: "File removed",
    FILE_GENERATION_PENDING: "This file is being generated.",
    FILE_GENERATION_ERROR: "An error occurred generating this PDF file. Please contact DefenseStorm for more information.",
    PREVIOUS_SUMMARY_NOTE: "Previous Summary",
    PREVIOUS_PARENT: "Previous Parent",
    WATCHER_ADDED: "Watcher added",
    WATCHER_REMOVED: "Watcher removed",
    WATCHER_CHANGED: "Watcher changed to {0}",
    ALERT_ADDED: "Alert added",
    FIRED_TIMESTAMP: "FIRED TIMESTAMP",
    EVENT_COUNT: "EVENT COUNT",
    ALERT_REMOVED: "Alert removed",
    POLICY_CONTROLS_ADDED: "Controls added",
    POLICY_CONTROLS_REMOVED: "Controls removed",
    POLICIES: "Policies",
    CUSTOM_EVENTS: "Custom Events",
    SYSTEM_EVENTS: "System Events",
    EVENTS_FROM_ALERT: "Events From Alert",
    POLICY_FILES: "Documents",
    POLICY_COMMENT_EDIT_SUCCESS: "Successfully changed comment",
    NO_POLICY_FILES: "This policy doesn't have any document.",
    ATTACH: "Attach",
    APPLY: "Apply",
    CONFIGURE_THREAT_MATCH: "Configure ThreatMatch",
    THREAT_MATCH: "ThreatMatch",
    MATCHED_THREAT: "Matched Threat",
    NEW_THREAT_INDICATORS_TOOLTIP: "Upload new indicators",
    DOWNLOAD_THREAT_INDICATORS_TOOLTIP: "Download threat indicators csv",
    ADD_NEW_POLICY: "Add New Policy",
    ADD_NEW_LINK: "Add New Link",
    MERGE_INCIDENTS_IN: "Merge Incidents In",
    TASK_DOES_NOT_EXIST: "Task does not exist",
    INCIDENT_DOES_NOT_EXIST: "Incident does not exist",
    PREVIOUS_TASK_EVENT_COUNTS: "Previous Event Counts",
    TASK_SELF_LINK: "Task cannot link to itself",
    INCIDENT_SELF_LINK: "Incident cannot link to itself",
    ADD_WATCHERS: "Add Watchers",
    ADD_WATCHER: "Add Watcher",
    ADD_WATCHER_WITH_NAME: "Add '{0}' as Watcher",
    ADD_SELF_WATCHER: "Add Myself as Watcher",
    WATCHERS_CURRENTLY_ADDING: "Adding the following watchers",
    ADD_NEW_SEARCH: "Add New Search",
    SEARCH_QUERIES: "Search Queries",
    LINKED_INCIDENTS: "Linked Incidents",
    SUBTASKS: "Subtasks",
    PARENT_AND_SUBTASKS: "Parent & Subtasks",
    LINKED_TASKS: "Linked Tasks",
    ACTIVITY_LOG: "Activity Log",
    ADD_TICKET_DESCRIPTION_TEMPLATE: "Add Template",
    TICKET_DESCRIPTION_TEMPLATE_LOADING_ERROR: "Error loading ticket templates, please refresh page to use.",
    DRAG_AND_DROP: "Drag & Drop Attachments or <span class='link-text'>Browse Files</span>",
    DRAG_AND_DROP_CSV: "Drag & Drop .CSV or <span class='link-text'>Browse Files</span>",
    TICKET_UPLOAD_ERROR: "An error occurred while uploading a file. Please try again.",
    ADDED_EVENTS: "Added {0} Events",
    ADDED_ONE_EVENT: "Added 1 Event",
    ADD_A_NEW_NOTE: "Add a New Note",
    ADD_A_REPLY: "Add a Reply",
    ENTER_A_NOTE: "Enter a note...",
    BULK_CLOSE_TICKETS: "Bulk Close Tickets",
    BULK_CLOSE_TICKETS_EXPLANATION: "You are about to close {0} ticket(s).",
    BULK_CLOSE_TICKETS_SOME_FAILED: "Closing {0} ticket(s) succeeded, but another {1} ticket(s) failed. They may have been modified by another user.",
    BULK_CLOSE_TICKETS_ALL_SUCCEEDED: "Closing {0} ticket(s) succeeded.",
    BULK_CLOSE_TICKETS_TOOLTIP: "Close {0} tickets...",
    RESOLUTION_CLOSED_AS: "Closed as {0}",
    SINGLE_COLUMN_ADDED: "A column was added",
    MULTIPLE_COLUMNS_ADDED: "{0} columns were added",
    SINGLE_COLUMN_REMOVED: "A column was removed",
    MULTIPLE_COLUMNS_REMOVED: "{0} columns were removed",
    COLUMNS_CHANGED: "The columns for some events were changed",
    PARENT: "Parent",
    SET_PARENT_TASK: "Set Parent Task",
    DETACH_PARENT_TASK: "Remove parent relationship",
    ATTACH_PARENT_TASK: "Attach parent",
    ATTACH_CHILD_TASK: "Attach subtask",
    DETACH_CHILD_TASK: "Remove subtask relationship",
    TICKET_TEMPLATES_TAB: "Ticket Templates",
    GLOBAL_TICKET_INBOX_TAB: "Global Ticket Inbox",
    GLOBAL_INCIDENTS_TAB: "Global Incidents",
    GLOBAL_TASKS_TAB: "Global Tasks",
    GLOBAL_DRAFTS_TAB: "Global Drafts",
    GLOBAL_TICKET_INBOX_INVALID_ORGS: "Could not find orgs for ids {0}",
    TRAC_CONTACT: "CTS Ops Contact",
    SLA_DUE: "SLA DUE",
    SLA_DATE: "SLA Date",
    CLAIM_TICKET: "Claim Ticket",
    TICKET_ALREADY_CLAIMED_BY: "Ticket has already been claimed by {0}",
    TICKET_SUCCESSFULLY_CLAIMED: "Ticket successfully claimed",
    CLAIMING_TICKET_WITHOUT_SLA: "Cannot claim a ticket that does not have an SLA",
    FAILED_TO_LOAD_TICKET: "Failed to load ticket. Please try again.",
    IN_PROGRESS_STATE: "In Progress",
    PENDING_STATE: "Pending",
    NEW_AND_OPEN: "New and Open",
    NOTIFY_TRAC: "Notify and Reassign to CTS Ops Engineer",
    // ticket resolutions
    EXPECTED_ACTIVITY: "Authorized / expected activity",
    FALSE_POSITIVE: "False Positive",
    TRUE_POSITIVE: "True Positive",
    ESCALATED: "Escalated",
    DUPLICATE: "Duplicate / related to another incident",
    TRIGGER_FOR_REVIEW: "Trigger Subject for Review",
    OTHER: "Other",
    NON_MALICIOUS: "Suspected non-malicious activity",
    INVESTIGATED: "Investigated; no cause, IOC, or IOA identified",
    POTENTIAL_CONFIRMED: "Potential / confirmed IOC or IOA identified",
    TEMPORARY_OUTAGE: "Temporary outage",
    TICKET_SEARCH_EVENTS_ERROR: "There was an error fetching the events for this query. Please ensure the query is valid.",
    TICKET_SEARCH_EVENTS_PENDING: "We are still working on fetching the results of this query. Please check back later.",
    SHOW_SEARCH_EVENTS: "Show Events",
    SHOW_FIRST_100_SEARCH_EVENTS: "Show First 100 Events",
    SHOW_FIRST_100_SEARCH_EVENTS_OF_TOTAL: "Show First 100 Events (of approximately {0})",
    SEARCH_QUERY_DESCRIPTION: "When a task is generated, the first 100 events which match each query will be saved and attached to the task. Some queries may match more than 100 events. Excess events will not be attached.",
    HIDE_SEARCH_EVENTS: "Hide Events",
    DUE_DATE: "Due Date",
    TICKET_DUE_DATE: "Ticket Due Date",
    DUE: "Due",
    PREVIOUS_DUE_DATE: "Previous Due Date",
    NEW_DUE_DATE: "New Due Date",
    NO_DUE_DATE: "No Due Date",
    ON_TIME: "On Time",
    TASK_DUE_AFTER: "Task Will Be Due After",
    SET_DUE_DATE: "Add Due Date",
    SHOW_TICKET_SEARCH_CHART: "Show Chart of Query Results",
    HIDE_TICKET_SEARCH_CHART: "Hide Chart of Query Results",
    SHOW_TICKET_SEARCH_TABLE: "Show Table of Query Aggregations",
    HIDE_TICKET_SEARCH_TABLE: "Hide Table of Query Aggregations",
    EXTERNAL_TICKET_ID: "External Id",
    SET_EXTERNAL_TICKET_ID: "Add External Ticket Id",
    PREVIOUS_EXTERNAL_TICKET_ID: "Previous External Id",
    REMOVED_EXTERNAL_ID: "Deleted External Id",
    // ticket links
    DEPENDS_ON: "Depends on ",
    DEPENDED_ON_BY: "Is depended on by",
    RELATED_TO: "Relates to",
    CAUSES: "Causes",
    CAUSED_BY: "Is caused by",
    DUPLICATES: "Duplicates",
    DUPLICATED_BY: "Is duplicated by",
    BLOCKS: "Blocks",
    BLOCKED_BY: "Is blocked by",
    UNEXPECTED_SUBTASKS_REFRESH_ERROR: "A communication problem has occurred while querying the subtasks. Please try again later.",
    COMPLETED_SUBTASKS_TOOLTIP: "Completed subtasks",
    TICKET_VIEW_DEFAULT: "Last Modified",
    TICKET_VIEW_PRIORITY: "Priority",
    TICKET_VIEW_TASK_GROUPS: "Task Groups",
    SELECT_EXISTING_PARENT_TASK_MODAL_TITLE: "Select New Parent Task",
    SELECT_EXISTING_CHILD_TASK_MODAL_TITLE: "Select New Subtask",
    TASK_INVALID_PARENT: "Invalid parent provided",
    TASK_CYCLE_DETECTED: "Cannot change to this parent as a cycle would be generated.",
    TASK_TOO_MANY_LEVELS: "Cannot change to this parent as there would be more than {0} levels of tasks.",
    MERGE_TICKETS: "Merge Tickets",
    ESCALATE_TO_CLIENT: "Escalate to Client",
    ESCALATE_TICKET_TO_CLIENT: "Escalate {0} to Client",
    ADD_ESCALATION_DETAILS: "Add Escalation Details",
    ESCALATE_TICKET_TO_CLIENT_DETAILS: "Escalate {0} to Client Details",
    ESCALATE_TICKET_TO_CLIENT_DETAILS_DESCRIPTION: "The owner and watchers selected below will be your default" +
        " settings for all escalation to client tickets in GRID",
    TICKET_ESCALATION_ACTION_DESCRIPTION: "Both owners and watchers will change to the ones specified below",
    MANAGE_ESCALATION_DETAILS: "Manage Escalation Details",
    MANAGE_ESCALATE_TICKET_TO_CLIENT_DETAILS: "Manage Escalate {0} to Client Details",
    TICKET_NEW_OWNER: "{0} New Owner",
    CURRENT_OWNER: "Current Owner",
    TRAC_ENGINEER_USER: "CTS Ops Engineer",
    FAILED_TO_LOAD_TICKET_ESCALATION_DETAILS: "Failed to load ticket escalation details",
    FAILED_TO_SAVE_TICKET_ESCALATION_DETAILS: "Failed to save ticket escalation details",
    FAILED_TO_ESCALATE_TICKET: "Failed to escalate ticket",
    INVALID_OWNER_FOR_TICKET: "The owner for this ticket is invalid",
    INVALID_SLUG_ID_VERSION_PAIRS: "There was an issue with the slug ids and versions of the tickets",
    INVALID_TICKET_TIMEZONE: "The timezone {0} is invalid for this ticket",
    INVALID_TIMEZONE: "The timezone {0} is invalid",
    INVALID_NEW_STATE: "This kind of ticket cannot use the state: {0}",
    RESOLVED_STATE_MISSING_RESOLUTION: "A ticket cannot be resolved if there is no resolution",
    ONLY_INCIDENTS_CAN_USE_RESOLUTIONS: "Only incidents can have a resolution",

    // incident triage auto-transition
    INCIDENT_STATE_AUTOMATIC_TO_ANALYSIS: "Incident state has been automatically updated to \"Analysis\".",
    INCIDENT_STATE_AUTOMATIC_TO_TRIAGE: "Incident state has been reverted to \"Triage\".",

    ALL: "All",
    Triage: "Triage",
    Analysis: "Analysis",
    Remediation: "Remediation",
    Resolved: "Resolved",
    Closed: "Closed",
    NO_INCIDENTS: "No Incidents",
    OVERDUE: "Overdue",
    HAS_DUE_DATE: "Has Due Date",
    DUE_TODAY: "Due Today",
    DUE_LATER: "Due Later",
    NO_GROUP_TASKS: "No {0} tasks",
    VIEW_ALL: "View all",
    // ticket list page
    TICKETS_LIST_EMPTY: "No Tickets Found",
    SUBTASKS_LIST_EMPTY: "No Subtasks Found",
    FILTERED_BY_TASK_SCHEDULE: "Filtered by Task Schedule",
    FILTERED_BY_TRIGGER: "Filtered by Trigger",
    INVALID_TICKET_DUE_DATE: "The due date for this ticket is invalid",
    INVALID_TASK_SCHEDULE_DUE_DATE: "The due date for this task schedule is invalid",

    // tickets add/edit modal
    INCIDENT_ADD_MODAL_TITLE: "New Incident",
    INCIDENT_EDIT_MODAL_TITLE: "Edit Incident",
    INCIDENT_TITLE_PLACEHOLDER: "Type a Title",
    INCIDENT_DESCRIPTION_PLACEHOLDER: "Type Description",
    EDIT_INCIDENT: "Edit Incident",
    EDIT_TASK: "Edit Task",
    ADD_NOTE_MODAL_LINK: "Add Note",
    ADD_NEW_SUBTASK: "Add New Subtask",
    TASK_ADD_MODAL_TITLE: "New Task",
    SUBTASK_ADD_MODAL_TITLE: "New Subtask",
    TASK_EDIT_MODAL_TITLE: "Edit Task",
    SUMMARY_NOTE: "Summary",
    CLOSE_TASK: "Close Task",
    SUMMARY_NOTE_TEXT: "Use this section to summarize the task results along with any relevant concerns. The summary you provide will be included in all parent tasks and reports that reference this task.",
    EDIT_TASK_STATE: "Edit Task State",
    CLOSED_WITH_FINDINGS_ON: "Closed With Findings On {0}",
    WITH_FINDINGS: "(With Findings)",
    CLOSED_ON: "Closed On {0}",
    FINDINGS: "Findings",
    EXCEPTIONS: "Exceptions",
    TICKET_SUMMARY_NOTE_TOOLTIP: "After completing the task, use this section to describe the results, as well as any relevant concerns.",
    TASK_DESCRIPTION_TOOLTIP: "Use this section to describe the task objective and to provide any guidance on carrying out the task.",
    NO_SUMMARY_NOTE: "No Summary",
    NO_DESCRIPTION: "No Description",
    TICKET_SUMMARY_NOTE_PLACEHOLDER: "Type Summary",
    UPLOAD_CONFIRMATION_ONE: "You are about to upload the following file(s) to <b>{0}, {1} ({2}/{3})</b>:",
    UPLOAD_CONFIRMATION_TWO: "Please confirm the files you are uploading to the client and ticket number above. If correct, click the <b>Save</b> button below, or click <b>Cancel</b> to go back.",

    // classifiers
    VALUE: "Value",
    DROP_EVENT: "Drop event",
    DROP_EVENT_TOOLTIP: "If checked, drops the event rather than logging it. The events that match " +
        "this classifier will not be searchable and will not trigger alerts.",
    SKIP_THREAT_MATCH: "Exclude from ThreatMatch",
    SKIP_THREAT_MATCH_TOOLTIP: "If checked, this will mark all messages that match this classifier " +
        "to be ignored by ThreatMatch.",
    SKIP_ANOMALY_DETECTION: "Exclude from PatternScout",
    SKIP_ANOMALY_DETECTION_TOOLTIP: "If checked, this will mark all messages that match this classifier " +
        "to be ignored by PatternScout.",
    SKIP_ASSET_DETECTION: "Exclude from Asset detection",
    SKIP_ASSET_DETECTION_TOOLTIP: "If checked, this will mark all messages that match this classifier " +
        "to be ignored by asset detection.",
    ACCELERATED_ARCHIVE: "Accelerated Archive",
    ACCELERATED_ARCHIVE_TOOLTIP: "If checked, this will mark all messages that match this classifier to be " +
        "archived after 3 days, instead of their normal schedule.",
    DELETE_CLASSIFIER_HEADER: "Delete Classifier",
    CREATE_CLASSIFIER_HEADER: "Create Classifier",
    UPDATE_CLASSIFIER_HEADER: "Update Classifier",
    CONFIRM_DELETE_CLASSIFIER: "You are about to delete a Classifier.",
    CLASSIFIER_CONFIRM_DELETED: "The classifier has been deleted",
    CLASSIFIER_CONFIRM_CREATE: "Classifier created",
    CLASSIFIER_CONFIRM_UPDATE: "Classifier updated",
    SINGLE_WILDCARD_QUERIES_DISALLOWED: "Queries composed of only wildcard characters are not allowed since they match too many events",
    INVALID_METADATA_KEY: "Metadata keys may only contain letters, numbers, and underscores",
    CLASSIFIER_SEARCH: "Search name, query, key, or value",
    NO_CLASSIFIERS_MATCHING_FILTERS: "No classifiers matching filters",
    NO_PERMS_TOGGLE_LIBRARY_CLASSIFIER: "This classifier can only be paused/unpaused by DefenseStorm",

    // Custom Parser Classifiers
    CUSTOM_PARSER: "Custom Parser",
    CUSTOM_PARSERS: "Custom Parsers",
    CREATE_CUSTOM_PARSER: "Create Custom Parser",
    DATA_SOURCE: "Data Source",
    CUSTOM_PARSER_SELECT_DATA_SOURCE: "Select the data source for mapping the events' field names",
    FIELD_MAPPINGS: "Field Mappings",
    MAPPING_FROM_FIELD: "From Field",
    MAPPING_TO_FIELD: "To Field",
    ADD_NEW_MAPPING: "Add New Mapping",
    NUMBER_OF_MAPPINGS: "Number of Mappings",
    CUSTOM_PARSER_CONFIRM_DELETED: "The Custom Parser has been deleted",
    NO_CUSTOM_PARSERS: "There are no Custom Parsers yet",
    ERROR_DUPLICATE_CUSTOM_PARSER_APP_NAME: "There is already a custom parser for this application name.",
    ERROR_TOO_MANY_CUSTOM_PARSERS: "You have reached the maximum of {0} Custom Parsers",
    ERROR_MAX_CUSTOM_PARSER_MAPPINGS: "Can't save this Custom Parser as the total number of mappings in your " +
        "organization would be {0} which is more than the maximum of {1}.",

    // ticket log
    NEWER: "Newer",
    OLDER: "Older",
    HISTORY: "History",
    REASON: "Reason for editing incident",
    ADDED: "Added",
    CHANGED: "Changed",
    MODIFIED: "Modified",
    REMOVED: "Removed",
    MORE: "Show More",
    LESS: "Show Less",

    // assets
    ASSETS: "Assets",
    ASSET_MANAGEMENT: "Asset Management",
    ADD_ASSET: "Add Asset",
    NO_ASSETS: "No Assets",
    ASSET_DETAILS_HEADER: "Asset Details",
    ADD_ASSET_HEADER: "Add Tracked Asset",
    EDIT_ASSET_HEADER: "Edit Asset",
    SEARCH_ASSETS: "Search Assets",
    ASSET_TAG_IN_USE: "Asset ID already in use, please choose a unique ID",
    LAST_DATA_SENT_DESCRIPTION: "Last time we received data generated by this asset",
    ASSET_NAME: "Name",
    ASSET_NAME_PLACEHOLDER: "Enter a name",
    ASSET_TAG: "ID",
    ASSET_TAG_PLACEHOLDER: "Enter an ID",
    ASSET_OWNER: "Owner",
    ASSET_OWNER_PLACEHOLDER: "Enter the owner's name",
    ASSET_LABELS: "Tags",
    ASSET_LABEL_PLACEHOLDER: "Search For Tags",
    ASSET_IP_ADDRESSES: "IP Addresses",
    ASSET_IP_ADDRESSES_PLACEHOLDER: "Enter an IP Address",
    ASSET_MAC_ADDRESSES: "MAC Addresses",
    ASSET_MAC_ADDRESSES_PLACEHOLDER: "Enter a MAC Address",
    ASSET_IP_OR_MAC_IN_USE: "An IP or MAC Address is already assigned to another asset",
    ASSET_NO_IP_MAC_PAIRS: "No IPs or MACs",
    ASSET_HOSTNAME: "Hostname",
    ASSET_HOSTNAME_PLACEHOLDER: "Enter a Hostname",
    ASSET_IMPORTANCE: "Importance",
    ASSET_IMPORTANCE_HIGH: "high",
    ASSET_IMPORTANCE_MEDIUM: "medium",
    ASSET_IMPORTANCE_LOW: "low",
    ASSET_IMPORTANCE_NONE: "none",
    ASSET_DESCRIPTION: "Description",
    ASSET_DESCRIPTION_PLACEHOLDER: "Enter a Description",
    ASSET_DELETE_WARN: "Permanently delete this asset?",
    ASSET_DELETE_FINAL_WARN: "Warning: this operation cannot be undone",
    ASSET_LAST_SEEN: "Last Seen",
    ASSET_LAST_DATA_SENT: "Last Heartbeat",
    ASSET_NO_HEARTBEAT: "Heartbeat data never sent",
    ASSET_LAST_MODIFIED_ON: "Last Modified",
    ASSET_UNKNOWN_DATE: "Unknown",
    UPLOAD_ASSETS_CSV: "Upload Asset CSV",
    TRACKED: "Tracked",
    UNTRACKED: "Untracked",
    UNTRACKED_ASSET: "Untracked Asset",
    FILTER_BY_LABEL: "Filter by Tag",
    FILTER_BY_SW_NAMES: "Filter by Software",
    SOFTWARE_NAME: "Name",
    SOFTWARE_VERSION: "Version",
    SOFTWARE_LAST_SEEN: "Last Seen",
    ASSET_SETTINGS_TITLE: "Asset Settings",
    ASSET_NO_SOFTWARE: "No Software Found",
    ASSET_NO_HOSTNAME: "No Hostname",
    ASSET_INFO_TAB: "Info",
    ASSET_IP_TAB: "IP/MAC",
    ASSET_SOFTWARE_TAB: "Software",
    ASSET_DELETE_TAB: "Delete",
    RESULTS_PER_PAGE: "Results Per Page",
    ASSET_AUTO_DETECTION_RANGES: "Asset Auto-Detection",
    CIDR_RANGES: "CIDR Ranges",
    INCLUDED_RANGES: "Included CIDR Ranges",
    EXCLUDED_RANGES: "Excluded CIDR Ranges",
    ENTER_CIDR_RANGE: "Enter CIDR Range",
    INVALID_CIDR_ERROR: "Please enter a valid IPv4 CIDR, ex. \"192.168.0.0/16\" or a " +
        "valid IPv6 CIDR, ex. \"fe80::0204:61ff:fe9d:f156/128\"",
    DUPLICATE_CIDR_ERROR: "This CIDR range is already present in the list.",
    ON: "On",
    OFF: "Off",
    MERGE_INTO_ASSET: "Merge into existing asset",
    UNTRACKED_MERGE_TITLE: "Merge {0} into existing asset",
    TRACK_UNTRACKED_ASSET: "Track this asset",
    CREATE_INCIDENT_FOR_ASSET: "Create incident from this asset",
    MERGE: "Merge",
    SELECTED: "Selected",
    SAVE_FAILED: "Save failed",
    ASSET_EVENT_DESCRIPTION: "Search for events containing information about this asset",
    ASSET_LAST_SEEN_DESCRIPTION: "Last time we saw an event which references this asset",
    DELETE_ASSETS_TOOLTIP: "Delete {0} Assets...",
    DOWNLOAD_ASSETS_TOOLTIP: "Download {0} Assets",
    UPLOAD_ASSETS_TOOLTIP: "Upload Assets",
    ASSET_CONFIRM_DELETE_SELECTED_HEADER: "Delete Selected Assets",
    ASSET_DELETE_SELECTED_CONFIRM: "Delete all {0} selected assets? This cannot be undone.",
    ASSET_DELETE_ONE_SELECTED_CONFIRM: "Delete the selected asset? This cannot be undone.",
    ASSET_CONFIRM_DELETE_BY_SEARCH_HEADER: "Delete Matching Assets",
    ASSET_DELETE_BY_SEARCH_CONFIRM: "Delete the {0} assets that match your current search?",
    ASSET_DELETE_ONE_BY_SEARCH_CONFIRM: "Delete the asset that matches your current search?",
    THIS_CANNOT_BE_UNDONE: "This cannot be undone",
    DETECT_BARE_MAC_ADDRESSES: "Detect bare MAC addresses",
    AUTO_TRACK_WINDOWS_AGENT_ASSETS: "Automatically track Windows Agent assets",
    NOTIFY_ASSETS_DOWN: "Create Audit events for Assets labeled as High Importance and that have not sent data for 5 minutes",
    ASSET_CONFIG_SAVED: "Assets configuration updated successfully",
    ASSETS_CONFIRM_DELETED: "{0} assets were deleted",
    QUERY_ASSET_TAG_REPLACED: "<i>$asset_tag:{0}</i> gets replaced with the hostnames, IP addresses, and MAC addresses of the assets with tag \"<i>{0}</i>\" (<span><a class=\"link-text\" target=\"_blank\" href=\"/assets?labels={0}\">View Assets</a><span>).",
    VIEW_ASSET_UPDATE_EVENTS: "View update events",

    // asset dropdown
    PIVOT_ON_ASSET_DESCR: "Search within the last 5 minutes for...",
    PIVOT_ON_IPS: "this Asset's IP address",
    PIVOT_ON_MACS: "this Asset's MAC address",
    PIVOT_ON_HOSTNAME: "this Asset's hostname",
    PIVOT_ON_ASSET: "any of the above",
    ASSET_DETAILS: "Asset Details",

    // threat match
    BASE_THREAT_MATCH: "ThreatMatch",
    LAST_UPDATED: "Last Updated",
    INDICATORS: "Indicators",
    MATCHES: "Matches",
    UNIQUE_MATCHES: "Unique\nMatches",
    SUBSCRIBED: "Subscribed",
    NOT_SUBSCRIBED: "Not subscribed",
    SUBSCRIBE_TO_THREATSOURCE: "Subscribe to ThreatSource {0}",
    UNSUBSCRIBE_FROM_THREATSOURCE: "Unsubscribe from ThreatSource {0}",
    THREATMATCH_TITLE: "ThreatMatch",
    THREATMATCH_SOURCES_SUBTITLE: "Threat Intelligence Sources",
    THREATMATCH_CONFIG_BUTTON: "Configure ThreatMatch",
    THREATMATCH_CONFIG_TITLE: "Configure ThreatMatch",
    UNEXPECTED_THREATMATCH_LOADING_ERROR: "Failed to load ThreatMatch information.",
    THREATSOURCE_SUBSCRIPTION_ALERTS: "Send Alerts to the Alert Inbox",
    INDICATOR_COUNT: "# of Indicators",
    MATCH_COUNT: "# of Matches",
    UNIQUE_MATCH_COUNT: "# of Unique Matches",
    THREAT_SOURCE_TAGS: "Tags",
    THREAT_SOURCE_URL: "URL",
    FOUND_TIME: "Found Time",
    HOSTS: "Hosts",
    HOSTS_PLACEHOLDER: "console.defensestorm.com",
    SIGNATURES: "Signatures",
    SIGNATURES_PLACEHOLDER: "a56675d...f232bd",
    COUNTRIES: "Countries",
    LOCATIONS: "Locations",
    LOCATIONS_ZIP_PLACEHOLDER: "98104-1742",
    LOCATIONS_COUNTRY_PLACEHOLDER: "United States",
    EMAILS: "Emails",
    EMAILS_PLACEHOLDER: "danger@malware.com",
    HOST: "Host",
    USER_ID: "User ID",
    SIGNATURE: "Signature",
    LOCATION: "Location",
    EMAIL_COLUMN_HEADER: "Email",
    LAST_SEEN: "Last Seen",
    SIGNATURE_TYPE: "Type",
    NO_THREATS_FOUND: "No threats found",
    THREAT_SOURCE: "Threat Source",
    UPLOAD_NEW_THREATS: "Upload new threat indicators",
    UPLOAD_THREAT_INSTRUCTIONS: "Upload a file with one indicator per line. Only one indicator type (domain name, " +
        "IP address, signature) allowed per file. Maximum file size is 32MiB.",
    UPLOAD_POSTAL_CODE_INSTRUCTIONS: "Upload a file with one indicator per line, with the following columns: " +
        "PostalCode,CityState,Description \n" +
        "Only one indicator type (IP Address, Postal Code, Country) per file. Maximum file size is 32MiB.",
    UPLOAD_COUNTRY_INSTRUCTIONS: "Upload a file with one IP Address per line, with the following columns: " +
        "Country,Description \n" +
        "Only one indicator type (IP Address, Postal Code, Country) per file. Maximum file size is 32MiB.",
    UPLOAD_IP_ADDRESS_INSTRUCTIONS: "Upload a file with one indicator per line. Only one indicator type " +
        "(IP Address, Postal Code, Country) per file. Maximum file size is 32MiB.",
    TYPE_THREAT_INSTEAD: "Or, enter indicators here instead",
    THREAT_INDICATORS_UPLOAD_SUCCESS: "Successfully uploaded {0} indicators. Expect the indicator count to be updated within 24 hours.",
    THREAT_INDICATORS_MODIFY_SUCCESS: "Successfully modified threat indicators.",
    THREAT_FILE_UPLOAD_SUCCESS: "Successfully uploaded {0} file. Expect the indicator count to be updated within 24 hours.",
    THREAT_INDICATORS_UPLOAD_ERROR: "Failed to upload indicators.",
    THREAT_FILE_UPLOAD_ERROR: "Failed to upload file \"{0}\".",
    THREAT_FILE_UPLOADED: "successfully uploaded.",
    THREAT_VALID_DATE: "Indicator Date Range",
    THREAT_START_DATE: "Start Date",
    THREAT_START_DATE_SELECT: "Select a start date",
    THREAT_END_DATE: "End Date",
    THREAT_END_DATE_SELECT: "Select an end date",
    THREAT_TYPE: "Threat Indicator Type",
    THREAT_UPLOAD_GROUP_NAME: "Upload Group Name",
    IP_ADDRESS: "IP Address",
    IP_ADDRESSES: "IP Addresses",
    FI_ROUTING_NUMBERS: "FI (Routing Numbers)",
    FI_ROUTING_NUMBER: "Routing Number",
    IP_CIDR: "IP CIDR",
    DOMAIN: "Domain name",
    THREAT_ADD_SOURCE: "Create a new threat source",
    THREAT_SOURCE_NAME_PLACEHOLDER: "Type a name for this threat source",
    THREAT_SOURCE_DESCRIPTION_PLACEHOLDER: "Type a description",
    THREAT_SOURCE_URL_PLACEHOLDER: "Is there a URL associated with this source?",
    MALWARE: "Malware",
    BRUTE_FORCE: "Brute Force",
    ANONYMOUS: "Anonymous",
    THREAT_SOURCE_DELETE_INSTRUCTIONS: "Deleting this threat source will remove it from your account and it " +
        "will no longer generate matches.",
    WATCHLIST_DELETE_INSTRUCTIONS: "Deleting this Watchlist will remove it from your account " +
        "and from any associated triggers",
    COMMON_SOURCE: "Common Source",
    ADD_SOURCE_TO_EVERY_CUSTOMER: "Add this source to every customer",
    TEXT_THREAT_INSTRUCTIONS: "Type one indicator per line.",
    TEXT_WATCHLIST_INSTRUCTIONS: "Type one indicator per line, this will overwrite the existing Watchlist indicators",
    UPLOAD_THREAT_INSTEAD: "Or, upload a .CSV or .TXT file instead.",
    THREAT_SOURCE_CONFIRM_CREATED: "Threat Source successfully created",
    THREAT_SOURCE_RESTORE: "Threat Source restored",
    VALID_FROM: "Valid From",
    EXPIRES: "Expires",
    CITY_STATE: "City, State",
    POSTAL_CODE: "Postal Code",
    COUNTRY: "Country",
    INDICATOR_GROUP_NAME: "Upload Group Name",
    NO_INDICATORS_FOUND: "No Active Indicators Found",
    ADD_INDICATOR: "Add Indicator",
    ADD_INDICATOR_REASON: "Reason",
    ADD_INDICATOR_REASON_HIGH_RISK_IP: "High-risk IP",
    ADD_INDICATOR_REASON_VPN: "VPN",
    ADD_INDICATOR_REASON_AGGREGATOR: "Aggregator",
    ADD_INDICATOR_REASON_MONEY_MULE: "Money Mule",
    ADD_INDICATOR_REASON_ATO_ATTACKER: "ATO Attacker",
    ADD_INDICATOR_REASON_OAO_ATTACKER: "OAO Attacker",
    ADD_INDICATOR_REASON_HIGH_RISK_FI: "High-risk FI",
    ADD_INDICATOR_REASON_HIGH_RISK_COUNTRY: "High-risk Country",
    ADD_INDICATOR_REASON_OFAC: "OFAC",
    ADD_INDICATOR_REASON_OTHER: "Other",
    SEARCH_INDICATOR: "Search Indicator",
    ADD_WATCHLIST_FILTERING: "Add Watchlist Filtering",
    DELETE_WATCHLIST: "Delete Watchlist",
    DELETE_WATCHLIST_WARNING: "Deleting this watchlist will also delete all the indicators in it. Do you want to proceed?",
    NEW_WATCHLIST: "New Watchlist",
    EDIT_WATCHLIST: "Edit Watchlist",
    WATCHLIST: "Watchlist",
    WATCHLISTS: "Watchlists",
    NO_WATCHLISTS_FOUND: "No Watchlists found",
    WATCHLIST_INFO: "Watchlist Info",
    WATCHLIST_FILTERING: "Watchlist Filtering",
    WATCHLIST_EXCLUSION: "Watchlist Exclusion",
    WATCHLIST_UPLOAD_TOO_LARGE: "Watchlist upload too large",
    SEARCH_WATCHLIST_DETAILS: "Search Watchlist name or type",
    SEARCH_WATCHLIST: "Search Watchlist",
    WATCHLISTS_OWNED_BY_COMMUNITY: "Community",
    WATCHLISTS_OWNED_BY_ORG: "This organization",
    ADDED_BY: "Added by",
    WATCHLIST_ITEMS_ADDED_BY_COMMUNITY: "Community",
    WATCHLIST_ITEMS_ADDED_BY_ORG: "This organization",
    LIST: "List",
    FIELD: "Field",
    FILTER_LIST: "Filter List",
    FILTER_FIELD: "Filter Field",
    EXCLUDE_LIST: "Exclude List",
    EXCLUDE_FIELD: "Exclude Field",
    WATCHLIST_FILTERS_APPLIED: "Watchlists Applied",
    ADD_WATCHLIST: "Create a new Watchlist",
    COMMUNITY_WATCHLIST_OPTION: "Public (Cross organization)",
    ORG_WATCHLIST_OPTION: "Private (Only this organization)",
    COMMUNITY_WATCHLIST_USER: "Community User",
    WATCHLIST_NAME_PLACEHOLDER: "Type a name for this Watchlist",
    WATCHLIST_CONFIRM_CREATED: "Watchlist successfully created",
    WATCHLISTS_LOADING_ERROR: "Failed to load Watchlists",
    WATCHLIST_REQUIRED_VALUES: "Watchlists require a field and a list to be valid",
    ADD_FILTER_LIST: "Add Filter Watchlist",
    ADD_EXCLUDE_LIST: "Add Exclude Watchlist",
    WATCHLIST_INDICATOR_LIMIT: "Watchlist indicator limit exceeded ({0}/{1}), modify list and try again",
    WATCHLIST_INDICATOR_INVALID: "This is not a valid indicator for {0}",
    INVALID_INDICATOR: "Invalid indicator",
    UPLOAD_NEW_WATCHLIST_INDICATORS: "Upload new Watchlist indicators",
    UPLOAD_WATCHLIST_INSTRUCTIONS: "Upload a file with one indicator per line, " +
        "this will overwrite the existing Watchlist indicators. Maximum file size is 32MiB.",
    ADD_THREAT_GROUP_NOTE: "Add note",
    INVALID_THREAT_SIGNATURE_HEX: "The provided threat signature is not a valid hex value",
    INVALID_THREAT_SOURCE_DOMAIN: "Expected the threat source's domain to be either security or fraud",
    INVALID_THREAT_SOURCE_URL: "The url for this threat group is invalid",
    INVALID_THREAT_GROUP_UPLOAD_KEY: "Could not find the uploaded threat group",
    INVALID_THREAT_RATING: "Expected the rating to be one of {0}",
    IP_ADDRESS_THREAT_MISSING_START_OR_END_TIME: "An IP address type threat should include start or end times",
    THREAT_GROUP_UPLOAD_TOO_LARGE: "The threat group upload was too large and could not be processed",
    UNSUPPORTED_THREAT_TYPE: "The threat type {0} is not supported",
    // attachments / files
    ATTACHMENTS: "Attachments",
    DROP_HERE: "Drop files here to upload",
    UPLOADED_BY: "Uploaded by",
    UPLOADED_ON: "Uploaded on",
    UPLOADING_FILE: "Uploading {0}...",
    UPLOAD_INDICATORS: "Upload Indicators",
    UPLOAD_INDICATORS_INSTRUCTIONS: "Upload a CSV file with one indicator per line. Maximum file size is {0}.",
    ADD_INDICATORS: "Add Indicators",
    ADD_INDICATORS_TO_LIST: "Add Indicators to Existing List",
    REPLACE_INDICATORS_IN_LIST: "Replace Indicators in Existing List",
    REPLACE_INDICATORS: "Replace Indicators",
    TYPE_INDICATORS: "Type Indicators",
    TYPE_INDICATORS_INSTRUCTIONS: "Type an indicator and then press <Enter>.",
    DOWNLOAD_INDICATORS: "Download Indicators (CSV)",
    DOWNLOAD_INDICATORS_ERROR: "An error occurred while downloading indicators.",
    INVALID_INDICATORS_WONT_BE_ADDED: "Invalid Entries will not be added",
    INVALID_AND_DUPLICATE_INDICATORS_DESCRIPTION: "The following invalid indicators and duplicates were ignored during the update",
    INVALID_INDICATORS: "Invalid Indicators",
    UPDATE_INDICATORS_SUCCESS: "Successfully updated indicators",
    UPDATE_INDICATORS_RESULTS: "Update Indicators Results",
    ADDED_X_INDICATORS_SUCCESSFULLY: "You have successfully added {0} indicator(s)",
    UPDATE_INDICATORS_ALL_FAILED: "We were unable to add indicators",
    ADD_TO_WATCHLIST: "Add to Watchlist",
    FAILED_TO_ADD_TO_WATCHLIST: "Failed to add to Watchlist",
    INDICATOR_WAS_INVALID_MESSAGE: "{0} could not be added to {1} because it does not fit the Watchlist's type: {2}",
    INDICATOR_WAS_A_DUPLICATE_MESSAGE: "{0} could not be added to {1} because it already exists",

    NEAR_WATCHLIST_INDICATOR_LIMIT: "You are close to reaching this Watchlist indicator limit",
    NEAR_WATCHLIST_INDICATOR_LIMIT_DETAILS: "You have uploaded {0} of {1} maximum indicators",
    EXCEEDED_WATCHLIST_INDICATOR_LIMIT: "You have exceeded this Watchlist indicator limit ({0}) by {1}",
    EXCEEDED_WATCHLIST_INDICATOR_LIMIT_DIRECTIONS: "Please review your submission and try again",

    // searchable ip dropdown
    SEARCH_THIS_IP: "Search for this address...",
    SEARCH_IP_1M: "Within 1 minute",
    SEARCH_IP_5M: "Within 5 minutes",
    SEARCH_VIRUS_TOTAL: "On VirusTotal",
    SEARCH_ABUSE_IPDB: "On Abuse IPDB",
    SEARCH_IBM_XFORCE: "On IBM X-Force",
    SEARCH_ROBTEX: "On Robtex",
    SEARCH_DSHIELD: "On DShield",

    // hash dropdown
    SEARCH_THIS_HASH: "Search for this hash...",

    // threatmatch dropdown
    RATE: "Rate",
    RATED_ON: "Rated On: {0}",
    HIGH_RISK: "High Risk",
    MEDIUM_RISK: "Medium Risk",
    LOW_RISK: "Low Risk",
    CLEAR_RATING: "Clear Rating",
    HARMLESS_AND_EXCLUDE: "Harmless, exclude from ThreatMatch",
    FOR_ONE_MONTH: "for one month",
    FOREVER: "forever",
    CRITICAL: "Critical",
    HIGH: "High",
    MEDIUM: "Medium",
    LOW: "Low",
    HARMLESS: "Harmless",

    // url dropdown
    SEARCH_THIS_URL: "Search for this URL...",

    // forgot password
    FORGOT_PASSWORD: "Forgot your password?",
    FORGOT_EMAIL_SENT: "A password-reset email has been sent (if {0} is a user in our system).",
    LOGIN: "Sign in with your credentials.",
    PASSWORD_BEEN_RESET: "Your password has been reset. Please log in using your new password. You will be redirected in 3 seconds.",
    PASSWORD_RESET: "Password Reset",
    RESET_PASSWORD: "Reset Password",
    SELECT_NEW_PASSWORD: "Please select a new password:",
    PASSWORD_RESET_PARAMS_MISSING: "Invalid password reset link. If you followed a link from an email, please contact DefenseStorm Support.",
    WELCOME_LEGEND: "Activate your DefenseStorm Account",
    WELCOME_TEXT: "Enter your password below to activate your account.",
    SET_PASSWORD: "Activate",
    PASSWORD_FOUND_IN_BREACHED_LIST: "The password you entered has been found in a database of compromised passwords. We recommend you do not use this password again to authenticate any services or products.",
    PASSWORD_LENGTH_ERROR: "The password must be between 8 and 4096 characters in length.",
    PASSWORD_CHARACTER_ERROR: "The password must contain only printable characters.",
    PASSWORD_CONTEXT_ERROR: "The password contains text similar to commonly known data such as a username, a phone number, or your company name.",
    MANY_CONSECUTIVE_SINGLE_CHAR_ERROR: "The password contains the same character repeated many times.",
    MANY_SEQUENTIAL_CHARS_ERROR: "The password contains many sequential characters.",

    // saved queries
    REFERENCED_BY: "Referenced By",
    RECENT: "Recent",
    SAVED: "Saved",
    UPDATED: "Updated",
    SAVED_QUERY: "Saved Query",
    NONEXISTENT_SAVED_QUERY_NAME: "Nonexistent Saved Query",
    NEW_SAVED_QUERY: "New Saved Query",
    NEW_LIBRARY_QUERY: "New Library Query",
    EDIT_SAVED_QUERY: "Edit Saved Query",
    UPDATE_SAVED_QUERY: "Update saved query",
    ADD_QUERY: "Add Query",
    ADD_MODIFIER_TO_QUERY: "Add Modifier to Query",
    SAVE_AS_NEW: "Save as New",
    SAVE_AND_ADD: "Save & Add",
    SAVE_AS_NEW_AND_ADD: "Save as New & Add",
    SAVE_QUERY: "Save Query",
    QUERY_SAVED: "Query Saved",
    UPDATE_QUERY: "Update Query",
    QUERY_PROMOTED: "Query Promoted",
    GO_TO_SAVED_QUERIES: "Go to saved queries",
    ORIGINAL_QUERY: "Original Query",
    ORIGINAL_LIBRARY_QUERY: "Original Library Query",
    QUERY_MODIFIER: "Query Modifier",
    NEW_QUERY: "New Query",
    LIBRARY_QUERY: "Library Query",
    LIBRARY_QUERIES: "Library Queries",
    MODIFIED_QUERY: "Modified Query",
    MODIFIED_QUERIES: "Modified Queries",
    SAVED_QUERIES: "Saved Queries",
    SAVED_QUERY_NAME_PLACEHOLDER: "A name for your saved query",
    SAVED_QUERY_DESCRIPTION_PLACEHOLDER: "A description for your query",
    SAVED_QUERY_STRING_PLACEHOLDER: "Type query appendix",
    SAVED_QUERY_CREATED: "Created Saved Query",
    SAVED_QUERY_UPDATED: "Updated Saved Query",
    SAVED_QUERY_UNDELETED: "Restored deleted Saved Query",
    SAVED_QUERY_DELETE_SUCCEEDED_ONE: "Deleting 1 saved query succeeded.",
    SAVED_QUERY_DELETE_SUCCEEDED: "Deleting {0} saved queries succeeded.",
    SAVED_QUERY_DELETE_SOME_FAILED: "Deleting {0} saved queries succeeded, but another {1} were skipped. They may have been deleted by another user.",
    SAVED_SEARCH: "Saved Query",
    SAVED_QUERY_NAME: "Name",
    SAVED_QUERY_INVALID_ERROR: 'The query "{0}" is invalid. Please enter a valid query.',
    SAVED_QUERY_LOADING_ERROR: "Could not load Saved Queries",
    SAVED_QUERY_DELETE_TOOLTIP_ONE: "Delete Saved Query...",
    SAVED_QUERY_DELETE_TOOLTIP: "Delete {0} Saved Queries...",
    SAVED_QUERY_DELETE_ONE: "Delete Saved Query",
    SAVED_QUERY_DELETE: "Delete Saved Queries",
    SAVED_QUERY_DELETE_CONFIRMATION_ONE_WITH_DEPS: "The following items reference this saved query and must be deleted before deleting this saved query.",
    SAVED_QUERY_DELETE_CONFIRMATION_MANY_WITH_DEPS: "The following items reference these saved queries and must be deleted before deleting these saved queries.",
    SAVED_QUERY_DELETE_CONFIRMATION_ONE: "Are you sure you want to delete this saved query?",
    SAVED_QUERY_DELETE_CONFIRMATION_MANY: "Are you sure you want to delete the {0} selected saved queries?",
    SAVED_QUERY_IN_ON_DEMAND_REPORT: "This saved query cannot be modified because it is used in an On-demand Report",
    QUERY_USED_IN: "This query is being used in",
    SAVED_QUERY_DUPLICATE_NAME_MESSAGE: "There is already a saved query with this name",
    SAVED_QUERY_DUPLICATE_QUERY_MESSAGE: "There is already a saved query with this query string: {0}",
    QUERY_DUPLICATE_NAME_MESSAGE: "There is already a query with this name",
    QUERY_DUPLICATE_QUERY_MESSAGE: "There is already a query with this query string: {0}",
    SAVED_QUERY_NAME_AND_QUERY_REQUIRED: "Enter Name and Query (or aggregation) to save and add",
    SAVED_QUERY_ADDITIONAL_FIELDS_WARNING: "Caution: additional_fields are frequently changed with parser improvements. We recommend using other fields in triggers for best reliability",
    UPDATE_NAME_AND_QUERY: "Update Name and query (or aggregation) to save as new query.",
    CLONE_QUERY: "Clone Query",
    CLONE_SAVED_QUERY: "Clone Saved Query",
    EDIT_QUERY: "Edit Query",
    BACK_TO_SAVED_QUERY: "Back to Saved Query",
    REVERT_TO_ORIGINAL_LIBRARY_QUERY: "Revert to Original Library Query",
    MODIFIER_MUST_BE_ADDED: "A Modifier must be added in order to save and add this query",
    CANNOT_MODIFY_AGGREGATION: "Aggregation can’t be modified because query is being used by other objects",
    SAVED_QUERY_SORT_BY: "Sort by",
    INVALID_SORT_ORDER: "Sort order must be ascending or descending",
    INVALID_SORT_FIELD: "Cannot sort on {0}",
    REFRESH_REFERENCES: "Refresh References",
    REVERT_NAME: "Revert name",
    REVERT_DESCRIPTION: "Revert description",
    REVERT_QUERY: "Revert query",
    REVERT_PRIMARY_FIELD: "Revert primary field",
    MUST_EDIT_NAME_AND_QUERY_TO_SAVE_AS_NEW: "You must edit both the name and the query (or the aggregation) in order to save as a new query",
    USE_EXISTING_QUERY: "Use the existing saved query",
    QUERY_INFO: "Query Info",
    LIBRARY_QUERIES_ARE_READ_ONLY: "Library queries are read only",
    LIBRARY_QUERY_DUPLICATE_QUERY_MESSAGE: "There is already a Library Query with this same query string and aggregation: {0}",
    CLONE_LIBRARY_QUERY: "Clone Library Query",
    VIEW_LIBRARY_QUERY: "View Library Query",
    EDIT_LIBRARY_QUERY: "Edit Library Query",
    EDIT_MODIFIED_QUERY: "Edit Modified Query",
    REVERT_TO_LIBRARY_QUERY: "Revert To Library Query",
    BACK_TO_MODIFIED_QUERY: "Back to Modified Query",
    CLONED_QUERY: "Cloned Query",
    BACK_TO_LIBRARY_QUERY: "Back to Library Query",
    EDIT_QUERY_TO_USE: "Edit both name and query (or aggregation) to clone this query",
    DEFAULT_MODIFIED_QUERY: "{0} - Modified",
    NO_QUERIES_FOUND: "No queries found",
    USED: "Used",
    IN_USE: "In Use",
    NOT_YET_USED: "Not yet used",
    TAG_LIBRARY_QUERY_AS_BETA: "Tag Library Query as BETA",
    UNNAMED_SAVED_QUERY: "Unnamed Saved Query",
    SEARCH_SAVED_QUERIES: "Search Saved Queries",
    SEARCH_LIBRARY_QUERIES: "Search Library Queries",
    SEARCH_FRAMEWORKS: "Search Frameworks",
    PROMOTE: "Promote",
    PROMOTE_TO_QUERY_LIBRARY: "Promote to Query Library",
    MODIFIED_QUERY_MISSING_LIBRARY_QUERY: "This modified query is missing a library query",
    MODIFIED_QUERY_USING_INVALID_SAVED_QUERY: "This modified query's saved query could not be found",
    MODIFIED_QUERY_USING_INVALID_LIBRARY_QUERY: "This modified query's library query could not be found",

    NOT_FOUND: "Not Found",
    RESOURCE_NOT_FOUND_MESSAGE: "The resource '{0}' was not found. Please check the url and try again.",
    NOT_AUTHORIZED: "Not Authorized",
    NOT_AUTHORIZED_MESSAGE: "You are not allowed to access '{0}'. Contact your Administrator if you need heightened permissions.",
    CARDINALITY_MAX_LIMIT_BREACHED: "Cardinality of aggregate field must be lower than {0}",

    // policy
    POLICY_MANAGEMENT: "Policy Management",
    CREATE_YOUR_OWN: "Create Your Own",
    INACTIVE_POLICIES: " ( {0} ) Inactive Policies",
    UNNAMED_LEGEND_LABEL: "Unnamed",

    CONTROLS: "Controls",
    CONTROL: "Control",
    ADD_CONTROL: "Add Control",
    EDIT_CONTROL: "Edit Control",
    CONTROL_DESCRIPTION: "Controls are distilled from your policy. They are what you want to track and measure with DefenseStorm.",
    POLICY_METADATA: "Policy Metadata",

    ALERTABLE_CONTROL_NO_TRIGGERS: "Alertable controls should have at least one trigger associated with them",
    CONTROL_WITH_OPEN_INCIDENTS: "There are open incidents for this control",

    ADD_POLICY: "Add Policy",
    ADD_GRID_COMPLIANCE: "Add GRID Compliance",
    EDIT_POLICY: "Edit Policy",
    ADD_POLICY_DESCRIPTION: "Add your organization's policies to DefenseStorm so that you can track your cybersecurity controls.",
    FRAMEWORK_OR_SELF_ASSESSMENT: "Framework or Self Assessment",

    START_DATE: "Start Date",
    FINALIZED: "Finalized",
    FINALIZE: "Finalize",
    EVALUATION_PERIOD_UPDATED: "Evaluation Period successfully updated",
    EVALUATION_PERIOD_FINALIZED: "Evaluation Period successfully finalized",
    FINALIZE_CURRENT_EVALUATION_PERIOD: "Finalize current Evaluation Period",
    SELECTED_EVALUATION_PERIOD: "Selected Evaluation Period",

    SLUG: "Slug",
    POLICY_DELETED_UNDO: "This policy has been deleted. Click here to restore.",
    ALL_POLICIES: "Policies",
    QUERY_ENABLED: "Query",
    NEW_POLICY_NAME: "New Policy Name",
    NEW_POLICY_DESCRIPTION: "Describe what this policy covers in a few short sentences",
    PREVIEW: "Preview",
    OWNER: "Owner",
    ORG: "Org",
    ORGANIZATION: "Organization",
    LAST_MODIFIED: "Last Modified",
    PUBLISHED_ON: "Published On",
    SELECT_A_USER: "Select a user...",
    VIEW_PROFILE: "View Profile",

    INACTIVITY_TIMEOUT: "Inactivity Timeout",
    INACTIVITY_TIMEOUT_WARNING: "You are currently inactive. You'll be logged out in {0} minutes.",
    INACTIVITY_TIMEOUT_ON: "On",
    INACTIVITY_TIMEOUT_OFF: "Off",
    DISABLE_INACTIVITY_TIMEOUT: "Disable Inactivity Timeout",   // TODO: remove with old navigation
    ENABLE_INACTIVITY_TIMEOUT: "Enable Inactivity Timeout",     // TODO: remove with old navigation

    SHORTCUT_KEYS: "Shortcut Keys",
    SHORTCUT_KEYS_ON: "On",
    SHORTCUT_KEYS_OFF: "Off",

    READONLY_MODE: "Readonly Mode",
    READONLY_MODE_ON: "On",
    READONLY_MODE_OFF: "Off",

    ALERT_INBOX_SHORTCUT_KEYS_DESCRIPTION: "Shortcut keys activated.  <strong>A</strong> = Ack | <strong>E</strong> = Escalate | <strong>D</strong> = Dismiss | <strong>F</strong> = False Positive",

    OPEN: "Open",
    CLOSED: "Closed",

    POLICY: "Policy",
    COMPLIANCE: "Compliance",
    GOVERNANCE: "Governance",
    MATURITY_EVALUATION: "Maturity Evaluation",
    MATURITY_LEVEL: "Maturity Level",
    RISK_PROFILE: "Risk Profile",
    RISK_PROFILE_SCORE: "Risk Profile Score",
    OVERVIEW: "Overview",
    RESULTS: "Results",
    FFIEC_CAT: "FFIEC CAT",
    FFIEC_DOMAIN: "Domain {0}",
    FACTOR_SHORT: "Factor {0}",
    FACTOR_LONG: "Assessment Factor {0}: {1}",
    OVERALL_DOMAIN_LEVEL: "Overall Domain Level",
    MARK_ALL_LINE_ITEMS_AS: "Mark all controls as",
    INVALID_LINE_ITEMS: "The controls don't exist",
    INVALID_LINE_ITEM_BEING_ANSWERED: "The control being answered doesn't exist",
    INVALID_ANSWER_FOR_MANDATORY_LINE_ITEM: "A mandatory control cannot be answered as N/A",
    SELECT_FROM_LIST: "Select",
    LINE_ITEM_YES_OPTION: "YES",
    LINE_ITEM_NO_OPTION: "NO",
    LINE_ITEM_NA_OPTION: "N/A",
    LINE_ITEM_YESC_OPTION: "YES (C)",
    EVIDENCE_ADD_EDIT: "Add/Edit Evidence",
    EVIDENCE_SHOW: "Show Evidence",
    EVIDENCE_HIDE: "Hide Evidence",
    ADD_COMMENT_EVIDENCE: "Add Comment Evidence",
    RISK_CATEGORY: "Category",
    RISK_CATEGORY_SHORT: "Category {0}",
    RISK_CATEGORY_LONG: "Category {0}: {1}",
    RISK_PROFILE_RESULTS: "Risk Profile Results",
    RISK_PROFILE_RESULTS_INTRO: "The Results Page represents a scoring sheet for all the values recorded. " +
        "The Inherent Risk Profile to the left will provide you with an overall risk " +
        "rating which you can compare to your program maturity.",
    RISK_PROFILE_RESULTS_TOTAL_RISK: "Total Risk",
    RISK_PROFILE_RESULTS_COUNTS: "Risk Profile Counts",
    RISK_PROFILE_RESULTS_SCORE: "Risk Profile Score",
    RISK_PROFILE_RESULTS_COUNTS_EXPLANATION: "The Risk Profile Counts' table shows how many questions have been " +
        "answered with each type of answer.",
    RISK_PROFILE_RESULTS_SCORE_EXPLANATION: "The Risk Profile Score's table gives a different weight to each type " +
        "of answer depending on their importance. The overall score depends on how many " +
        "questions are answered with each type of answer multiplied by the weight of " +
        "that type of answer.",
    RISK_PROFILE_ANSWER_LOWER_IS_BETTER: "A lower total risk score is a better score.",
    RISK_PROFILE_ANSWER_LEAST: "Least",
    RISK_PROFILE_ANSWER_MINIMAL: "Minimal",
    RISK_PROFILE_ANSWER_MODERATE: "Moderate",
    RISK_PROFILE_ANSWER_SIGNIFICANT: "Significant",
    RISK_PROFILE_ANSWER_MOST: "Most",
    EDIT_COMMENTS: "Edit Comments",
    MAT_LEVEL_BASELINE: "Baseline",
    MAT_LEVEL_EVOLVING: "Evolving",
    MAT_LEVEL_INTERMEDIATE: "Intermediate",
    MAT_LEVEL_ADVANCED: "Advanced",
    MAT_LEVEL_INNOVATIVE: "Innovative",
    FFIEC_CAT_REPORTS: "Generate Reports",
    FFIEC_REPORT_NAME: "FFIEC CAT Report",
    FFIEC_REPORT_EXPLANATION: "Create an FFIEC CAT Report, which contains a summary report of the maturity level " +
        "and risk profile of your organization.",
    EVIDENCE_REPORT_NAME: "FFIEC CAT Evidence Report",
    EVIDENCE_REPORT_EXPLANATION: "Create a detailed report of selected FFIEC CAT controls.",
    EVIDENCE_REPORT_INCLUDE_ONLY_THESE: "Include only these elements in the report:",
    EVIDENCE_REPORT_OTHER_ELEMENTS: "Other Elements:",
    EVIDENCE_REPORT_INCLUDE_ONE_OF_THESE: "Include at least one of these elements in the report",
    EVIDENCE_REPORT_DATE_TEXT: "Evidence Period",
    EVIDENCE_REPORT_PARAMETERS: "Parameters",
    ACET_SPREADSHEET_REPORT_NAME: "FFIEC CAT/ACET Spreadsheet Report",
    ACET_SPREADSHEET_REPORT_EXPLANATION: "Generate an ACET Spreadsheet Report, which contains an overview of the maturity " +
        "level and risk profile of your organization",
    UNEXPECTED_REPORT_GENERATION_ERROR: "Couldn't schedule the report generation at this time. Please try again later.",
    EVIDENCE_REPORT_INCLUDE_ANSWERS: "Answers",
    EVIDENCE_REPORT_INCLUDE_COMMENTS: "Comments",
    EVIDENCE_REPORT_INCLUDE_INCIDENTS: "Incidents",
    EVIDENCE_REPORT_INCLUDE_TASKS: "Tasks",
    EVIDENCE_REPORT_INCLUDE_TASK_SCHEDULES: "Task Schedules",
    EVIDENCE_REPORT_INCLUDE_TRIGGERS: "Triggers",
    EVIDENCE_REPORT_INCLUDE_ATTACHMENTS: "Attachments",
    EVIDENCE_REPORT_INCLUDE_EVENTS: "Events",
    EVIDENCE_REPORT_CHOOSE_AT_LEAST_ONE: "Select at least one",
    CANT_LOAD_REQUIRED_DATA: "Problem loading required information. Please try again later.",
    FFIEC_CAT_LINE_ITEMS_SELECTION: "Select FFIEC CAT Controls",
    TICKET_REPORT_NAME: "Ticket Report",
    TICKET_REPORT_PARAMETERS: "Parameters",
    TICKET_REPORT_INCLUDE_ONLY_THESE: "Include only these elements in the report:",
    TICKET_REPORT_INCLUDE_ATTACHMENTS: "Include attachments",
    TICKET_REPORT_INCLUDE_EVENTS: "Include events",
    TICKET_REPORT_INCLUDE_TREND_REPORT: "Include trend report",
    INCLUDE_SUBTASKS: "Include subtasks",
    TREND_REPORT: "Trend Report",
    TICKET_REPORT_ERROR: "An error occurred requesting a ticket report. Please try again later or contact support.",
    REPORTS_PREVIOUSLY_GENERATED: "Reports previously generated",
    ELEMENTS_INCLUDED: "Elements included",
    DOWNLOAD_REPORT: "Download Report",
    VIEW_REPORT_PROGRESS: "View Report Progress",
    REQUESTED: "Requested",
    REPORT_VERSION: "Report Version",
    REVERT_REPORT_VERSION_MESSAGE: "Your document will revert to the version from {0}",
    REPORT_VERSION_CREATED: "Report version has been created",
    REPORT_VERSION_RESTORED: "Report version has been restored",
    TOP_10_VALUES_PLOTTED_IN_CHART: "Top 10 values are plotted in chart",
    UNRECOGNIZED_ANSWER_TYPE: "The answer {0} was not recognized",
    INVALID_PERSON: "The person {0} is invalid",

    EVALUATION_PERIODS: "Evaluation Periods",
    FFIEC_CAT_EVALUATION_PERIODS: "FFIEC CAT Evaluation Periods",
    END_DATE_2: "End Date", //Todo: Rename END_DATE and have this become END_DATE
    ADD_12_MONTHS: "+12 months",
    ADD_18_MONTHS: "+18 months",
    H: "H",
    M: "M",
    IN_PROGRESS_2: "In Progress", //Todo: Rename IN_PROGRESS and have this become IN_PROGRESS
    PERIOD: "Period",
    NOW: "Now",
    ARCHIVED: "Archived",
    EVALUATION_PERIOD_COVERS: "This CAT evaluation period covers {0} days, from {1} to {2}.",
    ONCE_YOU_FINALIZE_YOU_WILL_BE_UNABLE_TO: "Once you finalize, you will be unable to",
    ONCE_YOU_FINALIZE_YOU_WILL_BE_UNABLE_TO_ONE: "Edit this Evaluation Period's name or description",
    ONCE_YOU_FINALIZE_YOU_WILL_BE_UNABLE_TO_TWO: "Edit this Evaluation Period's evidence, comments, and answers",
    ONCE_YOU_FINALIZE_YOU_WILL_BE_UNABLE_TO_THREE: "Edit this Evaluation Period's end date",
    FINALIZING_THE_EXISTING_EVALUATION_PERIOD_IS_PERMANENT_MESSAGE: "Finalizing the existing evaluation period is permanent. " +
        "Please check this box if you've reviewed all the CAT answers and are ready to finalize the current assessment.",
    COPY_ANSWERS: "Copy risk profile and maturity evaluation answers over to new evaluation period",
    COPY_COMMENTS: "Copy risk profile and maturity evaluation comments over to new evaluation period",

    // Frameworks
    FRAMEWORK: "Framework",
    ALL_FRAMEWORKS: "All Frameworks",
    POLICY_CHANGE_FRAMEWORK: "Select Framework",
    POLICY_CURRENT_FRAMEWORK: "Current Framework",
    POLICY_OTHER_FRAMEWORKS: "Other Frameworks",
    POLICY_FRAMEWORK_MAIN_SECTIONS: "Contents",
    POLICY_FRAMEWORK_NO_CONTENT: "This framework doesn't have any content",
    POLICY_FRAMEWORK_NONE_ACTIVE: `There is no current framework.<br/>Select one from the list below using the "Select Framework" button.`,
    CREATE_FRAMEWORK: "Create new framework",
    EDIT_FRAMEWORK: "Edit framework",
    FRAMEWORK_SECTION: "Section",
    BASIC_FRAMEWORK_INFO: "Basic Framework Info",
    ADD_NEW_FRAMEWORK_TOP_LEVEL_SECTION: "Add new top-level section",
    ADD_NEW_FRAMEWORK_SUB_SECTION: "Add new sub-section",
    CONFIRM_DELETE_FRAMEWORK_TITLE: "Delete Framework",
    CONFIRM_DELETE_FRAMEWORK: `Are you sure you want to delete the Framework "{0}"?`,
    FRAMEWORK_ADD_CONTROL: "Add new control",
    LINE_ITEM_ACHIEVED: "Achieved",
    LINE_ITEM_YES: "Yes",
    LINE_ITEM_PARTIAL: "Partial",
    LINE_ITEM_NO: "No",
    LINE_ITEM_NOT_APPLICABLE: "N/A",
    TICKET_POLICY_ADDED_SUCCESSFULLY: "Successfully added control to ticket",
    TRIGGER_POLICY_ADDED_SUCCESSFULLY: "Successfully added control to trigger",
    TICKET_POLICY_ADD_ERROR: "An error occurred when trying to add the task to this control",
    NO_COMMENTS_CONTROL: "There are no comments for this control",
    PUBLICLY_AVAILABLE: "Should this framework be visible to customers?",
    FRAMEWORK_IS_NOT_PUBLIC: "This framework is not publicly available",
    FRAMEWORK_REPORT_SELECT_CONTROLS: "Select the controls you want included in the report",
    EXPORT_FRAMEWORK: "Export Framework",
    CAPTURE_CONTROL_ASSESSMENT: "Capture Control Assessment",
    FRAMEWORK_SPREADSHEET_REPORT_NAME: "Framework Spreadsheet Report",
    FRAMEWORK_CONTROL_ASSESSMENT_REPORT_NAME: "Framework Control Assessment Report",
    FRAMEWORK_LINE_ITEMS_SELECTION: "Select Framework Controls",
    FRAMEWORK_SKUS: "SKU required for using this framework",
    FRAMEWORK_CIS_CONTROLS_SKU: "CIS Controls",
    FRAMEWORK_CYBER_PROFILE_SKU: "The Cyber Profile",
    FRAMEWORK_NIST_CYBER_SECURITY_SKU: "NIST Cyber Security",
    EVIDENCE_VIEW_MORE: "View More",
    FRAMEWORK_REPORT_SELECT_DATES_EXPLANATION: "Include evidence created within these dates:",
    FRAMEWORK_NO_ATTACHMENTS: "This comment has no file attachments",
    ADD_COMMENTS_EVIDENCE: "Add Comments Evidence",
    EDIT_COMMENTS_EVIDENCE: "Edit Comments Evidence",
    ADD_TASK_EVIDENCE: "Add Task Evidence",
    ADD_INCIDENT_EVIDENCE: "Add Incident Evidence",
    ADD_TASK_SCHEDULE_EVIDENCE: "Add Task Schedule Evidence",
    ADD_TRIGGER_EVIDENCE: "Add Trigger Evidence",
    ADD_RISK_EVIDENCE: "Add Risk Evidence",
    ADD_CONTROL_EVIDENCE: "Add Control Evidence",
    FRAMEWORK_NOT_PURCHASED: "Framework not yet purchased",
    FRAMEWORK_IS_ACTIVE: "This Framework is active",
    "FRAMEWORK_IS_NOT_ACTIVE": "This Framework is not active",
    "SET_FRAMEWORK_ACTIVE_STATUS_ERROR": "An error occurred while setting the active status for this framework",
    "GET_FRAMEWORK_ACTIVE_STATUS_ERROR": "An error occurred while getting the active status for this framework",
    "GET_FRAMEWORKS_ACTIVE_STATUSES_ERROR": "An error occurred while getting the active statuses for frameworks",

    // Reports
    REPORT: "Report",
    REPORTS: "Reports",
    REPORT_DOWNLOADS: "Report Downloads",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    GENERATED_ON: "Generated On",
    TEMPLATE: "Template",
    DEFAULT_TEMPLATE: "On-demand Report",
    BEGIN_DATE: "Begin",
    END_DATE: "End",
    REPORT_EMAIL: "Report Viewer",
    NO_REPORTS_GENERATED: "No Reports Generated Yet",
    VIEW_REPORT: "View Report",
    TIME_ZONE: "Time Zone",
    SELECT_TIME_ZONE: "Select a Time Zone",
    PENDING: "Requested",
    WAITING_FOR_DATA: "Waiting for Data",
    IN_PROGRESS: "Building Report",
    COMPLETE: "Completed",
    ERROR: "Failed",
    REPORT_TITLE: "Report Title",
    CHARTS: "Charts",
    TEMPLATES: "Templates",
    CHART_INFORMATION: "Chart Information",
    CHART_TYPE: "Chart Type",
    VERTICAL_BAR: "Vertical Bar",
    STACKED_VERTICAL_BAR: "Stacked Bar",
    HORIZONTAL_BAR: "Horizontal Bar",
    DONUT: "Doughnut",
    LINE: "Line",
    MAP: "Map",
    TABLE: "Table",
    EVENTS_TABLE: "Events Table",
    TICKET_PIPELINE: "Ticket Pipeline",
    NUMERIC_BADGE: "Numeric Badge",
    TASK_SCHEDULE: "Task Schedule",
    X_AXIS_TITLE: "X-Axis Title",
    Y_AXIS_TITLE: "Y-Axis Title",
    COLUMN_1_TITLE: "Column 1 Title",
    COLUMN_2_TITLE: "Column 2 Title",
    NUM_EVENTS: "Number of Events (1-100)",
    CUSTOM_COLUMNS_MIN_ERROR: "Select at least 1 Column in addition to timestamp.",
    X_SCALE: "X-Axis Scale",
    Y_SCALE: "Y-Axis Scale",
    LINEAR: "Linear",
    LOG: "Log",
    REPORT_DESCRIPTION: "Report Description",
    MOVE_UP: "Move Up",
    MOVE_DOWN: "Move Down",
    HEADING: "Heading",
    PARAGRAPH: "Paragraph",
    ADD_FRAGMENT_HERE: "Add a new fragment here",
    EDIT_FRAGMENT: "Edit Fragment",
    HEADING_TYPE: "Heading Type",
    PAGE_BREAK: "Page Break",
    INCLUDE_PAGE_BREAK: "Include Page Break",
    INCLUDE_TEXT_BOX: "Include Text Box for Notes",
    HEADING1: "Heading1",
    HEADING2: "Heading2",
    DATA_BEGIN: "Data Begin",
    DATA_END: "Data End",
    CUSTOM_REPORT_CREATE: "Create Custom Report",
    CUSTOM_START_DATE: "Start Date",
    CUSTOM_END_DATE: "End Date",
    GENERATE_REPORT: "Generate new report from this template",
    VIEW_TEMPLATE_REPORTS: "View reports generated from this template",
    CREATE_NEW_TEMPLATE: "Create New Template",
    NO_TEMPLATES_FOUND: "No templates found",
    NO_CHARTS_CREATED: "No charts have been created yet",
    ALL_TEMPLATES: "All Templates",
    COLLECTION_SCHEDULED: "Data collection scheduled",
    COLLECTION_ONGOING: "Data will continue being collected each hour until up to date",
    SEARCH_REPORT_TEMPLATES: "Search Report Templates",
    SEARCH_CHART_TEMPLATES: "Search Chart Templates",
    CHART: "Chart",
    YES: "Yes",
    NO: "No",
    SERIES_AGGREGATE_FIELD_ERROR: "There was an issue validating the aggregate field for this series",
    SERIES_INVALID: "A chart may have exactly one aggregate query, or up to {0} non-aggregate queries.",
    SERIES_EMPTY: "Add at least one query to this chart",
    SERIES_INVALID_MAP: "A map chart must have exactly one non-aggregate query",
    SERIES_INVALID_BADGE: "A numeric badge must have exactly one non-aggregate query",
    SERIES_INVALID_TICKET_PIPELINE: "A ticket pipeline doesn't need any queries",
    SERIES_INVALID_EVENTS_TABLE: "An events table must have exactly one non-aggregate query",
    SERIES_INVALID_EVENTS_TABLE_NUM_COLUMNS: "An events table must have at least a custom column selected",
    SERIES_INVALID_TASK_SCHEDULE_CHART: "This chart needs a task schedule",
    CUSTOM_COLUMNS_INVALID: "You must include at least 1 additional column in addition to timestamp.",
    ADD_NEW_QUERY: "Add New Query",
    SELECT_FROM_SAVED_QUERIES: "Select from Saved & Library Queries",
    SELECT_FROM_LIBRARY_QUERIES: "Select from Library Queries",
    SELECT_FROM_SAVED_QUERIES_EXCLUSIVE: "Select from Saved Queries",
    QUERY_NUM: "Query {0}",
    DATE_RANGE: "Date Range",
    DEFAULT_TEMPLATE_OPTION: "On-demand Report",
    FROM_PERMALINK: "From Permalink",
    NEEDS_ONE_CHART: "A template must contain at least one chart to be valid",
    UNSAVED_CHANGES: "This template has unsaved changes",
    SCHEDULE_TEMPLATE_REPORT: "Schedule for template: {0}",
    EDIT_SCHEDULE_TEMPLATE_REPORT: "Edit schedule",
    CREATE_SCHEDULE_TEMPLATE_REPORT: "Create schedule",
    CREATE_REPORT_FROM_TEMPLATE_EVERY: "Create report from template every",
    NOTIFY_REPORTS_VIA_EMAIL: "To send users an email with a link to the finished report type an email address",
    GENERATE_TICKET_REPORT: "Generate Ticket Report",
    CYBERSECURITY_REPORT: "Cybersecurity Report",
    INTRODUCTION: "Introduction",
    ON_DEMAND_REPORT_INTRO: "This is an executive report of your organization's cybersecurity as seen through the DefenseStorm Console. It provides an overview of your entire network with detailed summaries of how all the parts of your cybersecurity program worked together to protect your network. You should use this information to review your cybersecurity program and make any adjustments necessary to keep your network secure. Regularly reviewing these reports will allow you to detect network anomalies more easily by comparing metric charts between reporting periods.",
    ON_DEMAND_REPORT_ACTIVITY_SUMMARY_TITLE: "Activity Summary",
    ON_DEMAND_REPORT_ACTIVITY_SUMMARY_PARAGRAPH: "Your network through DefenseStorm processed <strong>{0}</strong> events this period, generating <strong>{1}</strong> alerts. Out of the <strong>{1}</strong> alerts, <strong>{2}</strong> were escalated to incidents and <strong>{3}</strong> were resolved.",
    ON_DEMAND_REPORT_CONSOLE_SUMMARY_TITLE: "Console Summary",
    ON_DEMAND_REPORT_CONSOLE_SUMMARY_PARAGRAPH: "An overall network activity comparison allows you to monitor the progress of your cybersecurity level.",
    ON_DEMAND_REPORT_EVENT_SUMMARY_TITLE: "Event Summary",
    ON_DEMAND_REPORT_EVENT_SUMMARY_PARAGRAPH: "DefenseStorm ingested <strong>{0}</strong> events this period. The total number of events per day gives you a high-level view of your network activity.",
    NUMBER_OF_EVENTS: "Number of Events",
    EVENT_HISTORY: "Event History",
    DATA_FROM_TIME_ZONE: "Data from Time Zone: {0}",
    ON_DEMAND_REPORT_INCIDENTS_SUMMARY_PARAGRAPH: "There were <strong>{0}</strong> incidents opened this period, <strong>{1}</strong> of which were resolved during this period. Each ticket took an average of <strong>{2}</strong> hours to resolve.",
    ON_DEMAND_REPORT_INCIDENTS_BY_SOURCE_TITLE: "Incidents by Source",
    ON_DEMAND_REPORT_INCIDENTS_BY_SOURCE_PARAGRAPH: "ThreatMatch, PatternScout, and Triggers are the main sources for incident detection. This chart shows how many incidents were identified through each of these sources, as well as how many were created manually.",
    ON_DEMAND_REPORT_INCIDENT_BREAKDOWN_BY_SEVERITY_TITLE: "Incident Breakdown by Severity",
    ON_DEMAND_REPORT_INCIDENT_BREAKDOWN_BY_SEVERITY_PARAGRAPH: "This graph shows what percentage of your incidents were critical, high, medium, or low severity. Of all your incidents, <strong>{0}</strong>, or <strong>{1}</strong>, were considered high priority.",
    ON_DEMAND_REPORT_AVERAGE_TIME_TO_RESOLUTION_TITLE: "Average Time to Resolution",
    ON_DEMAND_REPORT_AVERAGE_TIME_TO_RESOLUTION_PARAGRAPH: "The following chart shows the average amount of time it took to resolve incidents this period.",
    ON_DEMAND_REPORT_INCIDENT_BREAKDOWN_BY_RESOLUTION_TITLE: "Incident Breakdown by Resolution",
    ON_DEMAND_REPORT_INCIDENT_BREAKDOWN_BY_RESOLUTION_PARAGRAPH: "The following chart shows the resolution status for each closed incident.",
    ON_DEMAND_REPORT_TASKS_SUMMARY_PARAGRAPH: "There were <strong>{0}</strong> tasks opened this period, <strong>{1}</strong> of which were resolved during this period. Each ticket took an average of <strong>{2}</strong> hours to resolve.",
    ON_DEMAND_REPORT_TASKS_BY_SOURCE_TITLE: "Tasks by Source",
    ON_DEMAND_REPORT_TASKS_BY_SOURCE_PARAGRAPH: "This chart shows how many tasks were created manually or automatically from a Task Schedule.",
    ON_DEMAND_REPORT_TASKS_CLOSED_ON_TIME_TITLE: "Tasks Closed On Time",
    ON_DEMAND_REPORT_TASKS_CLOSED_ON_TIME_PARAGRAPH: "This chart shows how many tasks were closed On Time (before the due date), Overdue (after the due date) or had no Due Date.",
    ON_DEMAND_REPORT_ALERTS_SUMMARY_PARAGRAPH: "There were <strong>{0}</strong> total alerts on your DefenseStorm platform this period. Of the total alerts, <strong>{1}</strong> were active, and <strong>{2}</strong> were handled.",
    ON_DEMAND_REPORT_ALERTS_BREAKDOWN_BY_TYPE_TITLE: "Alerts Breakdown by Type",
    ON_DEMAND_REPORT_ALERTS_BREAKDOWN_BY_TYPE_PARAGRAPH: "Based on the total number of alerts, the following chart shows how many alerts were closed as dismissed, escalated, or deemed false positives during this time period.",
    ON_DEMAND_REPORT_ALERTS_MOST_FIRED_TITLE: "Most Fired Alerts",
    ON_DEMAND_REPORT_ALERTS_MOST_FIRED_PARAGRAPH: "The chart below shows a summary of the most fired alerts this period. Displaying the most fired alerts allows you to better triage your network and protect your DefenseStorm Console.",
    ON_DEMAND_REPORT_ALERTS_MOST_FIRED_NONE_PARAGRAPH: "No alert data available for given date range.",
    ON_DEMAND_REPORT_ASSETS_PARAGRAPH: "There are a total of <strong>{0}</strong> assets on your DefenseStorm platform. <strong>{1}</strong> of those assets are tracked, while the remaining <strong>{2}</strong> are untracked. To learn more about tracked and untracked assets, see the <a class='link-text' href='https://support.defensestorm.com/solution/articles/5000693862-using-assets'>Using Assets</a> KB.",
    ON_DEMAND_REPORT_ASSET_OVERVIEW_TITLE: "Asset Overview",
    ON_DEMAND_REPORT_ASSET_OVERVIEW_PARAGRAPH: "The chart below shows the number of tracked and untracked assets on your network.",
    ON_DEMAND_REPORT_ASSET_OVERVIEW_NONE_PARAGRAPH: "No asset data available for given date range.",
    ON_DEMAND_REPORT_ASSET_BREAKDOWN_TITLE: "Ground Asset Breakdown",
    ON_DEMAND_REPORT_ASSET_BREAKDOWN_PARAGRAPH: "The chart below shows your ground assets, displaying the number of assets within each tagged category.",
    ON_DEMAND_REPORT_ASSET_BREAKDOWN_NONE_PARAGRAPH: "No tagged asset data available for given date range.  Categorize your tracked assets with one or more tags to generate this graph.",
    ON_DEMAND_REPORT_ELEVATED_USER_ACTIVITY_TITLE: "Elevated User Activity",
    ON_DEMAND_REPORT_ELEVATED_USER_ACTIVITY_PARAGRAPH: "This section provides details about the domain users as well as users with elevated privileges.",
    ON_DEMAND_REPORT_USER_ACTIVITY_LEVEL_TITLE: "User Activity Level",
    ON_DEMAND_REPORT_USER_ACTIVITY_LEVEL_PARAGRAPH: "This chart displays the elevated users with the highest Active Directory activity levels this period.",
    ON_DEMAND_REPORT_USER_ACTIVITY_LEVEL_NONE_PARAGRAPH: "No user activity data available for given date range.",
    ON_DEMAND_REPORT_WINDOWS_OBJECTS_ACTIVITY_TITLE: "Windows Objects Activity",
    ON_DEMAND_REPORT_WINDOWS_OBJECTS_ACTIVITY_PARAGRAPH: "This chart displays elevated users with the highest Windows Objects activity levels this period.",
    ON_DEMAND_REPORT_WINDOWS_OBJECTS_ACTIVITY_NONE_PARAGRAPH: "No windows object activity data available for given date range.",
    ON_DEMAND_REPORT_NETWORK_ASSET_SYSTEM_LOGONS_TITLE: "Network Asset System Logons",
    ON_DEMAND_REPORT_NETWORK_ASSET_SYSTEM_LOGONS_PARAGRAPH: "This chart shows the network assets most accessed by elevated users, and how many times they were accessed during this period.",
    ON_DEMAND_REPORT_NETWORK_ASSET_SYSTEM_LOGONS_NONE_PARAGRAPH: "No system logon data available for given date range.",
    CANNOT_MODIFY_ON_DEMAND_REPORT_SERIES: "Cannot modify a series that is used in an On-Demand Report",
    NUMBER_OF_INCIDENTS: "Number of Incidents",
    NUMBER_OF_TASKS: "Number of Tasks",
    NUMBER_OF_TASKS_CLOSED: "Number of Tasks Closed",
    NUMBER_OF_ALERTS: "Number of Alerts",
    NUMBER_OF_ASSETS: "Number of Assets",
    MANUAL: "Manual",
    TIME_IN_HOURS: "Time (in hours)",
    TIME_IN_DAYS: "Time (in days)",
    ALERT_INBOX_CATEGORY: "Alert Inbox Category",
    ASSET_TAG_LABEL: "Asset Tag",
    TRANSACTIONS: "Transactions",
    REPORT_NOTE_PLACEHOLDER: "[Text box provided for analysis]",
    REPORT_DATA_SAVED: "Report data saved",
    PREPARING_REPORT_FOR_DOWNLOAD: "Preparing report for download...",
    EDIT_NOTE: "Edit Note",
    RESTORE_VERSION: "Restore version",

    // Charts
    NO_CHARTS_FOUND: "No charts found",
    DELETE_CHARTS_TOOLTIP: "Delete {0} chart(s)...",
    CHART_DELETE_SOME_FAILED: "Deleting {0} chart(s) succeeded, but another {1} chart(s) failed. They may have been modified by another user.",
    CHART_DELETE_ALL_SUCCEEDED: "Deleting {0} chart(s) succeeded.",
    CHART_RESTORED: "Chart successfully restored.",
    CHART_DELETE: "Delete Charts",
    CHART_DELETE_CONFIRMATION_ONE_WITH_DEPS: "Deleting the selected chart will also delete it from the following items:",
    CHART_DELETE_CONFIRMATION_MANY_WITH_DEPS: "Deleting the {0} selected charts will also delete them from the following items:",
    CHART_DELETE_CONFIRMATION_ONE: "Are you sure you want to delete this chart?",
    CHART_DELETE_CONFIRMATION_MANY: "Are you sure you want to delete the {0} selected charts?",
    ACTION_CANT_BE_UNDONE: "This action can't be undone",

    // Placeholder values for form inputs
    PLACEHOLDER_INPUT_EMAIL_ADDRESS: "Type an Email Address",
    PLACEHOLDER_SELECT_CONTROL: "Select a Control",
    PLACEHOLDER_SELECT_POLICY: "Select a Policy",
    PLACEHOLDER_ADD_POLICY: "Add a Policy",
    PLACEHOLDER_ADD_CONTROL: "Add a Control",
    PLACEHOLDER_SELECT_TICKET_OWNER: "Select an Owner",

    // Form headings
    FORM_HEADING_ACTIONS: "Actions",
    FORM_HEADING_ASSIGN_SEVERITY: "Assign a Severity",
    FORM_HEADING_ASSOCIATE_POLICY_CONTROL: "Associate a Policy / Control",
    FORM_HEADING_ASSOCIATE_CONTROLS: "Associate Controls",
    FORM_HEADING_CREATE_INCIDENT: "Create an Incident",
    FORM_HEADING_CREATE_INCIDENT_OWNED_BY: "Create an Incident, owned by",
    FORM_HEADING_EMAIL_RECIPIENTS: "Notify via Email to the following addresses",
    FORM_HEADING_NOTES: "Notes",

    //ASSET UPLOAD
    UPLOAD_LINE_NUMBER: "Line Number",
    UPLOAD_ASSET_NAME: "Asset Name",
    UPLOAD_ERROR: "Error",
    UPLOAD_FILE_NAME: "Upload <strong>{0}</strong>?",
    UPLOAD_COMPLETE: "Completed upload of <strong>{0}</strong>",
    UPLOADING_ASSET: "Uploading...",
    SUCCESSFUL_UPLOADS: "Successfully uploaded <strong>{0}</strong> assets",
    LINE_ERRORS_NOTIFICATION: "Unable to upload invalid assets",
    TOO_MANY_LINE_ERRORS_NOTIFICATION: "There are over 100 line errors present, we recommend you download the csv of the errors below",
    DOWNLOAD_ASSET_ERRORS: "Download csv of line errors",
    DOWNLOAD_EXAMPLE_CSV: "Download example csv",
    CLIENT_UPLOAD_ERROR: "Error with uploaded file. Please confirm that {0} is not malformed and try again.",
    SERVER_UPLOAD_ERROR: "Error occurred while uploading {0}. Please try again, or contact customer support.",
    MISSING_ASSET_HEADER_FIELDS: "Error with uploaded file's headers. Expected {0} to include the following header fields: {1}",

    //Dashboards & Templates
    DASHBOARD: "Dashboard",
    DASHBOARD_TEMPLATE: "Dashboard Template",
    DASHBOARD_SEARCH: "Search Dashboards",
    DASHBOARD_CHARTS: "Charts",
    DASHBOARD_LOADING: "Loading Dashboards",
    DASHBOARD_FROM_LIBRARY: "Dashboard from Library",
    NO_DASHBOARDS: "No Dashboards",
    DEFAULT_DASHBOARD: "Dashboards",
    REVEAL_DASHBOARDS: "Directory",
    DASHBOARD_NAME_PLACEHOLDER: "Dashboard Name",
    DASHBOARD_DESCRIPTION_PLACEHOLDER: "Dashboard Description",
    CHARTS_PER_ROW: "Charts per row",
    SELECT_CHART: "Please select the chart you would like to to add to your dashboard: ",
    NO_CHARTS: "No charts attached to dashboard",
    DELETE_DASHBOARDS_TOOLTIP: "Delete {0} dashboard(s)...",
    DASHBOARD_DELETE_SOME_FAILED: "Deleting {0} dashboard(s) succeeded, but another {1} dashboard(s) failed. They may have been modified by another user.",
    DASHBOARD_DELETE_ALL_SUCCEEDED: "Deleting {0} dashboard(s) succeeded.",
    DASHBOARD_SINGLE_DELETE_RESTORE: "Click here to restore just-deleted dashboard",
    DASHBOARD_RESTORE_SUCCEEDED: "Dashboard restored successfully.",
    CREATE_LIBRARY_DASHBOARD: "Add to Library",
    COPY_SELECTED_LIBRARY_DASHBOARDS: "Copy to All",
    COPY_SELECTED_LIBRARY_DASHBOARDS_ALL_SUCCESS: "Copied {0} library dashboard(s) to all customer accounts.",
    COPY_SELECTED_LIBRARY_DASHBOARDS_SOME_SKIPPED: "Copied {0} library dashboard(s) to all customer accounts ({1} skipped).",
    COPY_LIBRARY_DASHBOARD_TO_ALL: "Copy Library Dashboard",
    LIBRARY_DASHBOARD_COPY_SKIPPED: "Ignoring {0} library dashboards because they have been already been copied in the past",
    LIBRARY_DASHBOARD_OBJECTS_TO_COPY: "The following items will be copied to every account:",
    DASHBOARD_TYPE: "Dashboard Type",
    NORMAL_DASHBOARD: "Normal",
    LIBRARY_DASHBOARD: "Library",
    DASHBOARD_ERROR: "An error occurred. Please refresh the page or contact support.",
    ADD_CHART_MODAL_TITLE: "Chart selection",
    ADD_CHART_PLACEHOLDER: "Chart name",
    SELECTED_CHART: "Selected Chart: <strong>{0}</strong>",
    ADD_CHART: "Add Chart",
    ADD_CHART_DASHBOARD: "Add Chart to Dashboard",
    CHART_LAYOUT: "Chart layout",
    ABOVE_MAX_CHARTS_ALLOWED: "Dashboards cannot have more than {0} charts attached",
    NO_DASHBOARDS_MADE: "No dashboards have been created yet.",
    CREATE_DASHBOARD_LINK: "Click here to create a new dashboard",
    NO_DASHBOARDS_FOUND: "No dashboards found",
    NO_DATA_POINTS: "No data points",
    NO_DATA_POINTS_FOR_THIS_TIME_PERIOD: "No data points for this time period",
    SHOWING_THIRTY_DAYS: "(Showing 30 days)",
    GO_TO_SCHEDULE: "View Schedule",
    CHART_TEMPLATES_COUNT: "Chart Templates ({0})",
    DASHBOARD_TEMPLATES_COUNT: "Dashboard Templates ({0})",
    REVEAL_DASHBOARDS_COUNT: "Dashboards ({0})",
    REPORT_TEMPLATES_COUNT: "Report Templates ({0})",
    TASK_SCHEDULES_COUNT: "Task Schedules ({0})",
    LIBRARY_TASK_SCHEDULE: "Library Task Schedule",
    LIBRARY_TASK_SCHEDULES_COUNT: "Library Task Schedules ({0})",
    LIBRARY_TASK_SCHEDULES: "Library Task Schedules",
    TASK_SCHEDULE_QUERIES_COUNT: "Task Schedule Queries ({0})",
    LIBRARY_TASK_SCHEDULE_QUERIES_COUNT: "Library Task Schedule Queries ({0})",
    TRIGGERS_COUNT: "Triggers ({0})",
    LIBRARY_TRIGGERS_COUNT: "Library Triggers ({0})",
    LIBRARY_TRIGGERS: "Library Triggers",
    QUERIES_COUNT: "Modified Queries ({0})",
    DASHBOARD_SAVED: "Dashboard saved",
    CREATE_DASHBOARD: "New Dashboard",
    CREATE_WORKSPACE: "New Workspace",
    ADD_NEW_WORKSPACE: "Add New Workspace",
    EDIT_WORKSPACE: "Edit Workspace",
    WORKSPACE: "Workspace",
    REVEAL_WORKSPACES: "Workspaces",
    ALL_WORKSPACES: "All Workspaces",
    WORKSPACE_WHO_CAN_SEE: "Which users should be able to see this workspace?",
    WORKSPACE_SHARE_WITH_NOBODY: "Only the owner can see this workspace",
    WORKSPACE_SHARE_WITH_ALL: "Allow all other users to see this workspace",
    WORKSPACE_SHARE_WITH_USERS: "Only share this workspace with specific people",
    WORKSPACE_EDITABLE_BY_OTHERS: "Allow other users to make changes to this workspace",
    WORKSPACE_COPY_BY_DEFAULT: "Copy workspace by default to new customers",
    WORKSPACE_PREMIUM_SKU_ONLY: "Should this workspace be limited to Premium customers?",
    DELETE_DASHBOARD_WORKSPACE: "Delete Workspace",
    CONFIRM_DELETE_DASHBOARD_WORKSPACE: `Are you sure you want to delete the Workspace "{0}"?`,
    DASHBOARD_WORKSPACE_CONFIRM_DELETED: "The workspace has been deleted",
    COPY_TO_EXISTING_WORKSPACE: "Copy to an existing workspace",
    SELECT_WORKSPACE: "Select a workspace",
    COPY_TO_NEW_WORKSPACE: "Copy to a new workspace",
    DASHBOARD_COPY_SUCCESS: "Dashboards copied successfully",
    LIBRARY_DASHBOARD_COPY_SUCCESS: "Library dashboards copied successfully",
    PUBLIC_WORKSPACE: "Public",
    EDITABLE_BY_OTHERS_WORKSPACE: "Editor",
    NOT_EDITABLE_BY_OTHERS_WORKSPACE: "Viewer",
    WORKSPACE_COPIED_BY_DEFAULT: "Default",
    PREMIUM_ONLY_WORKSPACE: "Premium Only",
    PUBLIC_WORKSPACE_EXPLANATION: "Public",
    PRIVATE_WORKSPACE_EXPLANATION: "Personal",
    SHARED_WITH_PEOPLE_WORKSPACE_EXPLANATION: "Shared with me",
    SHARED_WITH_PEOPLE_OWNER_WORKSPACE_EXPLANATION: "Shared",
    WORKSPACE_COPIED_BY_DEFAULT_EXPLANATION: "This workspace is copied by default to new customers",
    TASK_DATA_SOURCE_EXPLANATION: "Task related data sources",
    TASKS_LIST_DATA_SOURCE_EXPLANATION: "All task details within a data range (limited to 1000)",
    TASKS_LIST_DATA_SOURCE: "Detailed list of tasks",
    TASK_COUNTS_DATA_SOURCE_EXPLANATION: "Task counts within a date range",
    TASK_COUNTS_DATA_SOURCE: "Task Counts",
    SAVED_QUERY_DATA_SOURCE_EXPLANATION: "Events matched by a Saved Query",
    LIBRARY_QUERY_DATA_SOURCE_EXPLANATION: "Events matched by a Library Query",
    PREFETCHED_SAVED_QUERIES: "Saved Query Counts",
    PREFETCHED_LIBRARY_QUERIES: "Library Query Counts",
    REALTIME_SAVED_QUERIES: "Saved Query",
    REALTIME_LIBRARY_QUERIES: "Library Query",
    PREFETCHED_SAVED_QUERY_DATA_SOURCE_EXPLANATION: "Counts of events matched by a Saved Query",
    PREFETCHED_LIBRARY_QUERY_DATA_SOURCE_EXPLANATION: "Counts of events matched by a Library Query",
    REALTIME_SAVED_QUERY_DATA_SOURCE_EXPLANATION: "All events matched by a Saved Query (limited to 1000 and 45 days of data)",
    REALTIME_LIBRARY_QUERY_DATA_SOURCE_EXPLANATION: "All events matched by a Library Query (limited to 1000 and 45 days of data)",
    FRAMEWORKS_DATA_SOURCE_EXPLANATION: "Answers provided for the controls of a Compliance Framework",
    INCIDENT_DATA_SOURCE_EXPLANATION: "Incident related data sources",
    INCIDENT_LIST_DATA_SOURCE_EXPLANATION: "All incident details within a date range (limited to 1000)",
    INCIDENT_LIST_DATA_SOURCE: "Detailed list of incidents",
    INCIDENT_COUNTS_DATA_SOURCE_EXPLANATION: "Incident counts within a data range",
    INCIDENT_COUNTS_DATA_SOURCE: "Incident Counts",
    ACTIVITY_FUNNEL_DATA_SOURCE: "Activity Summary",
    ACTIVITY_FUNNEL_DATA_SOURCE_EXPLANATION: "Summary of events ingested, alerts created and tickets opened and closed within a date range",
    FFIEC_CAT_DATA_SOURCE: "FFIEC CAT",
    FFIEC_CAT_DATA_SOURCE_EXPLANATION: "FFIEC CAT related data sources",
    CAT_SUMMARY_DATA_SOURCE: "FFIEC CAT Summary",
    CAT_SUMMARY_DATA_SOURCE_EXPLANATION: "Summary of FFIEC CAT answers for the most recent evaluation period",
    CAT_RISK_PROFILE_DATA_SOURCE: "FFIEC CAT Risk Profile",
    CAT_RISK_PROFILE_DATA_SOURCE_EXPLANATION: "Answers to the FFIEC CAT Risk Profile for the most recent evaluation period",
    TASK_SCHEDULE_LIST_DATA_SOURCE_EXPLANATION: "All task schedules",
    SECURITY_ALERTS: "Security Alerts",
    FRAUD_ALERTS: "Fraud Alerts",
    SECURITY_ALERT_COUNTS: "Security Alert Counts",
    FRAUD_ALERT_COUNTS: "Fraud Alerts Counts",
    ALERTS_DATA_SOURCE_EXPLANATION: "Alert related data sources",
    SECURITY_ALERT_LIST_DATA_SOURCE_EXPLANATION: "Alerts for Security triggers within a date range (limited to 1000)",
    FRAUD_ALERT_LIST_DATA_SOURCE_EXPLANATION: "Alerts for Fraud triggers within a date range (limited to 1000)",
    SECURITY_ALERT_COUNTS_DATA_SOURCE_EXPLANATION: "Alert Counts for Security triggers",
    FRAUD_ALERT_COUNTS_DATA_SOURCE_EXPLANATION: "Alert Counts for Fraud triggers",
    TASK_EVENTS_DATA_SOURCE: "Task Events",
    TASK_EVENTS_DATA_SOURCE_EXPLANATION: "Events for tasks generated by a task schedule",
    DASHBOARD_SELECT_DATA_SOURCE: "Select a Data Source",
    NO_DATA_SOURCES_AVAILABLE: "No data sources available",
    NEW_DASHBOARDS_BETA: "Try the New Dashboards",
    NEW_DASHBOARDS_FEEDBACK: "Give us feedback",
    DATASOURCE_REQUIRES_SKU_REPORTING_PREMIUM: "Premium Data Source",
    NO_DASHBOARDS_IN_WORKSPACE: "There are no dashboards to show in this workspace",
    NO_PERMISSIONS_FOR_DASHBOARD: "Either this dashboard doesn't exist, or you don't have permissions to access it.",
    NO_PERMISSIONS_FOR_WORKSPACE: "Either this workspace doesn't exist, or you don't have permissions to access it.",
    OPERATIONAL_RISKS_DATA_SOURCE: "Risks",
    RISK_CONTROLS_DATA_SOURCE: "Controls",
    RISK_INVENTORIES_DATA_SOURCE: "Inventory",
    RISK_ASSESSMENTS_DATA_SOURCE: "Assessments",
    RISK_DATA_SOURCE_EXPLANATION: "Risk related data sources",
    OPERATIONAL_RISKS_DATA_SOURCE_EXPLANATION: "All risk details",
    RISK_CONTROLS_DATA_SOURCE_EXPLANATION: "All risk control details",
    RISK_ASSESSMENTS_DATA_SOURCE_EXPLANATION: "All risk assessment details",
    RISK_INVENTORIES_DATA_SOURCE_EXPLANATION: "All risk inventory details",
    LOADING_DASHBOARDS_ERROR: "An error occurred while loading dashboards.",
    INCLUDE_DASHBOARD_FILE: "Include Dashboard",
    INCLUDE_DASHBOARD_FILE_EXPLANATION: "The selected dashboard will be included in every task generated by this Task Schedule.",
    ADD_DASHBOARD: "Add Dashboard",
    MANAGE_DASHBOARD: "Manage Dashboard",
    NO_DASHBOARD_SELECTED: "No Dashboard Selected",
    TASK_SCHEDULE_DASHBOARD_RANGE_EXPLANATION: "The dashboard should be generated with the following timeframe:",
    TASK_SCHEDULE_DASHBOARD_RANGE: "Timeframe",
    TASK_SCHEDULE_DASHBOARD_RETRIEVE_ERROR: "Couldn't retrieve current dashboard linked to this task schedule",
    TASK_SCHEDULE_DASHBOARD_UPDATE_ERROR: "Couldn't update dashboard linked to this task schedule",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_SINCE_LAST_RUN: "Since last run",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_LAST_24_HOURS: "Last 24 hours",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_LAST_7_DAYS: "Last 7 days",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_LAST_30_DAYS: "Last 30 days",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_LAST_90_DAYS: "Last 90 days",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_PREVIOUS_MONTH: "Previous month",
    TASK_SCHEDULE_DASHBOARD_INTERVAL_PREVIOUS_QUARTER: "Previous quarter",
    USER_ME: "me",
    EDITOR: "Editor",
    VIEWER: "Viewer",
    COPY_TO_WORKSPACE: "Copy to workspace",
    DEFENSESTORM_LIBRARY: "DefenseStorm Library",

    CYBER_RISK_READINESS: "Cyber Risk Readiness",
    CYBER_RISK_READINESS_DASHBOARD: "Cyber Risk Readiness Dashboard",
    CRR_BANNER_SUBTITLE: "Maintaining Control Over Evolving Risk",
    CRR_FRAUD_CATEGORY: "Fraud Prevention",
    CRR_THREAT_SURVEILLANCE_CATEGORY: "Threat Surveillance",
    CRR_GOVERNANCE_CATEGORY: "Governance Program",
    CRR_RISK_CATEGORY: "Risk Assessment",
    CRR_READINESS_ACTIONS: "Readiness Actions",
    CRR_CRITICAL_DATA_SOURCES_PRESENT: "Critical Data Sources present",
    CRR_CRITICAL_DATA_SOURCES_MISSING: "{0} Critical Data Sources missing",
    YOU_HAVE: "You have ",
    CRR_MODAL_REFRESH_DETAILS: "Please note that once you have completed the actions for the listed items, refresh the modal to view the latest update.",
    CRR_TRIGGER_COVERAGE: "Trigger Coverage",
    CRR_TRIGGER_COVERAGE_DETAILS: "{0} Library Trigger Groups Recommended",
    CRR_FRAUD_ALERT_RESPONSE_TIME: "Fraud Alert Response",
    CRR_HIGH_FRAUD_ALERT_RESPONSE_TIME: "High Fraud Alert Response Time",
    CRR_HIGH_FRAUD_ALERT_RESPONSE_TIME_DETAILS: "<b>{0}%</b> of Alerts Outside of Fraud Detection Alert Response Threshold Time of <b>{1} Minutes</b>",
    CRR_ACTION_LIST_ITEM: "<b>{0}</b>",
    MORE_ACTIONS: "More Actions",
    CRR_CRITICAL_DATA_CATEGORIES_PRESENT: "Critical {0} Data Categories",
    CRR_CRITICAL_DATA_CATEGORIES_MISSING: "{0} Critical {1} Data Categories Missing",
    CRR_TICKET_RESPONSE_TIME: "Threat Surveillance Incident Ticket Response Time",
    CRR_HIGH_TICKET_RESPONSE_TIME: "High Threat Surveillance Incident Ticket Response Time",
    CRR_HIGH_TICKET_RESPONSE_TIME_DETAILS: "<b>{0}%</b> of Incident Tickets Outside of Threat Surveillance Incident Ticket Response Threshold Time of <b>{1} Minutes</b>",
    CRR_TICKET_RESOLUTION_TIME: "Threat Surveillance Incident Ticket Resolution Time",
    CRR_HIGH_TICKET_RESOLUTION_TIME: "High Threat Surveillance Incident Ticket Resolution Time",
    CRR_HIGH_TICKET_RESOLUTION_TIME_DETAILS: "<b>{0}%</b> of Incident Tickets Outside of Threat Surveillance Incident Ticket Resolution Threshold Time of <b>{1} Minutes</b>",
    CRR_ACTIVE_TASK_SCHEDULES_HAS_RISK_SKU: "Active Task Schedules Linked to Active Frameworks or Risk Controls",
    CRR_ACTIVE_TASK_SCHEDULES_NO_RISK_SKU: "Active Task Schedules Linked to Active Frameworks",
    CRR_INCOMPLETE_ACTIVE_TASK_SCHEDULES_HAS_RISK_SKU: "Less than <b>{0}%</b> of Library Task Schedules mapped to your Active Frameworks or Risk Assessment Controls are Active <b>({1}/{2})</b>",
    CRR_NO_ACTIVE_TASK_SCHEDULES_HAS_RISK_SKU: "No Active Task Schedule from Active Frameworks or Risk Assessment Controls",
    CRR_INCOMPLETE_ACTIVE_TASK_SCHEDULES_NO_RISK_SKU: "Less than <b>{0}%</b> of Task Schedules mapped to your Active Frameworks are Active <b>({1}/{2})</b>",
    CRR_NO_ACTIVE_TASK_SCHEDULES_NO_RISK_SKU: "No Active Task Schedule from Active Frameworks",
    CRR_NO_TASKS_PAST_DUE: "No Tasks Past Due",
    CRR_TASKS_PAST_DUE: "{0} Tasks are Past Due or Multiple Tasks from the Same Task Schedule are Open",
    CRR_ACTIVE_FRAMEWORK_IN_USE: "Frameworks Active",
    CRR_NO_ACTIVE_FRAMEWORK_IN_USE: "There is No Framework Marked Active",
    CRR_ACTIVE_FRAMEWORK_ANSWERS_COMPLETION: "Active Frameworks Completion",
    CRR_ACTIVE_FRAMEWORK_PARTIAL_ANSWERS: "{0} Active Frameworks have \"Partial\" answers",
    CRR_ACTIVE_FRAMEWORK_NO_ANSWERS: "{0} Active Frameworks have \"No\" answers",
    CRR_ACTIVE_FRAMEWORK_UNANSWERED: "{0} Active Frameworks have \"Unanswered\" answers",
    CRR_ACTIVE_FRAMEWORK_HAS_PARTIAL_ANSWERS_DETAILS: "<b>{0}</b> Active Framework has <b>{1}</b> \"Partial\" Answers",
    CRR_ACTIVE_FRAMEWORK_HAS_NO_ANSWERS_DETAILS: "<b>{0}</b> Active Framework has <b>{1}</b> \"No\" Answers",
    CRR_ACTIVE_FRAMEWORK_HAS_UNANSWERED_DETAILS: "<b>{0}</b> Active Framework has <b>{1}</b> \"Unanswered\" Answers",
    CRR_FINALIZED_RISK_ASSESSMENT: "Finalized Risk Assessment",
    CRR_INEFFECTIVE_FINALIZED_RISK_ASSESSMENT: "No Risk Assessment Finalized in the last {0} Months",
    CRR_NO_FINALIZED_RISK_ASSESSMENT: "No Risk Assessment Finalized in Greater Than {0} Months",
    CRR_RISK_REGISTER: "Risk Register",
    CRR_INCOMPLETE_RISK_REGISTER: "Less than {0} Active Risks in the Risk Register ({1})",
    CRR_EMPTY_RISK_REGISTER: "No Active Risks in the Risk Register",
    CRR_CONTROL_REGISTER: "Control Register",
    CRR_INCOMPLETE_CONTROL_REGISTER: "Less than {0} Active Controls in the Control Register ({1})",
    CRR_EMPTY_CONTROL_REGISTER: "No Active Controls in the Control Register",
    CRR_SYSTEMS_AND_APPS_REGISTER: "Systems & Applications",
    CRR_INCOMPLETE_SYSTEMS_AND_APPS_REGISTER: "Less than {0} Active Systems and Applications in the Technologies Inventory ({1})",
    CRR_EMPTY_SYSTEMS_AND_APPS_REGISTER: "There are no Active Systems and Applications in the Technologies Inventory",
    CRR_CONTROLS_WITH_EVIDENCE: "Controls Leveraging Systematic Evidence Collection",
    CRR_INCOMPLETE_CONTROLS_WITH_EVIDENCE: "{0} Controls out of {1} Missing Systematic Evidence Collection",
    CRR_NO_CONTROLS_WITH_EVIDENCE: "No Evidence Linked to Any Controls",
    CRR_EXCEPTION_SCHEDULES: "Exception Schedules",
    CRR_EXCEPTIONS_PAST_DUE: "{0} Exceptions are Past Due",
    CRR_EXCEPTIONS_WITHOUT_SCHEDULES: "{0} Exceptions Logged Without Review Schedule",
    CRR_RISK_CONTROL_INVENTORY_SCHEDULES: "Risk, Control, and Technology Inventory Schedules",
    CRR_OVERDUE_RISK_CONTROL_INVENTORY_SCHEDULES: "{0} Risk, Control, and Technology Inventory Schedules are Past Due",
    CRR_OVERDUE_RISK_SCHEDULE_ACTION_ITEM: "{0} (<i>Risk</i> )",
    CRR_OVERDUE_CONTROL_SCHEDULE_ACTION_ITEM: "{0} (<i>Control</i> )",
    CRR_OVERDUE_SYSTEM_SCHEDULE_ACTION_ITEM: "{0} (<i>Technology Inventory</i> )",
    CRR_MANAGING_STALE_SCORES: "Risk, Control, and Inventory Scores are Maintained",
    CRR_INEFFECTIVE_MANAGING_STALE_SCORES: "Risk, Control, and Inventory Scores Not Maintained In Last {0} days",
    CRR_EFFECTIVELY_MANAGING_RISK: "Effectively Managing Risk ({0}%)",
    CRR_MODERATE_RESIDUAL_RISK: "Moderate Residual Risk ({0}%)",
    CRR_HIGH_RESIDUAL_RISK: "High Residual Risk ({0}%)",
    CRR_ALIGNMENT_SCORING_MODEL: "Alignment with DefenseStorm Risk Scoring Model",
    CRR_SOMEWHAT_UNSATISFIED_ALIGNMENT_SCORING_MODEL: "Not Aligned with DefenseStorm Risk Scoring Model By {0} Points",
    CRR_UNSATISFIED_ALIGNMENT_SCORING_MODEL: "Not Aligned with DefenseStorm Risk Scoring Model By {0} or more Points",
    CRR_SCORE_CYBER_RISK_READY: "Cyber Risk Ready",
    CRR_SCORE_RECOMMENDED_ACTION: "Recommended Action",
    CRR_SCORE_RECOMMENDED_ACTIONS: "Recommended Actions",
    CRR_SCORE_UNFULFILLED_ACTION: "Unfulfilled Action",
    CRR_SCORE_UNFULFILLED_ACTIONS: "Unfulfilled Actions",
    CRR_SCORE_UNDETERMINED: "Undetermined",
    CRR_LEARN_MORE_ON_KNOWLEDGE_BASE: "Learn More on DefenseStorm Knowledge Base",

    // LINE ERROR MESSAGES
    MISSING_NAME: "Asset is missing a name",
    INVALID_ID: "<strong>{0}</strong> is an invalid ID",
    INVALID_HOSTNAME: "At least one hostname is empty",
    INVALID_IP_ADDRESS: "<strong>{0}</strong> is an invalid IP address",
    INVALID_MAC_ADDRESS: "<strong>{0}</strong> is an invalid MAC address",
    NULL_IP_MAC_PAIR: "IP address and MAC address at position <strong>{0}</strong> are invalid as a pair",
    INVALID_IMPORTANCE: "<strong>{0}</strong> is an invalid importance; importance must be low, medium, or high",
    INVALID_LABEL: "<strong>{0}</strong> is an invalid label",
    MISSING_IDENTIFIER: "Asset missing identifier; Asset requires hostname, IP, or MAC",
    DUPLICATE_ID: "<strong>{0}</strong> is a duplicate ID for another uploaded Asset",
    DUPLICATE_IP_ADDRESS: "<strong>{0}</strong> is a duplicate IP address",
    DUPLICATE_MAC_ADDRESS: "<strong>{0}</strong> is a duplicate MAC address",
    DHCP_CONFLICT: "Server error; please retry", // an IP on this asset was assigned to another tracked asset by DHCP between the IP validation step and creation time
    NO_SUCH_ASSET: "No asset currently exists with an ID: <strong>{0}</strong>",

    // DATA RESTRICTIONS
    DATA_RESTRICTIONS: "Data Restrictions",
    DATA_RESTRICTION: "Data Restriction",
    NEW_DATA_RESTRICTION: "New Data Restriction",
    DATA_RESTRICTION_INCLUDE: "Only Allow Events Matching",
    DATA_RESTRICTION_EXCLUDE: "Exclude Events Matching",
    BLANK_FOR_NO_RESTRICTION: "(leave blank for no restriction)",
    ONE_OF_BLACK_WHITE_LIST_REQUIRED: "At least one of \"Only Allow Matching\" or \"Exclude Matching\" query strings required.",
    INVALID_RESTRICTION_QUERY: "Invalid query",
    REQUIRED: "Required",
    NO_MATCHING_RESTRICTIONS: "No matching Data Restrictions.",
    ERROR_SAVING_RESTRICTION: "An error occurred while saving your Data Restriction. Please try again or contact DefenseStorm support.",
    DATA_RESTRICTION_MODAL_DELETE_CONFIRM_TITLE: "Data Restriction in Use",
    DATA_RESTRICTION_MODAL_DELETE_BODY_TEXT: "Deleting this Data Restriction will remove it from {0} User(s). This cannot be automatically reverted.",
    DATA_RESTRICTION_DELETED: "Deleted Data Restriction: \"{0}\"",
    DATA_RESTRICTION_RESTORED: "Restored Data Restriction: \"{0}\"",
    TEST_RESTRICTION: "Test Restriction",
    SELECT_USER: "Select User",
    SELECT_USERS: "Select Users",
    RESTRICTED_USERS: "Restricted Users",

    TYPE_FIELD: "Type field",
    TYPE_FIELD_NAME: "Type Field Name",
    TYPE_VALUE: "Type value",
    ADD_NESTED_CONDITION: " Add as Nested Block Condition",
    ADD_CONDITION: " Add Condition",
    ADD_ALL_ORGS: " Add All Orgs",
    ADD_ALL_USERS: " Add All Users",
    REMOVE_ALL_USERS: " Remove All Users",
    MANUALLY_ADD_EDIT_QUERY: "Manually Add/Edit Query   >>",

    //Roles page:
    NO_MATCHING_ROLES: "No matching roles",
    ERROR_SAVING_ROLE: "An error occurred while saving your Role. Please try again or contact DefenseStorm support.",
    NEW_ROLE: "New Role",
    SWITCH_USERS_AND_DELETE: "Switch Users and Delete Role",
    USERS: "USERS",
    INVALID_ROLE_ERROR: "Could not perform action because role was invalid",
    ROLE_MODAL_DELETE_CONFIRM_TITLE: "Role in Use",
    ROLE_MODAL_DELETE_BODY_TEXT_PART_1: "If you wish to continue deleting the {0} role, you must move the existing {1} users in this role to a different role. Please select the role to move the users to.",
    ROLE_MODAL_DELETE_BODY_TEXT_PART_2: "Users in role {0} currently:",
    READ_ONLY: "Read Only",
    READ_AND_WRITE: "Read & Write",
    PERM_EVENT_SEARCH_NAME: "Event Search",
    PERM_EVENT_SEARCH_DESCRIPTION: "Allows searching for events on Events page",
    PERM_SAVED_SEARCH_NAME: "Saved Search",
    PERM_SAVED_SEARCH_DESCRIPTION: "Allows viewing, creating, editing, and deleting Saved Searches",
    PERM_CLASSIFIERS_NAME: "Classifiers",
    PERM_CLASSIFIERS_DESCRIPTION: "Allows viewing, creating, editing, and deleting Classifiers",
    PERM_DATA_RESTORES_NAME: "Data Restores",
    PERM_DATA_RESTORES_DESCRIPTION: "Allows viewing, creating and archiving data restores",
    PERM_ALERT_INBOX_NAME: "Alert Inbox",
    PERM_ALERT_INBOX_DESCRIPTION: "Allows viewing and interacting with the alert inbox, including acking, escalating, dismissing triggered alerts",
    PERM_TRIGGERS_NAME: "Triggers",
    PERM_TRIGGERS_DESCRIPTION: "Allows viewing, creating, editing, deleting, pausing, unpausing Triggers, as well as creation and modification of Trigger Schedules",
    PERM_THREATMATCH_NAME: "Threatmatch",
    PERM_THREATMATCH_DESCRIPTION: "Allows access to Threatmatch settings, including uploading threat sources, enabling/disabling threat sources and other settings",
    PERM_RATE_THREATS_NAME: "Rate threats",
    PERM_RATE_THREATS_DESCRIPTION: "Allows users to view and rate IPs at various threat levels",
    PERM_INCIDENT_NAME: "Incidents",
    PERM_INCIDENT_DESCRIPTION: "Allows viewing, creating, editing, and deleting Incidents",
    PERM_TASK_NAME: "Tasks",
    PERM_TASK_DESCRIPTION: "Allows viewing, creating, editing, and deleting Tasks",
    PERM_TASK_SCHEDULES_NAME: "Task Schedules",
    PERM_TASK_SCHEDULES_DESCRIPTION: "Allows viewing, creating, editing, and deleting Task Schedules, including access to the Task Schedule Library",
    PERM_GOVERNANCE_DASHBOARD_NAME: "Governance Dashboard",
    PERM_GOVERNANCE_DASHBOARD_DESCRIPTION: "Allows viewing the governance dashboard page",
    PERM_COMPLIANCE_RISK_NAME: "Risk",
    PERM_COMPLIANCE_RISK_DESCRIPTION: "Allows viewing the risk pages",
    PERM_FRAMEWORKS_NAME: "Frameworks",
    PERM_CAT_POLICIES_NAME: "CAT Policies",
    PERM_FRAMEWORKS_DESCRIPTION: "Allows viewing the Frameworks page",
    PERM_CAT_POLICIES_DESCRIPTION: "Allows viewing the CAT Policy page, including Risk Profile and Maturity Evaluation",
    PERM_CUSTOM_POLICIES_NAME: "Custom Policies",
    PERM_CUSTOM_POLICIES_DESCRIPTION: "Allows viewing, creating, editing, and deleting Custom Policies",
    PERM_POLICY_LINK_NAME: "Policy Link",
    PERM_POLICY_LINK_DESCRIPTION: "Allows users to link Policies to things like tickets, triggers, task schedules, etc... Required for editing Tickets.",
    PERM_ASSETS_NAME: "Assets",
    PERM_ASSETS_DESCRIPTION: "Allows viewing, creating, editing, and deleting assets on the Assets page",
    PERM_REPORTS_NAME: "Reports",
    PERM_REPORTS_DESCRIPTION: "Allows viewing, creating, editing, and deleting reports on the Reports page, as well as Report Templates. Recommended to also have the Charts and Saved Searches R&W permissions for best performance.",
    PERM_CHARTS_NAME: "Charts",
    PERM_CHARTS_DESCRIPTION: "Allows viewing, creating, editing, and deleting charts. To edit charts, must have Event Search and Saved Search R&W permissions.",
    PERM_DASHBOARDS_NAME: "Dashboards",
    PERM_DASHBOARDS_DESCRIPTION: "Allows viewing, creating, editing, and deleting dashboards. To view dashboards, must have Event Search permission. To fully edit Dashboards, recommended to have Charts R&W and Saved Search R&W permissions.",
    PERM_USERS_NAME: "Users",
    PERM_USERS_DESCRIPTION: "Allows viewing, creating, editing, and deleting users, as well as viewing, creating, editing, and deleting roles",
    PERM_AUTH_TOKENS_NAME: "Auth Tokens",
    PERM_AUTH_TOKENS_DESCRIPTION: "Allows access to viewing and interacting with the Auth Tokens page",
    PERM_INTEGRATIONS_NAME: "Integrations",
    PERM_INTEGRATIONS_DESCRIPTION: "Allows access to viewing and interacting with the Integrations page",
    PERM_DATA_RESTRICTIONS_NAME: "Data Restrictions",
    PERM_DATA_RESTRICTIONS_DESCRIPTION: "Allows viewing, creating, editing, and deleting data restrictions",
    PERM_ESCALATIONS_NAME: "Escalations",
    PERM_ESCALATIONS_DESCRIPTION: "Allows viewing, creating, editing, and deleting Escalations",
    PERM_CUSTOM_PARSERS_NAME: "Custom Parsers",
    PERM_CUSTOM_PARSERS_DESCRIPTION: "Allows viewing, creating, editing, and deleting Custom Parsers",
    PERM_METADATA_ACTIVITY_LOG_NAME: "Activity Logs",
    PERM_METADATA_ACTIVITY_LOG_DESCRIPTION: "Allows viewing activity logs and writing notes on activity logs",
    PERM_METADATA_TAG_NAME: "Tags",
    PERM_METADATA_TAG_DESCRIPTION: "Allows viewing and writing tags",
    PERM_MODULE_EVENTS_NAME: "EVENTS",
    PERM_MODULE_ALERTS_NAME: "ALERTS",
    PERM_MODULE_TICKETS_NAME: "TICKETS",
    PERM_MODULE_GOVERNANCE_NAME: "GOVERNANCE",
    PERM_MODULE_RISK_NAME: "RISK",
    PERM_MODULE_ASSETS_NAME: "ASSETS",
    PERM_MODULE_METADATA_NAME: "METADATA",
    PERM_MODULE_REPORTS_NAME: "REPORTS",
    PERM_MODULE_SETTINGS_NAME: "SETTINGS",
    SHARED: "Shared",
    ROLE_DELETED: "Deleted Role: \"{0}\"",
    ROLE_RESTORED: "Restored Role: \"{0}\"",
    NA: "N/A",
    USERS_IN_ROLE: "Users in {0} Role",
    SIM: "SIM",
    DIFF: "DIFF",
    SIMILARITIES_DIFFERENCES: "Similarities/Differences",
    PERFORM_SEARCH_ON_OVERLAPPING_FIELDS: "Perform search on overlapping fields",
    SET_ANCHOR_FOR_COMPARISONS: "Set anchor for comparisons",
    SHARED_ROLE: "Shared role",
    CANNOT_EDIT_SHARED_ROLE: "This shared role is not editable",

    // Job Descriptors
    INVALID_JOB_DESCRIPTOR_ERROR: "Invalid Job Descriptor(s)",
    USERS_WITH_DESCRIPTOR: "Users With Descriptor {0}",
    CREATE_DESCRIPTOR: "Create Job Descriptor",
    NEW_DESCRIPTOR: "New Job Descriptor",
    EDIT_DESCRIPTOR: "Edit Job Descriptor",
    SEARCH_JOB_DESCRIPTORS: "Search job descriptors",
    DESCRIPTOR_DELETED: "Deleted Descriptor: \"{0}\"",
    ALL_OTHER_ORGS: "All Other Orgs",

    // Fraud
    FRAUD: "Fraud",
    STORY_VIEW: "Story View",
    VIEW_MODE: "View Type",
    INBOX_SORT_BY: "Sort by",
    ALERT_CREATED_AT: "Alert Created",
    ALERT_ATTEMPTS: "Attempts",
    FIELD_user_name: "User name",
    FIELD_ip_src: "IP address",
    INBOX_VIEW_ALERT: "View the Alert",
    INBOX_VIEW_EVENTS: "View the Events",
    INPUT_FIELDS: "Input Fields",
    OUTPUT_FIELDS: "Output Fields",
    MATCHED_FIELDS: "Matched Fields",
    INBOX_NO_ALERTS_FOR_TRIGGER: "There are no alerts to display",
    INBOX_NO_ALERT_EVENTS_AVAILABLE: "Alert Info No Longer Available",
    INBOX_EXPIRED_EVENTS_FOR_ALERT: "The events for this alert are out of your current organization’s live data. Please submit a restore request if you wish to regain access to this information.",
    SEARCH_INCIDENT: "Search for an Incident...",
    SEARCH_TASK: "Search for a Task...",
    HIDE_ALERT_EVENTS: "Hide events",
    SHOW_ALERT_EVENTS: "Show events",
    NO_MORE_ALERTS: "There are no more alerts to show",
    NO_MORE_TRIGGERS: "There are no more triggers to show",
    NO_MORE_AGGREGATE_VALUES: "There are no more aggregate values to show",
    SELECT_NEW_ALERT: "Select an alert from the left menu to see its details",
    SELECT_NEW_TRIGGER: "Select a trigger from the left menu to see its alerts",
    LOAD_MORE: "Load more...",
    SEARCH_EVENTS: "Search Events",
    MANAGE_COLUMNS: "Manage Columns",
    USERNAME: "Username",
    DEVICE_INFO: "Device Info",
    EMAIL_ADDRESS: "Email Address",
    CONTACT_NUMBERS: "Contact Numbers",
    ADD_CONTACT_NUMBER: "Add Contact Number",
    PHONE_NUMBER: "Phone Number",
    PHONE_NUMBERS: "Phone Numbers",
    MAIN: "Main",
    OFFICE: "Office",
    MOBILE: "Mobile",
    EMERGENCY: "Emergency",
    ACCOUNT_NUMBER: "Account Number",
    CURRENT_STATE: "Current State:",

    // Risk:
    RISK: "Risk",
    RISKS: "Risks",
    RISK_PUBLIC_ID: "RISK",
    RISK_LIBRARY: "Risk Library",
    RISK_REGISTER: "Risk Register",
    CONTROLS_LIBRARY: "Controls Library",
    CONTROLS_REGISTER: "Controls Register",
    RISK_GET_MODULE: "Get Module",
    CREATE_RISK: "Create Risk",
    CREATE_RISK_OR_EXCEPTION: "Create Risk or Exception",
    CREATE_EXCEPTION: "Create Exception",
    ASSESSMENT_INCLUDE_EXCEPTIONS_FILTER: "Include",
    ASSESSMENT_EXCLUDE_EXCEPTIONS_FILTER: "Exclude",
    ASSESSMENT_ONLY_EXCEPTIONS_FILTER: "Only Exceptions",
    REVIEW_SCHEDULE_SUGGESTED_FOR_EXCEPTIONS: "A Review Schedule is suggested for exceptions",
    REVIEW_SCHEDULE_SUGGESTED_FOR_EXCEPTIONS_EXPLANATION: "Use the schedule to review the exception at an appropriate interval. " +
        "Schedules will send reminder emails to the designated watcher around the due date. " +
        "Supporting documentation can be attached directly into the exception.",
    EXCEPTION: "Exception",
    RISK_TYPE: "Risk Type",
    RISK_ONLY_EXCEPTIONS_FILTER: "Exceptions only",
    RISK_EXCLUDE_EXCEPTIONS_FILTER: "Exclude Exceptions",
    RISK_IS_EXCEPTION: "This risk is an exception",
    CREATE_CONTROL: "Create Control",
    ADD_TO_REGISTER: "Add to Register",
    ADD_TO_INVENTORY: "Add to Inventory",
    MODULE: "MODULE",
    MODULE_LABEL: "Module",
    RISK_MODULE_INFORMATION_SECURITY: "Information Security",
    RISK_MODULE_SYSTEMS_AND_APPLICATIONS: "Systems & Applications",
    RISK_MODULE_THIRD_PARTY_RISK: "Third-Party Risk",
    RISK_MODULE_INFORMATION_SECURITY_DESCRIPTION: "INFORMATION SECURITY \n This module includes risks and internal controls related to information security and cybersecurity. The module enables identifying, assessing, monitoring, and manging risks and associated internal controls and integrates with GRID security, fraud and compliance activities.",
    RISK_MODULE_SYSTEMS_AND_APPLICATIONS_DESCRIPTION: "SYSTEMS & APPLICATIONS \n This module includes risks and internal controls related to ensuring resilience against disruptions across the entire business. The module enables the tracking, monitoring, and assessing risks and associated internal controls and integrates with GRID security, fraud and compliance activities.",
    RISK_MODULE_THIRD_PARTY_RISK_DESCRIPTION: "THIRD-PARTY RISK \n This module includes risks and internal controls associated with the use of third-party providers. The module enables identifying, assessing, monitoring, and manging risks and associated internal controls and integrates with GRID security, fraud and compliance activities.",
    RISK_MODULES: "Risk Modules",
    YOUR_MODULES: "Your Modules",
    OTHER_MODULES: "Other Modules",
    NEW_RISK: "New Risk",
    ADD_NEW_RISK: "Add New Risk",
    ADD_NEW_CONTROL: "Add New Control",
    ADD_NEW_INVENTORY: "Add New Inventory",
    NEW_LIBRARY_RISK: "New Library Risk",
    EDIT_RISK: "Edit Risk",
    EDIT_LIBRARY_RISK: "Edit Library Risk",
    RISK_DESCRIPTION: "Definition",
    RISK_NAME_PLACEHOLDER: "Risk Name",
    RISK_DESCRIPTION_PLACEHOLDER: "Risk Definition",
    RISK_MITIGATION_PLAN_PLACEHOLDER: "Type Mitigation Plan summary",
    RISK_KEY_RISK: "Key Risk",
    RISK_SHOW_IN_PREVIEW: "Show in Preview",
    RISK_THREAT_TYPE: "Threat Type",
    RISK_ADD_THREAT_TYPE: "Add Threat Type",
    RISK_ASSESSMENT: "Risk Assessment",
    RISK_SCORES: "Risk Scores",
    RISK_SCORE: "Risk Score",
    RISK_MITIGATION_PLAN: "Mitigation Plan",
    RISK_INFO: "Info",
    OVERSIGHT: "Oversight",
    NO_OPERATIONAL_RISKS: "No risks found. Try selecting different modules and/or filters.",
    NO_RISK_CONTROLS: "No controls found. Try selecting different modules and/or filters.",
    NO_RISK_SYSTEMS: "No systems/apps found. Try selecting different filters.",
    NO_RISKS_IN_REGISTER: "You have not added any Risks yet from the Library",
    NO_CONTROLS_IN_REGISTER: "You have not added any Controls yet from the Library",
    NO_INVENTORY_IN_REGISTER: "You have not added any Inventory yet from the Library",
    INHERENT_RISK: "Inherent Risk",
    OVERALL_INHERENT_RISK: "Overall Inherent Risk",
    OVERALL_RISK: "Overall Risk",
    RECOMMENDED_RISK: "Recommended Risk",
    RESIDUAL_RISK: "Residual Risk",
    OVERALL_RESIDUAL_RISK: "Overall Residual Risk",
    RISK_OWNER: "Risk Owner",
    BUSINESS_UNIT: "Business Unit",
    BUSINESS_UNITS: "Business Units",
    BUSINESS_OWNER: "Business Owner",
    SYSTEM_ADMIN: "System Admin",
    SYSTEM_VENDOR: "Vendor",
    SYSTEM_GO_LIVE_DATE: "Go Live Date",
    LINKED_CONTROLS: "Linked Controls",
    LINKED_SYSTEMS: "Linked Systems",
    LINKED_RISKS_AND_CONTROLS: "Linked Risks and Controls",
    RISK_SCORE_potentialImpact: "Potential Impact",
    RISK_SCORE_likelihood: "Likelihood",
    RISK_SCORE_inherentRisk: "Inherent Risk",
    RISK_SCORE_controlStrength: "Strength of Controls",
    RISK_SCORE_residualRisk: "Residual Risk",
    RISK_SCORE_riskDirection: "Direction",
    RISK_SCORE_designEffectiveness: "Design Effectiveness",
    RISK_SCORE_operatingEffectiveness: "Operating Effectiveness",
    RISK_SCORE_overallScore: "Strength of Control",
    RISK_SCORE_dataSensitivity: "Data Sensitivity",
    RISK_SCORE_businessCriticality: "Business Criticality",
    RISK_SCORE_rare: "Rare",
    RISK_SCORE_almostCertain: "Almost Certain",
    RISK_SCORE_null: "Unknown",
    RISK_SCORE_undefined: "Unknown",
    RISK_SCORE_INSIGNIFICANT: "Insignificant",
    RISK_SCORE_LOW: "Low",
    RISK_SCORE_MODERATE: "Moderate",
    RISK_SCORE_EXTREME: "Very High",
    RISK_SCORE_HIGH: "High",
    RISK_SCORE_INEFFECTIVE: "Ineffective",
    RISK_SCORE_WEAK: "Weak",
    RISK_SCORE_STRONG: "Strong",
    RISK_SCORE_COMPLETE: "Very Strong",
    RISK_SCORE_DECREASING_RAPIDLY: "Dec. Rapidly",
    RISK_SCORE_DECREASING: "Decreasing",
    RISK_SCORE_STABLE: "Stable",
    RISK_SCORE_INCREASING: "Increasing",
    RISK_SCORE_INCREASING_RAPIDLY: "Inc. Rapidly",
    RISK_THREAT_EVENT_TYPE: "Risk Event Type",
    RISK_THREAT_EVENT_TYPE_LOG_HEADER: "Risk Event Type(s)",
    RISK_THREAT_EVENT_TYPE_HEADER: "Event Types",
    RISK_THREAT_EVENT_TYPE_SELECT: "Select event type to add",
    RISK_THREAT_SUB_EVENT_TYPE_SELECT_OPTIONAL: "Select event sub-type (optional)",
    EVENT_TYPE: "Event Type",
    RISK_THREAT_CAUSE: "Cause",
    RISK_THREAT_CAUSE_LOG_HEADER: "Cause(s)",
    RISK_THREAT_CAUSE_HEADER: "Causes",
    RISK_THREAT_CAUSE_SELECT: "Select cause to add",
    RISK_THREAT_SUB_CAUSE_SELECT_OPTIONAL: "Select sub-cause (optional)",
    RISK_THREAT_CONSEQUENCE: "Consequence",
    RISK_THREAT_CONSEQUENCE_LOG_HEADER: "Consequence(s)",
    RISK_THREAT_CONSEQUENCE_HEADER: "Consequences",
    RISK_THREAT_CONSEQUENCE_SELECT: "Select consequence to add",
    RISK_THREAT_SUB_CONSEQUENCE_SELECT_OPTIONAL: "Select sub-consequence (optional)",
    RISK_THREAT_ERM_CONNECTIONS: "ERM Connections",
    ERM_CONNECTIONS: "ERM Connections",
    ERM_CONNECTIONS_LOG_HEADER: "ERM Connection(s)",
    RISK_THREAT_RESPONSE: "Response",
    RISK_RESPONSE: "Response",
    SELECT_THREAT_SUBCATEGORY_RISK_EVENT: "Select Event Sub-Type",
    SELECT_THREAT_SUBCATEGORY_CAUSE: "Select Sub-Cause",
    SELECT_THREAT_SUBCATEGORY_SUBCATEGORY: "Select Sub-Consequence",
    RISK_SCORE_NOTE: "Note",
    SEARCH_RISKS_DETAILS: "Search risk name, ID, or description",
    SEARCH_SYSTEMS_DETAILS: "Search system name, ID, or description",
    RISK_SCORE_HISTORY_LOG: "History Log",
    NO_RISK_HISTORY_LOGS_FOUND: "The History Log is empty",
    RISK_ACTIVITY: "Activity",
    RISK_HISTORY_NOTE_ADDED: "Note Added",
    RISK_HISTORY_SCORE_SET_FIRST_TIME: "Score set to {0}",
    RISK_HISTORY_SCORE_CHANGED: "Score changed from {0} to {1}",
    RISK_STATE_CHANGED: "Risk state changed from {0} to {1}",
    RISK_ADD_SCORE: "Add Score",
    RISK_EDIT_SCORE: "Edit Score",
    RISK_SEE_SCORE_HISTORY: "See Score Activity Log",
    DOWNLOAD_RISKS: "Download {0} risk(s)",
    DOWNLOAD_RISKS_ERROR: "An error occurred during download.",
    DOWNLOAD_CONTROLS: "Download {0} control(s)",
    DOWNLOAD_CONTROLS_ERROR: "An error occurred during download.",
    COPY_RISKS_SUCCESS: "Copied {0} risk(s) to the register",
    COPY_RISK_SUCCESS: "Copied Risk \"{0}\"",
    COPY_CONTROLS_SUCCESS: "Copied {0} control(s) to the register",
    COPY_CONTROL_SUCCESS: "Copied Control \"{0}\"",
    COPY_SYSTEMS_SUCCESS: "Copied {0} system/app(s) to the register",
    COPY_SYSTEM_SUCCESS: "Copied System/App \"{0}\"",
    COPY_RISKS_FAILED: "Failed to copy {0} risk(s) because they already exist in the register",
    RISK_NOT_COPIED_FROM_LIBRARY: "This risk is not copied from the library",
    COPY_CONTROLS_FAILED: "Failed to copy {0} control(s) because they already exist in the register",
    CONTROL_NOT_COPIED_FROM_LIBRARY: "This internal control is not copied from the library",
    COPY_SYSTEMS_FAILED: "Failed to copy {0} system/app(s) because they already exist in the register",
    SYSTEM_NOT_COPIED_FROM_LIBRARY: "This system/app is not copied from the library",
    RISK_LINKED_SUCCESSFULLY: "Risk linked successfully",
    INVALID_RISK_LINKS: "Could not link to some invalid risks: {0}",
    CONTROL_LINKED_SUCCESSFULLY: "Control linked successfully",
    SYSTEM_LINKED_SUCCESSFULLY: "System/App linked successfully",
    INVALID_SYSTEM_LINKS: "Could not link to some invalid systems: {0}",
    RISK_RELATION_UPDATED_SUCCESSFULLY: "Risk relations updated successfully",
    MARK_ACTIVE: "Mark as active",
    MARK_INACTIVE: "Mark as inactive",
    ERM_CONNECTION_STRATEGIC: "Strategic",
    ERM_CONNECTION_REPUTATIONAL: "Reputational",
    ERM_CONNECTION_COMPLIANCE: "Compliance",
    ERM_CONNECTION_FINANCIAL: "Financial",
    ERM_CONNECTION_CREDIT: "Credit",
    RISK_RESPONSE_ACCEPT: "Accept",
    RISK_RESPONSE_AVOID: "Avoid",
    RISK_RESPONSE_TRANSFER: "Transfer",
    RISK_RESPONSE_MITIGATE: "Mitigate",
    GRID_COMPLIANCE: "GRID Compliance",
    RISK_LOG_STATE: "Changed state to {0}.",
    RISK_LOG_NAME: "Changed name from \"{0}\" to \"{1}\".",
    RISK_LOG_DESCRIPTION: "Changed description from \"{0}\" to \"{1}\".",
    RISK_LOG_MITIGATION_PLAN: "Changed mitigation plan from \"{0}\" to \"{1}\".",
    RISK_LOG_KEY_RISK_TO_TRUE: "This risk was marked as a \"Key Risk\".",
    RISK_LOG_KEY_RISK_TO_FALSE: "This risk was marked as no longer a \"Key Risk\".",
    RISK_LOG_WATCHER_ADD: "Added a watcher",
    RISK_LOG_WATCHER_REMOVE: "Removed a watcher",
    RISK_LOG_THREAT_DETAILS: "Changed the risk type",
    RISK_LOG_THREAT_DETAILS_CHANGE_FROM: "changed from",
    RISK_LOG_THREAT_DETAILS_CHANGE_TO: "to",
    RISK_LOG_FILE_ADD: "Added a file",
    RISK_LOG_FILE_REMOVE: "Removed a file",
    RISK_LOG_LINE_ITEM_ADD: "Added GRID compliance controls",
    RISK_LOG_LINE_ITEM_REMOVE: "Removed GRID compliance controls",
    RISK_LOG_RELATED_RISK_ADD: "Added related risks",
    RISK_LOG_RELATED_RISK_REMOVE: "Removed related risks",
    RISK_LOG_MODULES_ADD: "Added risk modules",
    RISK_LOG_MODULES_REMOVE: "Removed risk modules",
    RISK_LOG_AUDITS_ADD: "Added audits",
    RISK_LOG_AUDITS_REMOVE: "Removed audits",
    RISK_LOG_BUSINESS_UNIT_ADD: "Added business unit",
    RISK_LOG_BUSINESS_UNIT_REMOVE: "Removed business unit",
    RISK_LOG_BUSINESS_UNITS_ADD: "Added business units",
    RISK_LOG_BUSINESS_UNITS_REMOVE: "Removed business units",
    RISK_LOG_EXTERNAL_POLICIES_ADD: "Added related policies",
    RISK_LOG_EXTERNAL_POLICIES_REMOVE: "Removed related policies",
    RISK_LOG_RISK_OWNER_ADD: "Added risk owner",
    RISK_LOG_RISK_OWNER_REMOVE: "Removed risk owner",
    RISK_LOG_SYSTEM_VENDOR_ADD: "Added risk owner",
    RISK_LOG_SYSTEM_VENDOR_REMOVE: "Removed risk owner",
    RISK_LOG_SYSTEM_ADMIN_ADD: "Added system admin",
    RISK_LOG_SYSTEM_ADMIN_REMOVE: "Removed system admin",
    RISK_LOG_LINKED_RISKS_ADD: "Added linked risks",
    RISK_LOG_LINKED_RISKS_REMOVE: "Removed linked risks",
    RISK_LOG_LINKED_CONTROLS_ADD: "Added linked internal controls",
    RISK_LOG_LINKED_CONTROLS_REMOVE: "Removed linked internal controls",
    RISK_LOG_LINKED_SYSTEMS_ADD: "Added linked systems/apps",
    RISK_LOG_LINKED_SYSTEMS_REMOVE: "Removed linked systems/apps",
    RISK_LOG_SYSTEM_TYPE: "Changed system type from \"{0}\" to \"{1}\"",
    RISK_LOG_DATA_LOCATION_ADD: "Added FI data location",
    RISK_LOG_DATA_LOCATION_REMOVE: "Removed FI data location",
    RISK_LOG_VERSION_NUMBER_ADD: "Added version number",
    RISK_LOG_VERSION_NUMBER_REMOVE: "Removed version number",
    RISK_LOG_SENSITIVITY_QUESTIONS_CHANGED: "Changed the sensitivity of data assessment",
    RISK_LOG_PARAMETER_QUESTIONS_CHANGED: "Changed the parameter risk management assessment",
    RISK_LOG_PARAMETER_COMMENT_ADD: "Added a comment to the parameter risk management",
    RISK_LOG_PARAMETER_COMMENT_REMOVE: "Removed a comment from the parameter risk management",
    RISK_LOG_PARAMETER_AUTH_PEOPLE_ADD: "Added People Authorized to the parameter risk management",
    RISK_LOG_PARAMETER_AUTH_PEOPLE_REMOVE: "Removed People Authorized from the parameter risk management",
    RISK_LOG_CRITICALITY_QUESTIONS_CHANGED: "Changed the criticality to business assessment",
    RISK_LOG_ACCESS_QUESTIONS_CHANGED: "Changed the parameter access management assessment",
    RISK_LOG_ACCESS_COMMENT_ADD: "Added a comment to the access risk management",
    RISK_LOG_ACCESS_COMMENT_REMOVE: "Removed a comment from the access risk management",
    RISK_LOG_ACCESS_AUTH_PEOPLE_ADD: "Added People Authorized to the access risk management",
    RISK_LOG_ACCESS_AUTH_PEOPLE_REMOVE: "Removed People Authorized from the access risk management",
    RISK_LOG_SECURITY_QUESTIONS_CHANGED: "Changed the security risk management assessment",
    RISK_LOG_SECURITY_COMMENT_ADD: "Added a comment to the security risk management",
    RISK_LOG_SECURITY_COMMENT_REMOVE: "Removed a comment from the security risk management",
    RISK_LOG_GO_LIVE_DATE_CHANGED: "Changed the Go Live Date from \"{0}\" to \"{1}\"",
    RISK_LOG_POTENTIAL_IMPACT_NEW: "Potential Impact score was updated to",
    RISK_LOG_POTENTIAL_IMPACT_OLD: "Potential Impact score previously was",
    RISK_LOG_LIKELIHOOD_NEW: "Likelihood score was updated to",
    RISK_LOG_LIKELIHOOD_OLD: "Likelihood score previously was",
    RISK_LOG_INHERENT_RISK_NEW: "Inherent Risk score was updated to",
    RISK_LOG_INHERENT_RISK_OLD: "Inherent Risk score previously was",
    RISK_LOG_CONTROL_STRENGTH_NEW: "Strength of Controls score was updated to",
    RISK_LOG_CONTROL_STRENGTH_OLD: "Strength of Controls score was previously",
    RISK_LOG_RESIDUAL_RISK_NEW: "Residual Risk score was updated to",
    RISK_LOG_RESIDUAL_RISK_OLD: "Residual Risk score previously was",
    RISK_LOG_RISK_DIRECTION_NEW: "Direction of Risk score was updated to",
    RISK_LOG_RISK_DIRECTION_OLD: "Direction of Risk score previously was",
    RISK_LOG_DESIGN_EFFECTIVENESS_NEW: "Design Effectiveness score was updated to",
    RISK_LOG_DESIGN_EFFECTIVENESS_OLD: "Design Effectiveness score previously was",
    RISK_LOG_OPERATING_EFFECTIVENESS_NEW: "Operating Effectiveness score was updated to",
    RISK_LOG_OPERATING_EFFECTIVENESS_OLD: "Operating Effectiveness score was previously",
    RISK_LOG_OVERALL_SCORE_NEW: "Overall score was updated to",
    RISK_LOG_OVERALL_SCORE_OLD: "Overall score previously was",
    RISK_LOG_DATA_SENSITIVITY_NEW: "Data Sensitivity score was updated to",
    RISK_LOG_DATA_SENSITIVITY_OLD: "Data Sensitivity score previously was",
    RISK_LOG_BUSINESS_CRITICALITY_NEW: "Business Criticality score was updated to",
    RISK_LOG_BUSINESS_CRITICALITY_OLD: "Business Criticality score previously was",
    RISK_LOG_TAGS_ADD: "Added tags",
    RISK_LOG_TAGS_REMOVE: "Removed tags",
    RISK_LOG_ERM_CONNECTIONS_ADD: "Added ERM connections",
    RISK_LOG_ERM_CONNECTIONS_REMOVE: "Removed ERM connections",
    RISK_LOG_RESPONSE_ADD: "Added response",
    RISK_LOG_RESPONSE_REMOVE: "Removed response",
    RELATED_RISKS: "Related Risks",
    CANNOT_ADD_RELATED_RISKS_TO_INACTIVE_RISKS: "Cannot add related risks to inactive risks",
    ADD_RELATED_RISK: "Add Risk",
    ADD_LINKED_RISK: "Add Risk Link",
    ADD_TO_REGISTER_AND_LINK: "Add to register & Link",
    LINK: "Link",
    LINK_RISK: "Link Risk",
    LINK_CONTROL: "Link Control",
    LINK_SYSTEM: "Link System/App",
    MORE_SUGGESTIONS: "More Suggestions",
    SUGGESTED_TASK_SCHEDULES: "Suggested Task Schedules",
    LINK_TASK_SCHEDULE: "Link Task Schedule",
    ADD_RELATED_RISKS: "Add Related Risks",
    RISK_ADD_AUDIT_COVERAGE: "Add Audit Coverage",
    RISK_CUSTOM_LIST_TITLE_AUDIT_COVERAGE: "Audits",
    RISK_EDIT_CUSTOM_LIST_AUDIT_COVERAGE: "Audits List",
    RISK_SELECT_FROM_CUSTOM_LIST_AUDIT_COVERAGE: "Add Audits",
    RISK_CUSTOM_LIST_EMPTY_AUDIT_COVERAGE: "There are currently no Audits for your organization",
    RISK_MANAGE_CUSTOM_LIST_AUDIT_COVERAGE: "Manage Audits List",
    RISK_TYPE_CUSTOM_ITEM_AUDIT_COVERAGE: "Type Audit Name",
    RISK_DUPLICATED_ITEM_AUDIT_COVERAGE: "There is already an Audit with that name",
    RISK_ADD_EXTERNAL_POLICY: "Add Policy",
    RISK_CUSTOM_LIST_TITLE_EXTERNAL_POLICY: "Related Policies",
    RISK_EDIT_CUSTOM_LIST_EXTERNAL_POLICY: "Related Policies List",
    RISK_SELECT_FROM_CUSTOM_LIST_EXTERNAL_POLICY: "Add Related Policy",
    RISK_CUSTOM_LIST_EMPTY_EXTERNAL_POLICY: "There are currently no Related Policies for your organization",
    RISK_MANAGE_CUSTOM_LIST_EXTERNAL_POLICY: "Manage Related Policies List",
    RISK_TYPE_CUSTOM_ITEM_EXTERNAL_POLICY: "Type Related Policy Name",
    RISK_DUPLICATED_ITEM_EXTERNAL_POLICY: "There is already a Related Policy with that name",
    ADD_TO_LIST: "Add to List",
    ADD_BUSINESS_UNITS: "Add Business Units",
    RISK_CUSTOM_LIST_TITLE_BUSINESS_UNIT: "Business Units",
    RISK_CONTROL: "Risk Control",
    RISK_EDIT_CUSTOM_LIST_BUSINESS_UNIT: "Business Units List",
    RISK_SELECT_FROM_CUSTOM_LIST_BUSINESS_UNIT: "Add Business Unit",
    RISK_SELECT_FROM_CUSTOM_LIST_BUSINESS_OWNER: "Add Business Owner",
    RISK_SELECT_FROM_CUSTOM_LIST_SYSTEM_ADMIN: "Add System Admin",
    RISK_CUSTOM_LIST_EMPTY_BUSINESS_UNIT: "There are currently no Business Units for your organization",
    RISK_MANAGE_CUSTOM_LIST_BUSINESS_UNIT: "Manage Business Units List",
    RISK_TYPE_CUSTOM_ITEM_BUSINESS_UNIT: "Type Business Unit",
    RISK_DUPLICATED_ITEM_BUSINESS_UNIT: "There is already a Business Unit with that name",
    RISK_CUSTOM_LIST_TITLE_RISK_OWNER: "Risk Owners",
    RISK_CUSTOM_LIST_TITLE_RISK_VENDOR: "Vendors",
    RISK_EDIT_CUSTOM_LIST_RISK_OWNER: "Risk Owners List",
    RISK_EDIT_CUSTOM_LIST_RISK_VENDOR: "Risk Vendors List",
    RISK_SELECT_FROM_CUSTOM_LIST_RISK_OWNER: "Add Risk Owner",
    RISK_SELECT_FROM_CUSTOM_LIST_RISK_AUTHORIZED_PEOPLE: "Add Authorized People",
    RISK_SELECT_FROM_CUSTOM_LIST_RISK_VENDOR: "Add Vendor",
    RISK_CUSTOM_LIST_EMPTY_RISK_OWNER: "There are currently no Risk Owners for your organization",
    RISK_CUSTOM_LIST_EMPTY_RISK_VENDOR: "There are currently no Vendors for your organization",
    RISK_MANAGE_CUSTOM_LIST_RISK_OWNER: "Manage List of Non-GRID Users",
    RISK_MANAGE_CUSTOM_LIST_RISK_VENDOR: "Manage Vendors List",
    RISK_TYPE_CUSTOM_ITEM_RISK_OWNER: "Type Risk Owner",
    RISK_TYPE_CUSTOM_ITEM_RISK_VENDOR: "Type Vendor",
    RISK_DUPLICATED_ITEM_RISK_OWNER: "There is already a Risk Owner with that name",
    SEARCH_RISK_OWNER: "Search Risk Owner",
    MANAGE_NON_GRID_USERS: "Manage Non-GRID users list",
    MAKE_INACTIVE_CONFIRMATION_TITLE: "Confirm risk deactivation",
    MAKE_INACTIVE_CONFIRMATION: "This risk is linked to {0} other risks as related risks. Deactivating it will remove the links. Do you want to proceed?",
    NEW_INTERNAL_CONTROL: "New Control",
    NEW_LIBRARY_INTERNAL_CONTROL: "New Library Control",
    EDIT_INTERNAL_CONTROL: "Edit Control",
    EDIT_LIBRARY_INTERNAL_CONTROL: "Edit Library Control",
    INTERNAL_CONTROLS: "Internal Controls",
    INT_CONTROL_PUBLIC_ID: "Control",
    INT_CONTROL_DETAILS: "Details",
    INT_CONTROL_DESCRIPTION: "Definition",
    INT_CONTROL_NAME_PLACEHOLDER: "Control Name",
    INT_CONTROL_DESCRIPTION_PLACEHOLDER: "Control Definition",
    INT_CONTROL_KEY_CONTROL: "Key Control",
    INT_CONTROL_OWNER: "Control Owner",
    INT_CONTROL_SCORE: "Control Score",
    INT_CONTROL_SCORES: "Control Scores",
    INT_CONTROL_EVIDENCE: "Evidence",
    INT_CONTROL_CONFIGURATION: "Configuration",
    INT_CONTROL_TYPE_PREVENTATIVE: "Preventative",
    INT_CONTROL_TYPE_CORRECTIVE: "Corrective",
    INT_CONTROL_TYPE_DETECTIVE: "Detective",
    INT_CONTROL_CATEGORY_SECURITY_AND_AUTHENTICATION: "Security and authentication",
    INT_CONTROL_CATEGORY_STANDARDS_AND_DOCUMENTATION: "Standards and documentation",
    INT_CONTROL_CATEGORY_GOVERNANCE: "Governance",
    INT_CONTROL_CATEGORY_ROLES_AND_RESPONSIBILITIES: "Roles and responsibilities",
    INT_CONTROL_CATEGORY_CULTURE_AND_TRAINING: "Culture and training",
    INT_CONTROL_CATEGORY_ASSURANCE_AND_REMEDIATION: "Assurance and remediation",
    INT_CONTROL_CATEGORY_REDUNDANCY: "Redundancy",
    INT_CONTROL_CONFIGURATION_MANUAL: "Manual",
    INT_CONTROL_CONFIGURATION_AUTOMATED: "Automated",
    ADD_LINKED_CONTROL: "Add Control Link",
    ADD_LINKED_RISKS: "Add Risk Link",
    ADD_LINKED_SYSTEMS: "Add System/App Link",
    ADD_INTERNAL_CONTROLS: "Add Internal Controls",
    ADD_LIBRARY_INTERNAL_CONTROLS: "Add Library Internal Controls",
    SEARCH_CONTROLS: "Search control name or description",
    NO_CONTROLS_MATCH_QUERY: "No controls matched the query",
    NO_RISKS_MATCH_QUERY: "No risks matched the query",
    NO_SYSTEMS_MATCH_QUERY: "No systems/apps matched the query",
    FILTERED_BY_INTERNAL_CONTROL: "Filtered by Internal Control",
    COMPLETE_REQUIRED_FIELDS: "All required fields must be completed",
    INVENTORY: "Inventory",
    INVENTORY_LIBRARY: "Inventory Library",
    INVENTORY_REGISTER: "Inventory Register",
    RISK_DOWNLOAD_SYSTEMS: "Download Systems",
    RISK_CREATE_SYSTEM: "Create System",
    RISK_SYSTEM_PUBLIC_ID: "System & App",
    RISK_SYSTEM_APPS: "Systems/Apps",
    RISK_SYSTEM_NAME_PLACEHOLDER: "System & App Name",
    RISK_SYSTEM_DESCRIPTION_PLACEHOLDER: "System & App Definition",
    NEW_LIBRARY_SYSTEM_APP: "New Library System or Application",
    EDIT_LIBRARY_SYSTEM_APP: "Edit Library System or Application",
    NEW_SYSTEM_APP: "New System or Application",
    EDIT_SYSTEM_APP: "Edit System or Application",
    RISK_SYSTEM_TYPE: "System & App Type",
    RISK_DATA_LOCATION: "FI Data Location",
    RISK_VERSION_NUMBER: "Version Number",
    RISK_MANAGE_DETAILS: "Manage Details",
    SYSTEM_TYPE: "SA Type",
    SYSTEM_TYPE_SERVER: " ",
    SYSTEM_TYPE_CLIENT_SERVER: " ",
    SYSTEM_TYPE_HOSTED: " ",
    SYSTEM_TYPE_DESKTOP: " ",
    SYSTEM_TYPE_CLOUD: "Cloud",
    SYSTEM_TYPE_HYBRID: "Hybrid",
    SYSTEM_TYPE_ON_PREM: "On-Prem",
    RISK_ADD_MITIGATION_PLAN: "Add Mitigation Plan",
    RISK_MANAGE_MITIGATION_PLAN: "Manage Mitigation Plan",
    SYSTEM_DATA_SENSITIVITY: "Sensitivity of Data Assessment",
    SYSTEM_BUSINESS_CRITICALITY: "Criticality to Business Assessment",
    SYSTEM_PARAMETER_MGMT: "Parameter Risk Management",
    SYSTEM_ACCESS_MGMT: "Access Risk Management",
    SYSTEM_SECURITY_MGMT: "Security Risk Management",
    ADD_LINKED_SYSTEM: "Add System/Apps",
    RISK_ASSESSMENT_RISKS_DIFFERENT_COUNT: "{0} risk(s) have been added, {1} have been removed",
    RISK_ADD_ASSESSMENT: "Add Assessment",
    RISK_MANAGE_ASSESSMENT: "Manage Assessment",
    RISK_ADD_PARAMETER_MGMT_INFO: "Add Parameter Info",
    RISK_MANAGE_PARAMETER_MGMT_INFO: "Manage Parameter Info",
    RISK_ADD_ACCESS_MGMT_INFO: "Add Access Info",
    RISK_MANAGE_ACCESS_MGMT_INFO: "Manage Access Info",
    RISK_ADD_SECURITY_MGMT_INFO: "Add Security Info",
    RISK_MANAGE_SECURITY_MGMT_INFO: "Manage Security Info",
    RISK_MANAGE_INFO: "Manage Info",
    RISK_MANAGE_THREAT_TYPE: "Manage Threat Type",
    RISK_QUESTION_IS_IBS: "Is it an internet banking system?",
    RISK_QUESTION_HAS_CUSTOMER_NAMES: "Does data include customer/employee names?",
    RISK_QUESTION_HAS_TINS: "Does data include tax identification numbers (TINs)?",
    RISK_QUESTION_HAS_DRIVERS_LICENSE: "Does data include drivers licenses or other forms of identification?",
    RISK_QUESTION_HAS_ACCOUNT_NUMBERS: "Does data include account numbers?",
    RISK_QUESTION_HAS_CARD_NUMBERS: "Does data include debit or credit card numbers?",
    RISK_QUESTION_HAS_PASSWORDS: "Does data include passwords, codes, etc.?",
    RISK_QUESTION_HAS_BIOMETRIC_DATA: "Does data include biometric data?",
    RISK_QUESTION_DISCLOSURE_NEEDED: "Is GLBA/NYDFS Part 500 Disclosure Needed?",
    RISK_QUESTION_CONFIDENTIAL_DATA: "Does it contain confidential business data that could be damaging if disclosed to an unauthorized party?",
    RISK_QUESTION_VENDOR_REMOTE_ACCESS: "Does the vendor have remote access?",
    RISK_QUESTION_CYBER_RISKS: "Does it present cyber risk?",
    RISK_QUESTION_SECURITY_PARAMS: "Is it used to manage security parameters for a system?",
    RISK_QUESTION_MANAGE_PARAMS: "Does the FI manage parameters?",
    RISK_QUESTION_AUTOMATED_PARAMS_REVIEW: "Is the process for reviewing parameter changes automated?",
    RISK_QUESTION_SERVICE_MANY_CLIENTS: "Does the system/app service a material percentage of clients on a given day?",
    RISK_QUESTION_CAN_OPERATE_1_DAY: "Can the business operate for a day without the system or application?",
    RISK_QUESTION_CAN_OPERATE_1_WEEK: "Can the business operate for a week without the system or application?",
    RISK_QUESTION_CAN_OPERATE_FOREVER: "Can the business operate indefinitely without the system or application?",
    RISK_QUESTION_REGULATORY_SCRUTINY: "Could failure of the system or application result in regulatory scrutiny?",
    RISK_QUESTION_NEGATIVE_FINANCIAL: "Could failure of the system or application have a negative financial impact?",
    RISK_QUESTION_NEGATIVE_REPUTATION: "Could failure of the system or application have a negative impact on the business's reputation?",
    RISK_QUESTION_THREATEN_OBJECTIVES: "Could failure of the system or application threaten a strategic objective?",
    RISK_QUESTION_MONITORING_REQUIRED: "Administrative Monitoring Required?",
    RISK_QUESTION_REVIEW_REQUIRED: "User Access Review Required?",
    RISK_QUESTION_CENTRALIZED_CREDENTIALS: "Centralized Credentialing?",
    RISK_QUESTION_MFA_ENABLED: "MFA Enabled?",
    RISK_QUESTION_IP_RESTRICTED: "IP Restricted?",
    RISK_QUESTION_IS_ENCRYPTED: "Encrypted?",
    ASSESSMENT: "Assessment",
    RISK_AUTHORIZED_PEOPLE: "Authorized People",
    RISK_USER_ADMINISTRATORS: "User Administrators",
    RISK_SELECT_FROM_CUSTOM_LIST_RISK_USER_ADMINISTRATORS: "Add User Administrators",
    RISK_AUTHORIZED_PEOPLE_CHANGE_PARAMS: "People Authorized to change parameters",
    RISK_USER_ADMINISTRATORS_LABEL: "User Administrators (If not centralized)",
    RISK_ASSESSMENTS: "Risk Assessments",
    ASSESSMENTS: "Assessments",
    NEW_RISK_ASSESSMENT: "New Risk Assessment",
    MITIGATION_CONTROLS_AND_LINKS: "Controls and Links",
    RISK_MANAGEMENT_QUALITY: "Quality of Risk Management",
    RISK_DIRECTION: "Direction of Risk",
    ADD_AGGREGATE_RISK: "Add Aggregate Risk",
    EDIT_AGGREGATE_RISK: "Edit Aggregate Risk",
    AGGREGATE_RISK: "Aggregate Risk",
    AGGREGATE_RISK_MODAL_EXPLANATION: "All Scores and Executive Summary must be filled before this assessment can be completed and/or exported",
    AGGREGATE_RISK_MODAL_RECOMMENDATION_EXPLANATION: "We have recommended the following average scores for the set of risks on this assessment",
    AGGREGATE_RISK_NO_RECOMMENDATIONS_TOP: "Couldn't provide all recommended aggregate scores",
    AGGREGATE_RISK_NO_RECOMMENDATIONS_BOTTOM: " scores in this assessment's risks haven't been completed. Please complete all individual risk scores.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_LINKED_CONTROLS: "No Controls have been linked to this risk",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_LINKED_CONTROLS: "No Controls have been linked to this risk. Please link at least one control " +
        "and fill out their overall score to see Strength of Controls  recommendation.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_LINKED_CONTROL_OVERALL_SCORE: "Linked Controls’ overall score is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_LINKED_CONTROL_OVERALL_SCORE: "{0} Linked Controls do not have their Overall Score completed. " +
        "Please fill out this information to see Strength of Controls  recommendation.",
    RECOMMENDED_RISK_SCORE_WARNING_TITLE: "Couldn’t provide recommended score",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_LIKELIHOOD: "Likelihood is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_LIKELIHOOD: "Likelihood is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_POTENTIAL_IMPACT: "Potential Impact is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_POTENTIAL_IMPACT: "Potential Impact is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_LIKELIHOOD_AND_POTENTIAL_IMPACT: "Both Likelihood and Potential Impact is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_LIKELIHOOD_AND_POTENTIAL_IMPACT: "Both Likelihood and Potential Impact is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_INHERENT_RISK: "Inherent Risk is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_INHERENT_RISK: "Inherent Risk is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_CONTROL_STRENGTH: "Control Strength is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_CONTROL_STRENGTH: "Control Strength is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_INHERENT_RISK_AND_CONTROL_STRENGTH: "Both Inherent Risk and Control Strength is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_INHERENT_RISK_AND_CONTROL_STRENGTH: "Both Inherent Risk and Control Strength is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_DATA_SENSITIVITY: "Data Sensitivity is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_DATA_SENSITIVITY: "Data Sensitivity is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_BUSINESS_CRITICALITY: "Business Criticality is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_BUSINESS_CRITICALITY: "Business Criticality is required to provide recommendation. Please fill out this information.",
    RECOMMENDED_RISK_SCORES_WARNING_MISSING_DATA_SENSITIVITY_AND_BUSINESS_CRITICALITY: "Both Data Sensitivity and Business Criticality is required to provide recommendation",
    RECOMMENDED_RISK_SCORES_WARNING_EXPLANATION_MISSING_DATA_SENSITIVITY_AND_BUSINESS_CRITICALITY: "Both Data Sensitivity and Business Criticality is required to provide recommendation. Please fill out this information.",
    ERROR_GETTING_RECOMMENDED_RISK_SCORES: "An error occurred while getting recommended Risk scores",
    ERROR_GETTING_RECOMMENDED_RISK_SYSTEM_SCORES: "An error occurred while getting recommended System scores",
    LINKED_CONTROLS_LOADING_ERROR: "Couldn't load Linked Controls",
    RISK_LOADING_ERROR: "Couldn't load Risk",
    SYSTEM_LOADING_ERROR: "Couldn't load System",
    EXECUTIVE_SUMMARY: "Executive Summary",
    RISK_ASSESSMENT_SAVED: "Risk Assessment successfully saved",
    RISK_ASSESSMENT_RISKS_SAVED: "Risk Assessment's risks successfully saved",
    RISK_ASSESSMENT_MAKE_COMPLETE: "Complete & Export",
    RISK_ASSESSMENT_EXPORT: "Export",
    RISK_ASSESSMENT_EXPORT_NO_CONTROLS: "This risk assessment has no internal controls and cannot be exported in this format",
    RISK_ASSESSMENT_INCOMPLETE: "Aggregate Risk Scores and Executive Summary must be added to Complete assessment",
    EDIT_NAME: "Edit Name",
    LIKELIHOOD: "Likelihood",
    POTENTIAL_IMPACT: "Potential Impact",
    CONTROL_STRENGTH: "Strength of Controls",
    DESIGN_EFFECTIVENESS: "Design Effectiveness",
    OPERATING_EFFECTIVENESS: "Operating Effectiveness",
    OVERALL_SCORE: "Overall Score",
    DATA_SENSITIVITY: "Data Sensitivity",
    BUSINESS_CRITICALITY: "Business Criticality",
    MITIGATION_PLAN: "Mitigation Plan",
    RISK_EXISTING_CONTROL: "Existing Control",
    RISK_CONTROL_FROM_LIBRARY: "Control from Library",
    // Used for activity logs. Should match RiskLogType enum values from policy-service
    WATCHER_IDS: "Watchers",
    AUDIT: "Audit",
    EXTERNAL_POLICY: "External Policy",
    THREAT_DETAILS: "Threat Details",
    LINKED_RISKS: "Linked Risks",
    RELATED_RISK: "Related Risk",
    RISK_SCORE_NOTE_ACTIVITY_LOG: "Note: {0}",
    RISK_ALREADY_IN_REGISTER: "Already in register",
    RISK_ASSESSMENT_SPREADSHEET_EXPORT: "Export Risk Assessment",
    RISK_ASSESSMENT_CONTROL_EVIDENCE_EXPORT: "Export Control Evidence Report",
    RISK_CONTROL_ASSESSMENT_REPORT_NAME: "Risk Assessment Control Evidence Report",
    REPORT_BEING_GENERATED_MESSAGE: "Your report is being generated. This may take a few seconds.",
    REPORT_SUCCESS_MESSAGE: "Your report is complete and is being downloaded.",
    REPORT_ERROR_MESSAGE: "There has been an error generating your report.",
    SENSITIVITY_QUESTIONS: "Sensitivity Questions",
    SECURITY_QUESTIONS: "Security Questions",
    SECURITY_COMMENTS: "Security Comments",
    CRITICALITY_QUESTIONS: "Criticality Questions",
    PARAMETER_QUESTIONS: "Parameter Questions",
    PARAMETER_AUTH_PEOPLE: "Parameter Auth People",
    PARAMETER_COMMENTS: "Parameter Comments",
    ACCESS_QUESTIONS: "Access Questions",
    ACCESS_AUTH_PEOPLE: "Access Auth People",
    ACCESS_COMMENTS: "Access Comments",
    QUESTION_DOESNT_EXIST: "Question being answered doesn't exist",
    VENDOR: "Vendor",
    GO_LIVE_DATE: "Go Live Date",
    VERSION_NUMBER: "Version Number",
    DATA_LOCATION: "Data Location",
    MODULE_TYPE: "Module Type",
    RISK_REVIEW_SCHEDULE: "Review Schedule",
    RISK_SCHEDULE_ADD: "Add Review Schedule",
    CANNOT_CREATE_REVIEW_SCHEDULE_FOR_RISK_OBJECT_WITH_SCHEDULE: "Cannot create review schedule because this already has one",
    RISK_SCHEDULE_MANAGE: "Manage Review Schedule",
    RISK_SCHEDULE_REPEAT_EVERY: "Repeat Every",
    RISK_SCHEDULE_REVIEW_ON: "Review on",
    RISK_SCHEDULE_NEXT_REVIEW_BY: "Next Review by",
    RISK_SCHEDULE_NEXT_REVIEW: "Next Review",
    RISK_SCHEDULE_DAY_UNIT: "Day(s)",
    RISK_SCHEDULE_MONTH_UNIT: "Month(s)",
    RISK_SCHEDULE_YEAR_UNIT: "Year(s)",
    RISK_SCHEDULE_WATCHERS: "Watchers (Select at Least 1)",
    RISK_SCHEDULE_WATCHERS_INFO: "Watchers will be notified when a review is almost or past due",
    RISK_SCHEDULE_DUE_DATE_PLACEHOLDER: "yyyy-mm-dd",
    RISK_SCHEDULE_LOADING_ERROR: "An error occurred while loading a risk schedule",
    RISK_SCHEDULE_MISSING: "This does not have a review schedule",
    RISK_SCHEDULE_ADD_ERROR: "An error occurred while adding a risk schedule",
    RISK_SCHEDULE_EDIT_ERROR: "An error occurred while editing a risk schedule",
    RISK_SCHEDULE_DELETE_ERROR: "An error occurred while deleting a risk schedule",
    RISK_SCHEDULE_REVIEW_ERROR: "An error occurred while reviewing a risk schedule",
    RISK_SCHEDULE_DELETE: "Delete Review Schedule",
    RISK_SCHEDULE_DELETE_INFO: "Deleting a Review Schedule will remove all future instances of this schedule.",
    RISK_SCHEDULE_MARK_AS_REVIEWED: "Mark as Reviewed",
    RISK_SCHEDULE_MARKED_AS_REVIEWED: "Reviewed",
    RISK_SCHEDULE_LAST_REVIEWED_ON: "Last Reviewed on",
    RISK_SCHEDULE_REVIEWED: "Reviewed",
    RISK_SCHEDULE_NEEDS_MORE_TIME_UNTIL_REVIEW: "Some more time must pass before being able to review this schedule",
    RISK_SCHEDULE_YEARLY: "Yearly",
    RISK_SCHEDULE_MONTHLY: "Monthly",
    RISK_SCHEDULE_DAILY: "Daily",
    RISK_SCHEDULE_REVIEW_MODAL_TITLE: "Mark Review Schedule as Reviewed",
    RISK_SCHEDULE_REVIEW_MODAL_DESCRIPTION: "You are about to mark this schedule as reviewed. The next review will be due {0}.",
    RISK_SCHEDULE_ADDED: "Added Review Schedule",
    RISK_SCHEDULE_WATCHERS_FOR_REVIEW: "Watchers for Review",
    RISK_SCHEDULE_WATCHERS_FOR_REVIEW_ADDED: "Watchers for Review Added",
    RISK_SCHEDULE_WATCHERS_FOR_REVIEW_DELETED: "Watchers for Review Deleted",
    RISK_SCHEDULE_REPEAT: "Repeat {0}",
    RISK_SCHEDULE_REVIEW_SCHEDULED_FOR: "Review Scheduled for {0}",
    RISK_SCHEDULE_DELETED: "Deleted Review Schedule",
    RISK_SCHEDULE_EDITED: "Edited Review Schedule",
    RISK_SCHEDULE_MARKED_REVIEWED: "Marked as Reviewed",
    RISK_SCHEDULE_DUE_NEXT: "Next Review Scheduled for {0}",
    RISK_SCHEDULE_PAST_DUE: "past due",
    ACTIVE_RISK_SCHEDULE: "Review Schedule",

    //Ticket Similarity
    WITHIN_ORG: "Within this Organization",
    OUTSIDE_ORG: "Outside this Organization",
    VIEW_ALL_SIMILAR_INCIDENTS: "View All Similar Incidents",
    VERY_SIMILAR_INCIDENT: "Very Similar",
    SIMILAR_INCIDENT: "Similar",
    SOMEWHAT_SIMILAR_INCIDENT: "Somewhat Similar",
    USEFUL_QUESTION: "Useful?",
    MARKED_USEFUL: "Marked as useful",
    MARKED_UNUSEFUL: "Marked as unuseful",
    NO_SIMILAR_INCIDENTS: "No Similar Incidents to Display",
    INCIDENT_NAME: "NAME",
    INCIDENT_CREATED: "CREATED",
    INCIDENT_USEFUL: "USEFUL",
    DEFENSESTORM: "DefenseStorm",
    SIMILAR_INCIDENTS_TITLE: "Similar Incidents",
    INCIDENT_SORT_BY: "Sort by",
    INCIDENT_ORG_VIEW: "Org View",
    INCIDENT_USEFULNESS: "Useful",
    CLOSE_PREVIEW: "Close Preview",
    IS_INCIDENT_USEFUL: "Is this incident useful?",
    INCIDENT_PREVIEW: "Incident Preview",
    ALL_SIMILAR_INCIDENTS_RATED: "All Similar Incidents Have Been Rated",
    LOADING_SIMILAR_INCIDENTS_ERROR: "An error occurred while loading similar incidents",

    // Tags
    TAG_SECTION_HEADER: "Tags",
    TAG_ADD_TAGS: "Add tags",
    TAG_LIST_EMPTY: "There are currently no tags for your organization",
    TAG_TYPE_ITEM: "Type Tag Name",
    TAG_DUPLICATED_ITEM: "There is already a tag with that name",
    TAG_ADD_LIST: "Add Tags",
    TAG_MANAGE_TAGS: "Manage Tags",
    TAG_LIST_TITLE: "Tags",
    TAG_SEARCH_PLACEHOLDER: "Search a tag",
    NO_TAGS_MATCH_QUERY: "No Tags match your query",
    RESET_ALL_SELECTED_TAGS: "Reset all",
    INITIAL_RESULTS_LIST_TEXT: "Search items by tag to show results",
    NO_TAGGED_OBJECTS: "No items match the search criteria",
    TAG_SEARCH_ERROR: "An error occurred while searching for tags",

    // Activity Log
    TODAY_AT: "Today at",
    SHOW_ONLY_NOTES: "Show only notes",
    SHOW_ALL_ACTIVITY_LOGS: "Show all activity logs",
    GENERAL_AUDIT: "General Audit",
    UNKNOWN_LOG_TYPE: "Unknown log type. Log ID is: {0}",
    UNKNOWN_LOG_ACTION: "Unknown log action",
    ACTIVITY_LOG_RETRY_ERROR: "Couldn't retrieve new activity logs",
    ACTIVITY_LOG_ADD_TOP_LEVEL_NOTE: "Activity Log - Add Note",
    ACTIVITY_LOG_EDIT_TOP_LEVEL_NOTE: "Edit Note",
    ACTIVITY_LOG_NOTE_REPLY: "Add Reply",
    ACTIVITY_LOG_NOTE_EDIT_REPLY: "Edit Reply",
    CHANGED_TO: "Changed to",
    CHANGED_FROM: "Changed from",
    DEFAULT_CREATE_ACTIVITY_LOG_MESSAGE: "This resource was created before activity log functionality became supported, so past trigger changes may not all be reflected",
    LOG_TYPE: "Log Type",
    CREATE_TOP_LEVEL_NOTE: "Top Level Note",
    SUB_LEVEL_NOTE: "Sub Level Note",
    ARCHIVED_LOGS_START_HERE: "Previous Version",

    // Escalation
    ESCALATION: "Escalation",
    ESCALATION_DURING_BUSINESS_HOURS: "During Business Hours",
    ESCALATION_AFTER_BUSINESS_HOURS: "After Business Hours",
    OUT_OF_OFFICE: "Out of Office",
    OUT_OF_THE_OFFICE_NEXT: "Out of the Office Next",
    ADD_CONTACT_STEP: "Add Contact Step",
    ADD_FIRST_CONTACT_STEP: "Add First Contact Step",
    EDIT_CONTACT_STEP: "Edit Contact Step",
    ADD_BUSINESS_HOURS: "Add Business Hours",
    EDIT_BUSINESS_HOURS: "Edit Business Hours",
    SAVE_BUSINESS_HOURS: "Save Business Hours",
    BUSINESS_HOURS_SCHEDULE: "Business Hours Schedule",
    WEEKDAYS: "Weekdays",
    CUSTOM_SCHEDULE: "Custom Schedule",
    MONDAY_THRU_FRIDAY: "Mon - Fri",
    SELECT_GRID_USER: "Select existing GRID user",
    ADD_ESCALATION_USER: "Add Escalation User for this chart only",
    MAX_NUM_STEPS_REACHED: "You have reached the maximum number of contact steps",
    TODAYS_BUSINESS_HOURS: "Today",
    OUT_OF_BUSINESS_HOURS: "Out of Business Hours",
    UPDATE_PHONE_NUMBER_FROM_ESCALATION_STEP: "This number will be added to this user details in GRID",
    ESCALATION_INTERVAL_WARNING: "Please note: There will be a <strong>10 minute interval</strong> between all escalation steps. Thank you for your patience!",

    GENERIC_INVALID_FIELD_VALUE_ERROR_MESSAGE: "{1} is not a valid value for {0}",
    GENERIC_INVALID_FIELD_VALUE_EXPECTED_VALUES: "expected one of {0}",

    ERROR_ADDING_SEARCH_TO_TICKET: "Error occurred while adding Search to Ticket",
    ERROR_ADDING_FRAMEWORK_LINE_ITEM_COMMENT: "Error occurred while adding Framework Control Comment",
    ERROR_ADDING_CAT_LINE_ITEM_COMMENT: "Error occurred while adding CAT Control Comment",

    ERROR_CLAIMING_TICKET: "Error occurred while claiming Ticket",

    ERROR_CLOSING_TICKETS: "Error occurred while closing Tickets",

    ERROR_CREATING_ACTIVITY_LOG_NOTE: "Error occurred while creating Activity Log Note",
    ERROR_CREATING_AWS_INTEGRATION: "Error occurred while creating AWS Integration",
    ERROR_CREATING_CHART: "Error occurred while creating Chart",
    ERROR_CREATING_CHART_SERIES: "Error occurred while creating Chart Series",
    ERROR_CREATING_DASHBOARD: "Error occurred while creating Dashboard",
    ERROR_CREATING_FRAMEWORK: "Error occurred while creating Framework",
    ERROR_CREATING_POLICY: "Error occurred while creating Policy",
    ERROR_CREATING_POLICY_FILE: "Error occurred while attaching file to Policy",
    ERROR_CREATING_REPORT: "Error occurred while creating Report",
    ERROR_CREATING_REPORT_SCHEDULE: "Error occurred while creating Report Schedule",
    ERROR_CREATING_REPORT_TEMPLATE: "Error occurred while creating Report Template",
    ERROR_CREATING_RISK_ASSESSMENT: "Error occurred while creating Risk Assessment",
    ERROR_CREATING_RISK_FILE: "Error occurred while creating Risk File",
    ERROR_CREATING_TAGS: "Error occurred while creating Tags",
    ERROR_CREATING_TASK_SCHEDULE: "Error occurred while creating Task Schedule",
    ERROR_CREATING_TASK_SCHEDULE_GROUP: "Error occurred while creating Task Schedule Group",
    ERROR_CREATING_THREAT_SOURCE: "Error occurred while creating Threat Source",
    ERROR_CREATING_TICKET_NOTE: "Error occurred while creating Ticket Note",
    ERROR_CREATING_TOKEN: "Error occurred while creating Token",
    // ERROR_CREATING_TRIGGER: UNEXPECTED_TRIGGER_CREATION_ERROR,
    ERROR_CREATING_TRIGGER_GROUP: "Error occurred while creating Trigger Group",
    ERROR_CREATING_WATCHLIST: "Error occurred while creating Watchlist",

    ERROR_COPYING_DASHBOARDS: "Error occurred while copying Dashboards",
    ERROR_COPYING_RISKS: "Error occurred while copying Risks",
    ERROR_COPYING_INTERNAL_CONTROLS: "Error occurred while copying Internal Controls",
    ERROR_COPYING_RISK_SYSTEMS: "Error occurred while copying Risk Systems & Apps",
    ERROR_COPYING_TASK_SCHEDULES: "Error occurred while copying Task Schedules",
    ERROR_COPYING_TRIGGERS: "Error occurred while copying Triggers",

    ERROR_DELETING_AWS_INTEGRATION: "Error occurred while deleting AWS Integration",
    ERROR_DELETING_ASSETS: "Error occurred while deleting Assets",
    ERROR_DELETING_CHARTS: "Error occurred while deleting Charts",
    ERROR_DELETING_DASHBOARD: "Error occurred while deleting Dashboard",
    ERROR_DELETING_ESCALATION_STEPS: "Error occurred while deleting Escalation Steps",
    ERROR_DELETING_FRAMEWORK: "Error occurred while deleting Framework",
    ERROR_DELETING_FRAMEWORK_SECTION: "Error occurred while deleting Framework Section",
    ERROR_DELETING_FRAMEWORK_LINE_ITEM: "Error occurred while deleting Framework Control",
    ERROR_DELETING_FRAMEWORK_LINE_ITEM_FILE: "Error occurred while deleting Framework Control File",
    ERROR_DELETING_CAT_LINE_ITEM_FILE: "Error occurred while deleting CAT Control File",
    ERROR_DELETING_CUSTOM_PARSER: "Error occurred while deleting Custom Parser",
    ERROR_DELETING_LINE_ITEM: "Error occurred while deleting Control",
    ERROR_DELETING_PERCOLATOR_QUERY: "Error occurred while deleting Classifier",
    ERROR_DELETING_POLICY: "Error occurred while deleting Policy",
    ERROR_DELETING_POLICY_FILE: "Error occurred while deleting file from Policy",
    ERROR_DELETING_REPORT: "Error occurred while deleting Report",
    ERROR_DELETING_REPORT_SCHEDULE: "Error occurred while deleting Report Schedule",
    ERROR_DELETING_REPORT_TEMPLATE: "Error occurred while deleting Report Template",
    ERROR_DELETING_RISK_FILE: "Error occurred while deleting Risk File",
    ERROR_DELETING_SAVED_QUERY: "Error occurred while deleting Saved Query",
    ERROR_DELETING_TAGS: "Error occurred while deleting Tags",
    ERROR_DELETING_TASK_SCHEDULE: "Error occurred while deleting Task Schedule",
    ERROR_DELETING_TASK_SCHEDULES: "Error occurred while deleting Task Schedules",
    ERROR_DELETING_THREAT_SOURCE: "Error occurred while deleting Threat Source",
    ERROR_DELETING_TICKET_FILE: "Error occurred while deleting Ticket File",
    ERROR_DELETING_TICKET_SEARCH: "Error occurred while deleting Ticket Search",
    ERROR_DELETING_TOKEN: "Error occurred while deleting Token",
    ERROR_DELETING_TRIGGER: "Error occurred while deleting Trigger",
    ERROR_DELETING_TRIGGERS: "Error occurred while deleting Triggers",
    ERROR_DELETING_WATCHLIST: "Error occurred while deleting Watchlist",
    ERROR_DELETING_WATCHLIST_ITEM: "Error occurred while deleting Watchlist Item",
    ERROR_DELETING_DASHBOARD_WORKSPACE: "Error occurred while deleting Workspace",

    ERROR_DISABLING_CUSTOM_PARSER: "Error occurred while disabling Custom Parser",
    ERROR_DISABLING_PERCOLATOR_QUERY: "Error occurred while disabling Classifier",

    ERROR_DOWNLOADING_RISK_SYSTEMS: "Error occurred while downloading Risk Systems & Apps",

    ERROR_EDITING_ACTIVITY_LOG_NOTE: "Error occurred while editing Activity Log Note",
    ERROR_EDITING_CHART_SERIES: "Error occurred while editing Chart Series",
    ERROR_EDITING_REPORT_SCHEDULE: "Error occurred while editing Report Schedule",
    ERROR_EDITING_REPORT_TEMPLATE: "Error occurred while editing Report Template",
    ERROR_EDITING_TICKET_LOG_NOTE: "Error occurred while editing Ticket Log Note",

    ERROR_ENABLING_CUSTOM_PARSER: "Error occurred while enabling Custom Parser",
    ERROR_ENABLING_PERCOLATOR_QUERY: "Error occurred while enabling Classifier",

    ERROR_INITIALIZING_REVEAL: "Error occurred while initializing Reporting System",

    ERROR_LINKING_TASK_SCHEDULE_TO_CONTROL: "Error occurred while linking Task Schedule to Control",

    ERROR_LOADING_ACTIVITY_LOGS: "Error occurred while loading Activity Logs",
    ERROR_LOADING_ALERT_PAGE_FOR_TICKET: "Error occurred while loading Alerts for Ticket",
    ERROR_LOADING_ALERT: "Error occurred while loading Alert",
    ERROR_LOADING_ALERTS: "Error occurred while loading Alerts",
    ERROR_LOADING_ALERTS_FOR_NAVIGATION: "Error occured while loading Alerts for navigation",
    ERROR_LOADING_APITURE_INTEGRATION: "Error occurred while loading Apiture Integration",
    ERROR_LOADING_ALERT_LABELS: "Error occurred while loading Task Schedule Tags",
    ERROR_LOADING_ASSET_CONFIG: "Error occurred while loading Asset Settings",
    ERROR_LOADING_ASSETS: "Error occurred while loading Assets",
    ERROR_LOADING_ASSET_LABELS: "Error occurred while loading Tags",
    ERROR_LOADING_AUDIT_COVERAGES: "Error occurred while loading Audit Coverages",
    ERROR_LOADING_AWS_ACCOUNTS: "Error occurred while loading Amazon Web Services Accounts",
    ERROR_LOADING_AVERAGE_DAILY_MATCHES: "Error occurred while loading average daily matches",
    ERROR_LOADING_BUSINESS_UNITS: "Error occurred while loading Business Units",
    ERROR_LOADING_CAT_POLICIES: "Error occurred while loading CAT Policies",
    ERROR_LOADING_CAT_RISK_PROFILE_QUESTIONS: "Error occurred while loading CAT Risk Profile Questions",
    ERROR_LOADING_CHARTS: "Error occurred while loading Charts",
    ERROR_LOADING_CHART_REFERENCES: "Error occurred while loading Chart references",
    ERROR_LOADING_CONTROLS_LINKED_TO_RISKS: "Error occurred while loading Controls linked to Risks",
    ERROR_LOADING_CUSTOM_PARSER_APP_NAMES: "Error occurred while loading valid application names",
    ERROR_LOADING_CUSTOM_PARSERS: "Error occurred while loading Custom Parsers",
    ERROR_LOADING_CUSTOMER_RESTORES: "Error occurred while loading Restores",
    ERROR_LOADING_CYBER_RISK_READINESS_SCORES: "Error occurred while loading Cyber Risk Readiness Scores",
    ERROR_LOADING_DASHBOARD: "Error occurred while loading Dashboard",
    ERROR_LOADING_DASHBOARDS: "Error occurred while loading Dashboards",
    ERROR_LOADING_DATA_RESTRICTION: "Error occurred while loading Data Restriction",
    ERROR_LOADING_ESCALATION_SCHEDULE: "Error occurred while loading Business Hours Schedule",
    ERROR_LOADING_ESCALATION_STEPS: "Error occurred while loading Escalation Steps",
    ERROR_LOADING_EXTERNAL_POLICIES: "Error occurred while loading External Policies",
    ERROR_LOADING_FIELDS: "Error occurred while loading Field Names",
    ERROR_LOADING_FRAMEWORK: "Error occurred while loading Framework",
    ERROR_LOADING_FRAMEWORKS: "Error occurred while loading Frameworks",
    ERROR_LOADING_INCIDENTS: "Error occurred while loading Incidents",
    ERROR_LOADING_INFO_BANNERS: "Error occurred while loading Info Banners",
    ERROR_LOADING_INTERNAL_CONTROL: "Error occurred while loading Internal Control",
    ERROR_LOADING_INTERNAL_CONTROLS: "Error occurred while loading Internal Controls",
    ERROR_LOADING_LAST_SEEN_DATES: "Error occurred while loading Last Seen Dates",
    ERROR_LOADING_LINE_ITEMS: "Error occurred while loading Controls",
    // ERROR_LOADING_LINKED_CONTROLS: LINKED_CONTROLS_LOADING_ERROR,
    ERROR_LOADING_LINKED_CONTROL_SUGGESTIONS: "Error occurred while loading Control suggestions",
    ERROR_LOADING_LINKED_RISKS: "Error occurred while loading linked Risks",
    ERROR_LOADING_LINKED_RISK_SUGGESTIONS: "Error occurred while loading Risk suggestions",
    ERROR_LOADING_LINKED_SYSTEMS: "Error occurred while loading linked Systems & Apps",
    ERROR_LOADING_LINKED_SYSTEM_SUGGESTIONS: "Error occurred while loading System & App suggestions",
    ERROR_LOADING_PERCOLATOR_QUERIES: "Error occurred while loading Classifiers",
    ERROR_LOADING_POLICIES: "Error occurred while loading Policies",
    ERROR_LOADING_POLICY: "Error occurred while loading Policy",
    ERROR_LOADING_POLICY_CONFIG: "Error occurred while loading current Policy Configuration",
    ERROR_LOADING_POLICY_EVIDENCE_COMMENT: "Error occurred while loading Policy Evidence Comment",
    ERROR_LOADING_POLICY_VERSIONS: "Error occurred while loading Policy Versions",
    ERROR_LOADING_REPORT: "Error occurred while loading Report",
    ERROR_LOADING_REPORTS: "Error occurred while loading Reports",
    ERROR_LOADING_REPORT_TEMPLATE: "Error occurred while loading Report Template",
    ERROR_LOADING_REPORT_TEMPLATES: "Error occurred while loading Report Templates",
    ERROR_LOADING_RELATED_RISKS: "Error occurred while loading related Risks",
    ERROR_LOADING_RISK: "Error occurred while loading Risk",
    ERROR_LOADING_RISK_ASSESSMENT_RISKS: "Error occurred while loading Risks for Risk Assessment",
    ERROR_LOADING_RISK_FILES: "Error occurred while loading Risk Files",
    ERROR_LOADING_RISK_OWNERS: "Error occurred while loading Risk Owners",
    ERROR_LOADING_RISK_SUGGESTIONS: "Error occurred while loading Risk suggestions",
    ERROR_LOADING_RISK_SYSTEM: "Error occurred while loading Risk System",
    ERROR_LOADING_RISK_SYSTEMS: "Error occurred while loading Risk Systems & Apps",
    ERROR_LOADING_RISK_SYSTEM_RISK_SCORES: "Error occurred while loading contextual Operational Risk scores",
    ERROR_LOADING_RISK_SCORE_HISTORY_LOG: "Error occurred while loading Risk Score History",
    ERROR_LOADING_RISKS: "Error occurred while loading Risks",
    ERROR_LOADING_RISKS_AND_CONTROLS: "Error occurred while loading linked Risks and Controls",
    ERROR_LOADING_SAVED_QUERY: "Error occurred while loading Saved Query",
    ERROR_LOADING_SAVED_QUERY_REFERENCES: "Error occurred while loading Saved Query references",
    ERROR_LOADING_SEARCH_GRAPH: "Error occurred while loading Search Graph",
    ERROR_LOADING_SEARCH_RANGE: "Error occurred while loading Search Results",
    ERROR_LOADING_SOFTWARE: "Error occurred while loading Software",
    ERROR_LOADING_SORTABLE_FIELDS: "Error occurred while loading Fields",
    ERROR_LOADING_SW_NAMES: "Error occurred while loading Software Names",
    ERROR_LOADING_TAGS: "Error occurred while loading Tags",
    ERROR_LOADING_TICKET_LOGS: "Error occurred while loading Ticket Logs",
    // ERROR_LOADING_TICKET: FAILED_TO_LOAD_TICKET,
    ERROR_LOADING_TICKETS: "Error occurred while loading Tickets.",
    ERROR_LOADING_TASK_SCHEDULE: "Error occurred while loading Task Schedule",
    ERROR_LOADING_TASK_SCHEDULES: "Error occurred while loading Task Schedules",
    ERROR_LOADING_TASK_SCHEDULE_SUGGESTIONS_FOR_CONTROL: "Error occurred while loading Task Schedule suggestions",
    ERROR_LOADING_TASK_SCHEDULE_COUNTS: "An error occurred while loading Task Schedule counts",
    ERROR_LOADING_TASKS: "Error occurred while loading Tasks",
    ERROR_LOADING_THREAT_GROUP_INFO: "Error occurred while loading Threat Groups",
    ERROR_LOADING_THREAT_MATCH_CONFIG: "Error occurred while loading Threat Match Configuration",
    // ERROR_LOADING_TRIGGER: FAILED_TO_RETRIEVE_TRIGGER,
    ERROR_LOADING_TASK_SCHEDULE_FILES: "Error occurred while loading Task Schedule Files",
    ERROR_CREATING_TASK_SCHEDULE_FILE: "Error occurred while creating Task Schedule File",
    ERROR_DELETING_TASK_SCHEDULE_FILE: "Error occurred while deleting Task Schedule File",
    ERROR_LOADING_TOKENS: "Error occurred while loading Tokens",
    ERROR_LOADING_TOP_AGGREGATIONS: "Error occurred while loading Aggregations",
    ERROR_LOADING_TRIGGERS: "Error occurred while loading Triggers",
    ERROR_LOADING_TRIGGERS_AND_POLICIES: "Error occurred while loading Triggers and Policies",
    ERROR_LOADING_TRIGGER_STATS: "Error occurred while loading Trigger Stats",
    ERROR_LOADING_TRIGGER_GRAPH_STATS: "Error occurred while loading Trigger Graph information",
    ERROR_LOADING_TRIGGER_SCHEDULE: "Error occurred while loading Trigger Schedule",
    ERROR_LOADING_TRIGGER_SCHEDULES: "Error occurred while loading Trigger Schedules",
    ERROR_LOADING_USER_DEFINED_LIST: "Error occurred while loading Custom List",
    ERROR_LOADING_DASHBOARD_WORKSPACES: "Error occurred while loading Workspaces",
    ERROR_LOADING_1D_SEARCH_AGGREGATION: "Error occurred while loading one-dimensional Search Aggregation",
    ERROR_LOADING_S3_BUCKETS: "Error occurred while loading S3 Log Sources",
    ERROR_LOADING_UEBA_RISK_DETAILS: "An error occurred while loading UEBA Risk Details",

    ERROR_LOGGING_IN_TO_KNOWLEDGE_BASE: "Error occurred while logging in to Knowledge Base",

    ERROR_MERGING_ASSETS: "Error occurred while merging Assets",
    ERROR_MERGING_TICKETS: "Error occurred while merging Tickets",

    ERROR_PAUSING_REPORT_SCHEDULE: "Error occurred while pausing Report Schedule",
    ERROR_PAUSING_TASK_SCHEDULE: "Error occurred while pausing Task Schedule",
    ERROR_PAUSING_TRIGGER: "Error occurred while pausing Trigger",

    ERROR_RATING_THREAT_HOST: "Error occurred while rating Host",
    ERROR_RATING_THREAT_SIGNATURE: "Error occurred while rating Signature",

    ERROR_REORDERING_ESCALATION_STEPS: "Error occurred while reordering Escalation Steps",

    ERROR_RESTORING_DASHBOARD: "Error occurred while restoring Dashboard",
    ERROR_RESTORING_SAVED_QUERY: "Error occurred while restoring Saved Query",
    ERROR_RESTORING_PERCOLATOR_QUERY: "Error occurred while restoring Classifier",
    ERROR_RESTORING_POLICY: "Error occurred while restoring Policy",
    ERROR_RESTORING_REPORT: "Error occurred while restoring Report",
    ERROR_RESTORING_REPORT_TEMPLATE: "Error occurred while restoring Report Template",
    ERROR_RESTORING_TASK_SCHEDULE: "Error occurred while restoring Task Schedule",
    ERROR_RESTORING_TRIGGER: "Error occurred while restoring Trigger",
    ERROR_RESTORING_THREAT_SOURCE: "Error occurred while restoring Threat Source",
    ERROR_RESTORING_CHART: "Error occurred while restoring Chart",

    ERROR_RESUMING_REPORT_SCHEDULE: "Error occurred while resuming Report Schedule",
    ERROR_RESUMING_TASK_SCHEDULE: "Error occurred while resuming Task Schedule",
    ERROR_RESUMING_TRIGGER: "Error occurred while resuming Trigger",

    ERROR_REQUESTING_FRAMEWORK_CONTROL_REPORT: "Error occurred while requesting Framework Control Assessment Report",
    ERROR_REQUESTING_FRAMEWORK_SPREADSHEET_REPORT: "Error occurred while requesting Framework Spreadsheet Report",
    ERROR_REQUESTING_RISK_ASSESSMENT_CONTROL_REPORT: "Error occurred while requesting Risk Assessment Control Report",
    ERROR_REQUESTING_RISK_ASSESSMENT_REPORT: "Error occurred while requesting Risk Assessment Report",

    ERROR_SAVING_ASSET: "Error occurred while saving Asset",
    ERROR_SAVING_CAT_LINE_ITEM_EVIDENCE: "Error occurred while saving CAT Control Evidence",
    ERROR_SAVING_CLASSIFIER: "Error occurred while saving Classifier",
    ERROR_SAVING_CUSTOM_PARSER: "Error occurred while saving Custom Parser",
    ERROR_SAVING_DASHBOARD: "Error occurred while saving Dashboard",
    ERROR_SAVING_ESCALATION_SCHEDULE: "Error occurred while saving Business Hours Schedule",
    ERROR_SAVING_ESCALATION_STEP: "Error occurred while saving Escalation Step",
    ERROR_SAVING_FRAMEWORK_SECTION: "Error occurred while saving Framework Section",
    ERROR_SAVING_INTERNAL_CONTROL: "Error occurred while saving Internal Control",
    ERROR_SAVING_LIBRARY_TASK_SCHEDULE: "Error occurred while saving Library Task Schedule",
    ERROR_SAVING_LINE_ITEMS: "Error occurred while saving Controls",
    ERROR_SAVING_POLICY: "Error occurred while saving Policy",
    ERROR_SAVING_REPORT: "Error occurred while saving Report",
    ERROR_SAVING_RISK: "Error occurred while saving Risk",
    ERROR_SAVING_RISK_SYSTEM: "Error occurred while saving Risk System",
    ERROR_SAVING_SAVED_QUERY: "Error occurred while saving Saved Query",
    ERROR_SAVING_TASK_SCHEDULE: "Error occurred while saving Task Schedule",
    ERROR_SAVING_TICKET: "Error occurred while saving Ticket",
    ERROR_SAVING_TRIGGER: "Error occurred while saving Trigger",
    ERROR_SAVING_DASHBOARD_WORKSPACE: "Error occurred while saving Workspace",
    ERROR_SAVING_WATCHLIST: "Error occurred while saving Watchlist",
    ERROR_SAVING_WATCHLIST_EXCLUSION: "Error occurred while saving Watchlist Exclusion",

    ERROR_SENDING_FORGOT_PASSWORD_EMAIL: "Error occurred while sending password-reset email",

    ERROR_SETTING_UP_TWO_FACTOR: "Error occurred while setting up two-factor authentication",

    ERROR_UNRATING_THREAT_HOST: "Error occurred while removing rating from Host",
    ERROR_UNRATING_THREAT_SIGNATURE: "Error occurred while removing rating from Signature",

    ERROR_UPDATING_ASSET_CONFIG: "Error occurred while updating Asset Settings",
    ERROR_UPDATING_CAT_EVALUATION_VERSION: "Error occurred while finalizing CAT Evaluation Period",
    ERROR_UPDATING_CAT_EVALUATION_PERIOD: "Error occurred while updating CAT Evaluation Period",
    ERROR_UPDATING_CAT_RISK_PROFILE_ANSWER: "Error occurred while updating CAT Risk Profile Answer",
    ERROR_UPDATING_CHART: "Error occurred while updating Chart",
    ERROR_UPDATING_FRAMEWORK: "Error occurred while updating Framework",
    ERROR_UPDATING_FRAMEWORK_LINE_ITEMS_ANSWERS: "Error occurred while saving Framework Control Answers",
    ERROR_UPDATING_INTERNAL_CONTROL: "Error occurred while updating Internal Control",
    ERROR_UPDATING_LINE_ITEM: "Error occurred while updating Control",
    ERROR_UPDATING_LINE_ITEMS_ANSWERS: "Error occurred while updating Control Answers",
    ERROR_UPDATING_LINKED_CONTROLS: "Error occurred while updating linked Controls",
    ERROR_UPDATING_LINKED_RISKS: "Error occurred while updating linked Risks",
    ERROR_UPDATING_LINKED_SYSTEMS: "Error occurred while updating linked Systems & Apps",
    ERROR_UPDATING_PERSON: "Error occurred while updating Person",
    ERROR_UPDATING_POLICY: "Error occurred while updating Policy",
    ERROR_UPDATING_POLICY_CONFIG: "Error occurred while updating Policy Configuration",
    ERROR_UPDATING_RELATED_RISKS: "Error occurred while updating related Risks",
    ERROR_UPDATING_REPORT_VERSION: "Error occurred while updating Report Version",
    ERROR_UPDATING_RISK: "Error occurred while updating Risk",
    ERROR_UPDATING_RISK_ASSESSMENT: "Error occurred while updating Risk Assessment",
    ERROR_UPDATING_RISK_SYSTEM: "Error occurred while updating Risk System/App  ",
    ERROR_UPDATING_TASK_SCHEDULE: "Error occurred while updating Task Schedule",
    ERROR_UPDATING_TASK_SCHEDULE_RISK_LINKS: "Error occurred while updating linked Task Schedules",
    ERROR_UPDATING_TICKET: "Error occurred while updating Ticket",
    ERROR_UPDATING_TICKET_SEARCH: "Error occurred while updating Ticket Search",
    ERROR_UPDATING_TICKET_TEMPLATE: "Error occurred while updating Ticket Template",
    ERROR_UPDATING_TOKEN: "Error occurred while updating Token",
    ERROR_UPDATING_TRIGGER: "Error occurred while updating Trigger",
    ERROR_UPDATING_THREAT_MATCH_CONFIG: "Error occurred while updating Threat Match Configuration",
    ERROR_UPDATING_USER_DEFINED_LIST: "Error occurred while updating Custom List",
    ERROR_UPDATING_WATCHLIST: "Error occurred while updating Watchlist",
    ERROR_UPDATING_WATCHLIST_ITEMS: "Error occurred while updating Indicators",
    ERROR_BULK_EDITING_PRIMARY_COPIES: "Error occurred while bulk editing primary copies",

    ERROR_UPLOADING_FILE: "Error occurred while uploading File",
    ERROR_UPLOADING_REPORT: "Error occurred while uploading Report",

    ERROR_VALIDATING_WATCHLIST_ITEM: "Error occurred while trying to validate Indicator",
    ERROR_LOADING_INFO_NOTES: "Error occurred while loading notes",
    ERROR_ADDING_INFO_NOTE: "Error occurred while adding a note",
    ERROR_EDITING_INFO_NOTE: "Error occurred while editing a note",
    ERROR_DELETING_INFO_NOTE: "Error occurred while deleting a note",
    ERROR_LOADING_ORG_METADATA: "Error occurred while loading org metadata",
    ERROR_EDITING_ORG_METADATA: "Error occurred while editing org metadata",

    // Info Panel
    TYPE_A_NOTE: "Type a note",
    SEND: "Send",
    INFO_PANEL_FOR: "Info Panel For {0}",
    PIN_TO_TOP: "Pin to top",
    UNPIN_FROM_TOP: "Unpin from top",
    INFO_PANEL_TIME_EXPLANATION: "This date and time is displayed this org's timezone: {0}. The time for each note is shown in your timezone.",
    THREAT_LEVEL_ONE: "Active Incident",
    THREAT_LEVEL_TWO: "Suspected Activity",
    THREAT_LEVEL_THREE: "Added Information",
    THREAT_LEVEL_FOUR: "All Clear",

    // Actions
    ERROR_LOADING_ACTION_INTEGRATIONS: "Error occurred while loading Action Integrations",
    ERROR_CREATING_ACTION_INTEGRATION: "Error occurred while creating Action Integration",
    ERROR_UPDATING_ACTION_INTEGRATION: "Error occurred while updating Action Integration",
    ERROR_DELETING_ACTION_INTEGRATION: "Error occurred while deleting Action Integration",
    NO_ACTION_INTEGRATIONS: "You have not added any Action Integrations yet",
    NEW_ACTION_INTEGRATION: "New Action Integration",
    EDIT_ACTION_INTEGRATION: "Edit Action Integration",
    ACTION_INTEGRATION_PROVIDER: "Provider",
    ACTION_INTEGRATION_URL: "API Base URL",
    ACTION_INTEGRATION_AUTH_URL: "Authentication Base URL",
    ACTION_INTEGRATION_CLIENT_ID: "Client ID",
    ACTION_INTEGRATION_CLIENT_SECRET: "Client Secret",
    Q2: "Q2 Online Banking",
    DELETE_ACTION_INTEGRATION: "Delete Action Integration",
    DELETE_ACTION_INTEGRATION_WARNING: "Deleting this action integration will also delete all associated actions. Do you want to proceed?",
    ERROR_LOADING_ACTIONS: "Error occurred while loading Actions",
    DELETE_ACTION: "Delete Action",
    DELETE_ACTION_WARNING: "Are you sure you want to delete this action?",
    ERROR_DELETING_ACTION: "Error occurred while deleting Action",
    DELETE_ACTION_FIELD: "Delete Action Field",
    DELETE_ACTION_FIELD_WARNING: "Are you sure you want to delete this action field?",
    ERROR_DELETING_ACTION_FIELD: "Error occurred while deleting Action Field",
    ACTION_TYPE: "Action Type",
    ACTION_FIELDS: "Action Fields",
    MULTIPLE_FIELD_VALUES_OPTION: "Multiple Field Values Handling",
    ADD_ACTIONS: "Add Actions",
    ADD_ACTION_FIELDS: "Add Action Fields",
    MAX_ACTION_FIELDS_REACHED: "Maximum number of action fields reached.",
    MULTIPLE_FIELD_VALUES_OPTION_EXPLANATION: "If multiple values are detected across any Action Fields, which values should the Action use?",
    FIRST: "First",
    SEND_ALL_VALUES: "Send all values detected, one at a time",
    SEND_FIRST_VALUE: "Send first value detected in the first field specified",
    SEND_NO_VALUES: "Don't send any values and skip running the action",
    ERROR_CREATING_ACTION: "Error occurred while creating Action",
    ERROR_UPDATING_ACTION: "Error occurred while updating Action",
    ERROR_LOADING_ACTION_PROVIDERS: "Error occurred while loading available Action Providers",
    Q2_FREEZE_USER: "Q2 - Disable Logon",
    SYMX_ADD_WARNING_CODE: "SymXchange - Add Warning Code",
    PERM_ACTIONS_NAME: "Actions",
    PERM_ACTIONS_DESCRIPTION: "Allows users to setup Action Integrations and setup Actions on Triggers",
    ERROR_LOADING_ACTION_COUNTS: "Error occurred while loading Action Counts",

    // Ad Detector
    AD_BLOCK_NOTIFICATION: "It seems like you're using Adblock, which prevents us from collecting data on how you're using the console. To help us make our platform better and improve our user experience, please consider disabling Adblock!"
};
