import { LocalizationKey } from "@app2/shared/localization/default-strings";
import { localization } from "@app2/shared/localization/localization";
import { HandleableError } from "@app2/util/errors/handleable-errors";

export interface ValidatorError {
    dsErrorType: "ValidatorException";
    error: string; // a non-localized message generated by validateSomething calls
    errorId: uuid;
    status: number;
}

export function isValidatorError(err: HandleableError): err is ValidatorError {
    return err.dsErrorType === "ValidatorException";
}

export function formatValidatorError(err: ValidatorError, defaultMessageKey: LocalizationKey): string {
    return `${localization.getString(defaultMessageKey)}: ${err.error}`;
}
