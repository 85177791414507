import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, } from "@angular/material/snack-bar";
import { NotificationMessageComponent } from "@app2/shared/components/notifications/notification-message.component";
import { localization } from "@app2/shared/localization/localization";
import { ComponentType } from "@angular/cdk/portal";
import { ErrorTrackingService } from "@app2/shared/services/error-tracking.service";
import { log } from "@app2/logger";

export const DEFAULT_NOTIFICATION_TIMEOUT_MS = 10 * 1000;
export type NotificationType = "alert" | "warning" | "info" | "success" | "other";

/**
 * Usage:
 *
 * Basic usage:
 *     - Localize the given key:
 *       this.notificationsService.showSuccess("ALL_TRIGGERS_DISABLED")
 *
 *     - Localize the given key with some parameters:
 *       this.notificationsService.showSuccess("BULK_CLOSE_TICKETS_ALL_SUCCEEDED", someVar);
 *
 *     - Localize the given key, and pass an error variable that will be automatically logged:
 *       this.notificationsService.showError("THREAT_INDICATORS_UPLOAD_ERROR", error)
 *
 * Usage with custom clickable button:
 *
 *     this.notificationsService.showMessage(localization.getString("QUERY_ADDED"), "success", "GO_TO_TICKET")
 *         .afterDismissed()
 *         .subscribe(({ dismissedByAction }) => console.log("Was it dismissed by click in the button?", dismissedByAction));
 *
 * Usage with custom content:
 *
 *     this.notificationsService.showComponent(MyCustomNotificationComponent, {
 *         param1: value1,
 *         param2: value2,
 *     });
 */
@Injectable({
    providedIn: "root",
})
export class NotificationsService {

    constructor(private errorTrackingService: ErrorTrackingService,
                private snackBar: MatSnackBar) {
    }

    public showInfo(key: string, ...args: any[]) {
        this.showMessage(localization.getString(key, ...args), "info");
    }

    public showError(key: string, cause?: any, ...args: any[]) {
        const message = localization.getString(key, ...args);
        this.showMessage(message, "alert");
        if (cause) {
            log.error("Error occurred", cause);
        }
        this.errorTrackingService.sendError(message, { error: cause }, false);
    }

    public showSuccess(key: string, ...args: any[]) {
        this.showMessage(localization.getString(key, ...args), "success");
    }

    public showWarning(key: string, ...args: any[]) {
        this.showMessage(localization.getString(key, ...args), "warning");
    }

    public showOther(key: string, iconName: string, ...args: any[]) {
        this.showMessage(localization.getString(key, ...args), "other", null, iconName);
    }

    public showMessage(message: string, type: NotificationType, action?: string, iconName?: string): MatSnackBarRef<NotificationMessageComponent> {
        return this.showComponent(NotificationMessageComponent, { message, type, action, iconName });
    }

    public showComponent<T>(component: ComponentType<T>, data: any, config: MatSnackBarConfig = {}): MatSnackBarRef<T> {
        return this.snackBar.openFromComponent(component, {
            data,
            duration: DEFAULT_NOTIFICATION_TIMEOUT_MS,
            horizontalPosition: "center",
            verticalPosition: "bottom",
            ...config,
        });
    }

    public showComponentIndefinitely<T>(component: ComponentType<T>, data: any): MatSnackBarRef<T> {
        return this.snackBar.openFromComponent(component, {
            data,
            horizontalPosition: "center",
            verticalPosition: "bottom",
        });
    }
}
