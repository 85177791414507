import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { LastSeen } from "@app2/asset/asset-constants";
import { AssetSearchParams } from "@app2/asset/asset-search-params";
import { RestClient } from "@app2/clients/rest-client";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { TimeService } from "@app2/shared/services/time.service";
import {
    Asset,
    AssetConfig,
    AssetLabelsWrapper,
    AssetWrapper,
    Software,
    SoftwareWrapper,
} from "@app2/type-defs/assets/assets-types";

@Injectable({
    providedIn: "root",
})
export class AssetClientService {
    private assetClient: RestClient;
    private assetClientNoOrg: RestClient;

    constructor(private timeService: TimeService,
                httpClient: HttpClient,
                envService: EnvironmentService,
                orgsService: OrgsService) {
        const baseUrl = envService.getConfig().assetService;

        this.assetClient = new RestClient(baseUrl, httpClient, orgsService);
        this.assetClientNoOrg = new RestClient(baseUrl, httpClient);
    }

    public deleteByIds(assetIds: uuid[]): Promise<number> {
        const params = {
            assetId: assetIds,
        };
        return this.assetClient.doDelete("/assets", params);
    }

    public deleteBySearch(searchParams: AssetSearchParams): Promise<number> {
        const params = this.searchParamsToRestParams(searchParams);
        return this.assetClient.doDelete("/assets/search", params);
    }

    public downloadCsv(searchParams: AssetSearchParams) {
        const params = this.searchParamsToRestParams(searchParams);
        return this.assetClient.downloadCsvPost("/assets/search", undefined, params);
    }

    public getAssetConfig(): Promise<AssetConfig> {
        return this.assetClient.doGet<AssetConfig>("/config");
    }

    public getAssetSearch(searchParams: AssetSearchParams): Promise<AssetWrapper> {
        const params = this.searchParamsToRestParams(searchParams);
        return this.assetClient.doGet("/assets/search", params);
    }

    public createAsset(asset: Asset): Promise<Asset> {
        return this.assetClient.doPost("/asset", asset);
    }

    public updateAsset(asset: Asset): Promise<Asset> {
        return this.assetClient.doPut(`/asset/${ asset.id }`, asset);
    }

    public getLabels(): Promise<string[]> {
        return this.assetClient.doGet<AssetLabelsWrapper | string[]>("/labels")
            .then(result => (result as AssetLabelsWrapper).labels || (result as string[]));
    }

    public getSoftwareNames(): Promise<string[]> {
        return this.assetClient.doGet<string[]>("/software");
    }

    public updateAssetConfig(config: AssetConfig): Promise<AssetConfig> {
        return this.assetClient.doPost("/config", config);
    }

    public getTransformedAssetTagQuery(queryString: string): Promise<string> {
        return this.assetClient.doRequestText("GET", "/asset-tag-query", undefined, { query: queryString });
    }

    private getLastSeenBounds(lastSeen: LastSeen) {
        const thirtyDaysAgo = this.timeService.now().subtract(30, "days").toISOString();
        const before = lastSeen === "more-than-30-days" ? thirtyDaysAgo : undefined;
        const after = lastSeen === "last-30-days" ? thirtyDaysAgo : undefined;
        return { before, after };
    }

    private searchParamsToRestParams(searchParams: AssetSearchParams) {
        const lastSeen = this.getLastSeenBounds(searchParams.lastSeen);
        return {
            pageSize: searchParams.pageSize,
            sortBy: searchParams.sort.field,
            desc: searchParams.sort.desc,
            label: searchParams.labels,
            softwareNames: searchParams.softwareNames,
            tracked: searchParams.tracked,
            active: searchParams.status === "active",
            query: searchParams.query,
            pageId: searchParams.pageNumber - 1,
            importance: searchParams.importance,
            lastSeenBefore: lastSeen.before,
            lastSeenAfter: lastSeen.after,
        };
    }

    public getAssetsForIp(ipAddress: string, timestamp?: string): Promise<Asset[]> {
        const resource = "/ip/assets";
        const params = {
            address: ipAddress,
            timestamp: timestamp || undefined,
        };
        return this.assetClient.doGet(resource, params)
            .then(result => (result as AssetWrapper).assets);
    }

    public getAssetsForMac(macAddress: string, timestamp?: string): Promise<Asset[]> {
        const resource = "/mac/assets";
        const params = {
            address: macAddress,
            timestamp: timestamp || undefined,
        };
        return this.assetClient.doGet(resource, params)
            .then(result => (result as AssetWrapper).assets);
    }

    public getAssetsForHostname(hostname: string): Promise<Asset[]> {
        const resource = "/hostname/assets";
        const params = {
            hostname: hostname,
        };
        return this.assetClient.doGet(resource, params)
            .then(result => (result as AssetWrapper).assets);
    }

    public getAssetSoftware(assetId: uuid): Promise<Software[]> {
        const resource = `/asset/${ assetId }/software`;
        return this.assetClient.doGet(resource)
            .then((result: Software[] | SoftwareWrapper) => {
                return (result as SoftwareWrapper).software || (result as Software[]);
            });
    }

    public getAssetUploadUrl(size: number): string {
        return this.assetClient.getFullUrlFor(`/asset-upload?size=${ size }`);
    }

    public mergeUntrackedAsset(trackedAssetId: uuid, untrackedAssetId: uuid): Promise<Asset> {
        return this.assetClient.doPost(`/assets/merge`, null, {
            trackedAsset: trackedAssetId,
            untrackedAsset: untrackedAssetId,
        });
    }
}
