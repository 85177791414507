import { Injectable } from "@angular/core";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { OrgsService } from "@app2/account/orgs.service";
import { localization } from "@app2/shared/localization/localization";

@Injectable({
    providedIn: "root"
})
export class TitleStrategyService extends TitleStrategy {
    constructor(private orgsService: OrgsService,
                private title: Title) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const customTitle = this.buildTitle(routerState);
        const currentOrg = this.orgsService.getCurrentOrg();

        let title;
        if (!currentOrg) {
            title = "DefenseStorm Console";
        } else if (!customTitle) {
            title = currentOrg.name;
        } else {
            const localized = localization.getString(customTitle);
            title = `${ currentOrg.name } - ${ localized }`;
        }
        this.title.setTitle(title);
    }
}
