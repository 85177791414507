import { Pipe, PipeTransform } from "@angular/core";
import { TimeService } from "@app2/shared/services/time.service";

@Pipe({ name: "shortDate" })
export class ShortDatePipe implements PipeTransform {
    constructor(private timeService: TimeService) {
    }

    transform(date: string, onlyShowDate?: boolean) {
        return ShortDatePipe.transformImpl(date, this.timeService, onlyShowDate);
    }

    public static transformImpl(date: string, timeSource: any, onlyShowDate?: boolean) {
        const todayFormat = timeSource.formats.timeMeridiem;
        const otherFormat = timeSource.formats.timestampDate;
        /**
         * Formats the date as a time if it is from today,
         * otherwise formats as a date
         * If onlyShowDate == true, then always format as a date.
         *
         * @param {Number|string} date - a Unix timestamp in milliseconds or an ISO string
         * @returns {string} - a nicely-formatted representation
         */
        const momentDate = timeSource.timeZoneMoment(date);

        if (!momentDate.isValid()) return date;

        const midnightToday = timeSource.now().startOf("day");
        const inAlmostOneDay = timeSource.now().endOf("day");

        if (onlyShowDate || momentDate.isBefore(midnightToday) || momentDate.isAfter(inAlmostOneDay)) {
            return momentDate.format(otherFormat);
        } else {
            return momentDate.format(todayFormat);
        }
    }
}
