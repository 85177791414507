export const cyberFraudOfferingTemplate = `
    <h1 class="header">Fraud Detection</h1>
    <p>
        Interested in learning more about defending against cyber fraud?
    </p>
    <p>
        GRID Active Fraud Detection provides continuous monitoring and alerts from data across the network, online banking platform and core to proactively detect and stay ahead of evolving fraud threats and stop cyber fraud before funds leave the organization.
    </p>
    <p>
        GRID Active Fraud Detection will detect unusual patterns of behavior for your organization, like access in the middle of the night or an attempt for a large ACH transfer.
    </p>
    <p>
        Integrating Information Security and Fraud departments in a single platform, we can defend against threats, including fraudulent account opening/loan applications, account take-overs, payment fraud, money laundering, and internal fraud.
    </p>
    <p>
        Interested in learning more and integrating proactive cyber fraud detection through GRID Active? Contact your customer success manager or email us at <a class="link-text" href="mailto:cs@defensestorm.com">cs@defensestorm.com</a>.
    </p>
`;
