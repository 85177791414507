import { SavedQuery } from "@app2/type-defs/search/search-types";
import lz_string from "lz-string";

export function buildEmptySavedQuery(): Partial<SavedQuery> {
    return {
        name: "",
        query: "",
        primaryField: undefined,
        secondaryField: undefined,
        description: "",
    };
}

export function encodeQueryString(query: string) {
    return lz_string.compressToEncodedURIComponent(query);
}

export function decodeQueryString(query: string) {
    return lz_string.decompressFromEncodedURIComponent(query);
}