import { Injectable } from "@angular/core";
import { UserClientService } from "@app2/clients/user-client.service";
import { log } from "@app2/logger";
import { interval, Subscription } from "rxjs";
import { moment } from "@app2/util/legacy-moment";
import { startWith } from "rxjs/operators";

export const BACKEND_HEARTBEAT_INTERVAL = moment.duration(10, "minutes");

@Injectable({
    providedIn: "root",
})
export class BackendHeartbeatService {

    private subscription: Subscription;

    constructor(private readonly userService: UserClientService) {
    }

    /**
     * Notifies user-service that the user is still active, in order to keep the token alive.
     */
    public enableBackendHeartbeat() {
        this.subscription = interval(BACKEND_HEARTBEAT_INTERVAL.asMilliseconds())
            .pipe(startWith(0)) // Trigger a heartbeat immediately after enabling it
            .subscribe(() => {
                this.userService.sendHeartbeat()
                    .catch(err => log.error("Failed sending heartbeat to backend", err));
            });
    }

    /**
     * When we logout from the console, the heartbeat should stop to avoid getting errors 401.
     */
    public disableBackendHeartbeat() {
        this.subscription?.unsubscribe();
    }
}
