import { log } from "@app2/logger";
import * as _ from "underscore";
import { moment } from "@app2/util/legacy-moment";

export const preferentialTimeZones = [
    "America/Los_Angeles",
    "America/Denver",
    "America/Phoenix",
    "America/Chicago",
    "America/New_York",
    "America/Anchorage",
    "Pacific/Honolulu",
    "UTC",
];

export function guessLocalTimeZone(): string {
    const currentYear = new Date().getFullYear();
    // months are zero-indexed
    const januaryDate = new Date(currentYear, 0, 1);
    const julyDate = new Date(currentYear, 6, 1);

    const januaryOffset = januaryDate.getTimezoneOffset();
    const julyOffset = julyDate.getTimezoneOffset();

    const possibleZones: string[] = _.chain(moment.tz.names())
        .map(name => moment.tz.zone(name))
        .filter(zone => zone.utcOffset(januaryDate.getTime()) === januaryOffset &&
            zone.utcOffset(julyDate.getTime()) === julyOffset)
        .map(zone => zone.name)
        .value();

    if (possibleZones.length === 0)  log.error("cannot find a timezone");

    const preferentialMatch = possibleZones.find(zone => preferentialTimeZones.includes(zone));

    return preferentialMatch ? preferentialMatch : possibleZones[0];
}
