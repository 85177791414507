import { Directive, ElementRef, Input, OnChanges } from "@angular/core";

// Before using this component, consider if `*loading` is better suited. `*loading` doesn't show the user-provided content
// in the DOM while the condition is false, whereas `loadable` shows it in the DOM but hides it.

// Deprecated
@Directive({
    selector: "[loadable]",
})
export class LoadableDirective implements OnChanges {
    @Input() loading: boolean;

    constructor(private elementRef: ElementRef) {
    }

    ngOnChanges(): void {
        if (this.loading) {
            Array.from(this.elementRef.nativeElement.children).forEach((child: any) => child.hidden = true);
            this.elementRef.nativeElement.insertAdjacentHTML("beforeend",
                `<div class="loading-container"><i class="loading-spinner fa fa-spinner fa-spin fa-3x"></i></div>`);
        } else {
            Array.from(this.elementRef.nativeElement.children).forEach((child: any) => child.hidden = false);
            this.elementRef.nativeElement.querySelectorAll(".loading-container")
                .forEach(spinnerElem => spinnerElem.remove());
        }
    }
}
