import {
    booleanAttribute,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { DsSelectOption, DsSelectValue } from "@app2/shared/components/ds-select.component";
import { localization } from "@app2/shared/localization/localization";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { keyBy } from "lodash-es";

@UntilDestroy()
@Component({
    selector: "ds-single-select",
    template: `
        <ds-dropdown [showHeader]="false"
                     [showFooter]="false"
                     [disabled]="disabled"
                     [dropdownClass]="dropdownClass">
            <div id="ds-dropdown-trigger" class="dropdown-value" [class.value-selected]="selectedItem?.key">
                {{ selectedItemLabel }}
            </div>
            <div *ngIf="showSearch" id="search-box-item">
                <search-box [placeholderKey]="placeholderKey"
                            autocomplete="off"
                            [reportEveryValueChange]="reportEveryValueChange"
                            (searchTextChange)="onSearchTextChange($event)">
                </search-box>
            </div>
            <li *ngFor="let option of filteredOptions"
                class="click-target"
                [class.item-row-selected]="selectedItem === option"
                [class.item-row-disabled]="option.disabled"
                (click)="!option.disabled && toggleSelected(option)"
                [attr.test-id]="'select-item-checkbox-' + option.value"
                pr-dropdown-close-on-click>
                <div class="item-container">
                    <span class="flex-grow">{{ getKeyText(option) }}</span>
                    <i id="item-icon" [ngClass]="{'far fa-check': selectedItem === option}"></i>
                </div>
            </li>
        </ds-dropdown>
    `,
    styles: [`
        @import "/src/styles/colors";

        li {
            line-height: 32px;
            overflow: hidden;
            white-space: nowrap;
        }

        .item-container {
            display: flex;
            align-items: center;
            gap: 16px;

            .flex-grow {
                flex-grow: 1;
            }

            i {
                color: $gray-6;
                font-size: 16px;

                &.fa-check {
                    color: $ds-blue-6;
                }
            }
        }

        #ds-dropdown-trigger.dropdown-value:not(.value-selected) {
            color: $gray-6;
            font-weight: 400;
        }

        #ds-dropdown-trigger {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        #search-box-item {
            white-space: nowrap;
            line-height: 2rem;
            overflow: hidden;
            margin: 0 1rem;
        }

        .item-row-selected {
            background-color: $gray-4;
        }

        .item-row-disabled {
            color: $gray-6;
        }
    `],
})
export class DsSingleSelectComponent<V extends DsSelectValue> implements OnInit, OnChanges {
    @Input() control: AbstractControl;
    @Input() placeholderKey: string;
    @Input() selectedOption: V;
    @Input() options: DsSelectOption<V>[];
    @Input({ transform: booleanAttribute }) showSearch: boolean;
    @Input() disabled: boolean;
    @Input() localizeKey: boolean;
    @Input() dropdownClass: string;
    @Input({ transform: booleanAttribute }) reportEveryValueChange: boolean;

    @Output() selectedOptionChange = new EventEmitter<V>();

    optionsByValue: Record<string, DsSelectOption<V>> = {};
    selectedItem: DsSelectOption<V>;
    filteredOptions: DsSelectOption<V>[];

    ngOnInit() {
        if (this.selectedOption !== undefined) {
            this.selectedItem = this.optionsByValue[String(this.selectedOption)];
        } else if (this.control) {
            this.selectedItem = this.optionsByValue[String(this.control.value)];
            this.control.valueChanges
                .pipe(untilDestroyed(this))
                .subscribe(value => {
                    this.selectedItem = this.optionsByValue[value];
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && this.options) {
            this.filteredOptions = this.options;
            this.optionsByValue = keyBy(this.options, "value");
            this.selectedItem = this.selectedOption
                ? this.optionsByValue[String(this.selectedOption)]
                : this.optionsByValue[String(this.control?.value)];
        } else if (changes.selectedOption && this.selectedOption) {
            this.selectedItem = this.optionsByValue[String(this.selectedOption)];
            this.control?.setValue(this.selectedOption);
        }
    }

    get selectedItemLabel(): string {
        if (this.selectedItem?.value === undefined || this.selectedItem?.value === null) {
            return localization.getString(this.placeholderKey);
        }
        return localization.getString(this.selectedItem.key);
    }

    toggleSelected(option: DsSelectOption<V>): void {
        this.selectedItem = option;
        this.control?.setValue(option.value);
        this.selectedOptionChange.emit(option.value);
    }

    getKeyText(option: DsSelectOption<V>): string {
        if (option.key) {
            return this.localizeKey ? localization.getString(option.key) : option.key;
        } else {
            return localization.getString(this.placeholderKey);
        }
    }

    onSearchTextChange(searchText: string): void {
        const lowerSearchText = searchText.toLocaleLowerCase().trim();
        this.filteredOptions = this.options
            .filter(option => option.key.toLocaleLowerCase().includes(lowerSearchText));
    }
}
