import { Component, HostListener } from "@angular/core";
import { AuthStateService } from "@app2/account/auth-state.service";
import { OrgsService } from "@app2/account/orgs.service";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import * as _ from "underscore";

@Component({
    selector: "org-selector-page",
    template: `
        <div class="row">
            <div class="small-16 medium-8 medium-centered columns">
                <div class="sheet">
                    <table class="hover-table full-width">
                        <tbody>
                        <tr *ngFor="let org of allOrgs$ | async">
                            <td class="hover-tab"></td>
                            <td class="link-cell">
                                <a test-class="org-selector-link" (click)="selectOrg(org)">
                                    <i [class.fa-house]="org.id === homeOrg.id" class="far fa-fw"></i>
                                    {{ org.name }}
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    `,
})
export class OrgSelectorPageComponent {

    allOrgs$: Observable<OrgSummary[]>;
    homeOrg: OrgSummary;

    constructor(private readonly orgsService: OrgsService,
                private readonly authStateService: AuthStateService) {
        this.allOrgs$ = orgsService.getOrgsInfo$()
            .pipe(
                // Ignore events until we are correctly setup
                filter(({ homeOrg }) => !!homeOrg),
                map(({ homeOrg, authorizedOrgs }) => {
                    this.homeOrg = homeOrg;
                    return _.sortBy([homeOrg, ...authorizedOrgs], org => org.name.toLowerCase());
                }),
            );
    }

    /**
     * If "h" is pressed on the org selector we redirect to the Home Org.
     */
    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === "h" || event.key === "H") {
            this.selectOrg(this.homeOrg);
        }
    }

    selectOrg(org: OrgSummary) {
        this.authStateService.selectOrg(org);
    }
}
