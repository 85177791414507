import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "modal-buttons",
    template: `
        <div>
            <ds-button *ngIf="onCancel.observers.length" type="cancel" test-id="cancel-button"
                       id="cancel-button" (clicked)="onCancel.emit()">{{ 'CANCEL' | localize }}
            </ds-button>
            <ds-button *ngIf="onClose.observers.length" type="cancel" test-id="close-button"
                       id="close-button" (clicked)="onClose.emit()">{{ (closeKey || 'CLOSE') | localize }}
            </ds-button>
            <ds-button *ngIf="onSave.observers.length" id="commit-button" test-id="commit-button"
                       [matTooltip]="saveTooltip"
                       [matTooltipDisabled]="!saveTooltip || canSave"
                       [type]="saveType"
                       [disabled]="canSave === false || saveInProgress"
                       (clicked)="onSave.emit()">
                <i *ngIf="saveInProgress" class="fa fa-spinner fa-spin"></i> {{ (saveKey || 'SAVE') | localize }}
            </ds-button>
            <ds-button *ngIf="onDelete.observers.length && showDelete" type="delete"
                       id="delete-button" test-id="delete-button" [disabled]="canDelete === false"
                       (clicked)="onDelete.emit()">{{ (deleteKey || 'DELETE') | localize }}
            </ds-button>
        </div>

    `,
    styles: [`
        :host {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-top: 3px;
        }
        ds-button#commit-button, ds-button#delete-button {
            margin-left: 0.5rem;
        }
    `],
})
export class ModalButtonsComponent {
    @Input() canSave: boolean;
    @Input() canDelete: boolean;
    @Input() showDelete: boolean = true;
    @Input() saveKey: string;
    @Input() closeKey: string;
    @Input() deleteKey: string;
    @Input() saveType: "standard" | "warn" = "standard";
    @Input() saveInProgress: boolean;
    @Input() saveTooltip: string;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCancel = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDelete = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSave = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClose = new EventEmitter<void>();
}
