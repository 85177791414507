import { Pipe, PipeTransform } from "@angular/core";
import { localization } from "@app2/shared/localization/localization";
import { TimeService } from "@app2/shared/services/time.service";

@Pipe({ name: "longDate" })
export class LongDatePipe implements PipeTransform {

    constructor(private readonly timeService: TimeService) { }

    transform(date: string | number, placeholderKey?: any) {
        const momentDate = this.timeService.timeZoneMoment(date);

        if (momentDate.isValid()) {
            return momentDate.format(this.timeService.formats.longTimestamp);
        } else {
            if (placeholderKey) {
                return localization.getString(placeholderKey);
            } else {
                return date;
            }
        }
    }
}
