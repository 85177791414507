import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ProductSku } from "@app2/type-defs/user/user-types";
import { OrgsService } from "@app2/account/orgs.service";


/**
 * Usage:
 *     <div *dsSkuRequired="SKU_NAME">... </div>
 */
@UntilDestroy()
@Directive({
    selector: "[dsSkuRequired]",
})
export class DsSkuRequiredDirective implements OnInit {
    private skuName: ProductSku;
    private orgSkus: Set<ProductSku> = new Set();
    private visible: boolean;

    @Input() set dsSkuRequired(skuName: ProductSku) {
        this.skuName = skuName;
        this.addOrRemoveElement();
    }

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private orgsService: OrgsService) {
    }

    ngOnInit(): void {
        this.orgsService.getCurrentOrg$()
            .pipe(untilDestroyed(this))
            .subscribe(org => {
                this.orgSkus = new Set(org.productSkus);
                this.addOrRemoveElement();
            });
    }

    // see https://angular.dev/guide/directives/structural-directives
    addOrRemoveElement() {
        if (this.orgSkus.has(this.skuName) && !this.visible) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.visible = true;
        } else if (!this.orgSkus.has(this.skuName) && this.visible) {
            this.viewContainer.clear();
            this.visible = false;
        }
    }
}
