import { log } from "@app2/logger";
import * as TimeRange from "@app2/search/utils/time-range";
import { SelectedFilters } from "@app2/type-defs/search/search-types";
import { toFilteredQueryString } from "@app2/util/search-utils";
import * as _ from "underscore";
import { moment, Moment } from "@app2/util/legacy-moment";

export const ASSET_TAG_QUERY_REGEX = /\$asset_tag:("[^"]+"|[^"\s]+)/g;

export interface ToStateParamsOptions {
    query?: string;
    filters?: SelectedFilters;
    pageSize?: number;
    pageNumber?: number;
    intervalType?: string;
    begin?: Moment;
    end?: Moment;
    primaryField?: string;
    secondaryField?: string;
    lz?: string;
}

// The names of the interval keys were changed. There are two things that might be using old intervals still:
// saved searches and old URLs.
const intervalTranslations = {
    LAST_MONTH: "LAST_30_DAYS",
    LAST_WEEK: "LAST_7_DAYS",
    LAST_DAY: "LAST_24_HOURS",
    LAST_TWELVE_HOURS: "LAST_12_HOURS",
    LAST_THREE_HOURS: "LAST_3_HOURS",
};

/**
 * Used to convert an options object into a stateParams object when transitioning to the search page.
 *
 * @param {Object} options
 * @returns {Object} state params compatible object eg. { key: value } or { key: [value1, value2,...] }
 */
export function toStateParams(options: ToStateParamsOptions): Record<string, any> {
    options = options || {};
    const keys = [
        "query",
        "begin",
        "end",
        "filters",
        "intervalType",
        "primaryField",
        "secondaryField",
        "filterField",
        "filterListId",
        "excludeField",
        "excludeListId",
        "pageSize",
        "pageNumber",
        "lz"
    ];
    if (_.isUndefined(options.intervalType) || options.intervalType === "CUSTOM") {
        keys.push("begin");
        keys.push("end");
    } else {
        keys.push("intervalType");
    }
    const params = _.chain(options) // { query: "something", filters: { f1: [v1, v2], ... }
        .pick(...keys)
        .map(function (value: any, option: string): any {
            if (_.isUndefined(value)) {
                return false;
            }
            if (["query", "intervalType", "primaryField", "secondaryField", "filterField", "filterListId",
                "excludeField", "excludeListId", "lz"
            ].includes(option)) {
                return [option, value];
            } else if (option === "pageSize" || option === "pageNumber") {
                return [option, value.toString()];
            } else if (option === "begin" || option === "end") {
                const strValue = moment.isMoment(value) ? value.toISOString() : value;
                return [option, strValue];
            } else if (option === "filters") {
                // Handle filters
                return [
                    "filter",
                    _.chain(value) // { f1: [v1, v2], f2: [v3, v4]
                        .map((terms: string|string[], field: string) =>
                            _.map(_.isArray(terms) ? terms : [terms], term => field + ":" + term)) // [ [f1:v1, f1:v2], [f2:v3, f2:v4] ]
                        .flatten(true) // [ f1:v1, f1:v2, f2:v3, f2:v4 ]
                        .value(),
                ];
            }
        })// [ [query, "something"], [filter, [f1:v1, f1:v2, f2:v3, f2:v4]] ]
        .compact()
        .object()
        .value();
    // Make sure all optional properties are present on the object. Most of the time this doesn't matter, but
    // when you reload same state with different params, we want to specify that, if the old state had
    // these params and the new state doesn't, we don't want to keep the old state around. (ui-router is
    // using hasOwnProperty or equivalent to decide whether the new params object wants to set a param to
    // something.
    // I thought about using ui-router's `inherit:false` transition option, but that option has the side effect
    // not only clearing the previous params for this state (desirable) but also clearing the params of all
    // parent states, including the selected orgId and any features set via url param (undesirable).
    // These are all strings or objects so we will not run into problems with falsy values being overwritten
    // with undefined.
    params.query = params.query || undefined;
    params.filters = params.filters || undefined;
    params.pageSize = params.pageSize || undefined;
    params.pageNumber = params.pageNumber || undefined;
    params.primaryField = params.primaryField || undefined;
    params.secondaryField = params.secondaryField || undefined;
    params.filterField = params.filterField || undefined;
    params.filterListId = params.filterListId || undefined;
    params.excludeField = params.excludeField || undefined;
    params.excludeListId = params.excludeListId || undefined;
    params.intervalType = params.intervalType || undefined;
    params.begin = params.begin || undefined;
    params.end = params.end || undefined;
    params.lz = params.lz || undefined;
    return params;
}

export function getAssetTagsInQuery(query: string): string[] {
    if (!query) { return []; }
    let matches: null | string[] = query.match(ASSET_TAG_QUERY_REGEX);
    return matches ? matches.map(match => match.slice(11).replace(/\"/g, "")) : [];
}

/**
 * This is used whenever we have a queryString and filters, and want to make one string, so like for
 * a trigger, classifier, or a saved search.
 *
 * @param {Object} [options]
 * @param {String} [options.query] The query string, defaults to match all
 * @param {Object} [options.filters] Object of String keys to Array of String values to filter by
 */
export function toQueryString(options) {
    options = options || {};
    return toFilteredQueryString(options.query, options.filters);
}

/**
 * @param {Array} params pairs of key|value
 * @param {Function} timeZoneMoment the bound timeZoneMoment method from time.ts or TimeService
 * @returns {Object} an application search options object
 */
export function fromUrlQueryParams(params, timeZoneMoment: (str) => Moment) {
    return fromStateParams(queryParamsToStateParams(params), timeZoneMoment);
}

/**
 * @param {Array} params pairs of key|value
 * @returns {Object} stateParams compatable object, eg. { key: value } or { key: [value1, value2,...] }
 */
export function queryParamsToStateParams(params) {
    return _.chain(params)
        .groupBy("0")
        .map((pairs, key) => [key, pairs.length === 1 ? pairs[0][1] : _.pluck(pairs, "1")])
        .object()
        .value();
}

/**
 * @param {Object} stateParams { key: value } or { key: [value1, value2,...] }
 * @param {Function} timeZoneMoment the bound timeZoneMoment method from time.ts or TimeService
 * @returns {Object} an application search options object
 */
export function fromStateParams(stateParams, timeZoneMoment: (str) => Moment) {
    return _.chain(stateParams)
        .pick("query", "filter", "pageSize", "pageNumber", "begin", "end", "intervalType",
            "primaryField", "secondaryField")
        .map(function(value, key: string): any {
            if (_.isUndefined(value)) { return false; }
            if (_.isArray(value)) {
                value = _.compact(value);
                if (key !== "filter") {
                    value = _.last(value);
                }
            }
            let validateFn: (value: any) => boolean = () => true;
            if (key === "query" || key === "primaryField" || key === "secondaryField") {
                // No additional processing
            } else if (key === "pageSize" || key === "pageNumber") {
                value = parseInt(value);
                validateFn = (value) =>  value > 0;
            } else if (key === "begin" || key === "end") {
                // if value is an epoch, we need to convert it from a string to an int
                let testValue = timeZoneMoment(value);
                if (!testValue.isValid()) testValue = timeZoneMoment(parseInt(value));
                value = testValue;
                validateFn = (value) => value.isValid();
            } else if (key === "filter") {
                key = "filters";
                value = _.chain(_.isArray(value) ? value : [value]) // [ f1:v1, f1:v2, f2:v3 ]
                    .map(function(filter): any {
                        filter = filter.trim();
                        const delimIndex = filter.indexOf(":");
                        if (delimIndex < 1 || delimIndex === filter.length - 1) {
                            log.info("filter with empty key or value ignored", filter);
                            return false;
                        }
                        return [filter.slice(0, delimIndex), filter.slice(delimIndex + 1)];
                    }) // [ [f1,v1], [f1,v2], [f2,v3] ]
                    .compact().groupBy("0") // { f1: [ [f1,v1], [f1,v2] ], f2: [ [f2,v3] ]
                    .map((pairs, filterKey) => [filterKey, _.pluck(pairs, "1")]) // [ [f1, [v1,v2]], [f2, [v3]] ]
                    .object() // { f1: [v1, v2], f2: [v3] }
                    .value();
                validateFn = (value) => !_.isEmpty(value);
            } else if (key === "intervalType") {
                value = value ? value.trim() : value;
                value = value ? intervalTranslations[value] || value : value;
                validateFn = (value) => _.contains(TimeRange.getAllKeys(), value);
            }
            if (!validateFn(value)) {
                log.info("ignoring invalid value", key, value);
                return false;
            }
            return [key, value];
        })
        .compact()
        .object()
        .value();
}

/**
 * @param {Object} options
 * @returns {Array} query params pairs of key|value
 */
export function toUrlQueryParams(options) {
    return stateParamsToQueryParams(toStateParams(options));
}

/**
 * @param {Object} stateParams compatible object eg. { key: value } or { key: [value1, value2,...] }
 * @returns {Array} query params pairs of key|value
 */
export function stateParamsToQueryParams(stateParams: any|any[]) {
    return _.chain(stateParams) // { query: "something", filter: [f1:v1, f1:v2, f2:v3, f2:v4] }
        .map(function(value, key) {
            if (_.isArray(value)) {
                return _.map(value, (oneValue) => [key, oneValue]); // [[filter, f1:v1], [filter, f1:v2], [filter, f2:v3], [filter, f2:v4]]
            } else {
                return [[key, value]]; // So a shallow flatten doesn't break everything
            }
        }) // [ [[query, "something"]], [[filter, f1:v1], [filter, f1:v2], [filter, f2:v3], [filter, f2:v4]] ]
        .flatten(true) // [ [query, "something"], [filter, f1:v1], [filter, f1:v2], [filter, f2:v3], [filter, f2:v4] ]
        .filter(([, b]) => b !== undefined)
        .value();
}
