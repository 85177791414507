import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "svg-icon",
    template: `
        <mat-icon [svgIcon]="src" [inline]="inline"></mat-icon>
    `,
})
export class SvgIconComponent implements OnChanges {
    @Input() src: string;
    @Input() inline: boolean;

    constructor(private iconRegistry: MatIconRegistry,
                private sanitizer: DomSanitizer) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src) {
            this.iconRegistry.addSvgIcon(this.src, this.sanitizer.bypassSecurityTrustResourceUrl(this.src));
        }
    }
}
