export const ios2faTemplate = `
<h3>Requirements</h3>
<p>
    To use Google Authenticator on your iPhone, iPod Touch, or iPad, you must have iOS 5.0 or later. In
    addition, in order to set up the app on your iPhone using a QR code, you must have a 3G model or
    later.
</p>

<h3>Downloading the app</h3>
<ol>
    <li>Visit the App Store.</li>
    <li>Search for <strong>Google Authenticator</strong>.</li>
    <li>Download and install the application.</li>
</ol>

<h3>Setting up the app</h3>
<ol>
    <li>On your phone, open the Google Authenticator application.</li>
    <li>Tap the plus icon.</li>
    <li>Tap <strong>Time Based</strong>.</li>
    <li>
        To link your phone to your account:
        <ul>
            <li>
                <strong>Using Barcode</strong>: Tap "Scan Barcode" and then point your camera at the QR code
                on your computer screen.
            </li>
            <li>
                <strong>Using Manual Entry</strong>: Tap "Manual Entry" and enter the email address of your
                DefenseStorm account. Then, enter the secret key on your computer screen into the box next to
                <strong>Key</strong> and tap "Done".
            </li>
        </ul>
    </li>
</ol>
`;
