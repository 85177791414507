import { booleanAttribute, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl } from "@angular/forms";

// Every option must have a different value
export interface DsSelectOption<V extends DsSelectValue> {
    key: string;
    value: V;
    disabled?: boolean;
}

export type DsSelectValue = string | boolean | number;

@Component({
    selector: "ds-select",
    template: `
        <ds-multi-select *ngIf="multiple"
                         [placeholderKey]="placeholderKey"
                         [selectedOption]="selectedOption"
                         [control]="control"
                         [options]="options"
                         [showSearch]="showSearch"
                         [reportEveryValueChange]="reportEveryValueChange"
                         [shortLabel]="shortLabel"
                         [dropdownClass]="dropdownClass"
                         [savable]="savable"
                         (selectedOptionsChange)="onSelectOptions($event)">
        </ds-multi-select>
        <ds-single-select *ngIf="!multiple"
                          [placeholderKey]="placeholderKey"
                          [localizeKey]="localizeKey"
                          [selectedOption]="selectedOption"
                          [control]="control"
                          [options]="options"
                          [showSearch]="showSearch"
                          [reportEveryValueChange]="reportEveryValueChange"
                          [disabled]="disabled"
                          [dropdownClass]="dropdownClass"
                          (selectedOptionChange)="onSelectOption($event)">
        </ds-single-select>
    `,
    styles: [`
      :host {
        display: block;
      }
    `],
})
export class DsSelectComponent<M extends boolean, V extends DsSelectValue> {
    @Input() control: AbstractControl;
    @Input() selectedOption: M extends true ? V[] : V;
    @Input() placeholderKey: string = "SELECT";
    @Input() multiple: M;
    @Input() options: readonly DsSelectOption<V>[];
    @Input({ transform: booleanAttribute }) showSearch: boolean;
    @Input({ transform: booleanAttribute }) reportEveryValueChange: boolean;
    @Input() disabled: boolean;
    @Input() localizeKey: boolean = true;
    @Input() shortLabel: boolean;
    @Input() dropdownClass: string;
    @Input() savable: boolean;

    @Output() selectedOptionChange = new EventEmitter<V>();
    @Output() selectedOptionsChange = new EventEmitter<V[]>();

    onSelectOption(event: V) {
        this.selectedOptionChange.emit(event);
    }

    onSelectOptions(event: V[]) {
        this.selectedOptionsChange.emit(event);
    }
}
