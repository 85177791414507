import { Component, Input } from "@angular/core";
import { IpMacPair } from "@app2/type-defs/assets/assets-types";

@Component({
    selector: "ip-mac-pairs-view",
    template: `
        <table class="hover-table ip-mac-table">
            <thead>
            <tr>
                <th class="hover-tab"></th>
                <th>{{'ASSET_IP_ADDRESSES' | localize}}</th>
                <th>{{'ASSET_MAC_ADDRESSES' | localize}}</th>
            </tr>
            </thead>
            <tbody *ngIf="ipMacPairs.length > 0">
            <tr *ngFor="let pair of ipMacPairs">
                <td class="hover-tab"></td>
                <td>{{pair.ip}}</td>
                <td>{{pair.mac}}</td>
            </tr>
            </tbody>
            <tbody *ngIf="ipMacPairs.length === 0">
            <tr>
                <td class="no-data-text" colspan="3">{{'ASSET_NO_IP_MAC_PAIRS' | localize}}</td>
            </tr>
            </tbody>
        </table>
    `,
    styles: ["th, td {text-align: center;}"],
})
export class IpMacPairsViewComponent {
    @Input() ipMacPairs: IpMacPair[];
}
