import { Component, Input, OnInit } from "@angular/core";
import { TimeService } from "@app2/shared/services/time.service";
import { toStateParams } from "@app2/search/query";
import { moment } from "@app2/util/legacy-moment";

@Component({
    selector: "hash-dropdown",
    template: `
    <pr-dropdown *dsPermissionsRequired="'EVENT_VIEW'">
    <a class="inline" pr-dropdown-trigger>{{hash}}</a>
    <ul pr-dropdown-body test-class="hash-dropdown">
        <li class="no-hover threatmatch-li"><header>{{"SEARCH_THIS_HASH" | localize}}</header></li>
        <li class="threatmatch-li">
            <a class = "wide" target="_blank" dsRouterLink="/search" [queryParams]="params1Minute">
                <i class="fa fa-search visible-icon"></i> {{"SEARCH_AROUND_1M" | localize}}
            </a>
        </li>
        <li class="threatmatch-li">
            <a class = "wide" target="_blank" dsRouterLink="/search" [queryParams]="params5Minutes">
                <i class="fa fa-search visible-icon"></i> {{"SEARCH_AROUND_5M" | localize}}
            </a>
        </li>
        <li class="threatmatch-li">
            <a class="wide" href="{{virusTotalUrl}}" target="_blank" rel="noopener noreferrer">
                <i class="far fa-external-link visible-icon"></i>
                {{"SEARCH_VIRUS_TOTAL" | localize}}
            </a>
        </li>
    </ul>
</pr-dropdown>
<span *dsPermissionsRequired="'!EVENT_VIEW'" ng-class="inline">
    {{hash}}
</span>
    `,
})
export class HashDropdownComponent implements OnInit {
    @Input() hash: string;
    @Input() eventTime: date | number;

    virusTotalUrlBase = "https://www.virustotal.com/latest-scan/";
    virusTotalUrl;
    params1Minute;
    params5Minutes;

    constructor(private readonly time: TimeService) {}

    ngOnInit() {
        this.eventTime = this.getEventTime(this.eventTime);
        this.virusTotalUrl = this.virusTotalUrlBase + this.hash;
        this.params1Minute = this.getStateParams(1, this.hash, this.eventTime);
        this.params5Minutes = this.getStateParams(5, this.hash, this.eventTime);
    }

    getStateParams(minutes, hash, eventTime) {
        return toStateParams({
            query: hash + " -praesidio_skip_tm:true",
            begin: moment(eventTime).subtract(minutes, "minutes"),
            end: moment(eventTime).add(minutes, "minutes"),
        });
    }

    getEventTime(inputTime) {
        let eventTime = inputTime;
        if (/^\d+$/.test(eventTime)) {
            eventTime = parseInt(eventTime);
        }
        eventTime = eventTime || this.time.now().valueOf();
        return this.time.timeZoneMoment(eventTime);
    }
}
