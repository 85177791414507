import { APP_BASE_HREF } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SECURITY_DOMAIN } from "@app2/alert/constants";
import { deprecatedUrlRedirects } from "@app2/deprecated-url-redirects";
import { PRODUCT_DOMAIN } from "@app2/alert/alert-inbox/injection-tokens";
import { KnowledgeBaseRedirectPageComponent } from "@app2/integrations/knowledge-base-redirect-page.component";
import { SamlRedirectPageComponent } from "@app2/login/saml-redirect-page.component";
import { isLoggedInGuard } from "@app2/navigation/is-logged-in.guard";
import { notLoggedInGuard } from "@app2/navigation/not-logged-in.guard";
import { UnauthorizedPageComponent } from "@app2/navigation/unauthorized-page.component";
import { oneOf } from "@app2/navigation/utils";
import { PreloadModulesOneByOne } from "@app2/shared/preload-modules-one-by-one";
import { NotFoundPageComponent } from "@app2/navigation/not-found-page.component";

const routes: Routes = [
    ...deprecatedUrlRedirects,
    {
        path: "alerts",
        loadChildren: () => import("./alert/alert.module").then(m => m.AlertModule),
    },
    {
        path: "assets",
        loadChildren: () => import("./asset/asset.module").then(m => m.AssetModule),
        title: "ASSETS",
    },
    {
        path: "classifiers",
        loadChildren: () => import("./classifiers/classifiers.module").then(m => m.ClassifiersModule),
        title: "BASE_CLASSIFIERS",
    },
    {
        path: "governance",
        loadChildren: () => import("./policy/policy.module").then(m => m.PolicyModule),
    },
    {
        path: "dashboard", // Old dashboards
        loadChildren: () => import("./dashboards/old-dashboards/old-dashboards.module").then(m => m.OldDashboardsModule),
        title: "DASHBOARDS",
    },
    {
        path: "dashboards",
        loadChildren: () => import("./dashboards/dashboards.module").then(m => m.DashboardsModule),
    },
    {
        path: "fraud",
        loadChildren: () => import("./fraud/fraud.module").then(m => m.FraudModule),
    },
    {
        path: "home",
        loadChildren: () => import("./dashboards/cyber-risk-readiness/cyber-risk-readiness.module").then(m => m.CyberRiskReadinessModule),
    },
    {
        matcher: oneOf("task-schedules", "task-schedule"),
        loadChildren: () => import("./task-schedules/task-schedules.module").then(m => m.TaskSchedulesModule),
        title: "TASK_SCHEDULES",
    },
    {
        path: "library-task-schedules",
        loadChildren: () => import("./task-schedules/library-task-schedules/library-task-schedule.module").then(m => m.LibraryTaskScheduleModule),
        title: "LIBRARY_TASK_SCHEDULES",
    },
    {
        path: "reports",
        loadChildren: () => import("./report/report.module").then(m => m.ReportModule),
    },
    {
        path: "risk",
        loadChildren: () => import("./risk/risk.module").then(m => m.RiskModule),
    },
    {
        path: "search",
        loadChildren: () => import("./search/search.module").then(m => m.SearchModule),
    },
    {
        path: "threat",
        loadChildren: () => import("./threatmatch/threatmatch-page/threatmatch.module").then(m => m.ThreatmatchModule),
        title: "THREAT_MATCH",
    },
    {
        path: "tickets",
        loadChildren: () => import("./ticket/ticket.module").then(m => m.TicketModule),
    },
    {
        path: "triggers",
        loadChildren: () => import("./triggers/triggers.module").then(m => m.TriggersModule),
        title: "TRIGGERS",
    },
    {
        path: "trigger-schedules",
        loadChildren: () => import("./trigger-schedules/trigger-schedules.module").then(m => m.TriggerSchedulesModule),
        title: "TRIGGER_SCHEDULES",
    },
    {
        path: "universal-ticket-inbox",
        loadChildren: () => import("./ticket/universal-ticket-inbox/universal-ticket-inbox.module").then(m => m.UniversalTicketInboxModule),
        title: "GLOBAL_TICKET_INBOX_TAB",
    },
    {
        path: "ticket-templates",
        loadChildren: () => import("./ticket/templates/ticket-templates.module").then(m => m.TicketTemplatesModule),
        title: "TICKET_TEMPLATES_TAB",
    },
    {
        path: "watchlists",
        loadChildren: () => import("./watchlists/watchlists.module").then(m => m.WatchlistsModule),
        title: "WATCHLISTS",
    },
    {
        path: "settings",
        loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule),
    },
    {
        path: "knowledge-base",
        component: KnowledgeBaseRedirectPageComponent,
        canActivate: [isLoggedInGuard],
    },
    {
        path: "saml-redirect",
        component: SamlRedirectPageComponent,
        canActivate: [notLoggedInGuard],
    },
    {
        path: "unauthorized",
        component: UnauthorizedPageComponent,
    },
    // Default route when no path is provided
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/dashboards/default",
    },
    // If no route matches, show the error page
    {
        path: "**",
        component: NotFoundPageComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadModulesOneByOne,
        }),
    ],
    exports: [RouterModule],
    providers: [
        { provide: APP_BASE_HREF, useValue: "" },
        { provide: PRODUCT_DOMAIN, useValue: SECURITY_DOMAIN },
        PreloadModulesOneByOne,
    ],
})
export class AppRoutingModule {
}
