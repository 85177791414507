export const salesForceSandboxTemplate = `
<section>
    <ul>
        <li>Alternatively:
            <a class="link-text" href="{0}">
                Click this link to be redirected to SalesForce Sandbox login</a>
        </li>
    </ul>
    </section>
`;
