import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatOption } from "@angular/material/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "autocomplete-input",
    template: `
        <input #autoCompleteInput
               type="text"
               class="underlined-text-box"
               [placeholder]="placeholder"
               [formControl]="inputControl"
               [matAutocomplete]="auto"
               (keyup.enter)="onEnter()"
               (blur)="onBlur($event)">
        <mat-autocomplete tab-selected
                          #auto="matAutocomplete"
                          class="autocomplete-input-panel"
                          (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    `,
    styles: [`
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .mat-mdc-option {
            line-height: 30px;
            height: 30px;
            min-height: 30px;
            font-size: 14px;
            padding: 0 12px;
            font-weight: $font-weight-normal;
            font-family: inherit;
        }

        .mat-mdc-option:hover {
            background: $ds-blue-1;
        }

        .underlined-text-box:focus {
            border-bottom-color: $ds-blue-6;
            background: $ds-blue-1;
        }
    `],
})
export class AutocompleteInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input() invalid = false;
    @Input() initialValue: string = "";
    @Input() allOptions: string[] = [];
    @Input() excludedOptions: string[] = [];
    @Input() placeholder: string;
    @Input() clearOnSubmit: boolean = true;
    // Detects changes when the user types inside the input and compares it to the filtered options for form validation
    @Input() autoDetectChange: boolean = false;

    @Output() optionSelected = new EventEmitter<string>();
    @Output() inputBlurred = new EventEmitter<HTMLInputElement>();
    @Output() valueChange = new EventEmitter<string>();

    inputControl = new FormControl();
    allowedOptions: string[] = [];
    filteredOptions$: Observable<string[]>;
    matAutocompleteOptions: QueryList<MatOption>;

    @ViewChild("autoCompleteInput", { read: MatAutocompleteTrigger })
    autoComplete: MatAutocompleteTrigger;

    ngOnInit(): void {
        this.filteredOptions$ = this.inputControl.valueChanges
            .pipe(map(value => this.filterOptions(value)));

        this.inputControl.setValue(this.initialValue);

        this.inputControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => this.valueChange.emit(value));

        // Workaround for an Angular bug: https://github.com/angular/components/issues/10079#issuecomment-619887760
        window.addEventListener("scroll", this.scrollListenerFunc, true);
    }

    ngOnDestroy(): void {
        window.removeEventListener("scroll", this.scrollListenerFunc, true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allOptions || changes.excludedOptions) {
            this.allowedOptions = (this.allOptions || []).filter(s => !(this.excludedOptions || []).includes(s));

            if (this.autoDetectChange) {
                this.inputControl.setValue("");
            }
        }

        if (changes.invalid) {
            if (this.invalid) {
                // the input will automatically clear errors when the value is changed, so add a
                // validator that keeps it as invalid if it's being controlled from outside
                this.inputControl.addValidators(() => ({ "invalid": true }));
                this.inputControl.setErrors({ "invalid": true });
            } else {
                this.inputControl.clearValidators();
                this.inputControl.setErrors(null);
            }
        }
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        this.optionSelected.emit(event.option.value);
        if (this.clearOnSubmit) {
            this.inputControl.setValue("");
        }
    }

    onEnter(): void {
        if (!this.inputControl.value) {
            return;
        }
        this.optionSelected.emit(this.inputControl.value);
        if (this.clearOnSubmit) {
            this.inputControl.setValue("");
        }
    }

    onBlur($event: FocusEvent) {
        this.inputBlurred.emit(<HTMLInputElement>$event.target);
    }

    private filterOptions(value: string): string[] {
        if (!value) {
            if (this.autoDetectChange) {
                this.optionSelected.emit("");
            }
            return [];
        }

        const filterValue = value.toLowerCase();
        const filteredOptions = this.allowedOptions.filter(option => option.toLowerCase().includes(filterValue))
                                                    .sort((a, b) => a.length - b.length);

        if (this.autoDetectChange) {
            const selectedOption = filteredOptions.filter(option => option.toLowerCase().length === filterValue.length);
            selectedOption.length > 0 ? this.optionSelected.emit(this.inputControl.value) : this.optionSelected.emit("");
        }

        return filteredOptions;
    }

    // This is a function as the same value needs to be passed to the add and remove event listener functions
    private scrollListenerFunc = () => {
        if (this.autoComplete.panelOpen) {
            this.autoComplete.updatePosition();
        }
    };
}
