import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OrgsService } from "@app2/account/orgs.service";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { WindowService } from "@app2/shared/services/window.service";
import { RouterService } from "@app2/shared/services/router.service";

@Injectable({
    providedIn: "root",
})
export class OnLoginRedirectService {
    constructor(private readonly environmentService: EnvironmentService,
                private readonly orgsService: OrgsService,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly routerService: RouterService,
                private readonly windowService: WindowService) {
    }

    /**
     * After the app has completed initializing, redirect to the original page that the user attempted to access (ot
     * the /login  or /select-org through which the user went to login).
     * This method also adds the orgId state parameter where needed.
     * This method needs an orgId and user to be already setup.
     */
    public doRedirect(): void {
        // We only need to use an explicit orgId for users with authorized orgs
        const needsOrgId = this.orgsService.getOrgsInfoSnapshot().authorizedOrgs.length > 0;
        const currentOrgId = this.orgsService.getCurrentOrg().id;

        const { redirectUrl } = this.route.snapshot.queryParams;
        if (!redirectUrl) {
            this.routerService.navigate(["/dashboards/default"], needsOrgId ? { orgId: currentOrgId } : undefined);
            return;
        }

        if (needsOrgId) {
            const consoleUrl = this.environmentService.getConfig().consoleUrl;
            const jsUrl = new URL(redirectUrl, consoleUrl);
            if (!jsUrl.searchParams.get("orgId")) {
                jsUrl.searchParams.set("orgId", currentOrgId);
            }
            const finalUrl = jsUrl.href.replace(consoleUrl, "");
            this.router.navigateByUrl(finalUrl);
        } else {
            this.router.navigateByUrl(redirectUrl);
        }
    }

    public doFreshdeskRedirect(): boolean {
        if (this.route.snapshot.queryParams.freshdesk) {
            this.windowService.navigateTo(this.environmentService.getConfig().userService + "/redirect/freshdesk");
            return true;
        }
        return false;
    }
}
