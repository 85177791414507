import { Injectable } from "@angular/core";
import { AuthStateService } from "@app2/account/auth-state.service";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { TimeService } from "@app2/shared/services/time.service";
import { combineLatest, fromEvent, interval, merge, ReplaySubject, Subscription } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";
import { moment, Moment } from "@app2/util/legacy-moment";

export const VALIDATION_INTERVAL = moment.duration(1, "minutes");
export const WARNING_INACTIVE_TIME = moment.duration(25, "minutes");
export const MAXIMUM_INACTIVE_TIME = moment.duration(30, "minutes");

@Injectable({
    providedIn: "root",
})
export class InactivityTimeoutService {

    private inactivityTimeoutEnabled$ = new ReplaySubject<boolean>(1);
    private lastKnownUserActive: Moment = this.timeService.nowUtc();
    private subscription: Subscription;

    constructor(private readonly storageService: StorageService,
                private readonly authStateService: AuthStateService,
                private readonly notificationsService: NotificationsService,
                private readonly timeService: TimeService,
                userService: UserService,
                orgsService: OrgsService) {

        combineLatest([userService.getCurrentUser$(), orgsService.getCurrentOrg$()])
            .pipe(filter(([user, org]) => !!user && !!org))
            .subscribe(() => {
                const enabled = this.storageService.get(Keys.inactivityTimeoutEnabled);
                this.inactivityTimeoutEnabled$.next(enabled || false);

                if (enabled) {
                    this.enableInactivityTimeout();
                }
            });
    }

    public getTimeoutEnabled$() {
        return this.inactivityTimeoutEnabled$.asObservable();
    }

    public setTimeoutEnabled(enabled: boolean) {
        this.storageService.set(Keys.inactivityTimeoutEnabled, enabled);
        this.inactivityTimeoutEnabled$.next(enabled);

        if (enabled) {
            this.enableInactivityTimeout();
        } else {
            this.disableInactivityTimeout();
        }
    }

    private enableInactivityTimeout() {
        // This observable notifies each time a user moves the mouse or presses a key. It has a throttle to
        // avoid executing the callback for every single event, instead, we execute the callback at most once every
        // X milliseconds.
        const sub1 = merge(fromEvent(document, "mousemove"), fromEvent(document, "keydown"))
            .pipe(throttleTime(VALIDATION_INTERVAL.asMilliseconds()))
            .subscribe(() => {
                this.lastKnownUserActive = this.timeService.nowUtc();
            });

        // This observable invokes a method on regular intervals to determine if the user has been inactive for too
        // long.
        const sub2 = interval(VALIDATION_INTERVAL.asMilliseconds())
            .subscribe(() => this.validateInactivity());

        this.subscription = sub1;
        this.subscription.add(sub2);
    }

    private validateInactivity() {
        const currentInactiveTime = this.timeService.nowUtc().diff(this.lastKnownUserActive, "minutes");
        if (currentInactiveTime >= MAXIMUM_INACTIVE_TIME.asMinutes()) {
            this.authStateService.logOut();
            this.disableInactivityTimeout();
        } else if (currentInactiveTime >= WARNING_INACTIVE_TIME.asMinutes()) {
            const remainingTime = MAXIMUM_INACTIVE_TIME.clone().subtract(currentInactiveTime, "minutes");
            this.notificationsService.showWarning("INACTIVITY_TIMEOUT_WARNING", remainingTime.asMinutes());
        }
    }

    private disableInactivityTimeout() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
