/* eslint-disable no-console */
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";
import { AppModule } from "./app/app.module";
import { enableProdMode } from "@angular/core";

if (environment.production) {
    enableProdMode();
}

// Bootstrap Angular
platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {
        // Print some debugging information
        console.group("DefenseStorm GRID Console");
        console.log("Version:", environment.appVersion);
        console.log("Built at:", document.lastModified);
        console.groupEnd();
    })
    .catch(err => console.error("Error in bootstrapping", err));
