export const openDnsTemplate = `
<div class="integration-modal-setup">
    <p>Use of this integration requires an <strong>Insights</strong> or <strong>Platform</strong> OpenDNS subscription
        level; the <strong>Professional</strong>  subscription level is incompatible due to lacking the S3 bucket log
        export feature.
    </p>
    <p>Please review your subscription package to confirm you have access to the following feature:</p>
    <ul>
        <li>Retain logs with Amazon Web Services integration using customer-managed or Cisco-managed S3 bucket</li>
    </ul>
    <p class="activate-open-dns">To activate your OpenDNS account please send a request to
        <a class="link-text" href="mailto:support@defensestorm.com">support@defensestorm.com</a>
    </p>
</div>
`;
