import { cyberFraudOfferingTemplate } from "@app2/shared/localization/templates/cyberFraudOffering";
import { promoteSavedQuery } from "@app2/shared/localization/templates/promoteSavedQuery";
import { android2faTemplate } from "@app2/shared/localization/templates/twoFactorSetup/android";
import { blackberry2faTemplate } from "@app2/shared/localization/templates/twoFactorSetup/blackberry";
import { ios2faTemplate } from "@app2/shared/localization/templates/twoFactorSetup/ios";
import { windows2faTemplate } from "@app2/shared/localization/templates/twoFactorSetup/windowsPhone";
import { cloudtrailTemplate } from "@app2/shared/localization/templates/integrations/cloudtrailSetup";
import { elbTemplate } from "@app2/shared/localization/templates/integrations/elbSetup";
import { azureTemplate, o365Template } from "@app2/shared/localization/templates/integrations/azureSetup";
import { openDnsTemplate } from "@app2/shared/localization/templates/integrations/openDnsSetup";
import { ffiecCatOverviewTemplate } from "@app2/shared/localization/templates/policy/policyCatOverview";
import { ffiecCatRiskProfileOverviewTemplate } from "@app2/shared/localization/templates/policy/catRiskProfileOverview";
import { salesForceTemplate } from "@app2/shared/localization/templates/integrations/salesForceSetup";
import { salesForceSandboxTemplate } from "@app2/shared/localization/templates/integrations/salesForceSandboxSetup";

export const defaultTemplates = Object.freeze({
    ANDROID_2FA_INSTALLATION_INSTRUCTIONS: android2faTemplate,
    BLACKBERRY_2FA_INSTALLATION_INSTRUCTIONS: blackberry2faTemplate,
    I_OS_2FA_INSTALLATION_INSTRUCTIONS: ios2faTemplate,
    WINDOWS_PHONE_2FA_INSTALLATION_INSTRUCTIONS: windows2faTemplate,
    CLOUDTRAIL_SET_UP_INSTRUCTIONS: cloudtrailTemplate,
    ELB_SET_UP_INSTRUCTIONS: elbTemplate,
    O365_SET_UP_INSTRUCTIONS: o365Template,
    AZURE_SET_UP_INSTRUCTIONS: azureTemplate,
    SALESFORCE_SET_UP_INSTRUCTIONS: salesForceTemplate,
    SALESFORCE_SANDBOX_SET_UP_INSTRUCTIONS: salesForceSandboxTemplate,
    ADD_OPEN_DNS_ACCOUNT_INSTRUCTIONS: openDnsTemplate,
    FFIEC_CAT_OVERVIEW: ffiecCatOverviewTemplate,
    FFIEC_CAT_RISK_PROFILE_OVERVIEW: ffiecCatRiskProfileOverviewTemplate,
    CYBER_FRAUD_OFFERING: cyberFraudOfferingTemplate,
    PROMOTE_SAVED_QUERY: promoteSavedQuery,
});
