import { Component } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { hasAnyFraudSku } from "@app2/fraud/utils";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { FeaturesService } from "@app2/shared/services/features.service";
import { Observable } from "rxjs";

@Component({
    selector: "navigation-top-bar-fraud",
    template: `
        <nav *ngIf="hasSku"
             class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "FRAUD" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isFraudInboxActive$ | async"
                        *dsPermissionsRequired="'ALERT_INBOX_VIEW'">
                        <a dsRouterLink="/fraud/inbox">{{ "INBOX" | localize }}</a></li>
                    <li [class.active]="navSectionService.isFraudTriggersActive$ | async"
                        *dsPermissionsRequired="'TRIGGER_VIEW'">
                        <a dsRouterLink="/fraud/triggers">{{ "TRIGGERS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isFraudLibraryTriggersActive$ | async"
                        *dsPermissionsRequired="'TRIGGER_VIEW'">
                        <a dsRouterLink="/fraud/library">{{ "DEFAULT_TRIGGERS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isFraudThreatmatchActive$ | async"
                        *dsPermissionsRequired="'THREATMATCH_VIEW'">
                        <a dsRouterLink="/fraud/threat">{{ "BASE_THREAT_MATCH" | localize }}</a></li>
                    <ng-container *ngIf="hasNewWatchlistsEnabled$ | async">
                        <li [class.active]="navSectionService.isFraudWatchlistsActive$ | async"
                            *dsPermissionsRequired="'THREATMATCH_VIEW'">
                            <a dsRouterLink="/fraud/watchlists">{{ "WATCHLISTS" | localize }}</a></li>
                    </ng-container>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarFraudComponent {
    hasSku: boolean;
    hasNewWatchlistsEnabled$: Observable<boolean>;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService,
                orgsService: OrgsService) {
        this.hasSku = hasAnyFraudSku(orgsService.getCurrentOrg().productSkus);
        this.hasNewWatchlistsEnabled$ = this.featuresService.getFeature$("newWatchlistPage");
    }
}
