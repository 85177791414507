export const android2faTemplate = `
<h3>Requirements</h3>
<p>To use Google Authenticator on your Android device, it must be running Android version 2.1 or later.</p>
<h3>Downloading the app</h3>
<ol>
    <li>Visit Google Play.</li>
    <li>Search for <strong>Google Authenticator</strong>.</li>
    <li>Download and install the application.</li>
    </ol>
<h3>Setting up the app</h3>
<ol>
    <li>On your phone, open the Google Authenticator application.</li>
    <li>
        If this is the first time you have used Authenticator, click the <strong>Add an account button</strong>.
        If you are adding a new account, choose “Add an account” from the app’s menu.
    </li>
    <li>To link your phone to your account:
        <ul>
            <li>
                <strong>Using QR code:</strong> Select <strong>Scan account barcode</strong>. If the Authenticator app
                cannot locate a barcode scanner app on your phone, you might be prompted to download and install
                one. If you want to install a barcode scanner app so you can complete the setup process, press
                <strong>Install</strong> then go through the installation process. Once the app is installed, reopen
                Google Authenticator, point your camera at the QR code on your computer screen.
            </li>
            <li>
                <strong>Using secret key</strong>: Select <strong>Manually add account</strong>, then enter the email
                address of your Google Account in the box next to <strong>Enter account name</strong>. Next, enter the
                secret key on your computer screen into the box under <strong>Enter key</strong>. Make sure you've
                chosen to make the key <strong>Time based</strong> and press "Save."
            </li>
        </ul>
    </li>
</ol>
`;
