import { Component, Input } from "@angular/core";

/**
 * @deprecated
 * When migrating an Angular1 component that uses `ng1-a-link` use instead a normal `a` with a `dsRouterLink`.
 */
@Component({
    selector: "ng1-a-link",
    template: `
        <a [dsRouterLink]="dsRouterLink" [queryParams]="queryParams">
            <ng-content></ng-content>
        </a>
    `,
})
export class Ng1ALinkComponent {
    @Input() dsRouterLink: string[];
    @Input() queryParams: Record<string, any>;
}
