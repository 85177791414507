import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from "@angular/core";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { toCanvas } from "qrcode";

@Component({
    selector: "qr-canvas",
    template: `
        <canvas #qrCodeCanvas></canvas>
    `,
})
export class QrCanvasComponent implements AfterViewInit, OnChanges {

    @ViewChild("qrCodeCanvas") qrCodeCanvas: ElementRef;
    @Input() secretUri: string;

    constructor(private readonly notificationsService: NotificationsService) {
    }

    ngOnChanges(): void {
        this.redrawCanvas();
    }

    ngAfterViewInit(): void {
        this.redrawCanvas();
    }

    private redrawCanvas(): void {
        if (!this.qrCodeCanvas || !this.secretUri) {
            return;
        }

        toCanvas(this.qrCodeCanvas.nativeElement, this.secretUri, { width: 225, margin: 0 })
            .catch(error => {
                const message = getMessageForError(error.error, "QR_CODE_GENERATION_ERROR");
                this.notificationsService.showError(message, error);
            });
    }
}
