import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { StorageServiceKeys } from "@app2/util/storage-service-keys";
import { combineLatest, Observable, ReplaySubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class CyberRiskReadinessService {
    private bannerOpen$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private storageService: StorageService,
                private orgsService: OrgsService,
                private userService: UserService) {
        combineLatest([this.userService.getCurrentUser$(), this.orgsService.getCurrentOrg$()])
            .subscribe(([user, org]) => {
                if (user && org) {
                    const state = this.storageService.get(Keys.cyberRiskReadinessBannerOpen) ?? true;
                    this.bannerOpen$.next(state);
                }
            });
    }

    public openBanner() {
        // the banner only lives on the new dashboards page
        this.storageService.set(StorageServiceKeys.useNewDashboards, true);
        this.storageService.set(Keys.cyberRiskReadinessBannerOpen, true);
        this.bannerOpen$.next(true);
    }

    public closeBanner() {
        this.storageService.set(Keys.cyberRiskReadinessBannerOpen, false);
        this.bannerOpen$.next(false);
    }

    public get isBannerOpen$(): Observable<boolean> {
        return this.bannerOpen$.asObservable();
    }
}