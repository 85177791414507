import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder } from "@angular/forms";
import { AssetClientService } from "@app2/clients/asset-client.service";
import { ipOrMacRequiredValidator, ipValidator, macValidator } from "@app2/shared/custom-form-validator";
import { IpMacPair } from "@app2/type-defs/assets/assets-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged, startWith } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "ip-mac-pairs-edit",
    template: `
        <form class="nice-form"
              [formGroup]="pairsForm">
            <table class="hover-table ip-mac-table">
                <thead>
                <tr>
                    <th class="hover-tab"></th>
                    <th>{{ 'ASSET_IP_ADDRESSES' | localize}}</th>
                    <th>{{ 'ASSET_MAC_ADDRESSES' | localize}}</th>
                    <th class="add-pair button-col add-button click-target"
                        (click)="addNewPair()"
                        *dsPermissionsRequired="'ASSET_EDIT'"
                        test-id="add-new-ip-mac-pair">
                        <i class="fas fa-plus"></i>
                    </th>
                </tr>
                </thead>
                <tbody formArrayName="pairs" class="dont-add-error-border">
                <tr *ngFor="let pair of pairs.controls; let i=index"
                    [formGroupName]="i"
                    class="dont-add-error-border">
                    <td class="hover-tab"></td>
                    <td>
                        <input type="text"
                               formControlName="ip"
                               [placeholder]="'ASSET_IP_ADDRESSES_PLACEHOLDER' | localize"
                               [attr.test-id]="'asset-ip-' + i"/>
                    </td>
                    <td>
                        <input type="text"
                               formControlName="mac"
                               [placeholder]="'ASSET_MAC_ADDRESSES_PLACEHOLDER' | localize"
                               [attr.test-id]="'asset-mac-' + i"/>
                    </td>
                    <td class="button-col"
                        *dsPermissionsRequired="'ASSET_EDIT'">
                        <div class="click-target" (click)="removePair(i)"
                             [attr.test-id]="'asset-pair-delete-' + i">
                            <i class="fas fa-trash alert"></i>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="pairs.length === 0">
                    <td class="no-data-text" colspan="4">{{ 'ASSET_NO_IP_MAC_PAIRS' | localize }}</td>
                </tr>
                </tbody>
            </table>
        </form>
    `,
})
export class IpMacPairsEditComponent implements OnInit {
    @Input() ipMacPairs: IpMacPair[];
    @Output() ipMacPairsChange = new EventEmitter<IpMacPair[]>();
    @Output() formValidityChange = new EventEmitter<boolean>();

    pairsForm = this.fb.group({
        pairs: this.fb.array([]),
    });

    constructor(private assetClient: AssetClientService,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.ipMacPairs.forEach(pair => this.addPair(pair));

        this.pairsForm.statusChanges
            .pipe(untilDestroyed(this), distinctUntilChanged(), startWith("VALID"))
            .subscribe(isValid => {
                this.formValidityChange.emit(isValid === "VALID");
            });
        this.pairsForm.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                if (this.pairsForm.valid) {
                    const pairs: IpMacPair[] = this.pairs.controls.map(pair => ({
                        ip: pair.value.ip || null,
                        mac: pair.value.mac || null,
                    }));
                    this.ipMacPairsChange.emit(pairs);
                }
            });
    }

    get pairs(): FormArray {
        return this.pairsForm.get("pairs") as FormArray;
    }

    addNewPair() {
        this.addPair({ ip: "", mac: "" });
    }

    removePair(index: number) {
        this.pairs.removeAt(index);
    }

    private addPair(pair: IpMacPair) {
        this.pairs.push(this.fb.group({
            ip: [pair.ip, [ipValidator()]],
            mac: [pair.mac, [macValidator()]],
        }, { validators: ipOrMacRequiredValidator() }));
    }
}
