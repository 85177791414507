import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserService } from "@app2/account/user.service";
import { hasPermissions } from "@app2/shared/util";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
    selector: "[dsPermissionsRequired]",
})
export class DsPermissionsRequiredDirective {
    private permissionsRequired: string;
    private currentPermissions: string[];
    private hasUserContent: boolean;

    @Input() set dsPermissionsRequired(permissions: string) {
        this.permissionsRequired = permissions;
        this.addOrRemoveElement();
    }

    constructor(private readonly templateRef: TemplateRef<any>,
                private readonly viewContainer: ViewContainerRef,
                private readonly cd: ChangeDetectorRef,
                userService: UserService) {
        userService.getCurrentPermissions$()
            .pipe(untilDestroyed(this))
            .subscribe(permissions => {
                this.currentPermissions = permissions;
                this.addOrRemoveElement();
            });
    }

    private addOrRemoveElement() {
        const hasPerms = hasPermissions(this.permissionsRequired, this.currentPermissions);
        if (!hasPerms && this.hasUserContent) {
            this.viewContainer.clear();
            this.hasUserContent = false;
        } else if (hasPerms && !this.hasUserContent) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasUserContent = true;

            // if this view is in a component with OnPush change detection,
            // the contents may not be up to date because it didn't exist when
            // the @Inputs were changed
            this.cd.detectChanges();
        }
    }
}
