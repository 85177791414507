import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface SidebarListItem {
    id: any;
    text: string;
    linkPath?: any[];
    tooltip?: string;
    extraData?: any;
    disabled?: boolean;
}

@Component({
    selector: "sidebar-list-section",
    template: `
        <ds-page-section [titleKey]="titleKey"
                         [tooltipKey]="tooltipKey"
                         [loading]="loading">
            <ds-item-list [items]="items"
                          [editPermission]="editPermissions"
                          [forceHideDeleteItem]="!deleteItem"
                          (deleteItem)="deleteItem.emit($event)">
            </ds-item-list>
            <ng-container *dsPermissionsRequired="editPermissions">
                <ds-button *ngIf="addItemKey"
                           type="blue-link"
                           (clicked)="addItem.emit()"
                           test-id="add-button">
                    <i class="fas fa-plus"></i> {{ addItemKey | localize }}
                </ds-button>
            </ng-container>
        </ds-page-section>
    `,
})
export class SidebarListSectionComponent {
    @Input() loading: boolean;
    @Input() titleKey: string;
    @Input() addItemKey: string;
    @Input() tooltipKey: string;
    @Input() items: SidebarListItem[];
    @Input() editPermissions: string;

    @Output() addItem = new EventEmitter<void>();
    @Output() deleteItem = new EventEmitter<SidebarListItem>();
}
