import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "@app2/account/user.service";


export const notLoggedInGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const userService = inject(UserService);
        const router = inject(Router);

        if (!userService.getCurrentUser()) {
            return true;
        }

        const queryParams = { url: state.url };
        return router.createUrlTree(["/unauthorized"], { queryParams });
    };
