export const ffiecCatRiskProfileOverviewTemplate = `
<h3 class="sub-header">What is Inherent Risk Profile?</h3>
<p class="text-justify">
    Cybersecurity inherent risk is the level of risk posed to the institution by the following:
</p>
<ul>
    <li>Technologies and Connection Types</li>
    <li>Delivery Channels</li>
    <li>Online/Mobile Products and Technology Services</li>
    <li>Organizational Characteristics</li>
    <li>External Threats</li>
</ul>
<p class="text-justify">
    Inherent risk incorporates the type, volume, and complexity of the institution’s operations and threats directed
    at the institution. Inherent risk does not include mitigating controls. The Inherent Risk Profile includes
    descriptions of activities across risk categories with definitions for the least to most levels of inherent
    risk. The profile helps management determine exposure to risk that the institution’s activities, services,
    and products individually and collectively pose to the institution.
</p>
<figure>
    <img class="cat-policy-img"
         src="assets/images/FFIEC-CAT-Risk-Profile-1.png"
         alt="Inherent Risk">
</figure>
<p class="text-justify">
    When each of the activities, services, and products are assessed, management can review the results and determine
    the institution’s overall inherent risk profile. To derive your Inherent Risk Profile move to the Risk Profile tab
    and rate the described activities according to your current business environment. Each question contains both a
    description and rating text to help you decide where your company falls between least and most risk. Once the Risk
    Profile is complete proceed to the Maturity Evaluation Overview from the dropdown for the second step.
</p>
`;
