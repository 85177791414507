import { Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ShortcutsService } from "../shared/services/shortcuts.service";

@UntilDestroy()
@Component({
    selector: "shortcut-keys-toggle",
    template: `
        <a (click)="toggle()" class="toggleable-menu-item">
            {{ "SHORTCUT_KEYS" | localize }}
            <span *ngIf="enabled" class="toggle-on">
                {{ "SHORTCUT_KEYS_ON" | localize }}
            </span>
            <span *ngIf="!enabled" class="toggle-off">
                {{ "SHORTCUT_KEYS_OFF" | localize }}
            </span>
        </a>
    `,
})
export class ShortcutKeysToggleComponent {
    enabled: boolean;

    constructor(private readonly shortcutsService: ShortcutsService) {
        this.shortcutsService.getShortcutsActive$()
            .pipe(untilDestroyed(this))
            .subscribe(enabled => {
                this.enabled = enabled;
            });
    }

    toggle() {
        this.shortcutsService.setShortcutsActive(!this.enabled);
    }

}

