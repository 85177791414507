import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";
import { localization } from "@app2/shared/localization/localization";

export type TicketUserFriendlyError =
    GlobalTicketInboxInvalidOrgIdsError
    | InvalidNewStateError
    | InvalidTaskParentError
    | InvalidTicketDueOnError
    | InvalidTicketOwnerError
    | InvalidTicketTimeZoneError
    | InvalidTimezoneError
    | NoSlaTicketClaimError
    | SlugIdVersionPairNullPairError
    | SlugIdVersionPairNullVersionError
    | SlugIdVersionPairNullIdError
    | TaskTooManyLevelsError
    | TaskCycleError
    | TicketMissingResolutionError
    | UsingResolutionForNonIncidentError;

interface InvalidTaskParentError {
    key: "TASK_INVALID_PARENT";
}

interface TaskCycleError {
    key: "TASK_CYCLE_DETECTED";
}

interface TaskTooManyLevelsError {
    key: "TASK_TOO_MANY_LEVELS";
    maxLevels: number;
}

interface SlugIdVersionPairNullPairError {
    key: "SLUG_ID_VERSION_NULL_PAIR"
}

interface SlugIdVersionPairNullIdError {
    key: "SLUG_ID_VERSION_NULL_ID_PAIR";
}

interface SlugIdVersionPairNullVersionError {
    key: "SLUG_ID_VERSION_NULL_VERSION_PAIR";
}

interface InvalidTicketOwnerError {
    key: "INVALID_OWNER_FOR_TICKET";
    orgId: uuid;
    id: uuid;
}

interface InvalidTicketTimeZoneError {
    key: "INVALID_TICKET_TIME_ZONE_ID";
    timeZoneId: string;
}

interface InvalidTimezoneError {
    key: "INVALID_TIMEZONE";
    timezone: string;
}

interface InvalidTicketDueOnError {
    key: "INVALID_TICKET_DUE_ON" | "INVALID_TICKET_DUE_DATE";
    dueOn: string;
}

interface NoSlaTicketClaimError {
    key: "CLAIMING_TICKET_WITHOUT_SLA";
}

interface GlobalTicketInboxInvalidOrgIdsError {
    key: "INVALID_ORG_IDS";
    orgIds: uuid[];
}

interface InvalidNewStateError {
    key: "INVALID_NEW_STATE";
    state: string; // TicketState
}

interface TicketMissingResolutionError {
    key: "INVALID_NEW_STATE_MISSING_RESOLUTION";
}

interface UsingResolutionForNonIncidentError {
    key: "USING_RESOLUTION_KEY_FOR_NON_INCIDENT"
}

export function getMessageForTicketUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "INVALID_OWNER_FOR_TICKET":
            return localization.getString("INVALID_OWNER_FOR_TICKET");
        case "TASK_INVALID_PARENT":
            return localization.getString("TASK_INVALID_PARENT");
        case "TASK_CYCLE_DETECTED":
            return localization.getString("TASK_CYCLE_DETECTED");
        case "TASK_TOO_MANY_LEVELS":
            return localization.getString("TASK_TOO_MANY_LEVELS", error.maxLevels);
        case "SLUG_ID_VERSION_NULL_ID_PAIR":
        case "SLUG_ID_VERSION_NULL_VERSION_PAIR":
        case "SLUG_ID_VERSION_NULL_PAIR":
            return localization.getString("INVALID_SLUG_ID_VERSION_PAIRS");
        case "INVALID_TICKET_TIME_ZONE_ID":
            return localization.getString("INVALID_TICKET_TIMEZONE", error.timeZoneId);
        case "INVALID_TIMEZONE":
            return localization.getString("INVALID_TIMEZONE", error.timezone);
        case "INVALID_TICKET_DUE_ON":
        case "INVALID_TICKET_DUE_DATE":
            return localization.getString("INVALID_TICKET_DUE_DATE");
        case "CLAIMING_TICKET_WITHOUT_SLA":
            return localization.getString("CLAIMING_TICKET_WITHOUT_SLA");
        case "INVALID_ORG_IDS":
            return localization.getString("GLOBAL_TICKET_INBOX_INVALID_ORGS", error.orgIds.join(", "));
        case "INVALID_NEW_STATE":
            return localization.getString("INVALID_NEW_STATE", error.state);
        case "INVALID_NEW_STATE_MISSING_RESOLUTION":
            return localization.getString("RESOLVED_STATE_MISSING_RESOLUTION");
        case "USING_RESOLUTION_KEY_FOR_NON_INCIDENT":
            return localization.getString("ONLY_INCIDENTS_CAN_USE_RESOLUTIONS");
    }
}