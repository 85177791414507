export const salesForceTemplate = `
<p class="extra-padding-left">To integrate a SalesForce account to your DefenseStorm account, follow these steps:</p>
<h3 class="sub-header">Step One: <strong>Authorize DefenseStorm</strong></h3>
<section>
    <ul>
        <li>Once you click on the link below you will be redirected to SalesForce login</li>
        <li>SalesForce will then prompt you to accept various permissions DefenseStorm needs to access your SalesForce data
        </li>
        <li>After you read through and click the <strong>Accept</strong> button, SalesForce should redirect you back to this
            page</li>
        <li>Ready?
            <a class="link-text" href="{0}">
                Click this link to be redirected to SalesForce login</a>
        </li>
    </ul>
    </section>
`;
