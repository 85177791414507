// INTERNAL: Use StorageService (storage.service.ts) instead of the functions in this file

import { StorageServiceKeys } from "@app2/util/storage-service-keys";

class  LocalStorageUtils {
    public getUserOrgLocalStorageData(unprefixedKey: string, userId: uuid, orgId: uuid): any {
        return this.getLocalStorageData(this.getPrefixedKeyBy(unprefixedKey, userId, orgId));
    }

    public getUserLocalStorageData(unprefixedKey: string, userId: uuid): any {
        return this.getLocalStorageData(this.getPrefixedKeyBy(unprefixedKey, userId));
    }

    public setUserOrgLocalStorageData(unprefixedKey: string, userId: uuid, orgId: uuid, data): void {
        this.setLocalStorageData(this.getPrefixedKeyBy(unprefixedKey, userId, orgId), data);
    }

    public setUserLocalStorageData(unprefixedKey: string, userId: uuid, data: any): void {
        this.setLocalStorageData(this.getPrefixedKeyBy(unprefixedKey, userId), data);
    }

    public keyExistsInUserOrgLocalStorageData(unprefixedKey: string, userId: uuid, orgId: uuid): boolean {
        return this.localStorageKeyExists(this.getPrefixedKeyBy(unprefixedKey, userId, orgId));
    }

    private setLocalStorageData(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    private localStorageKeyExists(key: string): boolean {
        return localStorage.getItem(key) !== null;
    }

    private getLocalStorageData(key: string): any {
        const value = localStorage.getItem(key);
        // TODO: Fixing an issue with a bad value getting into LocalStorage. Remove this after September 1 2024
        if (key.endsWith(StorageServiceKeys.useNewDashboards) && value === "undefined") {
            localStorage.setItem(key, "false");
            return false;
        }
        return value ? JSON.parse(value) : undefined;
    }

    private getPrefixedKeyBy(unprefixedKey: string, userId: uuid, orgId?: uuid): string {
        const userPrefix = userId ? userId + "_" : "";
        const orgPrefix = orgId ? orgId + "_" : "";
        return userPrefix + orgPrefix + unprefixedKey;
    }
}

export const localStorageUtils = new LocalStorageUtils(); //Singleton
