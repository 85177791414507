import { Component, Input, OnInit } from "@angular/core";
import { PeopleService, UsersById } from "@app2/account/people.service";
import { Observable } from "rxjs";
import { getName } from "@app2/shared/util";

@Component({
    selector: "person-name",
    template: `
        <ng-container *ngIf="isSystem; else noSystem">{{ 'SYSTEM_USER' | localize }}</ng-container>
        <ng-template #noSystem>
            <ng-container *ngIf="people$ | async as people">
                <!-- If the user summary can't be accessed, the link will be unclickable because there is no text in it-->
                <!-- TODO: make this use the person's homeOrgId to open the link because it may be a person from a different org -->
                <a *ngIf="linkToUsersPage"
                   class="users-link"
                   dsRouterLink="/settings/users">
                    {{ getPersonName(people, personId, preferFullName) }}
                </a>
                <ng-container *ngIf="!linkToUsersPage">
                    {{ getPersonName(people, personId, preferFullName) }}
                </ng-container>
            </ng-container>
        </ng-template>
    `,
    styles: [`
        .users-link:not(:hover) {
            color: inherit;
        }
    `],
})
export class PersonNameComponent implements OnInit {
    //eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("id") personId: uuid;
    @Input() isSystem: boolean;
    //eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("fullName") preferFullName = true;
    @Input() linkToUsersPage = true;

    people$: Observable<UsersById>;

    constructor(private peopleService: PeopleService) {
    }

    ngOnInit() {
        this.people$ = this.peopleService.getAllUsersById$();
    }

    getPersonName(people: UsersById, personId: uuid, preferFullName: boolean) {
        return getName(people, personId, preferFullName);
    }
}
