/* eslint-disable no-console */

// including the numbers in LogLevel is not necessary (these are the defaults) but I think they make the code clearer
enum LogLevel {
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
}

// The Logger class is deliberately not exported in order to make its usage simpler.
class Logger {
    constructor(private logLevel: LogLevel) {}
    trace(value, ...rest) {
        if (this.logLevel <= LogLevel.TRACE) {
            console.trace(value, ...rest);
        }
    }
    debug(value, ...rest) {
        if (this.logLevel <= LogLevel.DEBUG) {
            console.debug(value, ...rest);
        }
    }

    info(value, ...rest) {
        if (this.logLevel <= LogLevel.INFO) {
            console.info(value, ...rest);
        }
    }

    warn(value, ...rest) {
        if (this.logLevel <= LogLevel.WARN) {
            console.warn(value, ...rest);
        }
    }

    error(value, ...rest) {
        if (this.logLevel <= LogLevel.ERROR) {
            console.error(value, ...rest);
        }
    }
}

// Currently this just mimics our use of angularjs' log. We can be more particular about it later if we need to.
export const log = isDevOrLocal(window.location.host) ? new Logger(LogLevel.DEBUG) : new Logger(LogLevel.INFO);

export function isDevOrLocal(host) {
    return /localhost/.test(host) || /127\.0\.0\.1/.test(host) || /dev/.test(host);
}
