import { Component, OnInit } from "@angular/core";
import { UserClientService } from "@app2/clients/user-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { ActivatedRoute } from "@angular/router";

// The user should be directed to this by KnowledgeOwl URL with the `r` parameter set
// Send that r value to UserService, and then go to the URL that it returns
// See: https://support.knowledgeowl.com/help/remote-authentication

@Component({
    selector: "knowledge-base-redirect-page",
    template: `
        <h1>{{ "KNOWLEDGE_BASE_AUTH" | localize }}</h1>
        <div class="sheet full-width">
            <div class="padded margin-bottom" loadable [loading]="loading">
                <div *ngIf="!error && !loading">
                    <span [innerHTML]="'REDIRECT_TO_KNOWLEDGE_BASE' | localize :loginUrl"></span>
                </div>
                <div *ngIf="error && !loading">
                    <button type="button" class="link" (click)="redirect()">
                        {{ "KNOWLEDGE_BASE_ERROR" | localize}}
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class KnowledgeBaseRedirectPageComponent implements OnInit {
    loading: boolean = true;
    error: boolean = false;
    loginUrl: string;

    constructor(private route: ActivatedRoute,
                private userClient: UserClientService,
                private notificationService: NotificationsService) {
    }

    ngOnInit(): void {
        this.redirect();
    }

    redirect() {
        this.loading = true;
        this.error = false;
        this.userClient.getKnowledgeBaseLogin(this.route.snapshot.queryParams.r)
            .then(loginDetails => {
                // this should just cause the browser to go to the right place;
                // if that doesn't work, also provide a link
                window.location.href = loginDetails.loginUrl;
                this.loginUrl = loginDetails.loginUrl;

                // keep the spinner for a few seconds while the user is being redirected;
                // I don't want to show a bunch of text that the user won't have time to read
                const self = this;
                setTimeout(() => self.loading = false, 4000);
            })
            .catch(err => {
                const message = getMessageForError(err.error, "ERROR_LOGGING_IN_TO_KNOWLEDGE_BASE");
                this.notificationService.showError(message, err);
                this.error = true;
                this.loading = false;
            });
    }
}
