import moment, { duration, Duration, Moment } from "moment-timezone/builds/moment-timezone-with-data-10-year-range.js";

/** @deprecated Use js-joda instead */
const _moment = moment;
/** @deprecated Use js-joda instead */
const _duration = duration;
/** @deprecated Use js-joda instead */
type _Duration = Duration;
/** @deprecated Use js-joda instead */
type _Moment = Moment;

export {
    _moment as moment,
    _duration as duration,
    _Moment as Moment,
    _Duration as Duration
};
