import { Injectable } from "@angular/core";
import { UserService } from "@app2/account/user.service";
import { combineLatest, ReplaySubject } from "rxjs";
import { OrgsService } from "@app2/account/orgs.service";
import { filter } from "rxjs/operators";
import { StorageService, Keys } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class ShortcutsService {

    private shortcutsActive$ = new ReplaySubject<boolean>(1);

    constructor(private readonly storageService: StorageService,
                userService: UserService,
                orgsService: OrgsService) {
        combineLatest([userService.getCurrentUser$(), orgsService.getCurrentOrg$()])
            .pipe(filter(([user, org]) => !!user && !!org))
            .subscribe(() => {
                const currentValue = this.storageService.get(Keys.shortcutKeysEnabled);
                this.shortcutsActive$.next(currentValue || false);
            });
    }

    public getShortcutsActive$() {
        return this.shortcutsActive$.asObservable();
    }

    public setShortcutsActive(active: boolean) {
        this.storageService.set(Keys.shortcutKeysEnabled, active);
        this.shortcutsActive$.next(active);
    }
}
