import { Component, Input } from "@angular/core";
import { NotificationType } from "@app2/shared/services/notifications.service";

@Component({
    selector: "notification-icon",
    template: `
        <i class="fa fa-2x far"
           [class.fa-circle-exclamation]="type === 'alert' || type === 'warning'"
           [class.fa-circle-info]="type === 'info'"
           [class.fa-circle-check]="type === 'success'"
           [class.success]="type === 'success'"
           [ngClass]="type === 'other' ? iconName : ''"></i>
    `,
})
export class NotificationIconComponent {
    @Input() type: NotificationType;
    @Input() iconName: string;
}
