import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { defaultFeatures, Features, orgFeatureOverrides } from "@app2/util/features";
import { Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class FeaturesService {

    private enabledFeatures: Features = {};
    private enabledFeatures$ = new ReplaySubject<Features>(1);
    private urlFeatures: Features = {};
    private currentOrgId: uuid;

    constructor(private readonly orgService: OrgsService,
                private readonly route: ActivatedRoute) {
        // Each time the url changes, take a look at which features are enabled
        route.queryParams
            .subscribe(({feature}) => {
                this.urlFeatures = FeaturesService.getUrlFeatures(feature);
                this.updateFeatures();
            });

        this.orgService.getCurrentOrg$()
            .subscribe(currentOrg => {
                this.currentOrgId = currentOrg && currentOrg.id;
                this.updateFeatures();
            });
    }

    /**
     * @returns a combination of default features, features enabled on each org, and features enabled through the url.
     */
    public getFeatures$(): Observable<Features> {
        return this.enabledFeatures$.asObservable();
    }

    public getFeatures(): Features {
        return this.enabledFeatures;
    }

    /**
     * @returns an observable that tells whether a given feature is enabled for the org.
     */
    public getFeature$(feature: string): Observable<boolean> {
        return this.getFeatures$()
            .pipe(
                map(features => features[feature]),
                distinctUntilChanged(),
            );
    }

    protected updateFeatures() {
        const enabledFeatures = {
            ...this.getDefaultFeatures(),
            ...this.getOrgOverrides(this.currentOrgId),
            ...this.urlFeatures,
        };
        this.enabledFeatures = enabledFeatures;
        this.enabledFeatures$.next(enabledFeatures);
    }

    /**
     * Useful for mocking in tests.
     */
    protected getDefaultFeatures(): Features {
        return defaultFeatures;
    }

    /**
     * Useful for mocking in tests.
     */
    protected getOrgOverrides(orgId: uuid): Features {
        return orgFeatureOverrides[orgId] || {};
    }

    /**
     * Gets a set of features from the url parameters.
     */
    private static getUrlFeatures(fromUrl: string | string[]): Features {
        if (!fromUrl) {
            return {};
        }

        // If there is more than one feature, Angular's router passes `features` as an array, but if there is only
        // one, it passes `features` as a string.
        if (typeof fromUrl === "string") {
            const feature = <string>fromUrl;
            return { [feature]: true };
        }

        const features: Features = {};
        for (let feature of fromUrl) {
            features[feature] = true;
        }
        return features;
    }
}
