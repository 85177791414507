import { DestroyRef, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

/**
 * This service is useful for allowing mocking from tests functions that are at the window level.
 */

export interface WindowMessageObject {
    event: object;
    window: Window;
}

export type WindowServiceCallback = (...args: any[]) => void;

@Injectable({
    providedIn: "root",
})
export class WindowService {

    protected messagesSubject = new Subject<WindowMessageObject>();

    constructor() {
    }

    // Useful to avoid creating real timers from tests where we don't want to.
    // Otherwise, we can end up with problematic: "Error: 1 periodic timer(s) still in the queue."
    public setTimeout(callback: WindowServiceCallback, ms: number = 0,
                      destroyRef?: DestroyRef): NodeJS.Timeout {
        const timeoutId = setTimeout(callback, ms);
        if (destroyRef) {
            destroyRef.onDestroy(() => this.clearTimeout(timeoutId));
        } else {
            return timeoutId;
        }
    }

    /**
     * Remember to invoke clearInterval afterwards unless you pass a `destroyRef`.
     *
     * The parameter "intervalName" is an arbitrary string, used to distinguish between setInterval invocations.
     * It is only used for mocking in tests. Look at MockWindowService.
     */
    public setInterval(callback: WindowServiceCallback, ms: number, _intervalName?: string,
                       destroyRef?: DestroyRef): NodeJS.Timeout {
        const intervalId = setInterval(callback, ms);
        if (destroyRef) {
            destroyRef.onDestroy(() => this.clearInterval(intervalId));
        } else {
            return intervalId;
        }
    }

    public clearTimeout(timeoutId: NodeJS.Timeout): void {
        return clearTimeout(timeoutId);
    }

    public clearInterval(intervalId: NodeJS.Timeout): void {
        return clearInterval(intervalId);
    }

    public navigateTo(url: string): void {
        window.location.href = url;
    }

    public get navigator(): Navigator {
        return window.navigator;
    }

    public closeTab(): void {
        window.close();
    }

    public open(url, target, features): WindowProxy {
        return window.open(url, target, features);
    }

    /**
     * When a page opens a new tab and passes `rel="opener"`, the child or parent tab can use this method to listen
     * to messages from the other tab.
     */
    public onMessageReceived$(): Observable<object> {
        if (!window["dsOnMessageReceived"]) {
            window["dsOnMessageReceived"] = (event: object, window: Window) => {
                this.messagesSubject.next({ event: event, window: window });
            };
        }
        return this.messagesSubject.asObservable();
    }

    /**
     * When a page is opened by another with the `rel="opener"` attribute set, the child or parent tab can use this
     * method to send messages to the other tab.
     */
    public sendMessage(event: object, childWindowObject?: Window): void {
        if (childWindowObject) {
            childWindowObject["dsOnMessageReceived"](event, window);
        } else if (window.opener) {
            window.opener["dsOnMessageReceived"](event, window);
        }
    }

    public copyToClipboard(text: string) {
        navigator.clipboard.writeText(text);
    }
}
