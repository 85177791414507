import { Component } from "@angular/core";

/**
 * This class is meant to preload some icons for faster use, like:
 * <i data-fa-symbol="calendar" class="far fa-calendar"></i>
 *
 * We may need it if we switch to Svg icons in FontAwesome. Leaving it around as it's uncertain if we'll need it in the
 * short term.
 */
@Component({
    selector: "fa-pro-icons",
    template: `
        <i data-fa-symbol="calendar" class="far fa-calendar"></i>
        <i data-fa-symbol="clock" class="far fa-clock"></i>
    `,
})
export class FaProIconsComponent {
    constructor() {
    }
}
