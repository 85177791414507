import { BeginEndRange } from "@app2/shared/type-defs";
import {
    SearchParams,
    SearchQuery,
    SearchWatchlistFilter,
    SelectedFilters,
    SortOption
} from "@app2/type-defs/search/search-types";
import * as _ from "underscore";

export interface QueryObject {
    query_string: string;
    begin: date;
    end: date;
    include?: SearchWatchlistFilter;
    exclude?: SearchWatchlistFilter;
    maxTerms?: number;
}

interface WatchlistDetails {
    filterField: string;
    filterListId: uuid;
    excludeField: string;
    excludeListId: uuid;
}

export function toSearchQuery(queryString: string, range: BeginEndRange, filters?: SelectedFilters, include?: SearchWatchlistFilter, exclude?: SearchWatchlistFilter): QueryObject {
    return {
        query_string: toFilteredQueryString(queryString, filters),
        begin: range.begin,
        end: range.end,
        include,
        exclude,
    };
}

export function toSearchParams(query: QueryObject, pageNumber: number, pageSize: number, sortOptions: SortOption, from?: number): SearchParams {
    if (pageNumber < 1) {
        throw new Error("pageNumber begins at 1, not 0");
    }
    let sorts: any[]  = [];
    if (sortOptions) {
        sorts = [{
            field_name: sortOptions.name,
            sort_order: sortOptions.desc ? "DESC" : "ASC",
        }];
    }

    return {
        size: pageSize,
        from: from ? from : (pageNumber - 1) * pageSize,
        sorts,
        query: <SearchQuery><unknown>query, //TODO: untangle the type errors here
    };
}

/**
 * Return value is ordered with include then exclude watchlists
 */
export function toSearchWatchlistFilters(watchlist?: WatchlistDetails): SearchWatchlistFilter[] {
    return [
        watchlist?.filterListId
            ? { field: watchlist.filterField, id: watchlist.filterListId }
            : undefined,
        watchlist?.excludeListId
            ? { field: watchlist.excludeField, id: watchlist.excludeListId }
            : undefined,
    ];
}

export function toFilteredQueryString(queryString: string, filters?: SelectedFilters): string {
    const filterString = _.chain(filters || {})
        .map((values, term) => {
            const valuesStr = values
                .map(value => {
                    // The reserved characters are: + - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \ /
                    if (/[\s\/\\=&|><!\(\)\{}\[\]\^~\*\?:"+-]/g.test(value)) {
                        const escaped = value.replace(/\\/g, "\\\\") // replace \ with \\, must be first
                            .replace(/"/g, '\\"'); // replace " with \"
                        return `"${escaped}"`;
                    } else {
                        return value;
                    }
                })
                .join(" OR ");
            return `${term}:(${valuesStr})`;
        })
        .value()
        .join(" AND ");
    if (filterString) {
        if (queryString) {
            return `(${queryString}) AND (${filterString})`;
        } else {
            return filterString;
        }
    } else {
        return queryString || "";
    }
}
