import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";

@Component({
    selector: "navigation-top-bar-tickets",
    template: `
        <nav class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "BASE_TICKETS" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isIncidentsActive$ | async"
                        *dsPermissionsRequired="'INCIDENT_VIEW'">
                        <a dsRouterLink="/tickets/incident">
                            {{ 'INCIDENTS' | localize }}
                        </a>
                    </li>
                    <ng-container *featureGate="'universalTicketInbox'">
                        <li [class.active]="navSectionService.isInboxIncidentsActive$ | async">
                            <a dsRouterLink="/universal-ticket-inbox/incident">
                                {{ "GLOBAL_INCIDENTS_TAB" | localize }}
                            </a>
                        </li>
                        <li [class.active]="navSectionService.isInboxTasksActive$ | async">
                            <a dsRouterLink="/universal-ticket-inbox/task">
                                {{ 'GLOBAL_TASKS_TAB' | localize }}
                            </a>
                        </li>
                        <ng-container *featureGate="'testInbox'">
                            <ng-container *dsPermissionsRequired="'TRAC_ENGINEER'">
                                <li [class.active]="navSectionService.isInboxDraftsActive$ | async">
                                    <a dsRouterLink="/universal-ticket-inbox/draft">
                                        {{ 'GLOBAL_DRAFTS_TAB' | localize }}
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *dsPermissionsRequired="'TRAC_USER'">
                        <li [class.active]="navSectionService.isTicketTemplateActive$ | async">
                            <a dsRouterLink="/ticket-templates">{{ "TICKET_TEMPLATES_TAB" | localize }}</a>
                        </li>
                    </ng-container>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarTicketsComponent {
    constructor(public navSectionService: ActiveNavSectionService) {
    }
}
