import { booleanAttribute, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserService } from "@app2/account/user.service";
import { Observable, of } from "rxjs";

@Component({
    selector: "ds-toggle",
    template: `
        @if (toggling) {
            <i class="fa fa-spinner fa-spin"></i>
        } @else if (canEdit || (hasPermissionsRequired$ | async)) {
            <a (click)="toggleEnabled()" test-id="clickable-toggle-button">
                <i class="far" [ngClass]="enabled ? 'primary fa-toggle-large-on' : 'secondary fa-toggle-large-off'"></i>
            </a>
        } @else {
            <i class="far secondary" [ngClass]="enabled ? 'fa-toggle-large-on' : 'fa-toggle-large-off'"></i>
        }
    `,
})
export class DsToggleComponent implements OnChanges {
    // The component is editable if at least one of "permissionsRequired" and "canEdit" yield true
    @Input() permissionsRequired: string;
    @Input({ transform: booleanAttribute }) canEdit: boolean;
    @Input({ transform: booleanAttribute }) enabled: boolean;
    @Input({ transform: booleanAttribute }) toggling: boolean;

    @Output() enabledChange = new EventEmitter<boolean>();

    hasPermissionsRequired$: Observable<boolean>;

    constructor(private userService: UserService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.permissionsRequired) {
            if (this.permissionsRequired) {
                this.hasPermissionsRequired$ = this.userService.hasPermission$(this.permissionsRequired);
            } else {
                this.hasPermissionsRequired$ = of(false);
            }
        }
    }

    toggleEnabled() {
        this.enabledChange.emit(!this.enabled);
    }
}
