import { Component, Input, OnInit } from "@angular/core";
import { RatedThreat } from "@app2/type-defs/threat/threat-types";
import { ThreatClientService } from "@app2/clients/threat-client.service";
import { fileHashRegex } from "@app2/threatmatch/threatmatch-page/constants";
import { Moment } from "@app2/util/legacy-moment";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { TimeService } from "@app2/shared/services/time.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

//TODO: this tag needs to be renamed to match the filename
@Component({
    selector: "threat-rating",
    template: `
<pr-dropdown *dsPermissionsRequired="'RATE_THREAT_EDIT'">
    <span pr-dropdown-trigger>
        <span *ngIf="!ratedThreat.rating"
              class="unrated-threat">
            {{'RATE' | localize}}
        </span>
        <span *ngIf="ratedThreat.rating"
              class="rated-threat"
              [ngClass]="'rating-'+ratedThreat.rating"
              title="{{'RATED_ON' | localize: (ratedThreat.ratingTime.toString() | shortDate) }}">
            {{ratedThreat.rating | localize}}
        </span>
    </span>
            <ul pr-dropdown-body>
                <li (click)="rate(threat, 'HIGH')"
                    [ngClass]="{'active': ratedThreat.rating === 'HIGH'}">
                    {{'HIGH_RISK' | localize}}
                    <i class="ion-checkmark"></i>
                </li>
                <li (click)="rate(threat, 'MEDIUM')"
                    [ngClass]="{'active': ratedThreat.rating === 'MEDIUM'}">
                    {{'MEDIUM_RISK' | localize}}
                    <i class="ion-checkmark"></i>
                </li>
                <li (click)="rate(threat, 'LOW')"
                    [ngClass]="{'active': ratedThreat.rating === 'LOW'}">
                    {{'LOW_RISK' | localize}}
                    <i class="ion-checkmark"></i>
                </li>
                <li class="no-hover" [ngClass]="{'active': ratedThreat.rating === 'HARMLESS'}">
                    {{'HARMLESS_AND_EXCLUDE' | localize}}...
                    <i class="ion-checkmark"></i>
                </li>
                <li class="indent" (click)="ignoreHarmlessForMonth(threat)">... {{'FOR_ONE_MONTH' | localize}}</li>
                <li class="indent" (click)="ignoreHarmlessForever(threat)">... {{'FOREVER' | localize}}</li>
                <li (click)="clearRating(threat)"
                    [ngClass]="{'active': !ratedThreat.rating}">
                    {{'CLEAR_RATING' | localize}}
                    <i class="ion-checkmark"></i>
                </li>
            </ul>
</pr-dropdown>
    <span *dsPermissionsRequired="'RATE_THREAT_VIEW, !RATE_THREAT_EDIT'">
    <span *ngIf="!ratedThreat.rating"
          [ngStyle]="{'cursor': 'default'}"
          class="unrated-threat">
        {{'RATE' | localize}}
    </span>
    <span *ngIf="ratedThreat.rating"
          class="rated-threat"
          [ngStyle]="{'cursor': 'default'}"
          [ngClass]="'rating-'+ratedThreat.rating"
          title="{{'RATED_ON' | localize: (ratedThreat.ratingTime.toString() | shortDate) }}">
        {{ratedThreat.rating | localize}}
    </span>
</span>`,
})
export class ThreatRatingDropdownComponent implements OnInit {
    @Input() threat: string;

    isSignature: boolean;
    ratedThreat: RatedThreat;
    ThreatMatchHarmless = "HARMLESS";

    constructor(private readonly threatClient: ThreatClientService,
                private readonly notificationsService: NotificationsService,
                private readonly time: TimeService) {

    }

    ngOnInit() {
        this.isSignature = fileHashRegex.test(this.threat);
        this.ratedThreat = {rating: null, ratingTime: null};
        this.loadRating();
    }

    loadRating() {
        if (this.isSignature) {
            this.threatClient.getRatedSignature({hex: this.threat, type: null})
                .then(rts => this.ratedThreat = rts);
        } else {
            this.threatClient.getRatedHost(this.threat)
                .then(rth => this.ratedThreat = rth);
        }

    }

    rate(threat: string, rating: string) {
        if (this.isSignature) {
            this.threatClient.rateSignature(threat, null, rating)
                .then(ratedSig => this.ratedThreat = ratedSig)
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_RATING_THREAT_SIGNATURE");
                    this.notificationsService.showError(message, err);
                });
        } else {
            this.threatClient.rateHost(threat, rating)
                .then(ratedHost => this.ratedThreat = ratedHost)
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_RATING_THREAT_HOST");
                    this.notificationsService.showError(message, err);
                });
        }
    }

    ignoreHarmlessForMonth(threat: string) {
        let oneMonthLater = this.time.now().add(1, "month");
        this.ignoreUntil(threat, oneMonthLater);
    }

    ignoreHarmlessForever(threat: string) {
        this.ignoreUntil(threat);
    }

    ignoreUntil(threat: string, until?: Moment) {
        if (this.isSignature) {
            this.threatClient.rateSignature(threat, null, this.ThreatMatchHarmless)
                .then(ratedSig => this.ratedThreat = ratedSig)
                .then(() => this.threatClient.ignoreSignature(threat, null, until))
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_RATING_THREAT_SIGNATURE");
                    this.notificationsService.showError(message, err);
                });
        } else {
            this.threatClient.rateHost(threat, this.ThreatMatchHarmless)
                .then(ratedHost => this.ratedThreat = ratedHost)
                .then(() => this.threatClient.ignoreHost(threat, until))
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_RATING_THREAT_HOST");
                    this.notificationsService.showError(message, err);
                });
        }
    }

    clearRating(threat: string) {
        if (this.isSignature) {
            this.threatClient.unrateSignature({hex: threat, type: null})
                .then(() => {
                    this.ratedThreat.rating = undefined;
                    this.ratedThreat.ratingTime = undefined;
                })
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_UNRATING_THREAT_SIGNATURE");
                    this.notificationsService.showError(message, err);
                });
        } else {
            this.threatClient.unrateHost(threat)
                .then(() => {
                    this.ratedThreat.rating = undefined;
                    this.ratedThreat.ratingTime = undefined;
                })
                .catch((err) => {
                    const message = getMessageForError(err.error, "ERROR_UNRATING_THREAT_HOST");
                    this.notificationsService.showError(message, err);
                });
        }
    }
}
