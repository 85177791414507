import { Injectable } from "@angular/core";
import { EnvironmentService } from "@app2/shared/services/environment.service";

declare let heap: any;

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    constructor(private environmentService: EnvironmentService) {
        this.setupHeap(environmentService.getConfig().heapAppId);
    }

    setUser(email: string) {
        heap.identify(email);
    }

    clearUser() {
        heap.resetIdentity();
    }

    sendEvent(eventName: string, extraData: object = {}) {
        heap.track(eventName, extraData);
    }

    /**
     * Setup code copied from:
     * https://developers.heap.io/docs/web#base-installation
     */
    protected setupHeap(heapAppId: string): void {
        //@ts-ignore
        window.heap = window.heap || [], heap.load = function (e, t) {
            //@ts-ignore
            window.heap.appid = e, window.heap.config = t = t || {};
            let r = document.createElement("script");
            r.type = "text/javascript", r.async = !0, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
            let a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(r, a);
            for (let n = function (e) {
                return function () {
                    heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
            }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++) heap[p[o]] = n(p[o]);
        };
        heap.load(heapAppId);
    }
}
