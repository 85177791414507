import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { UserService } from "@app2/account/user.service";
import { TwoFactorLoginService } from "@app2/login/two-factor-login.service";

export const canAccess2faLoginPagesGuard: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const userService = inject(UserService);
        const twoFactorLoginService = inject(TwoFactorLoginService);
        const router = inject(Router);

        // Ensure we are not yet authenticated and we have started a 2FA authentication process
        if (!userService.getCurrentUser() && twoFactorLoginService.isVerifyingTwoFactorAuth()) {
            return true;
        }

        const queryParams = { url: state.url };
        return router.createUrlTree(["/unauthorized"], { queryParams });
    };
