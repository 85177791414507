import { riskScheduleAlmostDueCutoffInDays } from "@app2/risk/constants";
import {
    ActiveRiskSchedule,
    RiskAssessmentType,
    RiskAssessmentTypeWeights,
    RiskScheduleReviewStatus,
    RiskThreatDetails,
    RiskThreatTypes,
} from "@app2/type-defs/risk/risk-types";
import {
    ProductSku,
    SKU_RISK_INFORMATION_SECURITY,
    SKU_RISK_SYSTEMS_AND_APPLICATIONS,
    SKU_RISK_THIRD_PARTY_RISK,
} from "@app2/type-defs/user/user-types";
import { ChronoUnit, Clock, LocalDate, ZoneId } from "@js-joda/core";

export function hasAnyRiskSku(skus: ProductSku[]) {
    return skus.includes(SKU_RISK_INFORMATION_SECURITY) ||
        skus.includes(SKU_RISK_SYSTEMS_AND_APPLICATIONS) ||
        skus.includes(SKU_RISK_THIRD_PARTY_RISK);
}

export function hasRiskSysAppsSku(skus: ProductSku[]) {
    return skus.includes(SKU_RISK_SYSTEMS_AND_APPLICATIONS);
}

//Precondition: for each elem in param riskAssessmentTypes, weights[elem] !== undefined
//Returns null if any elem in param riskAssessmentTypes is null or undefined
export function getSuggestedRiskAssessmentType(riskAssessmentTypes: RiskAssessmentType[], weights: RiskAssessmentTypeWeights): RiskAssessmentType {
    if (riskAssessmentTypes.some(assessmentType => !assessmentType)) {
        return null;
    }
    const assessmentTypes = Object.keys(weights) as Array<RiskAssessmentType>;
    let counts = {};
    //Map each risk assessment type to the number of times it occurs in param riskAssessmentTypes
    assessmentTypes.forEach(assessmentType => {
        counts[assessmentType] = riskAssessmentTypes.reduce((acc: number, val: RiskAssessmentType) => assessmentType === val ? acc + 1 : acc, 0);
    });
    //Compute weighted average
    const products = assessmentTypes.map(assessmentType => counts[assessmentType] * weights[assessmentType]);
    const countsSum = assessmentTypes.map(assessmentType => counts[assessmentType]).reduce((a, b) => a + b, 0);
    const productsSum = products.reduce((a, b) => a + b, 0);
    if (countsSum === 0) {
        return null;
    }

    const score = Math.round(productsSum / countsSum);
    return assessmentTypes.find(assessmentType => weights[assessmentType] === score);
}

/*
    The backend currently stores risk threat details as an array of category/subcategory pairs.
    For example: [{ causeCategory: "Cause", causeSubcategory: "Subcategory 1",
                   causeCategory: "Cause", causeSubcategory: "Subcategory 2" }]
    This function collects these pairs for each threat type(risk event type, cause, or consequence)
    and stores them into records, mapping categories to subcategories.

    When passed to this function, the above example would return:
    { causes: {"Cause": ["Subcategory 1", Subcategory 2"]} }

    Inverse function of getCategoryPairs provided below
 */
export function collectThreatTypes(threatDetails: RiskThreatDetails[]): RiskThreatTypes {
    let eventTypes = {};
    let causes = {};
    let consequences = {};
    threatDetails.forEach(threatDetails => {
        if (threatDetails.riskEventType) {
            if (!eventTypes[threatDetails.riskEventType]) {
                eventTypes[threatDetails.riskEventType] = [];
            }
            if (threatDetails.riskEventTypeSubcategory) {
                eventTypes[threatDetails.riskEventType].push(threatDetails.riskEventTypeSubcategory);
            }
        }
        if (threatDetails.causeCategory) {
            if (!causes[threatDetails.causeCategory]) {
                causes[threatDetails.causeCategory] = [];
            }
            if (threatDetails.causeSubcategory) {
                causes[threatDetails.causeCategory].push(threatDetails.causeSubcategory);
            }
        }
        if (threatDetails.consequence) {
            if (!consequences[threatDetails.consequence]) {
                consequences[threatDetails.consequence] = [];
            }
            if (threatDetails.consequenceSubcategory) {
                consequences[threatDetails.consequence].push(threatDetails.consequenceSubcategory);
            }
        }

    });
    return { eventTypes: eventTypes, causes: causes,
        consequences: consequences};
}

/*  Inverse function for collectThreatTypes
    See spec for collectThreatTypes above
 */
export function getThreatTypePairs(threatType: RiskThreatTypes): RiskThreatDetails[] {
    let pairs = [];
    const eventTypes = threatType.eventTypes;
    const causes = threatType.causes;
    const consequences = threatType.consequences;
    Object.keys(eventTypes).forEach(eventType => {
        if (!eventTypes[eventType].length) {
            pairs.push({riskEventType: eventType});
        } else {
            eventTypes[eventType].forEach(eventSubtype => {
                pairs.push({riskEventType: eventType, riskEventTypeSubcategory: eventSubtype});
            });
        }
    });
    Object.keys(causes).forEach(cause => {
        if (!causes[cause].length) {
            pairs.push({causeCategory: cause});
        } else {
            causes[cause].forEach(subCause => {
                pairs.push({causeCategory: cause, causeSubcategory: subCause});
            });
        }
    });
    Object.keys(consequences).forEach(consequence => {
        if (!consequences[consequence].length) {
            pairs.push({consequence: consequence});
        } else {
            consequences[consequence].forEach(subConsequence => {
                pairs.push({consequence: consequence, consequenceSubcategory: subConsequence});
            });
        }
    });
    return pairs;
}

export function getRiskScheduleLateness(riskSchedule: ActiveRiskSchedule, clock: Clock): RiskScheduleReviewStatus {
    const daysBetween = getDaysUntilRiskScheduleDue(riskSchedule, clock);
    if (daysBetween > riskScheduleAlmostDueCutoffInDays) {
        return RiskScheduleReviewStatus.EARLY;
    } else if (daysBetween <= riskScheduleAlmostDueCutoffInDays && daysBetween >= 0) {
        return RiskScheduleReviewStatus.ALMOST_DUE;
    } else {
        return RiskScheduleReviewStatus.LATE;
    }
}

export function getDaysUntilRiskScheduleDue(riskSchedule: ActiveRiskSchedule, clock: Clock): number {
    const nowDate = LocalDate.ofInstant(clock.instant(), ZoneId.of(riskSchedule.timeZoneId));
    const dueDate = LocalDate.parse(riskSchedule.dueDate);
    return ChronoUnit.DAYS.between(nowDate, dueDate);
}
