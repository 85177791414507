import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { UserService } from "@app2/account/user.service";
import { Observable } from "rxjs";

@Component({
    selector: "navigation-top-bar-policy",
    template: `
        <nav class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "GOVERNANCE" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isTasksActive$ | async"
                        *dsPermissionsRequired="'TASK_VIEW'">
                        <a dsRouterLink="/governance/tasks">
                            {{ 'TASKS' | localize }}
                        </a></li>
                    <li [class.active]="navSectionService.isTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'">
                        <a dsRouterLink="/task-schedules">{{ "TASK_SCHEDULES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isLibraryTaskSchedulesActive$ | async"
                        *dsPermissionsRequired="'TASK_SCHEDULE_VIEW'">
                        <a dsRouterLink="/library-task-schedules">{{ "LIBRARY_TASK_SCHEDULES_TAB" | localize }}</a></li>
                    <li [class.active]="navSectionService.isFrameworksActive$ | async"
                        *dsPermissionsRequired="'POLICY_FRAMEWORK_VIEW'">
                        <a dsRouterLink="/governance/frameworks"
                           [queryParams]="{canRedirect: true}">{{ "POLICY_FRAMEWORKS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isCustomPoliciesActive$ | async"
                        *dsPermissionsRequired="'CUSTOM_POLICY_VIEW'">
                        <a dsRouterLink="/governance/policies">{{ "POLICY_POLICIES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isCATPoliciesActive$ | async"
                        *dsPermissionsRequired="'CAT_POLICY_VIEW'">
                        <a dsRouterLink="/governance/cat">{{ "FFIEC_CAT" | localize }}</a></li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarPolicyComponent {
    hasFrameworkPerms$: Observable<boolean>;

    constructor(public navSectionService: ActiveNavSectionService,
                private userService: UserService) {
        this.hasFrameworkPerms$ = this.userService.hasPermission$("POLICY_FRAMEWORK_VIEW");
    }
}
