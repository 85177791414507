import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "abbreviate" })
export class AbbreviatePipe implements PipeTransform {
    transform(text: string, maxLength: number): string {
            const ellipses = "...";
            if (text.length <= maxLength) {
                return text;
            } else {
                const abbreviated = text.substring(0, maxLength - ellipses.length);
                return abbreviated + ellipses;
            }
    }
}
