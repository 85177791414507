import { ScheduleInterval } from "@app2/shared/type-defs";

export interface EscalationSchedule {
    id: uuid;
    orgId: uuid;
    timeZoneId: string;
    intervals: ScheduleInterval[];
}

export type EscalationStepType = "BUSINESS_HOURS" | "AFTER_HOURS";

export interface EscalationOutOfOffice {
    begin: date;
    end: date;
}

export interface EscalationStep {
    id: uuid;
    type: EscalationStepType;
    outOfOffice: EscalationOutOfOffice[];
    notes?: string;
    // For GRID users
    personId?: uuid;
    // For non-GRID users
    fullName?: string;
    phoneNumber?: string;
    email?: string;
}

export enum InfoNoteThreatLevel {
    ONE ="ONE",
    TWO = "TWO",
    THREE = "THREE",
    FOUR = "FOUR"
}

export interface InfoNote {
    id: uuid;
    createdBy: uuid;
    noteText: string;
    modifiedOn: string;
    pinned: boolean;
}

export interface InfoPanelOrgMetadata {
    orgId: uuid;
    title: string;
    threatLevel: InfoNoteThreatLevel;
}
