import { ComponentFactoryResolver, Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { LoadingPlaceholderComponent } from "@app2/shared/directives/loading/loading-placeholder.component";

@Directive({
    selector: "[dsLoading]",
})
export class DsLoadingDirective {
    // We need 2 variables as we have initially: no user content, no spinner
    private hasSpinner: boolean;
    private hasUserContent: boolean;

    @Input() set dsLoading(isLoading: boolean) {
        if (isLoading && !this.hasSpinner) {
            this.viewContainer.clear();

            // Instantiate the spinner
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingPlaceholderComponent);
            this.viewContainer.createComponent(componentFactory);

            this.hasSpinner = true;
            this.hasUserContent = false;
        } else if (!isLoading && !this.hasUserContent) {
            this.viewContainer.clear();

            // Show the user-provided content
            this.viewContainer.createEmbeddedView(this.templateRef);

            this.hasUserContent = true;
            this.hasSpinner = false;
        }
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }
}
