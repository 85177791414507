import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { MetadataClientService } from "@app2/clients/metadata-client.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { DsRichTextEditorComponent } from "@app2/shared/components/ds-rich-text-editor.component";
import { localization } from "@app2/shared/localization/localization";
import { FeaturesService } from "@app2/shared/services/features.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { TimeService } from "@app2/shared/services/time.service";
import { WindowService } from "@app2/shared/services/window.service";
import { InfoNote, InfoNoteThreatLevel, InfoPanelOrgMetadata } from "@app2/type-defs/metadata/metadata-types";
import { OrgSummary } from "@app2/type-defs/user/user-types";
import { getMessageForError } from "@app2/util/errors/handleable-errors";
import { Clock, DateTimeFormatter, ZonedDateTime, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

const dateFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("M-d-yyyy h:mm a").withLocale(Locale.US);

@UntilDestroy()
@Component({
    selector: "info-panel",
    template: `
        <div [@hideInfoPanel]="infoPanelVisible" class="info-panel-container">
            <div class="info-panel-header">
                <div class="org-container">
                    <div class="info-panel-title" *dsLoading="orgMetadataSaveInProgress">
                        @if (isEditingTitle) {
                            <input required
                                   type="text"
                                   name="orgTitle"
                                   maxlength="100"
                                   [(ngModel)]="orgTitle"
                                   class="org-title-edit"
                                   id="org-title-edit"
                                   test-id="org-title-edit"/>
                            <div class="action-buttons org-edit-button-container">
                                <ds-button type="outlined"
                                           (clicked)="onCancel()"
                                           size="small"
                                           id="org-title-edit-cancel-button"
                                           test-id="title-edit-cancel-button">
                                    {{ "CANCEL" | localize }}
                                </ds-button>
                                <ds-button [disabled]="orgTitle.trim().length === 0"
                                           size="small"
                                           (clicked)="saveOrgTitle()"
                                           id="org-title-edit-save-button"
                                           test-id="title-save-button">
                                    {{ 'SAVE' | localize }}
                                </ds-button>
                            </div>
                        } @else {
                            <div class="title-container">
                                <span class="title-span" test-id="info-panel-title">{{ orgMetadata.title }}</span>
                                <i class="fa-sharp fa-light fa-pen edit-icon show-on-hover"
                                   id="org-title-edit-icon"
                                   test-id="edit-title-icon"
                                   (click)="toggleEditingOrgTitle()"></i>
                            </div>
                        }
                    </div>
                    <div *dsLoading="loadingCurrentTime" class="org-details">
                        <span class="org-time" test-id="current-time-in-org">{{ currentTime }}</span>
                        <i *ngIf="orgZoneId" class="far fa-circle-question info-icon"
                           [matTooltip]="'INFO_PANEL_TIME_EXPLANATION' | localize : orgZoneId.id()"></i>
                        <pr-dropdown align="center" class="threat-level-dropdown-container">
                            <div pr-dropdown-trigger test-id="threat-level-dropdown"
                                 [attr.id]="'threat-level-dropdown-level-' + getThreatLevelNumber(orgMetadata.threatLevel)">
                                <threat-level-icon
                                        [threatLevel]="orgMetadata.threatLevel">
                                </threat-level-icon>
                            </div>
                            <ul pr-dropdown-body>
                                <li *ngFor="let threatLevel of InfoNoteThreatLevel | keyvalue: equalCheck"
                                    class="threat-level-option-container"
                                    [attr.id]="'threat-level-dropdown-option-' + getThreatLevelNumber(threatLevel.value)"
                                    [attr.test-id]="'threat-level-dropdown-option-' + getThreatLevelNumber(threatLevel.value)"
                                    (click)="updateOrgMetadataForThreatLevel(threatLevel.value)">
                                <span>
                                    <threat-level-icon
                                            [threatLevel]="threatLevel.value">
                                    </threat-level-icon>
                                    <span class="threat-level-option"> {{ 'THREAT_LEVEL_' + threatLevel.value | localize }} </span>
                                    <i *ngIf="threatLevel.value === orgMetadata.threatLevel"
                                       class="fa-sharp fa-check check-icon"></i>
                                </span>
                                </li>
                            </ul>
                        </pr-dropdown>
                    </div>
                </div>
                <ds-button type="black-link"
                           size="large"
                           id="close-info-panel-button"
                           (clicked)="closeInfoPanel()">
                    <i class="far fa-arrow-right-to-line"></i>
                </ds-button>
            </div>
            <div class="notes-section" cdkScrollable>
                <div *dsLoading="loadingNotes || loadingUserId" class="note-board-container">
                    <div *ngFor="let note of notes; index as i;"
                         [ngClass]="['note-container', note.pinned ? 'pinned' : '']">
                        <ng-container>
                            <div class="note-detail-container">
                                <div class="note-description" [attr.test-id]="'note-text-' + i">
                                    <span *ngIf="!newEditorEnabled">
                                         {{ note.noteText }}
                                     </span>
                                    <formatted-html *ngIf="newEditorEnabled"
                                                    [filters]="['markdown','sanitized']"
                                                    [linksOpenInNewTab]="true"
                                                    [text]="note.noteText">
                                    </formatted-html>
                                </div>
                                <div class="note-details" [attr.test-id]="'note-details-' + i">
                                    <person-name [id]="note.createdBy"
                                                 [linkToUsersPage]="false">
                                    </person-name>
                                    <span class="bulletpoint">&#8226;</span>
                                    <span>{{ note.modifiedOn | shortDate }}</span>
                                </div>
                            </div>
                            <div class="action-buttons">
                                <i *ngIf="note.pinned" class="fas fa-thumbtack"></i>
                                <ds-dropdown [showFooter]="false"
                                             type="icon"
                                             [attr.test-id]="'note-actions-dropdown-' + i">
                                    <div id="ds-dropdown-trigger" class="dropdown-value">
                                        <i class="far fa-ellipsis-vertical"></i>
                                    </div>
                                    <li class="remove-custom-styles click-target"
                                        (click)="toggleNotePinned(note, !note.pinned)"
                                        pr-dropdown-close-on-click
                                        [attr.id]="'info-note-pin-option-' + note.id">
                                        <div class="item-container">
                                            <span class="flex-grow">
                                                {{ (note.pinned ? "UNPIN_FROM_TOP" : "PIN_TO_TOP") | localize }}
                                            </span>
                                        </div>
                                    </li>
                                    <li *ngIf="note.createdBy === userId"
                                        class="remove-custom-styles click-target"
                                        (click)="toggleEditingNote(note)"
                                        pr-dropdown-close-on-click
                                        [attr.id]="'info-note-edit-option-' + note.id">
                                        <div class="item-container">
                                            <span class="flex-grow">{{ "EDIT" | localize }}</span>
                                        </div>
                                    </li>
                                    <li class="remove-custom-styles click-target delete-message"
                                        (click)="deleteNote(note)"
                                        pr-dropdown-close-on-click
                                        [attr.id]="'info-note-delete-option-' + note.id">
                                        <div class="item-container">
                                            <span class="flex-grow">{{ "DELETE" | localize }}</span>
                                        </div>
                                    </li>
                                </ds-dropdown>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="note-input-container">
                <textarea *ngIf="!newEditorEnabled"
                          rows="3"
                          name="noteInput"
                          [placeholder]="'TYPE_A_NOTE' | localize"
                          [(ngModel)]="noteText"
                          test-id="note-editor"
                          id="info-note-text-editor">
                </textarea>
                <ds-rich-text-editor #noteEditor
                                     *ngIf="newEditorEnabled"
                                     [(content)]="noteText"
                                     [placeholder]="'TYPE_A_NOTE' | localize"
                                     [height]="310"
                                     test-id="rich-text-note-editor"
                                     id="info-note-rich-text-editor">
                </ds-rich-text-editor>
                <div *ngIf="!noteBeingEdited"
                     class="message-edit-action-container">
                    <ds-button size="small"
                               type="outlined"
                               (clicked)="cancelEditingNote()"
                               test-id="add-note-cancel-button"
                               id="add-info-note-cancel-button">
                        {{ "CANCEL" | localize }}
                    </ds-button>
                    <ds-button size="small"
                               [disabled]="noteText.trim().length === 0"
                               (clicked)="addNote()"
                               test-id="note-submit-button"
                               id="info-note-submit-button">
                        {{ "SEND" | localize }}
                    </ds-button>
                </div>
                <div *ngIf="noteBeingEdited"
                     class="message-edit-action-container">
                    <ds-button size="small"
                               type="outlined"
                               (clicked)="cancelEditingNote()"
                               [attr.test-id]="'cancel-edit-note-button-' + noteBeingEdited.id"
                               [attr.id]="'edit-info-note-cancel-button-' + noteBeingEdited.id">
                        {{ "CANCEL" | localize }}
                    </ds-button>
                    <ds-button size="small"
                               (clicked)="editAndSaveNote()"
                               [disabled]="noteText.trim().length === 0"
                               test-id="message-save-button"
                               [attr.test-id]="'save-edit-note-button-' + noteBeingEdited.id"
                               [attr.id]="'edit-info-note-save-note-button-' + noteBeingEdited.id">
                        {{ "SAVE" | localize }}
                    </ds-button>
                </div>
            </div>
        </div>
    `,
    styles: [`
      @import "/src/styles/colors";
      @import "/src/styles/settings";

      .notes-section {
        overflow-y: scroll;
      }

      .info-panel-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 56px;
        bottom: 0;
        right: -500px;
        background-color: $content-bg-color;
        overflow-x: hidden;
        z-index: z-index(dropdown) - 100;
        border-left: 1px solid $gray-4;
        width: 500px;
      }

      .info-panel-header {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 16px 16px;
        border-bottom: 1px solid $gray-4;

        .org-container {
          width: 95%;
          margin: 8px 0;
        }

        .info-panel-title {
          display: flex;
          font-size: 1.25rem;
          font-weight: $font-weight-demibold;
          width: 100%;
        }

        .org-details {
          color: $gray-7;
          font-size: 0.875rem;
          width: 80%;

          .info-icon {
            margin-left: 8px;
            color: $ds-blue-6;
            font-size: 1rem;

            &:hover {
              color: $ds-blue-7;
            }
          }
        }
      }

      .bulletpoint {
        margin: 0 4px;
      }

      .note-board-container {
        display: flex;
        flex-direction: column;

        .note-detail-container {
          display: flex;
          flex-direction: column;
          width: 85%;
          align-self: flex-start;
          align-items: flex-start;
        }

        .note-description {
          margin-top: 8px;
        }

        .note-details {
          color: $gray-7;
          font-size: 0.875rem;
        }

        .note-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 16px 16px;
          border-bottom: 1px solid $gray-4;
          word-wrap: break-word;

          &:last-child {
            border: none;
          }
        }

        .pinned {
          background: $ds-blue-1;
        }
      }

      .action-buttons {
        display: flex;
        color: $gray-7;
        font-size: 0.875rem;

        .fa-thumbtack {
          margin-top: 12px;
          margin-right: 10px;
          font-size: 0.875rem;
          color: $ds-blue-6;
        }
      }

      .note-input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 16px;
        border-top: 1px solid $gray-4;

        .note-input-button {
          margin-top: 8px;
        }
      }

      textarea {
        resize: none;
      }

      .message-edit-action-container {
        display: flex;
        margin-top: 8px;
      }

      .delete-message {
        color: $cinnabar-red-6;
      }

      .threat-level-container {
        display: flex;
        align-self: flex-start;
        align-items: center;

        .threat-level-dropdown-label {
          color: $gray-7;
          font-size: 0.875rem;
          margin-right: 4px;
        }
      }

      .dropdown-trigger-container {
        i.fa-chevron-down {
          margin-left: 4px;
          font-size: 0.75rem;
        }
      }

      .check-icon {
        color: $ds-blue-6 !important;
        float: right;
        margin-top: 6px;
      }

      .threat-level-dropdown-container {
        margin-left: 16px;
      }

      .threat-level-option-container {
        width: 220px;
      }

      .threat-level-option {
        margin-left: 8px;
      }

      .org-title-edit {
        float: left;
        width: 50%;
      }

      .org-edit-button-container {
        padding-left: 16px;
      }

      .edit-icon {
        color: $ds-blue-6;
        padding-left: 16px;
        align-self: center;
      }

      .show-on-hover {
        opacity: 0;
      }

      .show-on-hover:hover {
        opacity: inherit;
      }

      .title-span:hover + .show-on-hover {
        opacity: inherit;
      }

      .title-container {
        width: 95%;
        word-wrap: break-word;
      }
    `],
    animations: [
        trigger("hideInfoPanel", [
            state("true", style({ transform: "translateX(-100%)" })),
            state("false", style({ transform: "translateX(100%)" })),
            transition("* => *", animate(250)),
        ]),
    ],
})
export class InfoPanelComponent implements OnInit {
    @Input() orgMetadata: InfoPanelOrgMetadata;
    @Output() hideInfoPanel = new EventEmitter();
    @Output() updateOrgMetadata: EventEmitter<InfoPanelOrgMetadata> = new EventEmitter<InfoPanelOrgMetadata>();

    protected readonly InfoNoteThreatLevel = InfoNoteThreatLevel;

    @ViewChild("noteEditor") editor: DsRichTextEditorComponent;

    notes: InfoNote[];
    currentOrg$: Observable<OrgSummary>;
    noteText: string = "";
    infoPanelVisible: boolean = false;
    loadingNotes: boolean;
    currentTime: string;
    orgZoneId: ZoneId;
    loadingCurrentTime: boolean;
    loadingUserId: boolean;
    userId: uuid;
    newEditorEnabled: boolean;
    isEditingTitle: boolean;
    orgTitle: string;
    noteBeingEdited: InfoNote;

    orgMetadataSaveInProgress: boolean;

    constructor(private orgsService: OrgsService,
                private metadataService: MetadataClientService,
                private notificationsService: NotificationsService,
                private timeService: TimeService,
                private clock: Clock,
                private userClient: UserClientService,
                private windowService: WindowService,
                private userService: UserService,
                private featuresService: FeaturesService) {
        this.currentOrg$ = orgsService.getCurrentOrg$();
    }

    ngOnInit() {
        this.loadingNotes = true;
        this.metadataService.getInfoNotes()
            .then(notes => {
                this.notes = notes;
                this.loadingUserId = true;
                this.userService.getCurrentUser$()
                    .pipe(take(1))
                    .subscribe(person => {
                        this.userId = person.id;
                        this.loadingUserId = false;
                    });
            })
            .catch(error => {
                const note = getMessageForError(error.error, "ERROR_LOADING_INFO_NOTES");
                this.notificationsService.showError(note, error);
            })
            .finally(() => this.loadingNotes = false);
        this.loadingCurrentTime = true;
        this.userClient.getOrgSettings()
            .then(orgSettings => {
                const emailTimezone = orgSettings.emailTimezone;
                this.orgZoneId = ZoneId.of(emailTimezone === "Etc/UTC" ? "UTC" : emailTimezone);
                this.updateCurrentTime(true);
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ORG_SETTINGS_LOAD_FAILED");
                this.notificationsService.showError(message, error);
            })
            .finally(() => this.loadingCurrentTime = false);

        this.featuresService.getFeature$("newEditor")
            .pipe(untilDestroyed(this))
            .subscribe(enabled => this.newEditorEnabled = enabled);
        this.orgTitle = this.orgMetadata.title;
        this.isEditingTitle = false;
    }

    addNote() {
        const request: InfoNote = <InfoNote>{
            noteText: this.noteText.trim(),
            pinned: false
        };
        this.loadingNotes = true;
        this.noteText = "";
        if (this.newEditorEnabled) {
            this.editor.clearContent();
        }
        this.metadataService.addInfoNote(request)
            .then(note => {
                this.insertNote(note);
            })
            .catch(error => {
                const note = getMessageForError(error.error, "ERROR_ADDING_INFO_NOTE");
                this.notificationsService.showError(note, error);
            })
            .finally(() => this.loadingNotes = false);
    }

    editAndSaveNote() {
        const request: InfoNote = {
            ...this.noteBeingEdited,
            noteText: this.noteText.trim(),
        };
        this.loadingNotes = true;
        this.noteText = "";
        this.metadataService.editInfoNote(this.noteBeingEdited.id, request)
            .then(note => {
                this.insertNote(note);
                this.noteBeingEdited = null;
                this.noteText = "";
                if (this.newEditorEnabled) {
                    this.editor.clearContent();
                }
            })
            .catch(error => {
                const note = getMessageForError(error.error, "ERROR_EDITING_INFO_NOTE");
                this.notificationsService.showError(note, error);
            })
            .finally(() => this.loadingNotes = false);
    }

    toggleNotePinned(note: InfoNote, pinned: boolean) {
        this.loadingNotes = true;
        const request = {
            ...note,
            pinned: pinned,
            noteText: note.noteText,
        };
        this.metadataService.editInfoNote(note.id, request)
            .then(note => this.insertNote(note))
            .catch(error => {
                const note = getMessageForError(error.error, "ERROR_EDITING_INFO_NOTE");
                this.notificationsService.showError(note, error);
            })
            .finally(() => this.loadingNotes = false);
    }

    insertNote(note: InfoNote) {
        note.pinned ? this.insertPinnedNote(note) : this.insertUnpinnedNote(note);
    }

    insertPinnedNote(note: InfoNote) {
        this.removeNote(note.id);
        this.notes.unshift(note);
    }

    insertUnpinnedNote(note: InfoNote) {
        this.removeNote(note.id);
        const pinnedNotes = this.notes.filter(otherNote => otherNote.pinned);
        this.notes = [...this.notes.slice(0, pinnedNotes.length),
            note, ...this.notes.slice(pinnedNotes.length)];
    }

    removeNote(noteId: uuid) {
        this.notes = this.notes.filter(otherNote => otherNote.id !== noteId);
    }

    closeInfoPanel() {
        this.infoPanelVisible = false;
        this.hideInfoPanel.emit();
    }

    deleteNote(note: InfoNote) {
        this.loadingNotes = true;
        this.metadataService.deleteInfoNote(note.id)
            .then(_ => {
                this.removeNote(note.id);
            })
            .catch(error => {
                const note = getMessageForError(error.error, "ERROR_DELETING_INFO_NOTE");
                this.notificationsService.showError(note, error);
            })
            .finally(() => this.loadingNotes = false);
    }

    updateCurrentTime(isInitialUpdate: boolean) {
        const zonedDateTime = ZonedDateTime.ofInstant(this.clock.instant(), this.orgZoneId);
        this.currentTime = zonedDateTime.format(dateFormatter).toLowerCase();
        let delay = (isInitialUpdate ? 60 - zonedDateTime.second() : 60) * 1000;
        this.windowService.setTimeout(() => this.updateCurrentTime(false), delay);
    }

    /**
     * This is the sorting function for the threat level enum values. It forces them to appear equal so that the list
     * respects their index order. Without this, they are sorted alphabetically by values (the default for iterating
     * in an enum).
     */
    equalCheck() {
        return 0;
    }

    updateOrgMetadataForThreatLevel(threatLevel: InfoNoteThreatLevel) {
        if (this.orgMetadata.threatLevel === threatLevel) {
            return;
        }
        const infoPanelOrgMetadata: InfoPanelOrgMetadata = {
            orgId: this.orgMetadata.orgId,
            title: localization.getString("THREAT_LEVEL_" + threatLevel),
            threatLevel: threatLevel
        };

        this.orgMetadataSaveInProgress = true;
        this.metadataService.editInfoPanelOrgMetadata(infoPanelOrgMetadata)
            .then(orgMetadata => {
                this.orgMetadata = orgMetadata;
                this.orgTitle = this.orgMetadata.title;
                this.updateOrgMetadata.emit(orgMetadata);
                this.isEditingTitle = false; // turn off title editing when changing threat level, it changes the title
            })
            .catch(error => {
                const errorMessage = getMessageForError(error.error, "ERROR_EDITING_ORG_METADATA");
                this.notificationsService.showError(errorMessage, error);
                this.isEditingTitle = false;
            }).finally(() => this.orgMetadataSaveInProgress = false);
    }

    toggleEditingOrgTitle() {
        this.isEditingTitle = !this.isEditingTitle;
    }

    saveOrgTitle() {
        this.orgTitle = this.orgTitle.trim();
        if (this.orgMetadata.title !== this.orgTitle) {
            const infoPanelOrgMetadata: InfoPanelOrgMetadata = {
                orgId: this.orgMetadata.orgId,
                title: this.orgTitle,
                threatLevel: this.orgMetadata.threatLevel
            };
            this.orgMetadataSaveInProgress = true;
            this.metadataService.editInfoPanelOrgMetadata(infoPanelOrgMetadata)
                .then(orgMetadata => {
                    this.orgMetadata = orgMetadata;
                    this.updateOrgMetadata.emit(orgMetadata);
                })
                .catch(error => {
                    const errorMessage = getMessageForError(error.error, "ERROR_EDITING_ORG_METADATA");
                    this.notificationsService.showError(errorMessage, error);
                }).finally(() => this.orgMetadataSaveInProgress = false);
        }
        this.isEditingTitle = false;
    }

    toggleEditingNote(note: InfoNote) {
        this.noteText = note.noteText;
        this.noteBeingEdited = note;
        if (this.newEditorEnabled) {
            this.editor.setContent(this.noteText);
        }
    }

    cancelEditingNote() {
        this.noteBeingEdited = null;
        this.noteText = "";
        if (this.newEditorEnabled) {
            this.editor.clearContent();
        }
    }

    onCancel() {
        this.orgTitle = this.orgMetadata.title;
        this.isEditingTitle = false;
    }

    getThreatLevelNumber(threatLevel: InfoNoteThreatLevel): number {
        return Object.keys(InfoNoteThreatLevel).indexOf(threatLevel) + 1;
    }

    toggleVisibility() {
        this.infoPanelVisible = !this.infoPanelVisible;
    }
}
