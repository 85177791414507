import { formatValidatorError, isValidatorError, ValidatorError } from "@app2/util/errors/validator-errors";
import {
    getMessageForGenericInvalidFieldValueError,
    isGenericInvalidFieldValueError,
    isUserFriendlyError,
    UserFriendlyError,
} from "@app2/util/errors/user-friendly-errors";
import { LocalizationKey } from "@app2/shared/localization/default-strings";
import { localization } from "@app2/shared/localization/localization";
import { getMessageForAlertUserFriendlyError } from "@app2/util/errors/alert-service-user-friendly-errors";
import { getMessageForReportUserFriendlyError } from "@app2/util/errors/report-service-user-friendly-errors";
import { getMessageForSearchUserFriendlyError } from "@app2/util/errors/search-service-user-friendly-errors";
import { getMessageForUserUserFriendlyError } from "@app2/util/errors/user-service-user-friendly-errors";
import { getMessageForTicketUserFriendlyError } from "@app2/util/errors/ticket-service-user-friendly-errors";
import { getMessageForPolicyUserFriendlyError } from "@app2/util/errors/policy-service-user-friendly-errors";
import { getMessageForThreatUserFriendlyError } from "@app2/util/errors/threat-service-user-friendly-errors";

export type HandleableError = ValidatorError | UserFriendlyError;

// dsErrorType is appended to the error objects by ExceptionMappers in service-lib
export function isHandleableError(err: unknown): err is HandleableError {
    return err !== null
        && typeof err === "object";
        // TODO: use the following condition when all services use service-lib 4.25+
        // && "dsErrorType" in err;
}

export function getMessageForError(error: unknown, defaultErrorMessageKey: LocalizationKey): string {
    if (!isHandleableError(error)) {
        return localization.getString(defaultErrorMessageKey);
    }

    if (isValidatorError(error)) {
        return formatValidatorError(error, defaultErrorMessageKey);
    }

    if (isUserFriendlyError(error)) {
        if (isGenericInvalidFieldValueError(error)) {
            return getMessageForGenericInvalidFieldValueError(error);
        }

        // we need to check through all possible keys because services can forward errors from one another
        // also because we handle promise chains that make requests to multiple different services
        const message = getMessageForAlertUserFriendlyError(error)
            ?? getMessageForTicketUserFriendlyError(error)
            ?? getMessageForReportUserFriendlyError(error)
            ?? getMessageForPolicyUserFriendlyError(error)
            ?? getMessageForSearchUserFriendlyError(error)
            ?? getMessageForUserUserFriendlyError(error)
            ?? getMessageForThreatUserFriendlyError(error);

        if (message) {
            return message;
        }
    }

    return localization.getString(defaultErrorMessageKey);
}

