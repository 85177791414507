import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "unauthorized-page",
    template: `
        <div class="padded sheet centered-container">
            <h2>{{ "NOT_AUTHORIZED" | localize }}</h2>
            <p>{{ "NOT_AUTHORIZED_MESSAGE" | localize:path }}</p>
        </div>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
        }
        .centered-container {
            padding: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        h2 {
            padding-left: 0;
            border-bottom: none;
        }
    `],
})
export class UnauthorizedPageComponent {
    path: string;

    constructor(private route: ActivatedRoute) {
        const path = this.route.snapshot.queryParams.url;

        // Remove query params from the path
        this.path = path.includes("?")
            ? path.substring(0, path.indexOf("?"))
            : path;
    }
}
