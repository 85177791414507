import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, Validators } from "@angular/forms";
import { AssetImportance, defaultAssetLabels, Importance, importanceOptions } from "@app2/asset/asset-constants";
import { HostnamesEditComponent } from "@app2/asset/asset-add-edit-modal/hostnames-edit.component";
import { AssetClientService } from "@app2/clients/asset-client.service";
import { localization } from "@app2/shared/localization/localization";
import { Asset } from "@app2/type-defs/assets/assets-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged } from "rxjs/operators";
import { DsSelectOption } from "@app2/shared/components/ds-select.component";

@UntilDestroy()
@Component({
    selector: "asset-details-edit",
    template: `
        <form class="nice-form"
              [formGroup]="assetDetailsForm">
            <label>
                {{ 'ASSET_NAME' | localize}}
                <input type="text"
                       formControlName="assetName"
                       test-id="asset-name"
                       [placeholder]="'ASSET_NAME_PLACEHOLDER' | localize"/>
            </label>
            <label>
                {{ 'ASSET_TAG' | localize }}
                <input type="text"
                       formControlName="assetTag"
                       test-id="asset-tag"
                       [placeholder]="'ASSET_TAG_PLACEHOLDER' | localize"/>
            </label>
            <label>
                {{ 'ASSET_OWNER' | localize }}
                <input type="text"
                       formControlName="assetOwner"
                       test-id="asset-owner"
                       [placeholder]="'ASSET_OWNER_PLACEHOLDER' | localize"/>
            </label>
            <label>
                {{ 'ASSET_IMPORTANCE' | localize }}
                <ds-select [control]="assetDetailsForm.get('assetImportance')"
                           [options]="importanceOptions"
                           [localizeKey]="false"
                           test-id="asset-importance">
                </ds-select>
            </label>

            <label>
                {{ 'ASSET_DESCRIPTION' | localize }}
                <textarea formControlName="assetDescription"
                          test-id="asset-description"
                          [placeholder]="'ASSET_DESCRIPTION_PLACEHOLDER' | localize"></textarea>
            </label>

            <label>{{ 'ASSET_LABELS' | localize }}</label>
            <label-editor [suggestionSet]="allLabels"
                          [(labels)]="asset.labels"
                          [readOnly]="false">
            </label-editor>

            <h1>{{ 'ASSET_HOSTNAME' | localize }}</h1>
            <section>
                <hostnames-edit [hostnames]="asset.hostnames">
                </hostnames-edit>
            </section>
        </form>
    `,
})
export class AssetDetailsEditComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() asset: Asset;
    @Input() labelList: string[];
    @Output() assetChange = new EventEmitter<Asset>();
    @Output() formValidityChange = new EventEmitter<boolean>();

    @ViewChild(HostnamesEditComponent) hostnamesForm: HostnamesEditComponent;

    allLabels: string[] = [];
    assetDetailsForm = this.fb.group({
        assetName: ["", Validators.required],
        assetTag: [""],
        assetOwner: [""],
        assetImportance: ["", Validators.required],
        assetDescription: [""],
    });

    importanceOptions: DsSelectOption<Importance>[] = importanceOptions.filter(o => o.key !== "ALL")
        .map(option => ({ key: this.getImportanceText(option), value: option.value }));

    constructor(private assetClient: AssetClientService,
                private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.assetDetailsForm.get("assetName").setValue(this.asset.name);
        this.assetDetailsForm.get("assetTag").setValue(this.asset.tag);
        this.assetDetailsForm.get("assetOwner").setValue(this.asset.owner);
        this.assetDetailsForm.get("assetImportance").setValue(this.asset.importance);
        this.assetDetailsForm.get("assetDescription").setValue(this.asset.description);

        this.assetDetailsForm.statusChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(isValid => {
                this.formValidityChange.emit(isValid === "VALID");
            });
        this.assetDetailsForm.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                if (this.assetDetailsForm.valid) {
                    const asset = <Asset>{
                        ...this.asset,
                        name: this.assetDetailsForm.get("assetName").value,
                        tag: this.assetDetailsForm.get("assetTag").value,
                        owner: this.assetDetailsForm.get("assetOwner").value,
                        importance: this.assetDetailsForm.get("assetImportance").value,
                        description: this.assetDetailsForm.get("assetDescription").value,
                        hostnames: (this.assetDetailsForm.get("hostnames") as UntypedFormArray).controls.map(h => h.value),
                    };
                    this.assetChange.emit(asset);
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.labelList) {
            this.allLabels = Array.from(new Set(this.labelList.concat(defaultAssetLabels)));
        }
    }

    ngAfterViewInit() {
        this.assetDetailsForm.addControl("hostnames", this.hostnamesForm.getFormGroup());
    }

    getImportanceText(importanceOption: AssetImportance): string {
        const name = localization.getString("ASSET_IMPORTANCE_" + importanceOption.key);
        return `${ importanceOption.symbol } ${ name }`;
    }
}
