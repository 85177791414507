import { defaultTemplates } from "@app2/shared/localization/default-templates";
import { defaultStrings } from "@app2/shared/localization/default-strings";
import * as _ from "underscore";

export class Localization {
    constructor(private readonly strings, private readonly templates) { }

    getString(key: string , ...interpolations: any[]): string {
        if (!key || key.constructor.name !== "String") {
            return key;
        }
        return Localization.replaceInterpolations(this.strings[key] || key, interpolations);
    }
    getTemplate(key: string, ...interpolations: any[]): string {
        if (!key || key.constructor.name !== "String") {
            return key;
        }
        return Localization.replaceInterpolations(this.templates[key] || key, interpolations);
    }

    private static replaceInterpolations(str, interpolations): string {
        _.each(interpolations, function(arg, index) {
            const token = new RegExp("\\{" + index + "\\}", "g");
            str = str.replace(token, arg);
        });

        return str;
    }
}

export const localization: Localization = new Localization(defaultStrings, defaultTemplates);
