import { Injectable } from "@angular/core";
import { TwoFactorDetails } from "@app2/type-defs/user/user-types";
import { log } from "@app2/logger";
import { moment } from "@app2/util/legacy-moment";
import { WindowService } from "@app2/shared/services/window.service";

export const TWO_FACTOR_TIMEOUT = moment.duration(10, "minutes");


@Injectable({
    providedIn: "root",
})
export class TwoFactorLoginService {

    private timeoutRef: any;
    private twoFactorDetails: TwoFactorDetails;

    constructor(private windowService: WindowService) {
    }

    public startTwoFactorAuth(twoFactorDetails: TwoFactorDetails, logoutCallback: () => void) {
        this.timeoutRef = this.setTimeout(() => {
                log.warn("Logging out due to a timeout on 2FA authentication");
                this.cancelTwoFactorAuth();
                logoutCallback();
            },
            TWO_FACTOR_TIMEOUT.asMilliseconds());

        this.twoFactorDetails = twoFactorDetails;
    }

    public cancelTwoFactorAuth() {
        clearTimeout(this.timeoutRef);
        this.timeoutRef = undefined;
        this.twoFactorDetails = undefined;
    }

    public getTwoFactorAuthDetails(): TwoFactorDetails {
        return this.twoFactorDetails;
    }

    public isVerifyingTwoFactorAuth(): boolean {
        return !!this.timeoutRef;
    }

    /**
     * Useful for mocking in tests
     * @protected
     */
    protected setTimeout(callback: () => void, millis: number): any {
        return this.windowService.setTimeout(callback, millis);
    }
}
