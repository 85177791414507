import {
    ActiveRiskScheduleUnit,
    ErmConnectionType,
    InternalControlCategory,
    InternalControlType,
    RiskModuleType,
    RiskResponseType,
} from "@app2/type-defs/risk/risk-types";
import { localization } from "@app2/shared/localization/localization";

export type ThreatDetailsItem = { type: string, subcategories: string[] };

export const riskEventTypes: ThreatDetailsItem[] = [
    {
        type: "People",
        subcategories: [
            "Breach of employment legislation or regulatory requirements",
            "Inadequate workspace safety",
            "Ineffective employee relations",
        ],
    },
    {
        type: "External Fraud",
        subcategories: [
            "3rd party/vendor fraud",
            "Agent/broker/intermediary fraud",
            "First party fraud",
        ],
    },
    {
        type: "Internal Fraud",
        subcategories: [
            "Internal fraud against the organization",
            "Internal fraud against customer/client",
            "Internal fraud against third-party",
        ],
    },
    {
        type: "Physical Security and Safety",
        subcategories: [
            "Damage to physical asset",
            "Natural disaster",
            "Unnatural Disaster",
            "Unauthorized physical access",
        ],
    },
    {
        type: "Resiliency",
        subcategories: [
            "Inadequate planning/event management related to BCP/DRP",
            "Inadequate planning/event management related to Incident Response",
            "Customer unable to access financial products/services",
        ],
    },
    {
        type: "Transaction Processing and Execution",
        subcategories: [
            "Processing/execution failure relating to clients and products",
            "Processing/execution failure relating to securities and collateral",
            "Processing/execution failure relating to third party",
            "Processing/execution failure relating to internal operations",
            "Change execution failure",
        ],
    },
    {
        type: "Technology",
        subcategories: [
            "Hardware Failure",
            "Software Failure",
            "Network Failure",
        ],
    },
    {
        type: "Conduct",
        subcategories: [
            "Insider Trading",
            "Anti-trust/anti-competition",
            "UDAAP",
            "Service Failure",
            "Client mistreatment/failure to fulfil duties to customers",
            "Client account mismanagement",
            "Improper marketing/distribution",
            "Improper product/service design",
            "Whistleblowing",
            "Breach of code of conduct",
            "Nonadherence to internal policy, procedure or process",
        ],
    },
    {
        type: "Legal",
        subcategories: [
            "Mishandling of legal processes",
            "Contractual rights/obligation failures",
            "Non-contractual rights/obligation failures",
        ],
    },
    {
        type: "Financial Crime",
        subcategories: [
            "Money laundering and terrorism financing",
            "Sanctions violation",
            "Bribery and corruption",
            "KYC and transaction monitoring control failure",
            "Untimely/Inaccurate Regaultory Filings",
        ],
    },
    {
        type: "Regulatory Compliance",
        subcategories: [
            "Ineffective response to regulatory recommendation/enforcement",
            "Inadequate response to regulatory change",
            "Improper licensing/certification/registration",
            "Breach of cross-border activities/extra-territorial regulations",
            "Safety & Soundness",
        ],
    },
    {
        type: "Third Party",
        subcategories: [
            "Third party management control failure",
            "Third party criminality/non-compliance with rules and regulations",
            "Inadequate contract terms/SLAs",
        ],
    },
    {
        type: "Information Security + Cyber",
        subcategories: [
            "Data theft",
            "Malicious manipulation of data",
            "Data loss",
            "Cyber Event",
            "Data Privacy breach/confidentiality mismanagement",
            "Unauthorized access to data",
        ],
    },
    {
        type: "Financial Reporting & Tax",
        subcategories: [
            "External financial and regulatory reporting failure",
            "Tax payment/filing failure",
            "Trade/transaction reporting failure",
        ],
    },
    {
        type: "Data Management",
        subcategories: [
            "Unavailability of data",
            "Data Integrity",
            "Inadequate data architecture/IT Infrastructure",
            "Inadequate data storage/retention and destruction management",
        ],
    },
    {
        type: "Model",
        subcategories: [
            "Model/methodology design error",
            "Model implementation error",
            "Model application error",
            "Inadequate model governance",
        ],
    },
    {
        type: "ESG",
        subcategories: [
            "Environmental",
            "Social",
            "Governance",
        ],
    },
];

export const riskCauseTypes = [
    {
        type: "Employees",
        subcategories: [
            "Accidental causes (people)",
            "Lack of adequate training/competency",
            "Insufficient resourcing level",
            "Ineffective roles and responsibilities",
            "Miscommunication",
            "Ineffective culture",
            "Malice",
        ],
    },
    {
        type: "Process Failure",
        subcategories: [
            "Procedure/process design failure",
            "Procedure/process implementation failure",
            "Change/projects mismanagement",
            "Governance failure",
        ],
    },
    {
        type: "External",
        subcategories: [
            "Natural disaster (e.g. pandemic, earthquakes, floods etc.)",
            "Malice (e.g. by vendors, suppliers, service providers, customers, counterparties etc.)",
            "Terrorism/external attacks (excl. cyber-attacks)",
            "Environment (excl. natural disaster)",
            "Geopolitical/economic/social instability",
            "Regulatory and legislative environment",
        ],
    },
    {
        type: "Systems",
        subcategories: [
            "Functionality issues",
            "Performance/capacity issues",
            "Lack of maintenance/unsupported legacy",
            "Unavailability",
            "Inadequate testing/development",
            "Release/deployment issues",
            "Misconfiguration",
            "Inadequate data storage/retention and destruction management (cause)",
            "Exploitation of IT security vulnerability",
            "Technology-related planning issues",
        ],
    },
];

export const riskConsequenceTypes = [
    {
        type: "Loss or remediation (direct financial impact)",
        subcategories: [
            "Timing losses",
            "Internal costs (excluding legal expenses)",
            "External costs (excluding legal expenses)",
            "Legal costs",
            "Operational risk gain",
            "Damaged/lost assets",
            "Regulatory fines/penalties",
            "Legal fines/penalties",
            "Revenue adjustment",
            "Recoveries",
            "Provisions",
            "Customer restitution and compensation",
            "Third party restitution and compensation (e.g. vendors, suppliers, and service providers)",
            "Employee restitution and compensation",
        ],
    },
    {
        type: "Indirect financial impact",
        subcategories: [
            "Opportunity Cost",
        ],
    },
    {
        type: "Non-financial impact",
        subcategories: [
            "Regulatory enforcement (non-financial)",
            "Reputation",
            "Disruption",
            "Legal/litigation",
            "Loss of Customers/Detirment of New Customers",
            "Loss of Employees/Difficulty Recruiting",
            "Termination of Third Party Relationship (vendor, supplier, service provider)",
            "Shareholder Perception",
            "Decrease Market Position or Weakened Competitive Advantage",
        ],
    },
];

export const ermConnectionTypes: ErmConnectionType[] = [
    ErmConnectionType.STRATEGIC,
    ErmConnectionType.REPUTATIONAL,
    ErmConnectionType.COMPLIANCE,
    ErmConnectionType.FINANCIAL,
    ErmConnectionType.CREDIT,
].sort();

export const ermConnectionsOptions = ermConnectionTypes.map(option => ({
    key: "ERM_CONNECTION_" + option,
    value: option,
}));

export const riskResponseTypes: RiskResponseType[] = [
    RiskResponseType.ACCEPT,
    RiskResponseType.AVOID,
    RiskResponseType.TRANSFER,
    RiskResponseType.MITIGATE,
].sort();

export const riskResponseOptions = riskResponseTypes.map(option => ({
    key: "RISK_RESPONSE_" + option,
    value: option,
}));

export const riskModuleTypes: RiskModuleType[] = [
    RiskModuleType.INFORMATION_SECURITY,
    RiskModuleType.SYSTEMS_AND_APPLICATIONS,
    RiskModuleType.THIRD_PARTY_RISK,
];

export const controlTypes: InternalControlType[] = [
    InternalControlType.CORRECTIVE,
    InternalControlType.DETECTIVE,
    InternalControlType.PREVENTATIVE,
];

export const controlCategories: InternalControlCategory[] = [
    InternalControlCategory.SECURITY_AND_AUTHENTICATION,
    InternalControlCategory.STANDARDS_AND_DOCUMENTATION,
    InternalControlCategory.GOVERNANCE,
    InternalControlCategory.ROLES_AND_RESPONSIBILITIES,
    InternalControlCategory.CULTURE_AND_TRAINING,
    InternalControlCategory.ASSURANCE_AND_REMEDIATION,
    InternalControlCategory.REDUNDANCY,
];

export const ErmConnectionTypeNames = Object.values(ErmConnectionType)
    .map(type => ({ id: type, name: localization.getString(`ERM_CONNECTION_${ type }`) }));

export const riskScheduleIntervals = Object.freeze([
    { value: ActiveRiskScheduleUnit.MONTH, key: "RISK_SCHEDULE_MONTH_UNIT" },
    { value: ActiveRiskScheduleUnit.YEAR, key: "RISK_SCHEDULE_YEAR_UNIT" },
]);

export const devRiskScheduleIntervals = Object.freeze([
    ... riskScheduleIntervals,
    { value: ActiveRiskScheduleUnit.DAY, key: "RISK_SCHEDULE_DAY_UNIT" },
]);

export const riskScheduleAlmostDueCutoffInDays = 14;
