import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { LongTermIntervalKey, ShortTermIntervalKey } from "@app2/search/utils/time-range";
import { RecentItemsBaseService } from "@app2/shared/services/recent-items-base.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { SelectedFilters } from "@app2/type-defs/search/search-types";
import * as _ from "underscore";

const MAX_RECENT_SEARCHES = 5;

export type LocalStorageSearch = FixedIntervalLocalStorageSearch | CustomIntervalLocalStorageSearch;

interface FixedIntervalLocalStorageSearch {
    query?: string;
    filters?: SelectedFilters;
    intervalType?: Exclude<ShortTermIntervalKey | LongTermIntervalKey, "CUSTOM">;
}

interface CustomIntervalLocalStorageSearch {
    query?: string;
    filters?: SelectedFilters;
    intervalType?: "CUSTOM";
    rangeBegin: date;
    rangeEnd: date;
}

@Injectable({
    providedIn: "root",
})
export class RecentSearchesService extends RecentItemsBaseService<LocalStorageSearch> {

    constructor(storageService: StorageService,
                userService: UserService,
                orgsService: OrgsService) {
        super(Keys.recentSearches, storageService, userService, orgsService);
    }

    public addSearch(newSearch: LocalStorageSearch): void {
        const updatedSearches = _.chain(this.recentItems)
            .reject(search => search.query === newSearch.query && _.isEqual(search.filters, newSearch.filters))
            .first(MAX_RECENT_SEARCHES - 1)
            .value();
        updatedSearches.unshift(newSearch);
        this.setRecentItems(updatedSearches);
    }
}
