import { Component, OnDestroy } from "@angular/core";

@Component({
    selector: "navigation-top-bar-assets",
    template: `
        <nav class="top-bar new-tab-nav no-bottom-border" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "BASE_ASSETS" | localize }}</h1>
            </section>
        </nav>
    `,
})
export class NavigationTopBarAssetsComponent implements OnDestroy {
    constructor() {
        document.documentElement.style.setProperty("--nav-top-bar-height", "4rem");
    }

    ngOnDestroy() {
        document.documentElement.style.setProperty("--nav-top-bar-height", null);
    }
}
