import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppMainComponent } from "@app2/app-main.component";
import { AppRoutingModule } from "@app2/app-routing.module";
import { NavigationModule } from "@app2/navigation/navigation.module";
import { LoginModule } from "@app2/login/login.module";
import { UnauthorizedHttpInterceptor } from "@app2/shared/interceptors/unauthorized-http.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Clock } from "@js-joda/core";
import { HttpErrorTrackerInterceptor } from "@app2/shared/interceptors/http-error-tracker.interceptor";
import { ErrorTrackingHandler } from "@app2/shared/services/error-tracking-handler.service";
import { TitleStrategy } from "@angular/router";
import { TitleStrategyService } from "@app2/shared/services/title-strategy.service";
import { SharedModule } from "@app2/shared/shared.module";

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule, // must be after BrowserModule
        BrowserAnimationsModule,

        NavigationModule,
        SharedModule,
        LoginModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorTrackerInterceptor,
            multi: true
        },
        { provide: Clock, useValue: Clock.systemDefaultZone() },
        { provide: ErrorHandler, useClass: ErrorTrackingHandler },
        { provide: TitleStrategy, useClass: TitleStrategyService },
    ],
    bootstrap: [AppMainComponent],
    declarations: [AppMainComponent],
})
export class AppModule {
}
