import { Domain } from "@app2/alert/constants";
import { ScheduleInterval, ScheduleUnit } from "@app2/shared/type-defs";
import { SlugName } from "@app2/ticket/ticket-constants";
import { SavedQuery } from "@app2/type-defs/search/search-types";
import { TicketWatcher } from "@app2/type-defs/ticket/ticket-types";

export interface QueryTrigger {
    id: uuid;
    name: string;
    orgId: uuid;
    active: boolean;
    pausedUntil?: date;
    emailNotifications: string[];
    createdOn: string; // iso date
    initialCreatedOn: string; //iso date
    createdBy: uuid;
    initialCreatedBy: uuid;
    lastModifiedOn: string; // iso date
    lastModifiedBy: uuid;
    ticketOwner?: uuid;
    deleted: boolean;
    archived: boolean;
    nextTriggerId?: uuid;
    parentTriggerId?: uuid;
    policyId?: uuid;
    lineItemId?: uuid;
    severity: TriggerSeverity;
    type: TriggerType;
    notifyOnly: boolean;
    tracManaged: boolean;
    ticketWatchers: TicketWatcher[];
    ticketSlug?: string;

    /** @deprecated use query field on savedQuery instead */
    queryString?: string;
    /** @deprecated use primaryField field on savedQuery instead */
    primaryField?: string;

    maxMatch?: number;
    minMatch?: number;
    intervalSeconds?: number;
    lastRan?: string; // iso date
    lastFired?: string; // iso date
    customHeaders: string[];
    dynamicThresholds: boolean;
    temporalAnomalyDetection: boolean;
    everyEvent: boolean;
    labels: string[];
    lineItemIds: uuid[];
    riskControlIds: uuid[];
    scheduleId?: uuid;
    description: string;
    aggStatus?: string;
    aggType?: string;
    aggField?: string;
    seqTriggerSteps: SequenceTriggerStep[];
    defaultTriggerId?: uuid;
    autoDisabled: boolean;
    autoDisabledReason?: any;
    savedQueryId?: uuid;
    savedQuery?: SavedQuery;
    domain: Domain;
    filterListId?: uuid;
    filterField?: string;
    excludeListId?: uuid;
    excludeField?: string;
    tagIds: uuid[];
    isPrimaryDefaultTriggerCopy: boolean;
}

export interface Alert {
    id: uuid;
    triggerId: uuid;
    triggerName: string;
    severity: TriggerSeverity;
    resultTimestamp: string;
    resultEndTimestamp: string;
    resultCount: number;
    currentState: string;
    lastModified: string;
    changedBy: uuid;
    isAnomaly: boolean;
    ticketSlug?: number;
    totalAlerts?: number;
    primaryTerm?: string;
    host?: string;
    signature?: string;
    signatureType?: string;
    location?: string;
    locationCityState?: string;
    locationDescription?: string;
    threatMatchId?: number;
    type?: "custom" | "threat";
    stateChangeReason?: StateChangeReasonKey;
    stateChangeDetail?: string;
}

export interface InboxAlert {
    id: uuid;
    triggerId: uuid;
    resultTimestamp: date;
    resultEndTimestamp: date;
    currentState: string;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    ticketSlugName: SlugName;
    ticketSlugId: number;
    aggregationId: number;
    threatMatchId: number;
    stateChangeReason: StateChangeReasonKey;
    stateChangeDetail: string;
    resultCount: number;
    primaryTerm: string;
    trigger: QueryTrigger;
    host?: string;
    signature?: string;
    location?: string;
}

export interface AlertStateChange {
    oldState?: string;
    newState: string;
    // used in escalate state changes
    ticketSlugName?: SlugName;
    ticketSlugId?: number;
    // used in dismissal state changes
    reasonKey?: StateChangeReasonKey;
    detail?: string;
    fraudType?: FraudType;
    fraudActionsTaken?: FraudActionTaken[];
    fraudExposure?: number;
}

export interface MultipleAlertStateChangeRequest {
    alertStateChange: AlertStateChange;
    alertIds: uuid[];
}

export interface AlertResult {
    alertId: uuid; //TODO: This is the same triggerId, remove once it's unused
    triggerId?: uuid;
    orgId: uuid;
    results: AlertResultPoint[];
    anomalies: AlertResultPoint[];
}

export interface AlertResultPoint {
    x: number;
    y: number;
    trend: number;
    primaryTerm?: string;
}

export interface TriggerQuery {
    triggerIds?: string[];
}

export enum SeqTriggerIntervalUnit {
    minute = "minute",
    hour = "hour",
    day = "day",
}

export interface SequenceTriggerStep {
    id?: uuid;

    /** @deprecated use savedQuery's query instead */
    queryString: string;

    inputFields: string[];
    outputFields: string[];
    targetCount: number;
    intervalMinutes: number;
    savedQueryId: uuid;
}

export interface MatchValue {
    outputKey: string;
    inputKey: string;
    value: string;
}

export interface SequenceTriggerAlertResultStep {
    triggeredOn: string; // iso date

    /** @deprecated backend populates this field with step's queryString. Use trigger's steps' saved query's query instead */
    queryString: string;

    matchValues: MatchValue[];
}

export interface SequenceTriggerAlertResult {
    alertId: uuid;
    stepResults: SequenceTriggerAlertResultStep[];
    resultTimestamp: date;
    lastModified: date;
    changedBy: uuid;
    currentState: string;
    ticketSlug?: number;
    stateChangeReason?: StateChangeReasonKey;
    stateChangeDetail?: string;
}

export interface SequenceTriggerAlertResultWithId {
    id: uuid;
    alertId: uuid;
    stepResults: SequenceTriggerAlertResultStep[];
    resultTimestamp: date;
    lastModified: date;
    changedBy: uuid;
    currentState: string;
    ticketSlug?: number;
    stateChangeReason?: StateChangeReasonKey;
    stateChangeDetail?: string;
}

export interface AnomalyAlertResult {
    id: uuid;
    alertId: uuid;
    expected: number;
    actual: number;
    currentState: string;
    anomalyTimestamp: date;
    resultTimestamp: date;
    resultEndTimestamp: date;
    primaryTerm: string;
    isHighPriorityCountry: boolean;
    isHighPriorityPort: boolean;
    reason: string[];
    // doesnt actually come from the api, manually calculating it with duplicated logic from ml service
    anomalyEndTimestamp: date;
}

export interface UbaAlertResult {
    id: uuid;
    alertId: uuid;
    currentState: string;
    anomalyTimestamp: date;
    resultTimestamp: date;
    resultEndTimestamp: date;
    primaryTerm: string;
    riskFactors: RiskFactor[];
}

export type AlertType = Alert | SequenceTriggerAlertResultWithId | AnomalyAlertResult | UbaAlertResult;

export interface RiskFactor {
    name: string;
    scoreImpact: number;
    oldValues: string[];
    newValues: string[];
}

export interface DefaultTriggerGroup {
    id: uuid;
    name: string;
    description: string;
    deleted: boolean;
    triggers: DefaultTrigger[];
    domain: Domain;
    savedQueryId: uuid;
}

export interface DefaultTrigger {
    id: uuid;
    enabledOnAccount: boolean;
    hasPrimaryCopy: boolean;
    groupId: uuid;
    name: string;
    description: string;
    deleted: boolean;
    /** @deprecated backend will not provide this soon */
    queryString: string;
    intervalSeconds: number;
    policyId?: uuid;
    lineItemId?: uuid;
    severity: TriggerSeverity;
    minMatch?: number;
    maxMatch?: number;
    /** @deprecated backend will not provide this soon */
    primaryField?: string;
    dynamicThresholds: boolean;
    temporalAnomalyDetection: boolean;
    labels: string[];
    lineItemIds: uuid[];
    createdOn: date;
    everyEvent: boolean;
    aggType?: string;
    aggField?: string;
    type: TriggerType;
    notifyOnly: boolean;
    domain: Domain;
    savedQueryId?: uuid;
    savedQuery?: SavedQuery;
    //TODO: add support for seqTriggers on backend
    seqTriggerSteps?: SequenceTriggerStep[];
    customHeaders: string[];
}

export const enum TriggerSeverity {
    informational = "INFORMATIONAL",
    critical = "CRITICAL",
    high = "HIGH",
    medium = "MEDIUM",
    low = "LOW",
    test = "TEST",
    none = "NONE",
}

export interface DefaultTriggerStats {
    orgId: uuid;
    triggerId: uuid;
    numAlerts: number;
    numEscalated: number;
    // savedQueryIds: uuid[]; // exists but not used
    savedQueries: SavedQuery[];
    active: boolean;
    lastModifiedOn: date;
}

export interface QueryTriggerStats {
    orgId: uuid;
    triggerId: uuid;
    numAlerts: number;
    numEscalated: number;
}

export interface TaskScheduleWatcher {
    personId: uuid;
    type: "all" | "state";
}

export interface TaskScheduleQuery {
    id: uuid;
    name: string;

    /** @deprecated use the saved query's query */
    queryString: string;

    customColumns: string[];
    taskScheduleId: uuid;
    libraryTaskScheduleId: uuid;
    savedQueryId?: uuid;
    savedQuery?: SavedQuery;
}

//alertUUID -> List<PersonNames>
export interface ItemViewerNames {
    [key: string]: string[];
}

//alertUUID -> List<PersonUUID>
export interface ItemViewerIds {
    [key: string]: uuid[];
}

export interface TaskSchedule {
    id: uuid;
    orgId: uuid;
    libraryTaskScheduleId: uuid;
    name: string;
    description: string;
    ticketOwner: uuid;
    queries: TaskScheduleQuery[];
    scheduleUnit: ScheduleUnit;
    scheduleInterval: number;
    timeZoneId: string;
    labels: string[];
    severity: string;
    lastRan: string; //iso datetime
    nextRun: string; //iso date (ie 2017-09-10)
    createdOn: string; //iso datetime
    createdBy: uuid;
    lastModifiedOn: string; //iso datetime
    lastModifiedBy: uuid;
    active: boolean;
    deleted: boolean;
    watchers: TaskScheduleWatcher[];
    dueTime: string;
    dueInUnit: string;
    dueInInterval: number;
    parentId: uuid | null;
    subSchedules: TaskSchedule[];
    immediateRunRequested: boolean;
    lineItemIds: uuid[];
    riskControlIds: uuid[];
    skipWeekends: boolean;
    tagIds: uuid[];
}

export interface LibraryTaskSchedule {
    id: uuid;
    groupId: uuid;
    name: string;
    description: string;
    queries: TaskScheduleQuery[];
    scheduleUnit: ScheduleUnit;
    scheduleInterval: number;
    labels: string[];
    severity: string;
    createdOn: date;
    createdBy: uuid;
    lastModifiedOn: date;
    lastModifiedBy: uuid;
    deleted: boolean;
    parentId: uuid;
    active: boolean;
    groupName: string;
    copiedToOrg: boolean;
    lineItemIds: uuid[];
    riskControlIds: uuid[];
    dueInInterval: number;
    dueTime: string;
    dueInUnit: ScheduleUnit;
}

export interface AlertRelatedObjects {
    taskScheduleQueries: TaskScheduleQuery[];
    taskSchedules: TaskSchedule[];
    libraryTaskScheduleQueries: TaskScheduleQuery[];
    libraryTaskSchedules: LibraryTaskSchedule[];
    queryTriggers: QueryTrigger[];
    defaultTriggers: DefaultTrigger[];
}

export interface LibraryTaskScheduleOptions {
    timezoneId: string;
    active: boolean;
    ticketOwner: uuid | null;
    dueTime: string;
    dueInUnit: string;
    dueInInterval: number | undefined;
    watchers: TaskScheduleWatcher[];
    libraryTaskScheduleIds: uuid[];
}

export interface LibraryTaskScheduleGroup {
    id: uuid;
    name: string;
    description: string;
    createdOn: date;
    lastModifiedOn: date;
    deleted: boolean;
    libraryTaskSchedules: LibraryTaskSchedule[];
}

export interface TriggerSchedule {
    id: uuid;
    name: string;
    description: string;
    timeZoneId: string;
    createdOn: string;
    createdBy: uuid;
    lastModifiedOn: string;
    lastModifiedBy: uuid;
    deleted: boolean;
    intervals: ScheduleInterval[];
}

export enum TriggerType {
    custom = "custom",
    sequence = "sequence",
    threat = "threat",
    patternscoutSmartThreshold = "patternscout_smart_threshold",
    ueba = "ueba",
    fraudUba = "fraud_uba",
    // These types are not yet supported by the backend:
    upperThreshold = "upperThreshold",
    dynamicThresholds = "dynamicThresholds",
    lowerThreshold = "lowerThreshold",
    temporalAnomalyDetection = "temporalAnomalyDetection",
    everyEvent = "everyEvent",
}

export interface TriggerTypeConfig {
    name: TriggerType;
    localizationKey: string;
    description: string;
    canEditQuery: boolean;
    canSetPrimaryField: boolean;
    canSetAggFn: boolean;
    canSetInterval: boolean;
    canSetThreshold: boolean;
    canSetWatchlists: boolean;
    canSetSavedQuery: boolean;
    selectable: boolean;

    suggestPredicate(average: number): boolean;

    warningPredicate(average: number, trigger: QueryTrigger | DefaultTrigger);
}

export interface AggregationCardinalityException {
    key: string;
    cardinality: number;
    maxCardinality: number;
    query: string;
    primaryField: string;
}

export interface TaskScheduleDashboard {
    revealDashboardId: uuid;
    dashboardName: string;
    intervalType: TaskScheduleDashboardIntervalType;
}

export enum TaskScheduleDashboardIntervalType {
    SINCE_LAST_RUN = "SINCE_LAST_RUN",
    LAST_24_HOURS = "LAST_24_HOURS",
    LAST_7_DAYS = "LAST_7_DAYS",
    LAST_30_DAYS = "LAST_30_DAYS",
    LAST_90_DAYS = "LAST_90_DAYS",
    PREVIOUS_MONTH = "PREVIOUS_MONTH",
    PREVIOUS_QUARTER = "PREVIOUS_QUARTER",
}

export const enum StateChangeReasonKey {
    noThreat = "NO_THREAT",
    adjustTrigger = "ADJUST_TRIGGER",
    duplicate = "DUPLICATE",
    other = "OTHER",
    flagFraud = "FRAUD_FLAGGED",
}

export const enum FraudState {
    fraudSuspected = "FRAUD_SUSPECTED",
    fraudConfirmed = "FRAUD_CONFIRMED",
}

export const enum FraudType {
    newAccount = "NEW_ACCOUNT",
    accountTakeover = "ACCOUNT_TAKEOVER",
    scam = "SCAM",
    billPay = "BILL_PAY",
    debitCard = "DEBIT_CARD",
    creditCard = "CREDIT_CARD",
    checkFraud = "CHECK_FRAUD",
    p2pPayment = "P2P_PAYMENT",
    ach = "ACH",
    wire = "WIRE",
    other = "OTHER",
    unknown = "UNKNOWN",
}

export const enum FraudActionTaken {
    accountRestricted = "ACCOUNT_RESTRICTED",
    cardReissued = "CARD_REISSUED",
    customerRefund = "CUSTOMER_REFUND",
    customerNotRefunded = "CUSTOMER_NOT_REFUNDED",
    institutionContacted = "SENDING_OR_RECEIVING_INSTITUTION_CONTACTED",
    suspectDetailsCaptured = "SUSPECT_DETAILS_CAPTURED",
    restitutionAccountOpened = "RESTITUTION_ACCOUNT_OPENED",
}

export interface RelatedAlertObjects {
    taskSchedules: TaskSchedule[];
    libraryTaskSchedules: LibraryTaskSchedule[];
    queryTriggers: QueryTrigger[];
    defaultTriggers: DefaultTrigger[];
}

export const WATCH_LIST_MAX_INDICATORS = 2048;

export interface GetSuggestedTaskSchedulesResponse {
    suggestedLibTaskSchedules: LibraryTaskSchedule[];
    suggestedTaskSchedules: TaskSchedule[];
}

interface Point {
    x: number;
    y: number;
}

export interface EventGraphData {
    mainSeries: Point[];
    trendLine: Point[];
    selectedPoint: Point;
    begin: number;
    end: number;
}

export interface DifferingTriggerFields {
    id: uuid;
    name: string;
    severity: string;
    maxMatch: number;
    minMatch: number;
    notifyOnly: boolean;
    intervalSeconds: number;
    savedQueryId: uuid;
    aggType: string;
    aggField: string;
    customHeaders: string[];
}

export interface TriggerResyncInfo {
    id: uuid;
    createdOn: string;
    timeOfAnalysis: string;
    triggerCopyDifferingFields: DifferingTriggerFields;
    defaultTriggerDifferingFields: DifferingTriggerFields;
    hasDifferingTriggerConfigurationType: boolean;
}

export interface TriggerResyncResponse {
    triggerResyncInfo: TriggerResyncInfo;
    updatedTrigger: QueryTrigger;
}

export enum DefaultTriggerGroupAnalysisStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
}

export interface DefaultTriggerGroupAnalysis {
    id: uuid;
    orgId: uuid;
    defaultTriggerGroupId: uuid;
    savedQueryId: uuid;
    status: DefaultTriggerGroupAnalysisStatus;
    shouldEnableTriggersInGroup: boolean;
    createdOn: string;
    createdBy: uuid;
}

export enum RecommendedActionsFilterType {
    ALL = "ALL",
    WITH_ACTIVITY = "WITH_ACTIVITY",
    WITHOUT_ACTIVITY = "WITHOUT_ACTIVITY",
}

export enum DefaultTriggerCopyStatus {
    NOT_COPIED = "NOT_COPIED",
    ACTIVE = "ACTIVE",
    PAUSED = "PAUSED",
    DELETED = "DELETED",
}

export interface DefaultTriggerGroupData {
    name: string;
}

export interface UebaRiskDetails {
    username: string;
    riskScore: number;
    savedQueryId: uuid;
    ticketSlugName: SlugName;
    ticketSlugId: number;
    appName: string;
}

export enum BulkEditPrimaryCopiesTicketUser {
    TRAC_CONTACT = "TRAC_CONTACT",
    TRAC_ENGINEER = "TRAC_ENGINEER"
}

export interface BulkEditPrimaryCopiesRequest {
    orgIds: uuid[];
    tracManaged: boolean;
    severity?: TriggerSeverity;
    ticketOwner?: BulkEditPrimaryCopiesTicketUser;
    ticketWatchers?: BulkEditPrimaryCopiesTicketUser[];
    ticketSlug?: SlugName;
    notifyOnly?: boolean;
    tracEngineerUserId: uuid;
}
