import { log } from "@app2/logger";
import { LocalDateTimeRange } from "@app2/shared/type-defs";
import { LocalDateTime } from "@js-joda/core";

export type BeginEndRangesByName = Record<string, (now: LocalDateTime) => LocalDateTimeRange>;
export type IntervalType = Record<string, string>;

export type IntervalKey = ShortTermIntervalKey | LongTermIntervalKey | "ALL_TIME";

export type ShortTermIntervalKey =
    "CUSTOM"
    | "LAST_HOUR"
    | "LAST_3_HOURS"
    | "LAST_12_HOURS"
    | "LAST_24_HOURS"
    | "LAST_7_DAYS"
    | "LAST_30_DAYS";

export type LongTermIntervalKey = "CUSTOM" | "LAST_7_DAYS" | "LAST_30_DAYS" | "LAST_90_DAYS";

export const IntervalTypes: IntervalType = {
    CUSTOM: "CUSTOM",
    LAST_HOUR: "ONE_HOUR",
    LAST_3_HOURS: "THREE_HOURS",
    LAST_12_HOURS: "TWELVE_HOURS",
    LAST_24_HOURS: "TWENTY_FOUR_HOURS",
    LAST_7_DAYS: "SEVEN_DAYS",
    LAST_30_DAYS: "THIRTY_DAYS",
    LAST_90_DAYS: "NINETY_DAYS",
};

export const shortTermRanges: BeginEndRangesByName = {
    LAST_HOUR: (now) => ({
        begin: now.minusHours(1),
        end: now,
    }),
    LAST_3_HOURS: (now) => ({
        begin: now.minusHours(3),
        end: now,
    }),
    LAST_12_HOURS: (now) => ({
        begin: now.minusHours(12),
        end: now,
    }),
    LAST_24_HOURS: (now) => ({
        begin: now.minusHours(24),
        end: now,
    }),
    LAST_7_DAYS: (now) => ({
        begin: now.minusDays(7),
        end: now,
    }),
    LAST_30_DAYS: (now) => ({
        begin: now.minusDays(30),
        end: now,
    }),
};

export const longTermRanges: BeginEndRangesByName = {
    LAST_7_DAYS: (now) => ({
        begin: now.minusDays(7),
        end: now,
    }),
    LAST_30_DAYS: (now) => ({
        begin: now.minusDays(30),
        end: now,
    }),
    LAST_90_DAYS: (now) => ({
        begin: now.minusDays(90),
        end: now,
    }),
};

export const allTimeRange: BeginEndRangesByName = {
    ALL_TIME: () => undefined,
};

const allRanges: BeginEndRangesByName = { ...shortTermRanges, ...longTermRanges, ...allTimeRange };

/**
 * Gets "short term" aka search keys. All strings should be localizable
 * @returns {ShortTermIntervalKey[]}
 */
export function getShortTermKeys(): ShortTermIntervalKey[] {
    return Object.keys(shortTermRanges) as ShortTermIntervalKey[];
}

/**
 * Gets "long term" aka stats keys. All strings should be localizable
 * @returns {LongTermIntervalKey[]}
 */
export function getLongTermKeys(): LongTermIntervalKey[] {
    return Object.keys(longTermRanges) as LongTermIntervalKey[];
}

/**
 * Gets all valid keys. All strings should be localizable
 * @returns {(ShortTermIntervalKey | LongTermIntervalKey)[]}
 */
export function getAllKeys(): (IntervalKey)[] {
    return Object.keys(allRanges) as IntervalKey[];
}

/**
 * Returns a LocalDateTimeRange or undefined, if key is invalid
 * @param key {string} one of the keys from get{Short,Long}TermKeys
 * @param now {LocalDateTime} from LocalDateTime.now() for example.
 * @returns {LocalDateTimeRange}
 */
export function getTimeRange(key: string, now: LocalDateTime): LocalDateTimeRange {
    if (allRanges[key]) {
        return allRanges[key](now);
    }
    log.warn("Unrecognized range name: " + key);
}
