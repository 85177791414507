export const elbTemplate = `
<section class="integration-modal-setup">
    <p>To connect your AWS ELB to your DefenseStorm account, follow these steps:</p>
    <div class="flex-container">
        <section class="flex-child-same-width">
            <h3>Step One</h3>
            <ul>
                <li>
                    <a class="link-text" href="https://console.aws.amazon.com/ec2/v2/home#LoadBalancers:" target="_new">
                    Follow this link to the AWS ELB Configuration.</a>
                </li>
                <li>Select the load balancer you'd like to monitor.</li>
                <li>Under the <strong>Description</strong> tab scroll down to Access Logs and click <strong>Edit</strong>.</li>
            </ul>
        </section>
        <section class="flex-child-same-width">
            <h3>Step Two</h3>
                <ul>
                    <li>
                        <strong>Fill in the following values: </strong>
                    </li>
                    <ul>
                        <li>Interval: <em>5 minutes</em></li>
                        <li>S3 path: <em>defensestorm-customer-elb-logs-<strong>aws-region</strong>/{0}</em>
                            <ul>
                                <li class="no-bullet">
                                    aws-region: <em>The portion of the ELB DNS name between the first and second periods.</em>
                                </li>
                            </ul>
                        </li>
                        <li>Create this bucket for me: <em>unchecked</em></li>
                    </ul>
                </ul>
        </section>
    </div>
    <p>
        Watch this page to make sure that DefenseStorm is receiving ELB information. It may take up to
        10 minutes for Amazon to send us the first log file.
    </p>

    <h3>Troubleshooting</h3>
    <ul class="troubleshooting">
        <li>
            If you see the error <code>Bucket already exists</code> make sure that <strong>Create this bucket for me</strong> is <strong>unchecked</strong>.
        </li>
        <li>
            If you see the error <code>S3Bucket: defensestorm-customer-elb-logs-<strong>aws-region</strong> does not exist</code> make
            sure that you've entered the <strong>S3 path</strong> correctly.
        </li>
        <li>
            If you have any other problems, <a class="link-text" href="{1}" target="_new">contact DefenseStorm Customer Support</a>
            for additional help.
        </li>
    </ul>
</section>
`;
