import { ErrorHandler, Injectable } from "@angular/core";
import { log } from "@app2/logger";
import { ErrorTrackingService } from "@app2/shared/services/error-tracking.service";

/**
 * Handles uncaught exceptions and unhandled failed promises.
 */
@Injectable()
export class ErrorTrackingHandler implements ErrorHandler {
    constructor(private errorTrackingService: ErrorTrackingService) {
    }

    public handleError(err: any): void {
        log.error("Uncaught exception", err);
        this.errorTrackingService.sendError(err.originalError || err, undefined, false);
    }
}
