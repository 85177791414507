
export const StorageServiceKeys = {
    dismissInfoBanner: "dismissInfoBanner",
    inactivityTimeoutEnabled: "InactivityTimeoutEnabled",
    lastSelectedDashboard: "lastSelectedDashboard",
    lastRevealWorkspace: "lastRevealWorkspace",
    lastRevealDashboard: "lastRevealDashboard",
    lastRevealWorkspaceInListPage: "lastRevealWorkspaceInListPage",
    lastRevealLibraryWorkspaceInListPage: "lastRevealLibraryWorkspaceInListPage",
    readonlyMode: "readonlyMode", //boolean
    recentSearches: "recent.searches",
    recentTickets: "recent.tickets",
    searchCustomColumns: "searchCustomColumns",
    searchEventSortOptions: "searchEventSortOptions",
    searchEventPageSize: "searchEventPageSize",
    selectedRiskModules: "selectedRiskModules",
    shortcutKeysEnabled: "ShortcutKeysEnabled",
    taskView: "taskView",
    taskScheduleView: "taskScheduleView",
    triggerListSelectedSort: "triggerListSelectedSort",
    useNewAlertInbox: "useNewAlertInbox",
    useNewDashboards: "useNewDashboards",
    alertInboxDropdownFilter: "alertInboxDropdownFilter",
    alertSortBy: "alertSortBy",
    darkMode: "darkMode", // boolean
    leftBarVisible: "leftBarVisible", // boolean
    showSubsection: "showSubsection", // number
    cyberRiskReadinessBannerOpen: "cyberRiskReadinessBannerOpen",
    escalationPageBannerDismissed: "escalationPageBannerDismissed",
};
