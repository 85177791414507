import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { StorageService } from "@app2/shared/services/storage.service";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

export class RecentItemsBaseService<T> {

    private recentItems$ = new ReplaySubject<T[]>(1);
    protected recentItems: T[];

    constructor(private readonly localStorageKey: string,
                private readonly storageService: StorageService,
                userService: UserService,
                orgsService: OrgsService) {
        // Update the recent items when the current user or the current org change (accounting for logout)
        combineLatest([userService.getCurrentUser$(), orgsService.getCurrentOrg$()])
            .subscribe(([user, currentOrg])  => {
                if (user && currentOrg) {
                    this.recentItems = this.storageService.get(this.localStorageKey) || [];
                } else {
                    this.recentItems = [];
                }
                this.recentItems$.next(this.recentItems);
            });
    }

    public getRecentItems$(): Observable<T[]> {
        return this.recentItems$.asObservable()
            .pipe(distinctUntilChanged());
    }

    protected setRecentItems(items: T[]): void {
        this.storageService.set(this.localStorageKey, items);
        this.recentItems = items;
        this.recentItems$.next(items);
    }

    public clearRecentItems(): void {
        this.setRecentItems([]);
    }

    public refresh() {
        this.recentItems = this.storageService.get(this.localStorageKey) || [];
        this.recentItems$.next(this.recentItems);
    }
}
