import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { PeopleService } from "@app2/account/people.service";
import { UserService } from "@app2/account/user.service";
import { BackendHeartbeatService } from "@app2/shared/services/backend-heartbeat.service";
import { RecentSearchesService } from "@app2/shared/services/recent-searches.service";
import { RecentTicketsService } from "@app2/shared/services/recent-tickets.service";

@Injectable({
    providedIn: "root",
})
export class AppStateService {

    constructor(private readonly heartbeatService: BackendHeartbeatService,
                private readonly recentTicketsService: RecentTicketsService,
                private readonly recentSearchesService: RecentSearchesService,
                private readonly userService: UserService,
                private readonly orgsService: OrgsService,
                private readonly peopleService: PeopleService) {
    }

    /**
     * Should only be called when the user and currentOrg have been set.
     */
    public startUp(): void {
        this.heartbeatService.enableBackendHeartbeat();
        this.peopleService.refresh();
    }

    public tearDown(): void {
        this.heartbeatService.disableBackendHeartbeat();
        this.orgsService.clear();
        this.userService.clear();
        this.peopleService.clear();
    }
}
