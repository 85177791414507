export const azureTemplate = `
<div class="integration-modal-setup">
    <p>To integrate an Azure account to your DefenseStorm account, follow these steps:</p>
    <h3>Step One: <strong>Authorize DefenseStorm</strong></h3>
    <ul>
        <li>Once you click on the link below you will be redirected to Microsoft Online</li>
        <li>Microsoft will then prompt you to accept various permissions DefenseStorm needs to access your Azure data</li>
        <li>After you read through and click the <strong>Accept</strong> button, Microsoft should redirect you back to this page</li>
        <li>Ready?
            <ul>
            <li>
                <a class="link-text" href="{0}">
                    Set up an Azure Account Integration
                </a>
            </li>
            </ul>
        </li>
    </ul>
</div>
`;

export const o365Template = `
<div class="integration-modal-setup">
    <p>To integrate an Office 365 account to your DefenseStorm account, follow these steps:</p>
    <h3>Step One: <strong>Authorize DefenseStorm</strong></h3>
    <ul>
        <li>Once you click on the link below you will be redirected to Microsoft Online</li>
        <li>Microsoft will then prompt you to accept various permissions DefenseStorm needs to access your Office 365 data</li>
        <li>After you read through and click the <strong>Accept</strong> button, Microsoft should redirect you back to this page</li>
        <li>Ready?
            <ul>
            <li>
                <a class="link-text" href="{0}">
                    Set up an Office 365 Account Integration
                </a>
            </li>
            </ul>
        </li>
    </ul>
</div>
`;
