import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { RestClient, unwrapIfAppropriate } from "@app2/clients/rest-client";
import {
    ChartTemplate,
    FrameworkControlAssessmentParams,
    Report,
    ReportData,
    ReportSchedule,
    ReportTemplate,
    ReportVersion,
    RevealDashboard,
    RevealWorkspace,
    RiskAssessmentControlParams,
    RiskAssessmentParams,
    Series,
    UserCustomizations,
} from "@app2/report/type-defs";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { DashboardTemplate, RelatedReportObjects } from "@app2/type-defs/report/report-types";
import { TicketPageFilters, TicketSummary } from "@app2/type-defs/ticket/ticket-types";
import { SlugName } from "@app2/ticket/ticket-constants";


export interface CatEvidenceParameters {
    answers: boolean;
    comments: boolean;
    incidents: boolean;
    tasks: boolean;
    taskSchedules: boolean;
    triggers: boolean;
    attachments: boolean;
    events: boolean;
    lineItemIds: uuid[];
}

@Injectable({
    providedIn: "root",
})
export class ReportClientService {
    private reportClient: RestClient;
    private reportClientNoOrg: RestClient;

    constructor(httpClient: HttpClient,
                envService: EnvironmentService,
                orgsService: OrgsService) {
        const baseUrl = envService.getConfig().reportService;
        this.reportClient = new RestClient(baseUrl, httpClient, orgsService);
        this.reportClientNoOrg = new RestClient(baseUrl, httpClient);
    }

    public createReportSchedule(reportSchedule: ReportSchedule): Promise<ReportSchedule> {
        return this.reportClient.doPost("/report-schedule", reportSchedule);
    }

    public editReportSchedule(reportSchedule: ReportSchedule): Promise<ReportSchedule> {
        return this.reportClient.doPut("/report-schedule", reportSchedule);
    }

    public deleteReportSchedule(scheduleId: uuid): Promise<void> {
        return this.reportClient.doDelete(`/report-schedule/${ scheduleId }`);
    }

    public requestTicketReport(parameters, timeZoneId: string): Promise<String> {
        return this.reportClient.doPost(`/ticketReport?timeZoneId=${ timeZoneId }`, parameters);
    }

    public requestCatSummaryReport(timeZoneId: string, versionId: uuid): Promise<Report> {
        return this.reportClient.doPost("/catSummaryReport", undefined, { timeZoneId, versionId });
    }

    public requestCatEvidenceReport(parameters: CatEvidenceParameters, timeZoneId: string, versionId: uuid): Promise<Report> {
        return this.reportClient.doPost("/catEvidenceReport", parameters, { timeZoneId, versionId });
    }

    public requestAcetSpreadsheetReport(versionId: uuid): Promise<Report> {
        // Default timeZoneId for this report, only used for generating a report title. Can be changed later
        const timeZoneId = "America/Los_Angeles";
        return this.reportClient.doPost("/acetSpreadsheetReport", undefined, { timeZoneId, versionId });
    }

    public requestFrameworkExportReport(frameworkId: uuid, lineItemIds: uuid[]): Promise<Report> {
        return this.reportClient.doPost(`/frameworkSpreadsheetReport`, { frameworkId, lineItemIds });
    }

    public requestExecutiveReport(beginAt: string, endAt: string, timeZoneId: string,
                                  templateId?: uuid, emailNotificationList?: string[]): Promise<Report> {
        return this.reportClient.doPost("/executiveReport", undefined,
            { beginAt, endAt, timeZoneId, templateId, emailNotificationList });
    }

    public requestFrameworkControlAssessmentReport(params: FrameworkControlAssessmentParams): Promise<Report> {
        return this.reportClient.doPost(`/frameworkControlAssessmentReport`, params);
    }

    public requestRiskAssessmentReport(params: RiskAssessmentParams): Promise<Report> {
        return this.reportClient.doPost("/riskAssessmentReport", params);
    }

    public requestRiskAssessmentControlReport(params: RiskAssessmentControlParams): Promise<Report> {
        return this.reportClient.doPost("/riskControlAssessmentReport", params);
    }

    public requestBulkTicketCsvReport(slugName: SlugName, filters?: Partial<TicketPageFilters>, sort?: string,
                                      descending?: boolean, pageStart?: TicketSummary): Promise<Report> {
        const params = { sort, descending, filters, pageStart };
        return this.reportClient.doPost("/bulkTicketCsvReport", { slugName, ticketPageRequest: params });
    }

    public getExecutiveReports(): Promise<Report[]> {
        return this.reportClient.doGet("/executiveReport").then(unwrapIfAppropriate);
    }

    public getReportData(reportRequestId: uuid): Promise<ReportData> {
        return this.reportClient.doGet("/report-data", { reportRequestId: reportRequestId }, ["counts_by_source"]);
    }

    public updateReportUserCustomizations(id: uuid, userCustomizations: UserCustomizations): Promise<ReportData> {
        return this.reportClient.doPut("/report-data", { id, userCustomizations });
    }

    public getAllReportSchedules(): Promise<ReportSchedule[]> {
        return this.reportClient.doGet("/report-schedule", { include_deleted: false });
    }

    public pauseReportSchedule(scheduleId: uuid): Promise<void> {
        return this.reportClient.doPost(`/report-schedule/${ scheduleId }/pause`);
    }

    public resumeReportSchedule(scheduleId: uuid): Promise<void> {
        return this.reportClient.doPost(`/report-schedule/${ scheduleId }/resume`);
    }

    public getReportVersionUploadUrl(reportVersionId: uuid, contentType: string | null): Promise<string> {
        return this.reportClient.doGet(`/report-version/${ reportVersionId }/upload-url`,
            { contentType: contentType || "application/octet-stream" });
    }

    public createReportVersion(reportVersion: ReportVersion): Promise<ReportVersion> {
        return this.reportClient.doPost(`/report-version`, reportVersion);
    }

    public updateReportVersion(reportVersion: ReportVersion): Promise<ReportVersion> {
        return this.reportClient.doPut(`/report-version`, reportVersion);
    }

    public createReportTemplate(template: ReportTemplate): Promise<ReportTemplate> {
        return this.reportClient.doPost("/report-template", template);
    }

    public editReportTemplate(template: ReportTemplate): Promise<ReportTemplate> {
        return this.reportClient.doPut("/report-template", template);
    }

    public getReportTemplate(templateId: uuid): Promise<ReportTemplate> {
        return this.reportClient.doGet(`/report-template/${ templateId }`);
    }

    public getReportTemplates(includeDeleted: boolean): Promise<ReportTemplate[]> {
        return this.reportClient.doGet(`/report-template`, { include_deleted: includeDeleted });
    }

    public deleteReportTemplate(templateId: uuid): Promise<void> {
        return this.reportClient.doDelete(`/report-template/${ templateId }`);
    }

    public undeleteReportTemplate(templateId: uuid): Promise<void> {
        return this.reportClient.doPost(`/report-template/${ templateId }/undelete`);
    }

    public getReferencedBySeries(seriesIds: uuid[]): Promise<RelatedReportObjects> {
        return this.reportClient.doGet("/referenced-by-series", { seriesIds });
    }

    public getReferencedByCharts(chartIds: uuid[]): Promise<RelatedReportObjects> {
        return this.reportClient.doGet("/referenced-by-charts", { chartIds });
    }

    public getReferencedBySavedQuery(savedQueryIds: uuid[]): Promise<RelatedReportObjects> {
        return this.reportClient.doPost("/referenced-by-saved-query", savedQueryIds);
    }

    public getAllSavedQueryDependents(): Promise<{ [key: string]: RelatedReportObjects }> {
        return this.reportClient.doPost("/referenced-by-saved-query-by-id", null);
    }

    public getRiskSystemsApps(timeZoneId: string): Promise<Blob> {
        return this.reportClient.downloadBinaryGet("/risk/inventory/download", { timeZoneId });
    }

    public getRiskAssessmentExport(assessmentId: uuid): string {
        return this.reportClient.getFullUrlFor(`/risk-assessment/${ assessmentId }`);
    }

    public getReportRequest(reportRequestIds: uuid[]): Promise<Report[]> {
        return this.reportClient.doPost("/report-requests", reportRequestIds);
    }

    /**
     * Series
     */

    public createSeries(series: Series): Promise<Series> {
        return this.reportClient.doPost("/series", series);
    }

    public editSeries(series: Series): Promise<Series> {
        return this.reportClient.doPut("/series", series);
    }

    /**
     * Reports
     */
    public restoreReport(reportId: uuid): Promise<void> {
        return this.reportClient.doPost(`/report/${ reportId }/restore`);
    }

    public deleteReport(reportId: uuid): Promise<void> {
        return this.reportClient.doDelete(`/report/${ reportId }/delete`);
    }

    /**
     * Charts
     */
    public getCharts(): Promise<ChartTemplate[]> {
        return this.reportClient.doGet("/chart-template");
    }

    public getChart(chartId: uuid): Promise<ChartTemplate> {
        return this.reportClient.doGet(`/chart-template/${ chartId }`);
    }

    public createChart(chart: ChartTemplate): Promise<ChartTemplate> {
        return this.reportClient.doPost("/chart-template", chart);
    }

    public editChart(chart: ChartTemplate): Promise<ChartTemplate> {
        return this.reportClient.doPut("/chart-template", chart);
    }

    public deleteCharts(chartIds: uuid[]): Promise<uuid[]> {
        return this.reportClient.doDelete(`/chart-template`, { chartIds });
    }

    public restoreChart(chartId: uuid): Promise<void> {
        return this.reportClient.doPost(`/chart-template/${ chartId }/undelete`);
    }

    /**
     * Dashboards
     */
    public getDashboards(includeDeleted: boolean): Promise<DashboardTemplate[]> {
        return this.reportClient.doGet(`/dashboard-template`, { include_deleted: includeDeleted });
    }

    public getDashboard(templateId: uuid): Promise<DashboardTemplate> {
        return this.reportClient.doGet(`/dashboard-template/${ templateId }`);
    }

    public getObjectsToBeCopiedForDashboardLibraries(dashboardIds: uuid[]): Promise<RelatedReportObjects> {
        return this.reportClientNoOrg.doGet(`/org/all/objects-to-be-copied`, { dashboardIds });
    }

    public copyLibraryDashboardToAllAccounts(dashboardIds: uuid[]): Promise<number> {
        return this.reportClientNoOrg.doPost(`/org/all/dashboard-template/copy-to-all-accounts`, dashboardIds);
    }

    public deleteDashboardTemplates(templateIds: uuid[]): Promise<uuid[]> {
        return this.reportClient.doDelete(`/dashboard-template`, { templateIds });
    }

    public deleteLibraryDashboardTemplates(templateIds: uuid[]): Promise<uuid[]> {
        return this.reportClientNoOrg.doDelete(`/org/all/dashboard-template`, { templateIds });
    }

    public restoreDashboardTemplate(dashboardId: uuid): Promise<void> {
        return this.reportClient.doPost(`/dashboard-template/${ dashboardId }/restore`);
    }

    public restoreLibraryDashboardTemplate(dashboardId: uuid): Promise<void> {
        return this.reportClientNoOrg.doPost(`/org/all/dashboard-template/${ dashboardId }/restore`);
    }

    public getDashboardTemplates(includeDeleted: boolean): Promise<DashboardTemplate[]> {
        return this.reportClient.doGet(`/dashboard-template`, { include_deleted: includeDeleted });
    }

    public createDashboardTemplate(dashboard: DashboardTemplate): Promise<DashboardTemplate> {
        return this.reportClient.doPost(`/dashboard-template`, dashboard);
    }

    public editDashboardTemplate(dashboard: DashboardTemplate): Promise<DashboardTemplate> {
        return this.reportClient.doPut(`/dashboard-template/${ dashboard.id }`, dashboard);
    }

    public createLibraryDashboardTemplate(dashboard: DashboardTemplate): Promise<DashboardTemplate> {
        return this.reportClientNoOrg.doPost(`/org/all/dashboard-template`, dashboard);
    }

    public editLibraryDashboardTemplate(dashboard: DashboardTemplate): Promise<DashboardTemplate> {
        return this.reportClientNoOrg.doPut(`/org/all/dashboard-template/${ dashboard.id }`, dashboard);
    }

    /**
     * Reveal Dashboards
     */
    public createRevealDashboard(dashboard: RevealDashboard): Promise<RevealDashboard> {
        return this.reportClient.doPost("/reveal-dashboard", dashboard);
    }

    public updateRevealDashboard(dashboard: RevealDashboard): Promise<RevealDashboard> {
        return this.reportClient.doPut(`/reveal-dashboard/${ dashboard.id }`, dashboard);
    }

    public getRevealDashboards(includeDeleted = true): Promise<RevealDashboard[]> {
        return this.reportClient.doGet(`/reveal-dashboard`, { includeDeleted });
    }

    public getRevealDashboard(dashboardId: uuid): Promise<RevealDashboard> {
        return this.reportClient.doGet(`/reveal-dashboard/${ dashboardId }`);
    }

    public deleteDashboard(dashboardId: uuid): Promise<void> {
        return this.reportClient.doDelete(`/reveal-dashboard/${ dashboardId }`);
    }

    public restoreDashboard(dashboardId: uuid): Promise<void> {
        return this.reportClient.doPost(`/reveal-dashboard/${ dashboardId }/restore`);
    }

    /**
     * Reveal Workspaces
     */
    public getWorkspaces(): Promise<RevealWorkspace[]> {
        return this.reportClient.doGet(`/reveal-workspace`);
    }

    public createWorkspace(workspace: Partial<RevealWorkspace>): Promise<RevealWorkspace> {
        return this.reportClient.doPost(`/reveal-workspace`, workspace);
    }

    public updateWorkspace(workspace: Partial<RevealWorkspace>): Promise<RevealWorkspace> {
        return this.reportClient.doPut(`/reveal-workspace/${ workspace.id }`, workspace);
    }

    public deleteWorkspace(workspaceId: uuid): Promise<void> {
        return this.reportClient.doDelete(`/reveal-workspace/${ workspaceId }`);
    }

    /**
     * Reveal Library Dashboards
     */
    public createLibraryRevealDashboard(dashboard: RevealDashboard): Promise<RevealDashboard> {
        return this.reportClientNoOrg.doPost("/org/all/reveal-dashboard", dashboard);
    }

    public updateLibraryRevealDashboard(dashboard: RevealDashboard): Promise<RevealDashboard> {
        return this.reportClientNoOrg.doPut(`/org/all/reveal-dashboard/${ dashboard.id }`, dashboard);
    }

    public getLibraryRevealDashboards(includeDeleted = true): Promise<RevealDashboard[]> {
        return this.reportClientNoOrg.doGet(`/org/all/reveal-dashboard`, { includeDeleted });
    }

    public getLibraryRevealDashboard(dashboardId: uuid): Promise<RevealDashboard> {
        return this.reportClientNoOrg.doGet(`/org/all/reveal-dashboard/${ dashboardId }`);
    }

    public deleteLibraryDashboard(dashboardId: uuid): Promise<void> {
        return this.reportClientNoOrg.doDelete(`/org/all/reveal-dashboard/${ dashboardId }`);
    }

    public restoreLibraryDashboard(dashboardId: uuid): Promise<void> {
        return this.reportClientNoOrg.doPost(`/org/all/reveal-dashboard/${ dashboardId }/restore`);
    }

    public copyLibraryDashboards(workspaceId: uuid, selectedDashboardIds: uuid[]): Promise<void> {
        return this.reportClient.doPost(`/reveal-dashboard/copy-from-library`,
            selectedDashboardIds, { workspaceId });
    }

    /**
     * Reveal Library Workspaces
     */
    public getLibraryWorkspaces(): Promise<RevealWorkspace[]> {
        return this.reportClientNoOrg.doGet(`/org/all/reveal-workspace`);
    }

    public createLibraryWorkspace(workspace: Partial<RevealWorkspace>): Promise<RevealWorkspace> {
        return this.reportClientNoOrg.doPost(`/org/all/reveal-workspace`, workspace);
    }

    public updateLibraryWorkspace(workspace: Partial<RevealWorkspace>): Promise<RevealWorkspace> {
        return this.reportClientNoOrg.doPut(`/org/all/reveal-workspace/${ workspace.id }`, workspace);
    }

    public deleteLibraryWorkspace(workspaceId: uuid): Promise<void> {
        return this.reportClientNoOrg.doDelete(`/org/all/reveal-workspace/${ workspaceId }`);
    }
}
