import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { Lost2faDeviceModalComponent } from "@app2/login/lost-2fa-device-modal.component";
import { UserService } from "@app2/account/user.service";
import { AuthStateService } from "@app2/account/auth-state.service";
import { ModalService } from "@app2/shared/modal/modal.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { RouterService } from "@app2/shared/services/router.service";
import { WindowService } from "@app2/shared/services/window.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Component({
    selector: "totp-authentication-page",
    template: `
        <div class="row">
            <div *dsLoading="redirecting"
                 class="padded sheet small-16 medium-5 medium-centered columns">
                <form #totpForm="ngForm" name="totpForm" role="form" novalidate>
                    <input #totpInput required autofocus autocomplete="off"
                           type="text" id="one-time-password" name="one-time-password"
                           [placeholder]="'ONE_TIME_CODE' | localize"
                           [disabled]="validating"
                           [(ngModel)]="totpCode"/>

                    <button type="submit"
                            class="button"
                            (click)="submitTotpCode()"
                            [disabled]="totpForm.invalid || validating">
                        <i *ngIf="validating" class="fa fa-spinner fa-spin"></i>
                        {{ "AUTHENTICATE" | localize }}
                    </button>

                    <a class="de-emphasize" (click)="showLost2faDeviceModal()">{{ 'LOST_DEVICE' | localize }}</a>
                </form>
            </div>
        </div>
    `,
    styles: [`
        button {
            margin-top: 8px;
        }
    `],
})
export class TotpAuthenticationPageComponent implements AfterViewInit {
    @ViewChild("totpInput") totpInput: ElementRef;

    totpCode: string;
    validating: boolean;
    redirecting: boolean;

    constructor(private authStateService: AuthStateService,
                private modalService: ModalService,
                private notificationsService: NotificationsService,
                private routerService: RouterService,
                private userClient: UserClientService,
                private userService: UserService,
                private windowService: WindowService) {
    }

    ngAfterViewInit(): void {
        this.windowService.setTimeout(() => this.totpInput.nativeElement.focus());
    }

    submitTotpCode() {
        // If the user is not authenticated yet, this page is being used to login.
        // Otherwise, it's being used to setup TOTP for the first time.
        this.validating = true;
        const loggedInUser = this.userService.getCurrentUser();
        const promise = loggedInUser
            ? this.setupTotp2faFirstTime(loggedInUser.id, this.totpCode)
            : this.authStateService.attemptTotpCodeAuth(this.totpCode);

        promise
            .then(() => this.redirecting = true)
            .catch(error => {
                const message = getMessageForError(error.error, "INVALID_TOTP");
                this.notificationsService.showError(message, error);
                this.validating = false;
            });
    }

    showLost2faDeviceModal() {
        this.modalService.open(Lost2faDeviceModalComponent);
    }

    private setupTotp2faFirstTime(userId: uuid, totpCode: string): Promise<void> {
        return this.userClient.confirmGoogle2fa(userId, totpCode)
            .then(() => {
                const user = this.userService.getCurrentUser();
                user.google2faEnabled = true;
                this.userService.setCurrentUser(user);
                this.notificationsService.showSuccess("TOTP_SUCCESSFUL_SETUP");
                this.routerService.navigate(["/settings/profile"]);
            });
    }
}
