import { Moment } from "@app2/util/legacy-moment";

export interface ThreatSignature {
    hex: string;
    type: string;
}

export interface RatedThreat {
    rating?: string;
    ratingTime?: date | Moment; //TODO: this comes from the backend, it is a string, not a Moment
}

export interface PolicyLineItemTuple {
    policyId: uuid;
    lineItemId: uuid;
}

export interface RatedThreatHost extends RatedThreat {
    host: string;
}

export interface RatedThreatSignature extends RatedThreat {
    signature: ThreatSignature;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RatedThreatBatcher<T, U extends RatedThreat> {
    getRatedThreat(threat: T);
}

export interface RatedHostsWrapper {
    ratedHosts: RatedThreatHost[];
}

export interface RatedSignaturesWrapper {
    ratedSignatures: RatedThreatSignature[];
}

export interface ThreatGroupResponse {
    threatGroupsByThreatMatchId: Record<number, ThreatGroup>;
}

export interface ThreatGroup {
    description?: string;
    source?: string;
    url?: string;
    groupUploadKey?: uuid;
    threatInfo?: string[];
    threatLocationInfo?: ThreatLocationInfo[];
    type: string;
    signatureType?: string;
    beginDate?: date;
    endDate?: date;
    note?: string;

    name?: string;
    createdOn?: string;
    createdBy?: uuid;
}

export interface ThreatUpload {
    groupUploadKey: uuid;
    groupUploadUrl: string;
}

export interface ThreatMatchConfig {
    orgId: uuid;
    emailNotifications: string[];
    ticketOwner: uuid;
    lineItemIds: uuid[];
    severity: string;
    policyInfo: PolicyLineItemTuple[];
}

export interface ThreatSourceSubscriptionSettings {
    notifyOnly: boolean;
}

export interface ThreatSourceMatches {
    hosts: MatchedHost[];
    signatures: MatchedSignature[];
    locations: MatchedLocation[];
}

export interface MatchedThreat {
    id: number;
    host: string;
    signature: string;
    signatureType: string;
    location: string;
    locationCityState: string;
    locationDescription: string;
}

export interface ThreatSourceUniqueMatches {
    hosts: UniqueMatchedHosts[];
    signatures: UniqueMatchedSignatures[];
    locations: UniqueMatchedLocations[];
}

export interface UniqueMatchedHosts {
    host: string;
    count: number;
}

export interface UniqueMatchedSignatures {
    signature: string;
    count: number;
}

export interface UniqueMatchedLocations {
    location: string;
    count: number;
    lastSeen: string;
}

export interface MatchedSignature extends ThreatSignature {
    foundTime: string;
    signature: string;
}

export interface MatchedHost {
    foundTime: string;
    host: string; //TODO rename on backend to be hostname/name or something to avoid host.host
}

export interface MatchedLocation {
    postalCode: string;
    country: string;
    matchedLocation: string;
    host: string;
    userId: string;
    cityState: string;
    description: string;
    foundTime: string;
}

export type IndicatorSortField = "VALID_FROM" | "EXPIRES" | "GROUP_NAME";
export interface ThreatSourceSearchRequest {
    threatSourceId: uuid;
    types: string[];
    query: string;
    sort: IndicatorSortField;
    descending: boolean;
}

export interface ThreatSourceIndicators {
    threatSourceId: uuid;
    hosts: ThreatHostInfo[];
    signatures: ThreatSignatureInfo[];
    locations: ThreatLocationInfo[];
    emails: ThreatIndicatorInfo[];
}

export interface ThreatSourceIndicator {
    id: number;
    indicator?: string;
    host?: string;
    signature?: string;
    location?: string;
    enabled: boolean;
    isEditing: boolean;
    isDeleted: boolean;
}

export interface ThreatIndicatorInfo extends ThreatSourceIndicator {
    validFrom: date;
    expires: date;
}

export interface ThreatHostInfo extends ThreatSourceIndicator {
    validFrom: date;
    expires: date;
    groupName?: string;
}

export interface ThreatSignatureInfo extends ThreatSourceIndicator {
    validFrom: date;
    expires: date;
    groupName?: string;
}

export interface ThreatLocationInfo extends ThreatSourceIndicator {
    cityState?: string;
    description: string;
    modifiedOn: date;
}

export type ThreatIndicatorType = "DOMAIN" | "IP_ADDRESS" | "SIGNATURE" | "POSTAL_CODE" | "COUNTRY" | "EMAIL_ADDRESS";
export const ThreatClientTimeout = 500;
