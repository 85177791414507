import { Component, OnInit } from "@angular/core";
import { RiskService } from "@app2/risk/risk-shared/risk.service";
import { RiskModuleType } from "@app2/type-defs/risk/risk-types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export interface RiskModuleInfo {
    moduleType: RiskModuleType;
    name: string;
    description: string;
    badgeIcon: string;
}

const riskModuleItems: RiskModuleInfo[] = [
    {
        moduleType: RiskModuleType.INFORMATION_SECURITY,
        name: "RISK_MODULE_INFORMATION_SECURITY",
        description: "RISK_MODULE_INFORMATION_SECURITY_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-is.svg",
    },
    {
        moduleType: RiskModuleType.SYSTEMS_AND_APPLICATIONS,
        name: "RISK_MODULE_SYSTEMS_AND_APPLICATIONS",
        description: "RISK_MODULE_SYSTEMS_AND_APPLICATIONS_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-sa.svg",
    },
    {
        moduleType: RiskModuleType.THIRD_PARTY_RISK,
        name: "RISK_MODULE_THIRD_PARTY_RISK",
        description: "RISK_MODULE_THIRD_PARTY_RISK_DESCRIPTION",
        badgeIcon: "assets/icons/risk-module-icon-tp.svg",
    },
];

@UntilDestroy()
@Component({
    selector: "navbar-risk-menu",
    template: `
        <div class="risk-badges-container"
             test-id="risk-badges-container">
            <svg-icon *ngFor="let module of riskModuleItems"
                      [inline]="true"
                      [src]="module.badgeIcon"
                      [matTooltip]="module.description | localize"
                      matTooltipClass="pre-line"
                      [ngClass]="purchasedModules.includes(module.moduleType) ? 'module-purchased' : 'module-nonpurchased'"
                      [class.module-selected]="isSelectedModule(module.moduleType)"
                      (click)="selectModule(module.moduleType)"
                      class="risk-module-icon">
            </svg-icon>
        </div>
    `,
    styles: [`
      .risk-badges-container {
        margin: 0 8px;
        padding-top: 4px;
        line-height: 16px;
      }

      svg-icon {
        width: 26px;
        margin: 0 2px;
        cursor: pointer;
      }

      .fa-angle-down {
        font-size: 1.5rem;
      }
    `],
})
export class NavbarRiskMenuComponent implements OnInit {
    isMultiSelected: ("all" | "some" | "none") = "some";
    purchasedModules: RiskModuleType[];
    nonPurchasedModules: RiskModuleType[];
    selectedModules: RiskModuleType[];
    riskModuleItems = riskModuleItems;

    constructor(private riskService: RiskService) {
        this.purchasedModules = this.riskService.getPurchasedModules();
        this.nonPurchasedModules = this.riskService.getNonPurchasedModules();
    }

    ngOnInit() {
        this.riskService.getSelectedModules$()
            .pipe(untilDestroyed(this))
            .subscribe(modules => {
                this.selectedModules = modules;
            });
    }

    selectModule(module: RiskModuleType): void {
        this.riskService.selectModule(module, !this.isSelectedModule(module));
        this.isMultiSelected = this.nonPurchasedModules.includes(module) ? "none" :
            (this.selectedModules.length === this.purchasedModules.length ? "all" : "some");
    }

    isSelectedModule(module: RiskModuleType): boolean {
        return this.selectedModules.includes(module);
    }
}
