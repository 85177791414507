import { Component, Input } from "@angular/core";

@Component({
    selector: "sort-order-arrows",
    template: `
        <i *ngIf="currentField === fieldName"
           class="far"
           [ngClass]="currentDesc ? 'fa-arrow-down': 'fa-arrow-up'"></i>
    `,
})
export class SortOrderArrowsComponent {

    @Input() fieldName: string;
    @Input() currentField: string;
    @Input() currentDesc: boolean;

    public constructor() {
    }
}
