import { ChronoUnit, DateTimeFormatter, LocalDate, LocalDateTime, ZonedDateTime, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

// Similar to DateTimeFormatter.ISO_LOCAL_DATE_TIME but including trailing zeroes
export const isoFormatter = DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.SSSX");

export const shortDateFormatter: DateTimeFormatter = DateTimeFormatter.ofPattern("MMM d, yyyy").withLocale(Locale.US);

export function isoStringToLocalDate(datetime: date, localTimeZoneId: ZoneId): LocalDate {
    return ZonedDateTime.parse(datetime)
        .withZoneSameInstant(localTimeZoneId)
        .toLocalDate();
}

export function localDateToISOString(datetime: LocalDate, localTimeZoneId: ZoneId): date {
    return datetime
        .atStartOfDay()
        .atZone(localTimeZoneId)
        .withZoneSameInstant(ZoneId.UTC)
        .format(isoFormatter);
}

export function localDateTimeToISOString(datetime: LocalDateTime, localTimeZoneId: ZoneId): date {
    return datetime
        .atZone(localTimeZoneId)
        .withZoneSameInstant(ZoneId.UTC)
        .format(isoFormatter);
}

export function zonedDateTimeToISOString(datetime: ZonedDateTime): date {
    return datetime
        .withZoneSameInstant(ZoneId.UTC)
        .format(isoFormatter);
}

export function atEndOfDay(date: LocalDate): LocalDateTime;
export function atEndOfDay(datetime: LocalDateTime): LocalDateTime;
export function atEndOfDay(zonedDateTime: ZonedDateTime): ZonedDateTime;
export function atEndOfDay(value: LocalDate | LocalDateTime | ZonedDateTime): LocalDateTime | ZonedDateTime {
    if (value instanceof LocalDate) {
        return localDateAtEndOfDay(value);
    } else if (value instanceof LocalDateTime) {
        return localDateTimeAtEndOfDay(value);
    } else if (value instanceof ZonedDateTime) {
        return zonedDateTimeAtEndOfDay(value);
    } else {
        throw `atEndOfDay() invoked with invalid date object: ${ value } (${ (<any>value)?.constructor?.name })`;
    }
}

function localDateAtEndOfDay(date: LocalDate): LocalDateTime {
    return date
        .atStartOfDay()
        .plusDays(1)
        .minusNanos(1_000_000);
}

function localDateTimeAtEndOfDay(date: LocalDateTime): LocalDateTime {
    return date
        .truncatedTo(ChronoUnit.DAYS)
        .plusDays(1)
        .minusNanos(1_000_000);
}

function zonedDateTimeAtEndOfDay(date: ZonedDateTime): ZonedDateTime {
    return date
        .truncatedTo(ChronoUnit.DAYS)
        .plusDays(1)
        .minusNanos(1_000_000);
}
