import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, delay, map, shareReplay } from "rxjs/operators";

// How much time we will wait between one module being preloaded before starting preloading the next one
const PRELOAD_DELAY_MS = 500;

/**
 * Preloads one lazy-loaded module only when the previous has finished downloading, instead of downloading all in
 * parallel (as `PreloadAllModules` does).
 */
export class PreloadModulesOneByOne implements PreloadingStrategy {
    // Each time we are asked to preload a module, we assign to this field an Observable that completes whenever
    // the latest preloaded module finishes downloading
    private latestPreload$: Observable<any> = of(null);

    /**
     * @param route Route that we want to preload.
     * @param fn Function that preloads the module, this `preload` method needs to decide when `fn` should be executed.
     *           Angular lacks good documentation about what `fn` is, so here is the source code:
     *           https://github.com/angular/angular/blob/master/packages/router/src/router_preloader.ts
     */
    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        this.latestPreload$ = this.latestPreload$
            .pipe(
                delay(PRELOAD_DELAY_MS),
                map(() => fn()),
                catchError(() => of(null)),
                shareReplay(),
            );

        return this.latestPreload$;
    }
}
