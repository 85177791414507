import { Component, Input } from "@angular/core";

@Component({
    selector: "setup-2fa-sidebar",
    template: `
        <div class="padded sheet code-and-secret">
            <ng-container *dsLoading="!secretUri">
                <h3 class="sidebar-title">{{ "QR_CODE" | localize }}</h3>
                <div class="sidebar-description">
                    {{ "QR_CODE_DESCRIPTION" | localize }}
                </div>
                <div class="center">
                    <qr-canvas [secretUri]="secretUri"></qr-canvas>
                </div>

                <h3 class="sidebar-title">{{ "MANUAL_SECRET" | localize }}</h3>
                <div class="sidebar-description">{{ "MANUAL_SECRET_DESCRIPTION" | localize }}</div>
                <input id="read-only-code"
                       type="text"
                       [ngModel]="secretCode"
                       [readOnly]="true"/>
            </ng-container>
        </div>
    `,
})
export class SetupTotpSidebarComponent {
    @Input() secretCode: string;
    @Input() secretUri: string;
}
