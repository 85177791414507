import { Component, OnInit } from "@angular/core";
import { UserService } from "@app2/account/user.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { TwoFactorLoginService } from "@app2/login/two-factor-login.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Component({
    selector: "setup-totp-page",
    template: `
        <div [class.setup-2fa-offset]="isUnauthenticated">
        <div class="page-header">
            <h1>{{ "SETUP_TWO_FACTOR_AUTHENTICATION" | localize }}</h1>
            <div>{{ "SETUP_TWO_FACTOR_AUTHENTICATION_EXPLANATION" | localize }}</div>
            <h3>{{ "SETUP_TWO_FACTOR_AUTHENTICATION_DESCRIPTION" | localize }}</h3>
        </div>

        <div class="article main-page-body">
            <div class="installation-instructions padded sheet">
                <mat-tab-group mat-align-tabs="center" disableRipple mat-stretch-tabs animationDuration="0ms">
                    <mat-tab [label]="'ANDROID' | localize">
                        <div class="instructions-text"
                             [innerHTML]="'ANDROID_2FA_INSTALLATION_INSTRUCTIONS' | localizeTemplate">
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'BLACKBERRY' | localize">
                        <div class="instructions-text"
                             [innerHTML]="'BLACKBERRY_2FA_INSTALLATION_INSTRUCTIONS' | localizeTemplate">
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'I_OS' | localize">
                        <div class="instructions-text"
                             [innerHTML]="'I_OS_2FA_INSTALLATION_INSTRUCTIONS' | localizeTemplate">
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'WINDOWS_PHONE' | localize">
                        <div class="instructions-text"
                             [innerHTML]="'WINDOWS_PHONE_2FA_INSTALLATION_INSTRUCTIONS' | localizeTemplate">
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

            <setup-2fa-sidebar [secretUri]="secretUri"
                               [secretCode]="secretCode">
            </setup-2fa-sidebar>
        </div>

        <button type="submit"
                class="button right setup-complete-button"
                [dsRouterLink]="completeTotpSetupUrl()">
            {{ "TWO_FACTOR_SET_UP_COMPLETE" | localize}}
        </button>
        </div>
    `,
})
export class SetupTotpPageComponent implements OnInit {
    // Pattern for the google2faUri returned by user-service
    private readonly uriPattern = /otpauth:\/\/totp\/.*secret=([A-Z0-9]{16})/i;
    // Text code that the user can write by hand
    secretCode: string;
    // URI used to draw the QR code in a canvas
    secretUri: string;
    // We automatically add an offset class to the main container when the user is authenticated. In this case, we want
    // that same offset to apply to this page.
    isUnauthenticated = false;

    constructor(private readonly twoFactorLoginService: TwoFactorLoginService,
                private readonly userClient: UserClientService,
                private readonly userService: UserService,
                private readonly notificationsService: NotificationsService) {
    }

    ngOnInit(): void {
        // If we came to this page through the login page, then we are setting up 2FA for this user for the first time
        // (in this case, we don't have a "currentUser" yet, but we have 2FA details in the TwoFactorLoginService).
        const twoFactorDetails = this.twoFactorLoginService.getTwoFactorAuthDetails();
        if (twoFactorDetails) {
            this.isUnauthenticated = true;
            this.update2faUri(twoFactorDetails.google2faUri);
            return;
        }

        // If we arrived here from the Profile page, then we already have a currentUser, ask user-service to setup a
        // new 2FA URI for that user.
        this.userClient.setupGoogle2fa(this.userService.getCurrentUser().id)
            .then(twoFactorDetails => {
                this.update2faUri(twoFactorDetails.google2faUri);
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_SETTING_UP_TWO_FACTOR");
                this.notificationsService.showError(message, error);
            });
    }

    completeTotpSetupUrl(): string {
        return this.userService.getCurrentUser() ? "/settings/setup-totp-profile/input-code" : "/totp-authentication";
    }

    private update2faUri(google2faUri: string): void {
        // Extract the secret code from the secret URI
        const matches = this.uriPattern.exec(google2faUri);
        if (!matches) {
            // eslint-disable-next-line no-console
            console.error("Invalid QR uri provided.");
            return;
        }

        // Put spaces every 4 digits so the user can read it more easily
        this.secretCode = matches[1].match(/.{1,4}/g).join(" ");
        this.secretUri = google2faUri;
    }

}
