import { Component } from "@angular/core";
import { InfoBannerService } from "@app2/shared/services/info-banner.service";

@Component({
    selector: "info-banner",
    template: `
        <div *ngIf="infoBannerService.getNextBanner$() | async as banner"
             class="banner-container"
             [ngClass]="'banner-type-' + banner.type"
             test-id="banner-container">
            <div class="banner-inner-container">
                <span class="banner-main-icon"
                      [ngClass]="iconClassesByType[banner.type]"
                      test-id="banner-icon"></span>
                <span class="banner-message-container">
                    <div class="banner-title">{{ banner.title }}</div>
                    <div class="banner-message">
                        {{ banner.message }}
                        <a *ngIf="banner.hyperlink"
                           class="link-text" target="_blank" rel="noopener noreferrer"
                           [ngClass]="'banner-type-' + banner.type"
                           [href]="banner.hyperlink">{{ 'LEARN_MORE' | localize }}</a>
                    </div>
                </span>
                <button class="link banner-close-icon ion-close-round"
                        (click)="infoBannerService.dismissBanner(banner)"
                        test-id="banner-dismiss-button"></button>
            </div>
        </div>
    `,
})
export class InfoBannerComponent {

    iconClassesByType = {
        success: "banner-type-success ion-checkmark-circled",
        info: "banner-type-info ion-information-circled",
        warning: "banner-type-warning ion-alert-circled",
        alert: "banner-type-alert ion-close-circled",
    };

    constructor(public infoBannerService: InfoBannerService) {
    }
}
