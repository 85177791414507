import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";
import { Observable } from "rxjs";
import { FeaturesService } from "@app2/shared/services/features.service";

@Component({
    selector: "navigation-top-bar-alerts",
    template: `
        <nav class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "THREAT_SURVEILLANCE" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isAlertInboxActive$ | async"
                        *dsPermissionsRequired="'ALERT_INBOX_VIEW'">
                        <a dsRouterLink="/alerts/inbox">{{ "INBOX" | localize }}</a></li>
                    <li [class.active]="navSectionService.isTriggersActive$ | async"
                        *dsPermissionsRequired="'TRIGGER_VIEW'">
                        <a dsRouterLink="/triggers">{{ "TRIGGERS" | localize}}</a></li>
                    <li [class.active]="navSectionService.isTriggerSchedulesActive$ | async"
                        *dsPermissionsRequired="'TRIGGER_VIEW'">
                        <a dsRouterLink="/trigger-schedules">{{ "TRIGGER_SCHEDULES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isLibraryTriggersActive$ | async"
                        *dsPermissionsRequired="'TRIGGER_VIEW'">
                        <a dsRouterLink="/triggers/library">{{ "DEFAULT_TRIGGERS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isThreatMatchActive$ | async"
                        *dsPermissionsRequired="'THREATMATCH_VIEW'">
                        <a dsRouterLink="/threat">{{ "THREAT_MATCH" | localize }}</a></li>
                    <ng-container *ngIf="hasNewWatchlistsEnabled$ | async">
                        <li [class.active]="navSectionService.isWatchlistsActive$ | async"
                            *dsPermissionsRequired="'THREATMATCH_VIEW'">
                            <a dsRouterLink="/watchlists">{{ "WATCHLISTS" | localize }}</a></li>
                    </ng-container>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarAlertsComponent {
    hasNewWatchlistsEnabled$: Observable<boolean>;

    constructor(public navSectionService: ActiveNavSectionService,
                private featuresService: FeaturesService) {
        this.hasNewWatchlistsEnabled$ = this.featuresService.getFeature$("newWatchlistPage");
    }
}
