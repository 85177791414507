import { Component, DestroyRef, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { UserService } from "@app2/account/user.service";
import { RouterService } from "@app2/shared/services/router.service";
import { OrgsService } from "@app2/account/orgs.service";
import { InfoBannerService } from "@app2/shared/services/info-banner.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "app-main",
    template: `
        <info-banner/>
        <ad-detector *featureGate="'adblockDetector'"/>
        <navigation/>
        <main [class.offset]="offsetContent"
              [class.new-tab-offset]="newTabOffset"
              [class.info-banner-visible]="infoBannerVisible">
            <router-outlet/>
        </main>
    `,
})
export class AppMainComponent implements OnInit {
    offsetContent: boolean;
    newTabOffset: boolean;
    infoBannerVisible: boolean;

    constructor(private destroyRef: DestroyRef,
                private infoBannerService: InfoBannerService,
                private orgsService: OrgsService,
                private router: Router,
                private routerService: RouterService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.router.events
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(event => {
                if (event instanceof NavigationStart && event.url?.match(/^\/#.*freshdesk=true/)) {
                    this.router.navigateByUrl(event.url.replace("/#", ""));
                }
            });

        combineLatest([this.userService.getCurrentUser$(), this.orgsService.getCurrentOrg$(), this.routerService.getQueryParams$()])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([user, currentOrg, queryParams]) => {
                const isNewTab = queryParams.isNewTab === "true";
                this.newTabOffset = isNewTab;
                this.offsetContent = !!user && !!currentOrg && !isNewTab;
            });

        this.infoBannerService.isBannerVisible$()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(visible => this.infoBannerVisible = visible);
    }
}
