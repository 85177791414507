import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { AppStateService } from "@app2/app-state.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { OnLoginRedirectService } from "@app2/login/on-login-redirect.service";
import { TwoFactorLoginService } from "@app2/login/two-factor-login.service";
import { OrgSummary, Person, TwoFactorDetails } from "@app2/type-defs/user/user-types";
import * as _ from "underscore";
import { ActivatedRoute, Router } from "@angular/router";
import { RouterService } from "@app2/shared/services/router.service";
import { Location } from "@angular/common";
import { log } from "@app2/logger";

@Injectable({
    providedIn: "root",
})
export class AuthStateService {

    constructor(private readonly appStateService: AppStateService,
                private readonly orgsService: OrgsService,
                private readonly route: ActivatedRoute,
                private readonly location: Location,
                private readonly router: Router,
                private readonly routerService: RouterService,
                private readonly userService: UserService,
                private readonly userClient: UserClientService,
                private readonly onLoginRedirectService: OnLoginRedirectService,
                private readonly twoFactorLoginService: TwoFactorLoginService) {
    }

    /**
     * This is the first method the console invokes on bootstrap.
     * If there is a valid cookie, the console continues loading the requested page, otherwise, the user is forwarded
     * to the login screen.
     */
    public attemptAuthenticateFromCookie(): Promise<unknown> {
        return this.userClient.authenticate()
            .then(user => this.doAfterLogin(user));
    }

    /**
     * Invoked from the login page to perform authentication with email+password. If successful, the user is redirected
     * to the original requested page.
     */
    public attemptEmailPasswordLogIn(email: string, password: string): Promise<void> {
        return this.userClient.logIn(email, password)
            .then(response => {
                const isPersonResponse = (<TwoFactorDetails>response).google2faInitialized === undefined;
                // User doesn't have any form of 2FA enabled, so the login process has already succeeded
                if (isPersonResponse) {
                    return this.doAfterLogin(<Person>response);
                }

                const twoFactorDetails = <TwoFactorDetails>response;
                this.twoFactorLoginService.startTwoFactorAuth(twoFactorDetails, () => this.logOut());

                const queryParams = this.route.snapshot.queryParams;

                // User needs to authenticate with a U2F token
                if (twoFactorDetails.u2fInitialized) {
                    this.routerService.navigate(["/u2f-authentication"], queryParams);
                    return;
                }
                // User needs to authenticate with a TOTP code
                if (twoFactorDetails.google2faInitialized) {
                    this.routerService.navigate(["/totp-authentication"], queryParams);
                    return;
                }
                // If the user has not setup the TOTP/U2F yet, we ask them to do so
                this.routerService.navigate(["/setup-totp-login"], queryParams);
            });
    }

    /**
     * Invoked when the user attempts to login by inputting a TOTP code.
     */
    public attemptTotpCodeAuth(totpCode: string): Promise<void> {
        return this.userClient.validateTotpCode(totpCode)
            .then(user => {
                this.twoFactorLoginService.cancelTwoFactorAuth();
                this.doAfterLogin(user);
            });
    }

    /**
     * Invoked when the user attempts to login by using a WebAuthn credential.
     */
    public attemptWebAuthnAuth(encodedAssertionResponse: object): Promise<void> {
        return this.userClient.validateWebAuthnToken(encodedAssertionResponse)
            .then(user => {
                this.twoFactorLoginService.cancelTwoFactorAuth();
                this.doAfterLogin(user);
            });
    }

    /**
     * This method is invoked:
     * - Manually, when the user clicks on the LogOut link
     * - Automatically, when an HTTP endpoint answers with an error 401.
     * - Automatically, after a period of inactivity.
     */
    public logOut(): void {
        this.userClient.logOut()
            .finally(() => {
                this.appStateService.tearDown();
                this.router.navigateByUrl("/login");
            });
    }

    /**
     * Invoked by the org selectors to change to another authorized org.
     */
    public selectOrg(org: OrgSummary) {
        this.orgsService.setCurrentOrg(org);
        return this.completeInitApp();
    }

    private doAfterLogin(user: Person): void {
        // Redirects to Freshdesk, for which we only need to be authenticated, but we don't need to bootstrap the whole
        // application
        if (this.onLoginRedirectService.doFreshdeskRedirect()) {
            return;
        }

        this.populateLoggedUserInfo(user)
            .then(selectedOrg => {
                if (selectedOrg) {
                    this.completeInitApp();
                } else {
                    this.router.navigate(["select-org"], { queryParamsHandling: "preserve" });
                }
            })
            .catch(error => {
                log.error("Unexpected exception loading user or org info", error);
            });
    }

    /**
     * This method queries the basic user info, user permissions, home org, and authorized orgs and makes them available
     * to the rest of the application.
     *
     * When the info has been set, it sets the currentOrg from the url (with the parameter orgId) or sets currentOrg to
     * the homeOrg if the user doesn't have any authorized orgs. If it can't determine what the currentOrg should be, it
     * redirects to the OrgSelectorComponent.
     *
     * Returns the currentOrg.
     */
    private populateLoggedUserInfo(user): Promise<OrgSummary> {
        // Query and store user-related info needed for the rest of the application
        const userPromise = this.userClient.getCurrentPermissions()
            .then(permissions => {
                this.userService.setCurrentUser(user);
                this.userService.setCurrentPermissions(permissions);
            });

        // Query and store org-related info needed for the rest of the application
        const orgPromise = Promise.all(
            [
                this.userClient.getHomeOrg(),
                this.userClient.getAuthorizedOrgs(user.id),
            ])
            .then(([homeOrg, authorizedOrgs]) => {
                this.orgsService.setHomeAndAuthorizedOrgs(homeOrg, authorizedOrgs);

                // Auto-select the homeOrg if there are no authorized orgs
                if (authorizedOrgs.length === 0) {
                    this.orgsService.setCurrentOrg(homeOrg);
                    return homeOrg;
                }

                // Auto-select the desired org if there is an orgId in the url
                const targetOrgId = this.route.snapshot.queryParams.orgId;
                const targetOrg = homeOrg.id === targetOrgId
                    ? homeOrg
                    : _.find(authorizedOrgs, org => org.id === targetOrgId);
                if (targetOrg) {
                    this.orgsService.setCurrentOrg(targetOrg);
                    return targetOrg;
                }

                // If there are some authorized orgs, but there is no orgId in the url (or it's an invalid orgId),
                // then we can't set the currentOrg and we'll need extra steps.
                return null;
            });

        return userPromise.then(() => orgPromise);
    }

    /**
     * Should only be called when the user and currentOrg have been set.
     * @private
     */
    private completeInitApp(): void {
        this.appStateService.startUp();
        this.onLoginRedirectService.doRedirect();
    }
}
