import { RecommendedActionsFilterType } from "@app2/clients/types/alert-typedefs";
import { SortType, StateType } from "@app2/fraud/type-defs";
import { DropdownOption } from "@app2/shared/predicate-dropdown-menu.component";

export const SECURITY_DOMAIN = "SECURITY";
export const FRAUD_DOMAIN = "FRAUD";
export type Domain = "SECURITY" | "FRAUD";

export const defaultAlertInboxSortOptions: DropdownOption<SortType>[] = [
    { key: "TIMESTAMP_DESC", value: "newest_first" },
    { key: "TIMESTAMP_ASC", value: "oldest_first" },
    { key: "SEVERITY_DESC", value: "highest_severity" },
    { key: "SEVERITY_ASC", value: "lowest_severity" }
];

const escalatedSortOptions: DropdownOption<SortType>[] = [
    { key: "LAST_ESCALATED_DESC", value: "last_escalated_desc" },
    { key: "LAST_ESCALATED_ASC", value: "last_escalated_asc" }
];

const dismissedSortOptions: DropdownOption<SortType>[] = [
    { key: "LAST_DISMISSED_DESC", value: "last_dismissed_desc" },
    { key: "LAST_DISMISSED_ASC", value: "last_dismissed_asc" }
];

const fraudFlaggedSortOptions: DropdownOption<SortType>[] = [
    { key: "LAST_FRAUD_FLAGGED_DESC", value: "last_fraud_flagged_desc" },
    { key: "LAST_FRAUD_FLAGGED_ASC", value: "last_fraud_flagged_asc" }
];

export const alertSortOptionsByState: Record<StateType, DropdownOption<SortType>[]> = {
    "NEW": defaultAlertInboxSortOptions,
    "ACKED": defaultAlertInboxSortOptions,
    "ESCALATED": defaultAlertInboxSortOptions.concat(escalatedSortOptions),
    "DISMISSED": defaultAlertInboxSortOptions.concat(dismissedSortOptions),
    "FRAUD_FLAGGED": defaultAlertInboxSortOptions.concat(fraudFlaggedSortOptions)
};

export const resyncFields: string[] = ["name", "severity", "maxMatch", "minMatch", "notifyOnly",
    "intervalSeconds", "savedQueryId", "customHeaders"];

export const recommendedActionsOptions: DropdownOption<RecommendedActionsFilterType>[] = [
    { key: "ALL", value: RecommendedActionsFilterType.ALL },
    { key: "WITH_ACTIVITY", value: RecommendedActionsFilterType.WITH_ACTIVITY },
    { key: "WITHOUT_ACTIVITY", value: RecommendedActionsFilterType.WITHOUT_ACTIVITY }
];
