import { Injectable } from "@angular/core";
import { Role } from "@app2/type-defs/user/user-types";
import { Observable, ReplaySubject } from "rxjs";
import { UserClientService } from "@app2/clients/user-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Injectable({
    providedIn: "root",
})
export class RolesService {
    private roles$ = new ReplaySubject<Role[]>(1);
    private firstTimeLoad = true;

    constructor(private userClient: UserClientService,
                private notificationsService: NotificationsService) {
    }

    public getRoles$(): Observable<Role[]> {
        if (this.firstTimeLoad) {
            this.refresh();
        }
        return this.roles$.asObservable();
    }

    public refresh(): Promise<void> {
        this.firstTimeLoad = false;
        return this.userClient.getRoles(false, true)
            .then(roles => this.roles$.next(roles))
            .catch(error => {
                const message = getMessageForError(error.error, "UNEXPECTED_ROLES_REFRESH_ERROR");
                this.notificationsService.showError(message, error);
            });
    }
}
