import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { RecentItemsBaseService } from "@app2/shared/services/recent-items-base.service";
import { StorageService, Keys } from "@app2/shared/services/storage.service";
import { TicketSummary } from "@app2/type-defs/ticket/ticket-types";
import * as _ from "underscore";

const MAX_RECENT_TICKETS = 5;

@Injectable({
    providedIn: "root",
})
export class RecentTicketsService extends RecentItemsBaseService<TicketSummary> {
    constructor(storageService: StorageService,
                userService: UserService,
                orgsService: OrgsService) {
        super(Keys.recentTickets, storageService, userService, orgsService);
    }

    public addTicket(newTicket: TicketSummary): void {
        const updatedTickets = _.chain(this.recentItems)
            .reject(ticket => ticket.slugName === newTicket.slugName && ticket.slugId === newTicket.slugId)
            .first(MAX_RECENT_TICKETS - 1)
            .value();
        updatedTickets.unshift(newTicket);
        this.setRecentItems(updatedTickets);
    }
}
