import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";

@Component({
    selector: "navigation-top-bar-events",
    template: `
        <nav class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "EVENTS" | localize }}</h1>
                <ul class="left">
                    <li *dsPermissionsRequired="'EVENT_VIEW'"
                        [class.active]="navSectionService.isEventsListActive$ | async">
                        <a dsRouterLink="/search">{{ 'OVERVIEW' | localize }}</a>
                    </li>
                    <li [class.active]="navSectionService.isSavedQueriesActive$ | async"
                        *dsPermissionsRequired="'SAVED_SEARCH_VIEW'">
                        <a dsRouterLink="/search/saved">{{ "SAVED_QUERIES" | localize }}</a>
                    </li>
                    <li [class.active]="navSectionService.isClassifiersActive$ | async"
                        *dsPermissionsRequired="'CLASSIFIER_VIEW'">
                        <a dsRouterLink="/classifiers">{{ "BASE_CLASSIFIERS" | localize }}</a>
                    </li>
                    <ng-container *featureGate="'classifierLibrary'">
                        <li [class.active]="navSectionService.isLibraryClassifiersActive$ | async"
                            *dsPermissionsRequired="'TRAC_ENGINEER'">
                            <a dsRouterLink="/classifiers/library">{{ "LIBRARY_CLASSIFIERS" | localize }}</a>
                        </li>
                    </ng-container>
                    <li [class.active]="navSectionService.isCrossOrgSearchActive$ | async"
                        *dsPermissionsRequired="'any: TRAC_ADMIN, TRAC_ENGINEER'">
                        <a dsRouterLink="/search/cross-org-search">{{ "CROSS_ORG_SEARCH" | localize }}</a>
                    </li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarEventsComponent {
    constructor(public navSectionService: ActiveNavSectionService) {
    }
}
