import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { UserService } from "@app2/account/user.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { Keys, StorageService } from "@app2/shared/services/storage.service";
import { InfoBanner } from "@app2/type-defs/user/user-types";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { getMessageForError } from "@app2/util/errors/handleable-errors";

@Injectable({
    providedIn: "root",
})
export class InfoBannerService {
    banners: InfoBanner[];
    nextBannerSubject = new ReplaySubject<InfoBanner>(1);

    constructor(private userService: UserService,
                private orgsService: OrgsService,
                private userClient: UserClientService,
                private storageService: StorageService,
                private notificationsService: NotificationsService) {
        combineLatest([userService.getCurrentUser$(), orgsService.getCurrentOrg$()])
            .pipe(
                map(([user, currentOrg]) => user?.id && currentOrg?.id),
                distinctUntilChanged(),
            )
            .subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.refreshBanners();
                } else {
                    this.nextBannerSubject.next(null);
                }
            });
    }

    public isBannerVisible$(): Observable<boolean> {
        return this.nextBannerSubject
            .asObservable()
            .pipe(
                map(banner => !!banner),
                distinctUntilChanged(),
            );
    }

    public getNextBanner$(): Observable<InfoBanner> {
        return this.nextBannerSubject.asObservable();
    }

    public dismissBanner(banner: InfoBanner): void {
        this.storageService.setShared(Keys.dismissInfoBanner + "-" + banner.id, true);
        this.selectNextBanner();
    }

    private refreshBanners() {
        this.userClient.getInfoBanners()
            .then(banners => {
                this.banners = banners;
                this.selectNextBanner();
            })
            .catch(error => {
                const message = getMessageForError(error.error, "ERROR_LOADING_INFO_BANNERS");
                this.notificationsService.showError(message, error);
            });
    }

    private selectNextBanner() {
        for (let banner of this.banners) {
            if (!this.storageService.getShared(Keys.dismissInfoBanner + "-" + banner.id)) {
                this.nextBannerSubject.next(banner);
                return;
            }
        }
        this.nextBannerSubject.next(null);
    }
}
