import { localization } from "@app2/shared/localization/localization";
import { validScheduleUnits } from "@app2/util/constants";

// This deepClone implementation may look ugly, but the alternative was to use Lodash, which supports deep cloning
// but respects `undefined` values, which we don't want because our code already relies in not having those
// `undefined` around, so we may introduce bugs.
/**
 * @deprecated Use lodash's cloneDeep instead.
 */
export function deepClone(obj: any) {
    return cloneWithoutUndefined(obj);
}

export function cloneWithoutUndefined<T>(obj: T): T {
    if (obj === undefined) {
        return obj;
    }
    return JSON.parse(JSON.stringify(obj));
}

export function localizeScheduleUnit(unit: string) {
    const unitKey = validScheduleUnits.filter(u => u.value === unit)[0].key;
    return localization.getString(unitKey);
}

export function describeScheduleInterval(scheduleInterval: number, scheduleUnit: string): string {
    const unitKey = validScheduleUnits.filter(u => u.value === scheduleUnit)[0].key;
    const unit = localization.getString(unitKey);

    if (scheduleInterval === 1) {
        if (scheduleUnit === "day") {
            return localization.getString("DAILY");
        } else if (scheduleUnit === "week") {
            return localization.getString("WEEKLY");
        } else {
            return localization.getString("SINGLE_SCHEDULE_INTERVAL", unit);
        }
    } else {
        return localization.getString("MULTIPLE_SCHEDULE_INTERVAL", scheduleInterval, unit);
    }
}

export function hyphenateUUID(id: uuid): string {
    return `${ id.substring(0, 8) }-${ id.substring(8, 12) }-${ id.substring(12, 16) }-${ id.substring(16, 20) }-${ id.substring(20) }`;
}

/**
 * Utility sorting methods
 */

type ObjectWithName = { name: string };

export function byName(): (a: ObjectWithName, b: ObjectWithName) => number {
    return (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export function byField(fieldName: string): (a: object, b: object) => number {
    return (a, b) => a[fieldName]?.toLowerCase().localeCompare(b[fieldName]?.toLowerCase());
}

export function lowercasing(): (a: string, b: string) => number {
    return (a, b) => a?.toLowerCase().localeCompare(b?.toLowerCase());
}
