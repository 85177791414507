import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OrgsService } from "@app2/account/orgs.service";
import { RestClient } from "@app2/clients/rest-client";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { ActivityLog, ActivityLogNoteRequest } from "@app2/type-defs/activity-log/activity-log-types";
import {
    EscalationSchedule,
    EscalationStep,
    EscalationStepType,
    InfoNote,
    InfoPanelOrgMetadata,
} from "@app2/type-defs/metadata/metadata-types";
import { TagValue } from "@app2/type-defs/tag/tag-types";

@Injectable({
    providedIn: "root",
})
export class MetadataClientService {
    private metadataClient: RestClient;

    constructor(httpClient: HttpClient,
                envService: EnvironmentService,
                orgsService: OrgsService) {
        const baseUrl = envService.getConfig().metadataService;

        this.metadataClient = new RestClient(baseUrl, httpClient, orgsService);
    }

    public getTags(): Promise<TagValue[]> {
        return this.metadataClient.doGet("/tags");
    }

    public getTagsByIds(tagIds: uuid[]): Promise<TagValue[]> {
        return this.metadataClient.doGet("/tag/tagIds", { tagIds });
    }

    public createTag(tag: string): Promise<TagValue> {
        return this.metadataClient.doPost("/tag", { tag });
    }

    public deleteTag(tagId: uuid): Promise<TagValue> {
        const resource = `/tag/${ tagId }`;
        return this.metadataClient.doDelete(resource);
    }

    public getActivityLogs(resourceId: uuid): Promise<ActivityLog[]> {
        return this.metadataClient.doGet(`/activity-log/${ resourceId }`);
    }

    public addActivityLogNote(resourceId: uuid, activityLogId: uuid, noteText: string) {
        const request: ActivityLogNoteRequest = { noteText: noteText };
        return this.metadataClient.doPost(`/activity-log/${ resourceId }/notes`,
            request,
            activityLogId && { activityLogId });
    }

    public editActivityLogNote(resourceId: uuid, noteId: uuid, noteText: string) {
        const request: ActivityLogNoteRequest = { noteText: noteText };
        return this.metadataClient.doPost(`/activity-log/${ resourceId }/notes/${ noteId }`, request);
    }

    /**
     * Escalation
     */

    public getEscalationSchedule(): Promise<EscalationSchedule> {
        return this.metadataClient.doGet(`/escalation/schedule`);
    }

    public saveEscalationSchedule(schedule: EscalationSchedule): Promise<EscalationSchedule> {
        return this.metadataClient.doPut(`/escalation/schedule`, schedule);
    }

    public getEscalationSteps(type: EscalationStepType): Promise<EscalationStep[]> {
        return this.metadataClient.doGet(`/escalation/steps`, { type });
    }

    public createEscalationStep(step: EscalationStep): Promise<EscalationStep> {
        return this.metadataClient.doPost(`/escalation/step`, step);
    }

    public updateEscalationStep(step: EscalationStep): Promise<EscalationStep> {
        return this.metadataClient.doPut(`/escalation/step/${ step.id }`, step);
    }

    public deleteEscalationStep(stepId: uuid): Promise<void> {
        return this.metadataClient.doDelete(`/escalation/step/${ stepId }`);
    }

    public reorderEscalationSteps(type: EscalationStepType, stepIds: uuid[]): Promise<void> {
        return this.metadataClient.doPut(`/escalation/reorder-steps`, stepIds, { type });
    }

    // Info notes
    public getInfoNotes(): Promise<InfoNote[]> {
        return this.metadataClient.doGet(`/info-panel/notes`);
    }

    public addInfoNote(infoNote: InfoNote): Promise<InfoNote> {
        return this.metadataClient.doPost(`/info-panel/note`, infoNote);
    }

    public editInfoNote(noteId: uuid, infoNote: InfoNote): Promise<InfoNote> {
        return this.metadataClient.doPut(`/info-panel/note/${ noteId }`, infoNote);
    }

    public deleteInfoNote(noteId: uuid): Promise<InfoNote> {
        return this.metadataClient.doDelete(`/info-panel/note/${ noteId }`);
    }

    public getInfoPanelOrgMetadata(): Promise<InfoPanelOrgMetadata> {
        return this.metadataClient.doGet(`/info-panel/org-metadata`);
    }

    public editInfoPanelOrgMetadata(infoPanelOrgMetadata: InfoPanelOrgMetadata): Promise<InfoPanelOrgMetadata> {
        return this.metadataClient.doPut(`/info-panel/org-metadata`, infoPanelOrgMetadata);
    }
}
