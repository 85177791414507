import { Injectable } from "@angular/core";
import { environment } from "@app2/../environments/environment";

/**
 * This class is a wrapper around the environment constants to allow easy mocking in tests.
 * Use this instead of directly accessing the environment files.
 */
@Injectable({
    providedIn: "root",
})
export class EnvironmentService {
    getConfig(): Record<string, any> {
        return environment;
    }
}
