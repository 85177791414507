import {AfterViewInit, Directive} from "@angular/core";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {TAB} from "@angular/cdk/keycodes";

@Directive({ selector: "[tab-selected]" })
export class TabSelectedDirective implements AfterViewInit {
    constructor(private auto: MatAutocomplete) {}
    ngAfterViewInit() {
        this.auto._keyManager.onKeydown = (event: KeyboardEvent) => {
            switch (event.keyCode) {
                case TAB:
                    if (this.auto.isOpen) {
                        const option = this.auto.options.toArray()[0];
                        if (option) {
                            option.select();
                            event.preventDefault();
                            return;
                        }
                    }
                    this.auto._keyManager.tabOut.next();
                    break;
            }
        };
    }
}
