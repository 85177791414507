import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from "@angular/router";
import { Observable, ReplaySubject } from "rxjs";

export interface DsNavigationExtras {
    openInNewTab?: boolean;
}

/**
 * Helper service around Angular's Router to correctly handle our custom params: "orgId" and "feature", which need to
 * subsist even when navigating without specifying them. This needs some custom management as it's not something
 * the router was thought for.
 *
 * We should always use this service instead of directly accessing Angular's `Router`.
 */
@Injectable({
    providedIn: "root",
})
export class RouterService {

    // Once we are in Angular2 completely, we can trust ActivatedRoute's url
    private urlSubject = new ReplaySubject<string>(1);

    constructor(private readonly router: Router,
                private readonly activatedRoute: ActivatedRoute) {
        router.events
            .subscribe(e => {
                if (e instanceof NavigationEnd) {
                    this.urlSubject.next(e.url);
                }
            });
    }

    /**
     * Similar to Angular's Router.navigate but respecting our `orgId` and `feature` queryParams.
     */
    public navigate<T>(commands: any[], queryParams: Partial<T> = {}, extras: NavigationExtras = {},
                       dsExtras: DsNavigationExtras = {}): void {
        const ngExtras = {
            queryParams: this.addCustomParams(queryParams),
            ...extras,
        };
        if (dsExtras.openInNewTab) {
            const urlTree = this.router.createUrlTree(commands, ngExtras);
            const url = this.router.serializeUrl(urlTree);
            window.open(url, "_blank");
        } else {
            this.router.navigate(commands, ngExtras);
        }
    }

    /**
     * Replaces the current query params with the new ones.
     * For example, invoking `mergeParams({filter: "xyz", query: "def"})` in this url:
     *     /assets?page=1&query=abc
     * will update the url to:
     *     /assets?query=def&filter=xyz
     */
    public replaceParams<T>(queryParams: Partial<T>, extras: NavigationExtras = {}): void {
        this.router.navigate([], {
            queryParams: this.addCustomParams(queryParams),
            ...extras,
        });
    }

    /**
     * Merges the current query params with the new ones.
     * For example, invoking `mergeParams({filter: "xyz", query: "def"})` in this url:
     *     /assets?page=1&query=abc
     * will update the url to:
     *     /assets?page=1&query=def&filter=xyz
     */
    public mergeParams<T>(queryParams: Partial<T>, extras: NavigationExtras = {}): void {
        this.router.navigate([], {
            queryParams: this.addCustomParams(queryParams),
            queryParamsHandling: "merge",
            ...extras,
        });
    }

    public getQueryParams$(): Observable<Params> {
        return this.activatedRoute.queryParams;
    }

    public getUrl$(): Observable<string> {
        return this.urlSubject.asObservable();
    }

    private addCustomParams(queryParams: Params): Params {
        // These 2 queryParams should always be kept when navigating around, unless overridden in the `queryParams`
        // parameter
        const { orgId, feature } = this.activatedRoute.snapshot.queryParams;

        // Avoid adding `undefined` fields to the query params
        const newQueryParams: Params = { ...queryParams };
        if (orgId) {
            newQueryParams.orgId = orgId;
        }
        if (feature) {
            newQueryParams.feature = feature;
        }

        return newQueryParams;
    }
}
