import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UserService } from "@app2/account/user.service";
import { toStateParams } from "@app2/search/query";
import { encodeQueryString } from "@app2/search/utils/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import * as _ from "underscore";

const searchParamNames = [
    "query",
    "begin",
    "end",
    "intervalType",
    "primaryField",
    "secondaryField",
    "filterField",
    "filterListId",
    "excludeField",
    "excludeListId",
    "pageSize",
    "pageNumber",
    "lz"
];

@UntilDestroy()
@Component({
    selector: "search-link",
    template: `
        <a dsRouterLink="/search"
           *ngIf="hasEventViewPerm"
           [queryParams]="searchParams"
           [ngClass]="{'link-text': linkClass}"
           [target]="newTab ? '_blank' : ''"
           [attr.target]="newTab ? '_blank' : ''"
           test-class="search-link"
           rel="noopener noreferrer">
            <span *ngIf="!defaultText">
                 <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
            </span>
            <span *ngIf="defaultText">
                <span> {{ readableQuery }} </span>
            </span>
        </a>
        <span *ngIf="!hasEventViewPerm">
            <span *ngIf="!defaultText">
                <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
            </span>
            <span *ngIf="defaultText">
                <span> {{ readableQuery }} </span>
            </span>
        </span>

        <ng-template #tempOutlet>
            <ng-content></ng-content>
        </ng-template>
    `,
})
export class SearchLinkComponent implements OnChanges, OnInit {
    //eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("queryString") query: string;
    @Input() begin: string;
    @Input() end: string;
    @Input() intervalType: string;
    @Input() primaryField: string;
    @Input() secondaryField: string;
    @Input() filterField: string;
    @Input() filterListId: uuid;
    @Input() excludeField: string;
    @Input() excludeListId: uuid;
    @Input() pageSize: number;
    @Input() pageNumber: number;
    @Input() newTab: boolean;
    @Input() orgId: uuid;
    @Input() linkClass: boolean = true;
    @Input() defaultText: boolean = false;

    searchParams: Record<string, any>;
    hasEventViewPerm: boolean;
    readableQuery: string;
    lz: string = "true";

    constructor(private userService: UserService) {
    }

    ngOnInit() {
        this.userService.hasPermission$("EVENT_VIEW")
            .pipe(untilDestroyed(this))
            .subscribe(value => this.hasEventViewPerm = value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.readableQuery = changes.query.currentValue;
            this.query = encodeQueryString(this.query);
        }

        const presentParams = _.chain(this)
            .pick(searchParamNames)
            .pairs()
            .filter(pair => !!pair[1])
            .object()
            .value();
        this.searchParams = toStateParams(presentParams);
        if (this.orgId) {
            this.searchParams.orgId = this.orgId;
        }
    }
}
