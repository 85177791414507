import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

export const DEFAULT_NOTIFICATION_TIMEOUT_MS = 10 * 1000;

//DEPRECATED: Use NotificationsService instead
@Component({
    selector: "notification",
    template: `
        <div class='alert-box'
             [ngClass]="additionalClasses"
             (click)='onClick.emit()'
             *ngIf="show">
            <div [ngSwitch]="type">
                <div *ngSwitchCase="'alert'">
                    <i class="fa fa-exclamation-circle alert fa-2x main-icon"></i>
                </div>
                <div *ngSwitchCase="'warning'">
                    <i class="fa fa-exclamation-triangle warning fa-2x main-icon"></i>
                </div>
                <div *ngSwitchCase="'info'">
                    <i class="fa fa-info-circle info fa-2x main-icon"></i>
                </div>
                <div *ngSwitchCase="'success'">
                    <i class="fa fa-check-circle success fa-2x main-icon"></i>
                </div>
            </div>
            <div class="flex-grow">
                <div class="flex-contents" test-id="notification-area">
                    <ng-content></ng-content>
                </div>
            </div>
            <div *ngIf="showCloseButton">
                <button class="link" (click)="onCloseClicked.emit()"><i class="fa secondary fa-times"></i></button>
            </div>
        </div>
    `})
export class NotificationComponent implements OnChanges {
    @Input() type: string = "";
    @Input() show: boolean = false;
    @Input() customTimeout: number;
    @Input() position: string = "";

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClick = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onShouldHide = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCloseClicked = new EventEmitter<void>();

    showCloseButton = false;
    additionalClasses: string[] = [];
    timeoutId: number|null = null;


    ngOnChanges(changes: SimpleChanges): void {
        this.showCloseButton = this.onCloseClicked.observers.length > 0;

        // the div should have it's type and position as classes,
        // as well as "click-target" if it does something on-click
        this.additionalClasses = [this.type, this.position];
        if (this.onClick.observers.length > 0) {
            this.additionalClasses.push("click-target");
        }

        if (changes.show) {
            if (changes.show.currentValue && this.onShouldHide.observers.length > 0) {
                this.clearTimeout();
                this.timeoutId = this.setTimeout(() => {
                    this.onShouldHide.emit();
                    this.timeoutId = null;
                }, this.customTimeout || DEFAULT_NOTIFICATION_TIMEOUT_MS);
            }
            // If it gets hidden from the parent, cancel the timeout (noop if timeout has already happened)
            if (!changes.show.currentValue) {
                this.clearTimeout();
            }
        }
    }

    // The main purpose of this method is to allow the Mock subclass to remove the timeout completely to avoid issues
    // with remaining timers in the tests.
    protected setTimeout(handler: Function, timeout: number) {
        return setTimeout(handler, timeout);
    }

    private clearTimeout() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = null;
    }
}
