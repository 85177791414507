import { inject, Injectable, ViewContainerRef } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { filter, take } from "rxjs/operators";
import { NavigationStart, Router } from "@angular/router";

interface ModalConfig {
    width: string;
    height: string;
    viewContainerRef: ViewContainerRef;
    panelClass: string | null;
    minWidth: string | null;
    disableClose: boolean;
    autoFocus: boolean;
}

export interface ModalRef<T> {
    matDialogRef: MatDialogRef<T>;
    componentInstance: T;
    close();
    afterClosed(): Observable<any>;
    afterClosedWithResult(): Observable<any>;
}


@Injectable({
    providedIn: "root",
})
export class ModalService {

    private readonly router: Router = inject(Router);

    constructor(private readonly matDialog: MatDialog) {
        // When a user clicks on a link in a modal that causes a navigation to another page, we need to close the modal
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.matDialog.closeAll();
            }
        });
    }

    public open<T>(component: ComponentType<T>, data: object = {}, extraConfig: Partial<ModalConfig> = {}): ModalRef<T> {
        const defaultConfig: MatDialogConfig = {
            width: "50%",
            minWidth: "37.5rem",
            closeOnNavigation: true,
            autoFocus: false,
            disableClose: extraConfig.disableClose === undefined ? true : extraConfig.disableClose,
        };
        const dialogRef = this.matDialog.open(component, {
            ...defaultConfig,
            ...extraConfig,
            data,
        });
        //This, along with the disableClose: true, is what makes modals escapable by Escape key
        // but not by clicking outside the modal in the backdrop.
        dialogRef.keydownEvents().subscribe(event => {
            if (event.code === "Escape" && extraConfig.disableClose === undefined) {
                dialogRef.close();
            }
        });

        return {
            /**
             * Use this in case you need finer control over the dialog's behavior
             */
            matDialogRef: dialogRef,

            /**
             * Just for convenience
             */
            componentInstance: dialogRef.componentInstance,
            close(): void {
                dialogRef.close();
            },

            /**
             * Returns an observable we can subscribe to to know when the dialog was closed with or without some result.
             * This observable is automatically unsubscribed, so we don't need to worry about that in our code.
             */
            afterClosed(): Observable<any> {
                return dialogRef.afterClosed()
                    .pipe(take(1));
            },

            /**
             * Executes a callback when the dialog is closed with a result.
             * This observable is automatically unsubscribed, so we don't need to worry about that in our code.
             */
            afterClosedWithResult(): Observable<any> {
                return this.afterClosed()
                    .pipe(filter(result => result !== undefined));
            },
        };
    }
}
