import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "ds-page-section",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="page-section">
            <h3 *ngIf="titleKey"
                class="section-header" test-id="section-title">
                <span>
                    {{ titleKey | localize }}
                    <i *ngIf="tooltipKey"
                       class="far fa-info-circle"
                       [matTooltip]="tooltipKey | localize"></i>
                </span>
                <ng-content select="ds-actions"></ng-content>
            </h3>
            <section>
                <div *dsLoading="loading">
                    <ng-content></ng-content>
                </div>
            </section>
        </div>
    `,
    styles: [`
        @import "/src/styles/colors";
        @import "/src/styles/settings";

        .page-section {
            background: white;
            margin-bottom: 12px;
            padding: 16px 24px 24px;
        }

        .section-header {
            display: flex;
            margin-bottom: 16px;
            border-bottom: $border-rule;
            line-height: 1.5rem;
            font-size: 0.75rem;
            font-weight: $font-weight-demibold;
            color: $sub-header;
            text-transform: uppercase;
            justify-content: space-between;
        }
    `]
})
export class DsPageSectionComponent {
    @Input() titleKey: string;
    @Input() tooltipKey: string;
    @Input() loading: boolean;
}
