
export type AssetStatus = "active" | "deleted";
export type TrackedStatus = "both" | "tracked" | "untracked";
export type Importance = "all" | "high" | "medium" | "low" | "none";
export type LastSeen = "all" | "last-30-days" | "more-than-30-days";

export type AssetImportance = { value: Importance, key: string, symbol: string };

export const importanceOptions: AssetImportance[] = [
    { value: "all", key: "ALL", symbol: "" },
    { value: "high", key: "HIGH", symbol: "•••" },
    { value: "medium", key: "MEDIUM", symbol: "••" },
    { value: "low", key: "LOW", symbol: "•" },
    { value: "none", key: "NONE", symbol: "" },
];

export const statusOptions: Array<{value: AssetStatus, key: string}> = [
    { value: "active", key: "ACTIVE" },
    { value: "deleted", key: "DELETED" },
];

export const trackedOptions: Array<{value: TrackedStatus, key: string}> = [
    { value: "both", key: "ALL" },
    { value: "tracked", key: "TRACKED" },
    { value: "untracked", key: "UNTRACKED" },
];

export const lastSeenOptions: Array<{value: LastSeen, key: string}> = [
    { value: "all", key: "ALL" },
    { value: "last-30-days", key: "LAST_30_DAYS" },
    { value: "more-than-30-days", key: "MORE_THAN_30_DAYS" },
];

export const defaultAssetLabels = [
    "Scanner",
    "Domain Controller",
    "Load Balancer",
    "Firewall",
    "Health Monitored",
];
