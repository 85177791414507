import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";
import { localization } from "@app2/shared/localization/localization";

export type ReportUserFriendlyError =
    CardinalityError
    | InvalidCardinalityForAggregateFieldError
    | InvalidWorkspaceError
    | ModifyingOnDemandReportSeriesError;

interface CardinalityError {
    key: "CARDINALITY_MAX_LIMIT_BREACHED";
    limit: number;
}

interface InvalidWorkspaceError {
    key: "INVALID_WORKSPACE_ID";
}

interface InvalidCardinalityForAggregateFieldError {
    key: "INVALID_CARDINALITY_FOR_AGG_FIELD";
}

interface ModifyingOnDemandReportSeriesError {
    key: "CANNOT_MODIFY_ON_DEMAND_REPORT_SERIES";
}

export function getMessageForReportUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "INVALID_CARDINALITY_FOR_AGG_FIELD":
            return localization.getString("SERIES_AGGREGATE_FIELD_ERROR");
        case "CANNOT_MODIFY_ON_DEMAND_REPORT_SERIES":
            return localization.getString("CANNOT_MODIFY_ON_DEMAND_REPORT_SERIES");
        case "CARDINALITY_MAX_LIMIT_BREACHED":
            return localization.getString("CARDINALITY_MAX_LIMIT_BREACHED");
        case "INVALID_WORKSPACE_ID":
            return localization.getString("NO_PERMISSIONS_FOR_WORKSPACE");
    }
}
