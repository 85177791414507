import { Injectable } from "@angular/core";
import Rollbar from "rollbar";
import { EnvironmentService } from "@app2/shared/services/environment.service";
import { log } from "@app2/logger";

@Injectable({
    providedIn: "root",
})
export class ErrorTrackingService {
    private rollbar: Rollbar;

    constructor(envService: EnvironmentService) {
        const accessToken = envService.getConfig().rollbarToken;
        if (!accessToken) {
            return;
        }

        this.rollbar = new Rollbar({
            accessToken,
            captureUncaught: true,
            captureUnhandledRejections: true,
            environment: envService.getConfig().envName,
            codeVersion: envService.getConfig().appVersion,
            itemsPerMinute: 10,
            filterTelemetry: e => {
                // Don't send successful HTTP requests to Rollbar
                if (e.type === "network") {
                    const statusCode = e.body["status_code"] as number;
                    return statusCode >= 200 && statusCode <= 399;
                }
                return false;
            },
        });
    }

    public setUser(id: uuid, email: string): void {
        this.rollbar?.configure({
            payload: {
                person: { id, username: email, email }
            }
        });
    }

    public clearUser(): void {
        this.rollbar?.configure({
            payload: {
                person: null,
            }
        });
    }

    public sendError(message: string, cause?: any, logToConsole = true): void {
        if (logToConsole) {
            log.error(message, cause);
        }
        this.rollbar?.error(message, { error: cause });
    }
}
