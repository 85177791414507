import { Component, OnInit } from "@angular/core";
import { NotificationsService } from "@app2/shared/services/notifications.service";

@Component({
    selector: "ad-detector",
    template: `
        <div class="ad-block" aria-hidden="true"></div>
    `,
})
export class AdDetectorComponent implements OnInit {
    constructor(private notificationService: NotificationsService) {
    }

    ngOnInit() {
        let adBoxEl = document.querySelector(".ad-block");
        let hasAdBlock = window.getComputedStyle(adBoxEl).display === "none";
        if (hasAdBlock) {
            this.notificationService.showOther("AD_BLOCK_NOTIFICATION", "fa-road-barrier alert");
        }
    }
}
