import { Component } from "@angular/core";
import { ActiveNavSectionService } from "@app2/shared/services/active-nav-section.service";

@Component({
    selector: "navigation-top-bar-reports",
    template: `
        <nav class="top-bar new-tab-nav" role="navigation">
            <section class="top-bar-section nav-section-main">
                <h1 class="nav-top-item-main">{{ "REPORTS" | localize }}</h1>
                <ul class="left">
                    <li [class.active]="navSectionService.isReportsListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports">{{ "REPORT_DOWNLOADS" | localize }}</a></li>
                    <li [class.active]="navSectionService.isReportTemplatesListActive$ | async"
                        *dsPermissionsRequired="'REPORT_VIEW'">
                        <a dsRouterLink="/reports/templates/list">{{ "TEMPLATES" | localize }}</a></li>
                    <li [class.active]="navSectionService.isChartsListActive$ | async"
                        *dsPermissionsRequired="'CHART_VIEW'">
                        <a dsRouterLink="/reports/charts/list">{{ "CHARTS" | localize }}</a></li>
                </ul>
            </section>
        </nav>
    `,
})
export class NavigationTopBarReportsComponent {
    constructor(public navSectionService: ActiveNavSectionService) {
    }
}
