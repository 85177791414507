import { UserFriendlyError } from "@app2/util/errors/user-friendly-errors";
import { localization } from "@app2/shared/localization/localization";

export type ThreatUserFriendlyError =
    InvalidSeverityError
    | InvalidDomainError
    | InvalidUrlError
    | InvalidHexError
    | InvalidGroupUploadKeyError
    | InvalidThreatSignatureError
    | InvalidRatingError
    | InvalidSkuError
    | IpAddressMissingBeginOrEndTimeError
    | ThreatGroupUploadTooLargeError
    | UnsupportedThreatTypeError;

interface InvalidSeverityError {
    key: "INVALID_SEVERITY";
    validSeverities: string;
}

interface InvalidDomainError {
    key: "INVALID_DOMAIN";
    validDomains: string;
}

interface IpAddressMissingBeginOrEndTimeError {
    key: "IP_ADDRESS_TYPE_MISSING_START_OR_END_TIME";
}

interface UnsupportedThreatTypeError {
    key: "UNSUPPORTED_THREAT_TYPE";
    threatType: "COUNTRY" | "DOMAIN" | "EMAIL_ADDRESS" | "IP_ADDRESS" | "POSTAL_CODE" | "SIGNATURE";
}

interface InvalidUrlError {
    key: "INVALID_URL";
    url?: string;
}

interface InvalidHexError {
    key: "INVALID_HEX";
}

interface InvalidGroupUploadKeyError {
    key: "INVALID_GROUP_UPLOAD_KEY";
}

interface InvalidThreatSignatureError {
    key: "INVALID_THREAT_SIGNATURE";
    signature: string;
}

interface InvalidRatingError {
    key: "INVALID_RATING";
    validRatings: string;
}

interface InvalidSkuError {
    key: "INVALID_SKU";
    sku: string;
}

interface ThreatGroupUploadTooLargeError {
    key: "THREAT_GROUP_UPLOAD_TOO_LARGE";

}

export function getMessageForThreatUserFriendlyError(error: UserFriendlyError): string {
    switch (error.key) {
        case "INVALID_THREAT_SIGNATURE":
        case "INVALID_HEX":
            return localization.getString("INVALID_THREAT_SIGNATURE_HEX");
        case "INVALID_DOMAIN":
            return localization.getString("INVALID_THREAT_SOURCE_DOMAIN");
        case "INVALID_URL":
            return localization.getString("INVALID_THREAT_SOURCE_URL");
        case "INVALID_GROUP_UPLOAD_KEY":
            return localization.getString("INVALID_THREAT_GROUP_UPLOAD_KEY");
        case "INVALID_RATING":
            return localization.getString("INVALID_THREAT_RATING", error.validRatings);
        case "IP_ADDRESS_TYPE_MISSING_START_OR_END_TIME":
            return localization.getString("IP_ADDRESS_THREAT_MISSING_START_OR_END_TIME");
        case "THREAT_GROUP_UPLOAD_TOO_LARGE":
            return localization.getString("THREAT_GROUP_UPLOAD_TOO_LARGE");
        case "UNSUPPORTED_THREAT_TYPE":
            return localization.getString("UNSUPPORTED_THREAT_TYPE", error.threatType);
        case "INVALID_SEVERITY":
            return localization.getString("INVALID_SEVERITY", error.validSeverities);
    }
}