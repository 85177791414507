import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule, Routes } from "@angular/router";
import { U2fAuthenticationPageComponent } from "@app2/account/u2f-authentication-page.component";
import { Lost2faDeviceModalComponent } from "@app2/login/lost-2fa-device-modal.component";
import { SamlRedirectPageComponent } from "@app2/login/saml-redirect-page.component";
import { OrgSelectorPageComponent } from "@app2/login/org-selector-page.component";
import { ResetPasswordPageComponent } from "@app2/login/reset-password-page.component";
import { QrCanvasComponent } from "@app2/login/setup-totp-page/qr-canvas.component";
import { SetupTotpPageComponent } from "@app2/login/setup-totp-page/setup-totp-page.component";
import { SetupTotpSidebarComponent } from "@app2/login/setup-totp-page/setup-totp-sidebar.component";
import { canAccess2faLoginPagesGuard } from "@app2/navigation/can-access-2fa-login-pages.guard";
import { isLoggedInGuard } from "@app2/navigation/is-logged-in.guard";
import { SharedModule } from "@app2/shared/shared.module";
import { LoginPageComponent } from "@app2/login/login-page.component";
import { ForgotPasswordPageComponent } from "@app2/login/forgot-password-page.component";
import { TotpAuthenticationPageComponent } from "@app2/login/totp-authentication-page.component";
import { notLoggedInGuard } from "@app2/navigation/not-logged-in.guard";

const routes: Routes = [
    {
        path: "login",
        component: LoginPageComponent,
        canActivate: [notLoggedInGuard],
    },
    // Page where the "Forgot your password?" flow starts
    {
        path: "forgot-password",
        component: ForgotPasswordPageComponent,
    },
    // Page linked in the "Welcome" email to new users
    {
        path: "new-user",
        component: ResetPasswordPageComponent,
        data: {
            isNewUser: true,
        },
    },
    // Page linked in the "Reset Password" email sent to users
    {
        path: "reset-password",
        component: ResetPasswordPageComponent,
        data: {
            isNewUser: false,
        },
    },
    {
        path: "select-org",
        component: OrgSelectorPageComponent,
        canActivate: [isLoggedInGuard],
    },
    // Page that allows setting up a new TOTP device before logging in (where the QR code is scanned)
    {
        path: "setup-totp-login",
        component: SetupTotpPageComponent,
        canActivate: [canAccess2faLoginPagesGuard],
    },
    // Page that allows inputting a TOTP code to login
    {
        path: "totp-authentication",
        component: TotpAuthenticationPageComponent,
        canActivate: [canAccess2faLoginPagesGuard],
    },
    // Page that allows pressing on an U2F to login
    {
        path: "u2f-authentication",
        component: U2fAuthenticationPageComponent,
        canActivate: [canAccess2faLoginPagesGuard],
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatTabsModule,

        RouterModule.forChild(routes),
    ],
    declarations: [
        // Top-level pages
        ForgotPasswordPageComponent,
        LoginPageComponent,
        OrgSelectorPageComponent,
        ResetPasswordPageComponent,
        SetupTotpPageComponent,
        TotpAuthenticationPageComponent,
        U2fAuthenticationPageComponent,

        // Others
        SamlRedirectPageComponent,
        QrCanvasComponent,
        SetupTotpSidebarComponent,

        // Modals
        Lost2faDeviceModalComponent,
    ],
})
export class LoginModule {
    constructor() {
    }
}
