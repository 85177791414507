import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationsService } from "@app2/shared/services/notifications.service";
import { RouterService } from "@app2/shared/services/router.service";
import { UserClientService } from "@app2/clients/user-client.service";
import { WindowService } from "@app2/shared/services/window.service";
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from "@angular/forms";

const REDIRECT_TIMEOUT_MS = 3000;

@Component({
    selector: "reset-password-page",
    template: `
        <div class="row">
            <div *dsLoading="redirecting"
                 class="padded sheet small-16 medium-5 medium-centered columns clearfix">
                <form #resetPasswordForm id="new-user" method="post" name="resetPasswordForm" role="form"
                      [formGroup]="passwordForm">
                    <fieldset>
                        <legend class="center-legend">
                            {{ (isNewUser ? "WELCOME_LEGEND" : "PASSWORD_RESET") | localize }}</legend>
                        <div class="extra-margin-bottom">{{ "SELECT_NEW_PASSWORD" | localize }}</div>
                        <input autofocus
                               class="extra-margin-bottom"
                               type="password" id="newPassword" autocomplete="new-password" name="newPassword"
                               formControlName="newPassword"
                               [placeholder]="'PASSWORD' | localize"
                               test-id="new-password"/>
                        <div *ngIf="getErrorKey('newPassword'); let errorKey"
                             class="form-error-text" test-id="new-password-error">
                            {{ errorKey | localize}}
                        </div>

                        <input required
                               class="extra-margin-bottom"
                               type="password" id="confirmPassword" autocomplete="new-password" name="confirmPassword"
                               formControlName="confirmPassword"
                               [placeholder]="'CONFIRM_PASSWORD' | localize"
                               test-id="confirm-password"/>
                        <div *ngIf="getErrorKey('confirmPassword'); let errorKey"
                             class="form-error-text" test-id="confirm-password-error">
                            {{ errorKey | localize}}
                        </div>

                        <button type="submit"
                                class="button right"
                                (click)="resetPassword()"
                                [disabled]="passwordForm.invalid || processing">
                            <i *ngIf="processing" class="fa fa-spinner fa-spin"></i>
                            {{ (isNewUser ? "SET_PASSWORD" : "RESET_PASSWORD") | localize }}
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    `,
    styles: [`
        .extra-margin-bottom {
            margin-bottom: 8px;
        }

        .center-legend {
            margin: 0 auto;
        }
    `],
})
export class ResetPasswordPageComponent implements OnInit {

    passwordForm = this.fb.group({
        newPassword: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(8)]],
    }, { validators: [this.passwordsMatchValidator] });

    processing: boolean;
    redirecting: boolean;

    constructor(private fb: FormBuilder,
                private notificationsService: NotificationsService,
                private route: ActivatedRoute,
                private routerService: RouterService,
                private userClient: UserClientService,
                private windowService: WindowService) {
    }

    ngOnInit(): void {
        if (!this.route.snapshot.queryParams.id || !this.route.snapshot.queryParams.confirmation) {
            this.notificationsService.showError("PASSWORD_RESET_PARAMS_MISSING");
            this.redirecting = true;
            this.routerService.navigate(["/forgot-password"]);
        }
    }

    get isNewUser(): string {
        return this.route.snapshot.data.isNewUser;
    }

    getErrorKey(fieldName: string): string {
        const passwordField = this.passwordForm.get(fieldName);
        if (!passwordField.dirty) {
            return null;
        }
        if (passwordField.errors?.required) {
            return "ERROR_REQUIRED";
        }
        if (fieldName === "confirmPassword" && this.passwordForm.errors?.passwordsMismatch) {
            return "PASSWORDS_MUST_MATCH";
        }
        if (passwordField.errors?.minlength) {
            return "PASSWORDS_MUST_VALIDATE_LENGTH";
        }
    }

    resetPassword(): void {
        const queryParams = this.route.snapshot.queryParams;
        const newPassword = this.passwordForm.get("newPassword").value;

        this.processing = true;
        this.userClient.resetPassword(queryParams.id, newPassword, queryParams.confirmation)
            .then(() => {
                this.notificationsService.showSuccess("PASSWORD_BEEN_RESET");
                this.redirecting = true;
                this.windowService.setTimeout(() => this.routerService.navigate(["/login"]), REDIRECT_TIMEOUT_MS);
            })
            .catch(error => {
                const errorKey = error?.error?.errorKey || "PASSWORD_CHANGE_FAILED";
                this.notificationsService.showError(errorKey);
                this.processing = false;
            });
    }

    private passwordsMatchValidator(formGroup: AbstractControl): ValidationErrors | null {
        const newPassword = formGroup.get("newPassword").value;
        const confirmPassword = formGroup.get("confirmPassword").value;
        return newPassword === confirmPassword ? null : { passwordsMismatch: true };
    }
}
